import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface SettingsClassLevelObject {
  color: string;
  classCategories: { id: string }[];
  id: string;
  name: string;
  badge: string | null;
}

export interface SettingsClassLevelListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  classLevel: Array<SettingsClassLevelObject>;
  errors: unknown;
}

export interface ClassLevelFilterInfo {
  name: string;
}

@Module
export default class SettingsClassLevelModule
  extends VuexModule
  implements SettingsClassLevelListInfo
{
  classLevel = [] as Array<SettingsClassLevelObject>;
  allClassLevel = [] as Array<SettingsClassLevelObject>;
  classLevelFilter = {} as ClassLevelFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get classLevel list
   * @returns Array<SettingsClassLevelObject>
   */
  get classLevelList(): Array<SettingsClassLevelObject> {
    return this.classLevel;
  }

  get allClassLevelList(): Array<SettingsClassLevelObject> {
    return this.allClassLevel;
  }

  /**
   * Get total items
   * @returns number
   */
  get classLevelListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_SETTINGS_CLASS_LEVEL](data) {
    this.classLevel = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_SETTINGS_CLASS_LEVEL](data) {
    this.allClassLevel = data.data;
  }

  @Mutation
  [Mutations.SET_SETTING_CLASS_LEVEL_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_SETTINGS_CLASS_LEVEL_LIST]() {
    this.classLevel = [] as Array<SettingsClassLevelObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SETTINGS_CLASS_LEVEL_FILTER](obj) {
    this.classLevelFilter.name = obj.name;
  }

  @Action
  [Actions.RESET_SETTINGS_CLASS_LEVEL_STORE]() {
    this.context.commit(Mutations.RESET_SETTINGS_CLASS_LEVEL_LIST);
  }

  @Action
  [Actions.UPDATE_SETTINGS_CLASS_LEVEL_FILTER](val) {
    this.context.commit(Mutations.SET_SETTINGS_CLASS_LEVEL_FILTER, val);
  }

  @Action
  [Actions.CREATE_SETTING_CLASS_LEVEL](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/settings/class-level', data)
      .then((data) => {
        this.context.commit(Mutations.RESET_SETTINGS_CLASS_LEVEL_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPLOAD_CLASS_LEVEL_ATTACHMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/settings/attachments/${id}`, data)
      .then(() => {
        this.context.commit(Mutations.RESET_GROOMERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_SETTING_CLASS_LEVEL](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/settings/class-level', id)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_CLASS_LEVEL_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SETTING_CLASS_LEVEL]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/class-level', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_CLASS_LEVEL_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SETTINGS_CLASS_LEVEL_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_SETTING_CLASS_LEVEL_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_SETTING_CLASS_LEVEL](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/class-level', id);
  }

  @Action
  [Actions.GET_SETTINGS_CLASS_LEVEL]() {
    const params = {
      page: this.page,
      name: this.classLevelFilter.name,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/settings/class-level', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SETTINGS_CLASS_LEVEL, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_SETTINGS_CLASS_LEVEL]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/settings/class-level/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_SETTINGS_CLASS_LEVEL, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
