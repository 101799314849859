
  import { defineComponent, onMounted, ref, computed, watch } from 'vue';
  import { Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import SearchField from '@/components/common/SearchField.vue';

  export default defineComponent({
    name: 'taxi-meter-creating',
    components: {
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedTrainer = ref<string>('');
      const trainerCostMatrix = ref([]);
      const costMatrixValue = ref({});

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };
      watch(
        () => selectedTrainer.value,
        async () => {
          if (!selectedTrainer.value) {
            return;
          }
          costMatrixValue.value = {};

          trainerCostMatrix.value = await store.dispatch(
            Actions.GET_COOST_MATRIX,
            selectedTrainer.value
          );
          trainerCostMatrix.value.find(
            (item: {
              durationId: string;
              classTypeId: string;
              horseTypeId: string;
              price: number;
            }) => {
              let key = `${item.durationId}_${item.horseTypeId}_${item.classTypeId}`;
              costMatrixValue.value[key] = item.price || undefined;
            }
          );
        }
      );

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('costMatrixUpdating'), [
          translate('assets'),
        ]);
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        delete values.trainerId;
        const payload = Object.keys(values)
          .map((key) => {
            const [durationId, horseTypeId, classTypeId] = key.split('_');
            const price = values[key];

            // Check if price is defined before including it in the payload
            if (price !== undefined) {
              return {
                durationId,
                horseTypeId,
                classTypeId,
                price: Number(price),
              };
            } else {
              return null;
            }
          })
          .filter((entry) => entry !== null);

        const data = {
          trainerCostMatrix: payload,
        };

        await store.dispatch(Actions.UPDATE_TRAINER, {
          data: data,
          id: selectedTrainer.value,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_COST_MATRIX'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };
      const getCostMatrixValue = (durationId, horseTypeId, classTypeId) => {
        return (
          costMatrixValue[`${durationId}_${horseTypeId}_${classTypeId}`] ||
          undefined
        );
      };

      await store.dispatch(Actions.GET_ALL_TRAINERS, {});

      const trainers = computed(() => store.getters.allTrainersList);

      const lookups = await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.HORSE_TYPE,
        LookupNameIds.DURATION,
      ]);

      const classes = lookups.filter(
        (i) => i.lookupNameId === LookupNameIds.CLASS_TYPE
      );
      console.log(classes);

      const horses = lookups.filter(
        (i) => i.lookupNameId === LookupNameIds.HORSE_TYPE
      );

      const durations = lookups.filter(
        (i) => i.lookupNameId === LookupNameIds.DURATION
      );

      const horseDorationsArr = ref<any>([]);

      horses.forEach((horse) => {
        durations.forEach((duration) => {
          if (duration.slug != '15' && duration.slug != '60') {
            return horseDorationsArr.value.push({
              type: horse,
              duration: duration,
            });
          }
        });
      });

      return {
        trainers,
        onSubmitCreate,
        classes,
        horseDorationsArr,
        horses,
        submitButton,
        selectedTrainer,
        trainerCostMatrix,
        translate,
        shipmentData: [],
        goBack,
        can,
        getCostMatrixValue,
        costMatrixValue,
      };
    },
  });
