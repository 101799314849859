
  import { computed, defineComponent, ref } from 'vue';
  import { ErrorMessage, Field, Form, useForm } from 'vee-validate';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n/index';
  import store from '@/store/';
  import Swal from 'sweetalert2/dist/sweetalert2.min.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'sign-in',
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { t, te, locale } = useI18n();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const pathRoute = ref<string>(route.path);
      const email = ref<string>('');
      const response = ref();
      const password = ref('');
      const passwordVisible = ref(false);
      const conrifmPasswordVisible = ref(false);
      const { resetForm } = useForm();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      //Create form validation object
      const loginSchema = Yup.object().shape({
        password: Yup.string()
          .required(() => translate('PASSWORD_IS_REQUIRED_FIELD'))
          .min(6, () =>
            translate('Password must be at least 6 characters long.')
          )
          .max(20, () =>
            translate('Password must be at max 20 characters long.')
          )
          .oneOf([Yup.ref('confirmPassword'), null], () =>
            translate('PASSWORDS_DO_NOT_MATCH')
          )
          .label('Confirm Password'),
        confirmPassword: Yup.string()
          .required(() => translate('CONFIRM_PASSWORD_IS_REQUIRED_FIELD'))
          .oneOf([Yup.ref('password'), null], () =>
            translate('PASSWORDS_DO_NOT_MATCH')
          )
          .label('Confirm Password'),
      });

      //Form submit function
      const onSubmitReset = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
        submitButton.value!.disabled = true;
          // Activate indicator
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.id = route.params.id;
        await store.dispatch(Actions.RESET_PASSWORD, values);

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('PASSWORD_RESET_SUCCESSFULLY'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('continue'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-primary',
            },
          }).then(() => {
            resetForm();
          });
        }
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
      submitButton.value!.disabled = false;
      };

      return {
        onSubmitReset,
        loginSchema,
        password,
        email,
        pathRoute,
        submitButton,
        translate,
        passwordVisible,
        conrifmPasswordVisible,
      };
    },
  });
