import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }
const _hoisted_6 = { class: "card-body pt-0" }
const _hoisted_7 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_8 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_9 = { class: "svg-icon svg-icon-3" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_cancellationFilter = _resolveComponent("cancellationFilter")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'settings'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              disabled: _ctx.disabledFilterButton,
              type: "button",
              class: "btn btn-flex btn-light btn-active-primary fw-bolder",
              "data-kt-menu-trigger": "click",
              "data-kt-menu-placement": "bottom-start",
              "data-kt-menu-flip": "top-end"
            }, [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen031.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate('filter')), 1)
            ], 8, _hoisted_4),
            _createVNode(_component_cancellationFilter, {
              onFilterSelected: _ctx.searchItems,
              onReset: _ctx.onReset
            }, null, 8, ["onFilterSelected", "onReset"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            loading: _ctx.loading,
            "rows-per-page": 10,
            total: _ctx.totalItems,
            "enable-items-per-page-dropdown": false,
            onCurrentChange: _ctx.pageChanged
          }, {
            "cell-customer": _withCtx(({ row: cancellation }) => [
              _createTextVNode(_toDisplayString(cancellation.customer.name), 1)
            ]),
            "cell-startDate": _withCtx(({ row: cancellation }) => [
              _createTextVNode(_toDisplayString(_ctx.convertDateDDMMYYYY(cancellation.lesson.startDate)), 1)
            ]),
            "cell-time": _withCtx(({ row: cancellation }) => [
              _createTextVNode(_toDisplayString(cancellation.lesson.time), 1)
            ]),
            "cell-status": _withCtx(({ row: cancellation }) => [
              (cancellation.status)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.translate('Done')), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.translate('inactive')), 1))
            ]),
            "cell-actions": _withCtx(({ row: cancellation }) => [
              (_ctx.can('remove', 'settings'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: `/manage/operations/lessons/lessons-listing?lessonId=${cancellation.lessonId}`,
                    class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_9, [
                        _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr036.svg" })
                      ])
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true),
              (_ctx.can('remove', 'settings'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: "#",
                    class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                    onClick: _withModifiers(($event: any) => (_ctx.deleteCancellation(cancellation)), ["prevent"])
                  }, [
                    _createElementVNode("span", _hoisted_11, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen027.svg" })
                    ])
                  ], 8, _hoisted_10))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["table-data", "table-header", "loading", "total", "onCurrentChange"])
        ])
      ]))
    : _createCommentVNode("", true)
}