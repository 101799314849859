
  import { defineComponent, ref, watch, computed } from 'vue';
  import Multiselect from '@vueform/multiselect';

  export default defineComponent({
    components: {
      Multiselect,
    },
    props: {
      options: {
        type: Array,
        required: true,
      },
      modelValue: {
        type: [String, Number],
        required: true,
      },
      mode: {
        type: String,
        required: false,
        default: 'single',
      },
      label: {
        type: String,
        default: 'Select item',
      },
      className: {
        type: String,
        default: '',
      },
      trackBy: {
        type: String,
        default: 'name',
      },
      hiddenItem: {
        type: Array,
        default: () => [],
      },
      haveNone: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const selectedValue = ref(props.modelValue);
      const arrOptions = computed(() => {
        const options = Array.isArray(props.options) ? props.options : [];
        const hiddenItem = props.hiddenItem || [];

        const filteredOptions = options.filter(
          (item) => !hiddenItem.includes(item.id)
        );

        if (!filteredOptions.some((item) => item.id === selectedValue.value)) {
          const selectedOption = options.find(
            (item) => item.id === selectedValue.value
          );
          if (selectedOption) {
            filteredOptions.push(selectedOption);
          }
        }
        if (props.haveNone) {
          return [{ id: null, [props.trackBy]: 'None' }, ...filteredOptions];
        } else {
          return filteredOptions;
        }
      });

      watch(selectedValue, (newValue) => {
        emit('update:modelValue', newValue);
      });

      watch(
        () => props.modelValue,
        (newValue) => {
          selectedValue.value = newValue;
        }
      );

      return {
        selectedValue,
        arrOptions,
      };
    },
  });
