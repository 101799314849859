
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { HorseType } from '@/store/modules/operations/HorsesModule';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import Multiselect from '@vueform/multiselect';
  import Attachment from '@/components/common/Attachments.vue';
  import SearchField from '@/components/common/SearchField.vue';

  export default defineComponent({
    name: 'horse-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
      Attachment,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const gender = ref<number | string>(0);
      const status = ref<number | string>(0);
      const passportType = ref<number | string>(0);
      const nationalityId = ref<number | string>(0);
      const horseCategory = ref<number | string>(0);
      const horseColor = ref<number | string>(0);
      const horseBreed = ref<number | string>(0);
      const horseType = ref<number | string>(0);
      const horseLocation = ref<number | string>(0);
      const docType = ref<number | string>(0);
      const horseId = ref();
      const dateOfBirth = ref();
      const joiningDate = ref();

      const customerId = ref(0);
      const ridersId = ref([]);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('horseLocationCreating'), [
          translate('settings'),
        ]);
      });
      const files = ref<{ file: File }[]>([]);
      const previewFiles = ref<string[]>([]);

      const previewImage = (event) => {
        const selectedFiles = event.target.files;
        for (let i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i];
          files.value.push({ file });
          const fileURL = URL.createObjectURL(file);
          previewFiles.value.push(fileURL);
        }
      };

      const removeImage = (index: number) => {
        files.value.splice(index, 1);
        previewFiles.value.splice(index, 1);
      };

      const validationSchema = Yup.object().shape({
        nameAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Name Ar'),
        nameEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('Name En'),
        genderId: Yup.string()
          .notOneOf(['0'], () => translate('GENDER_IS_REQUIRED_FIELD'))
          .required(() => translate('GENDER_IS_REQUIRED_FIELD'))
          .label('Gender'),
        statusId: Yup.string()
          .notOneOf(['0'], () => translate('STATUS_IS_REQUIRED_FIELD'))
          .required(() => translate('STATUS_IS_REQUIRED_FIELD'))
          .label('Status'),
        colorId: Yup.string()
          .notOneOf(['0'], () => translate('COLOR_IS_REQUIRED_FIELD'))
          .required(() => translate('COLOR_IS_REQUIRED_FIELD'))
          .label('Color'),
        categoryId: Yup.string()
          .notOneOf(['0'], () => translate('CATEGORY_IS_REQUIRED_FIELD'))
          .required(() => translate('CATEGORY_IS_REQUIRED_FIELD'))
          .label('Categroy'),
        breedId: Yup.string()
          .notOneOf(['0'], () => translate('BREED_IS_REQUIRED_FIELD'))
          .required(() => translate('BREED_IS_REQUIRED_FIELD'))
          .label('Breed'),
        customerId: Yup.string()
          .notOneOf(['0'], () => translate('CUSTOMER_IS_REQUIRED_FIELD'))
          .required(() => translate('CUSTOMER_IS_REQUIRED_FIELD'))
          .label('Customer'),
        horseLocationId: Yup.string()
          .notOneOf(['0'], () => translate('LOCATION_IS_REQUIRED_FIELD'))
          .required(() => translate('LOCATION_IS_REQUIRED_FIELD'))
          .label('Breed'),
        passportTypeId: Yup.string()
          .notOneOf(['0'], () => translate('Passport Type is required field'))
          .required(() => translate('Passport Type is required field'))
          .label('Breed'),
        typeId: Yup.string()
          .notOneOf(['0'], () => translate('TYPE_HORSE_IS_REQUIRED_FIELD'))
          .required(() => translate('TYPE_HORSE_IS_REQUIRED_FIELD'))
          .label('Type'),
        passport: Yup.string()
          .required(() => translate('PASSPORT_NUMBER_IS_REQUIRED_FIELD'))
          .label('Passport Number'),
        dateOfBirth: Yup.date()
          .nullable()
          .required('Date of birth is required'),
        joiningDate: Yup.date()
          .nullable()
          .required('Date of birth is required'),
        ridersId: Yup.string().when(['typeId'], (typeId, schema) => {
          if (
            (isHorseKRCPrivate.value || isHorsePrivate.value) &&
            ridersId.value.length === 0
          ) {
            return schema
              .notOneOf(['0'], () => translate('RIDERS_IS_REQUIRED_FIELD'))
              .required(() => translate('RIDERS_IS_REQUIRED_FIELD'))
              .label('riders');
          } else {
            return schema; // Skip validation when conditions are not met.
          }
        }),
      });
      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        if (!nationalityId.value) {
          console.log('run ');
          delete values.nationalityId;
        }
        values.classLimitPerDay = Number(values.classLimitPerDay);
        values.workCapacity = Number(values.workCapacity);

        const data = await store.dispatch(
          Actions.CREATE_HORSES_OPERATIONS,
          values
        );
        horseId.value = data.data.data.id;

        if (isHorseKRCPrivate.value || isHorsePrivate.value) {
          const value = {
            customers: ridersId.value,
          };
          await store.dispatch(Actions.UPDATE_HORSE, {
            id: horseId.value,
            data: value,
          });
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_HORSE_OPERATION'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
      };
      const onSubmitCreateAttachment = async (values, cb) => {
        await store.dispatch(Actions.UPLOAD_HORSE_ATTACHMENT, {
          data: values,
          id: horseId.value,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ATTACHMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({
              name: 'horse-editing',
              params: { id: horseId.value },
            });
          });
        }
        cb();
      };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.NATIONALITY,
        LookupNameIds.STATUS,
        LookupNameIds.HORSE_GENDER,
        LookupNameIds.PASSPORT_TYPE,
        LookupNameIds.HORSE_CATEGORY,
        LookupNameIds.HORSE_COLOR,
        LookupNameIds.HORSE_BREED,
        LookupNameIds.HORSE_TYPE,
        LookupNameIds.DOCUMENT_TYPE,
      ]);
      await store.dispatch(Actions.GET_ALL_SETTINGS_HORSE_LOCATIONS);

      await store.dispatch(Actions.GET_ALL_CUSTOMERS, { horseOwner: true });

      const lookupsList = computed(() => store.getters.allLookupsList);
      const horseLocationList = computed(() => store.getters.allLocationsList);

      const nationalityList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.NATIONALITY;
      });

      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.STATUS;
      });
      const genderList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_GENDER;
      });
      const passportTypeList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.PASSPORT_TYPE;
      });
      const colorList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_COLOR;
      });

      const breedList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_BREED;
      });
      const categoryList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_CATEGORY;
      });
      const horseTypeList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_TYPE;
      });
      const customerList = computed(() => store.getters.allCustomersList);

      const isHorsePrivate = computed(() => {
        const type = horseTypeList.find((type) => type.id === horseType.value);
        return type ? type.slug === HorseType.PRIVATE : null;
      });
      const isHorseKRCPrivate = computed(() => {
        const type = horseTypeList.find((type) => type.id === horseType.value);
        return type ? type.slug === HorseType.KRC_PRIVATE : null;
      });
      const docTypeList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DOCUMENT_TYPE;
      });

      return {
        onSubmitCreate,
        onSubmitCreateAttachment,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        horseLocationList,
        nationalityList,
        statusList,
        genderList,
        passportTypeList,
        categoryList,
        breedList,
        colorList,
        horseTypeList,
        customerList,
        docTypeList,
        gender,
        status,
        horseLocation,
        passportType,
        horseCategory,
        horseColor,
        horseBreed,
        horseType,
        previewImage,
        removeImage,
        previewFiles,
        files,
        isHorsePrivate,
        isHorseKRCPrivate,
        customerId,
        docType,
        ridersId,
        horseId,
        nationalityId,
        dateOfBirth,
        joiningDate,
      };
    },
  });
