
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { ShopObject } from '@/store/modules/operations/ShopModule';

  export default defineComponent({
    name: 'shop-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const imagePreview = ref<string | null>(null);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('Shop Editing'), [
          translate('settings'),
        ]);
      });

      const { data } = await store.dispatch(Actions.GET_SHOP, route.params.id);

      const shop = ref<ShopObject>(data.data);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const previewImage = ref<string>(shop.value.image);
      const imageFile = ref<File | null>(null);

      shop.value.phone = shop.value.phone.replace('965', '');
      const onImageChange = (event: Event) => {
        const file = (event.target as HTMLInputElement).files?.[0] || null;
        if (file) {
          imageFile.value = file;
          const reader = new FileReader();
          reader.onload = (e) => {
            previewImage.value = e.target?.result as string;
          };
          reader.readAsDataURL(file);
        }
      };
      const removeImage = () => {
        imageFile.value = null;
        imagePreview.value = null;
      };

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const validationSchema = Yup.object().shape({
        nameEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('nameEn'),
        nameAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('nameAr'),
        phone: Yup.string()
          .matches(/^\d{8}$/, {
            message: () => translate('Phone number is not valid'),
          })
          .required(() => translate('PHONE_NUMBER_IS_REQUIRED_FIELD')),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          submitButton.value.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        await store.dispatch(Actions.UPDATE_SHOP, {
          data: values,
          id: shop.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (previewImage.value !== shop.value.image) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            console.log('here', previewImage.value, shop.value.image);
            const formData = new FormData();
            if (previewImage.value) {
              if (imageFile.value) {
                formData.append('attachments', imageFile.value);
              }
              await store.dispatch(Actions.UPLOAD_SHOP_ATTACHMENT, {
                data: formData,
                id: data.data.id,
              });
              const [errorName] = Object.keys(store.getters.getErrors);
              const error = store.getters.getErrors[errorName];
              if (error) {
                Swal.fire({
                  text: translate(error[0]),
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: translate('tryAgainExcl'),
                  customClass: {
                    confirmButton: 'btn fw-bold btn-light-danger',
                  },
                });
              } else {
                console.log('here');
                Swal.fire({
                  text: translate('Successfully Updated Shop'),
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: translate('ok'),
                  customClass: {
                    confirmButton: 'btn btn-light-primary',
                  },
                }).then(() => {
                  router.push({ name: 'shop-listing' });
                });
              }
            }
          } else {
            console.log('here');
            Swal.fire({
              text: translate('Successfully Updated Shop'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            }).then(() => {
              router.push({ name: 'shop-listing' });
            });
          }
        }
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
      submitButton.value!.disabled = false;
      };

      return {
        onSubmitCreate,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        imagePreview,
        onImageChange,
        removeImage,
        shop,
        previewImage,
        status,
      };
    },
  });
