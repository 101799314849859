enum Actions {
  // action types
  CLEAR_ERRORS = 'clearErrors',
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGIN_TRAINER = 'LOGIN_TRAINER',
  LOGOUT = 'logout',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  CHANGE_LOCALE = 'changeLocale',
  RESET_ROLES_STORE = 'resetRolesStore',
  CREATE_ROLE = 'createRole',
  DELETE_ROLE = 'deleteRole',
  UPDATE_ROLE = 'updateRole',
  UPDATE_ROLE_CURRENT_PAGE = 'updateRoleCurrentpage',
  GET_ROLE = 'getRole',
  GET_ROLES = 'getRoles',
  GET_ROLES_GROUPS = 'getRolesGroups',
  GET_PERMISSIONS = 'getPermission',
  RESET_GROUPS_STORE = 'resetGroupsStore',
  CREATE_GROUP = 'createGroup',
  DELETE_GROUP = 'deleteGroup',
  UPDATE_GROUP = 'updateGroup',
  UPDATE_GROUP_CURRENT_PAGE = 'updateGroupCurrentPage',
  GET_GROUP = 'getGroup',
  GET_GROUPS = 'getGroups',
  GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CLIENT_LOGIN = 'CLIENT_LOGIN',
  GET_GROUP_ID = 'GET_GROUP_ID',
  GET_ALL_ROLES = 'GET_ALL_ROLES',
  CREATE_ADMIN_ACCOUNT = 'CREATE_ADMIN_ACCOUNT',
  RESET_ADMINS_STORE = 'RESET_ADMINS_STORE',
  DELETE_ADMIN_ACCOUNT = 'DELETE_ADMIN_ACCOUNT',
  UPDATE_ADMIN_ACCOUNT = 'UPDATE_ADMIN_ACCOUNT',
  UPDATE_ADMIN_CURRENT_PAGE = 'UPDATE_ADMIN_CURRENT_PAGE',
  GET_ADMIN_USER = 'GET_ADMIN_USER',
  GET_ADMIN_USERS = 'GET_ADMIN_USERS',
  RESET_SETTINGS_LOOKUPS_STORE = 'RESET_SETTINGS_LOOKUPS_STORE',
  UPDATE_SETTINGS_LOOKUPS_FILTER = 'UPDATE_SETTINGS_LOOKUPS_FILTER',
  UPDATE_SETTING_LOOKUPS_CURRENT_PAGE = 'UPDATE_SETTING_LOOKUPS_CURRENT_PAGE',
  GET_SETTINGS_LOOKUPS = 'GET_SETTINGS_LOOKUPS',
  GET_ALL_SETTINGS_LOOKUPS = 'GET_ALL_SETTINGS_LOOKUPS',
  GET_SETTING_LOOKUP = 'GET_SETTING_LOOKUP',
  DELETE_SETTING_LOOKUP = 'DELETE_SETTING_LOOKUP',
  UPDATE_SETTING_LOOKUP = 'UPDATE_SETTING_LOOKUP',
  CREATE_SETTING_LOOKUP = 'CREATE_SETTING_LOOKUP',
  RESET_SETTINGS_HORSE_LOCATIONS_STORE = 'RESET_SETTINGS_HORSE_LOCATIONS_STORE',
  UPDATE_SETTINGS_HORSE_LOCATION = 'UPDATE_SETTINGS_HORSE_LOCATION',
  CREATE_SETTING_HORSE_LOCATION = 'CREATE_SETTING_HORSE_LOCATION',
  DELETE_HORSE_LOCATION = 'DELETE_HORSE_LOCATION',
  UPDATE_HORSE_LOCATION = 'UPDATE_HORSE_LOCATION',
  UPDATE_HORSE_LOCATIONS_CURRENT_PAGE = 'UPDATE_HORSE_LOCATIONS_CURRENT_PAGE',
  GET_SETTING_HORSE_LOCATION = 'GET_SETTING_HORSE_LOCATION',
  GET_SETTINGS_HORSE_LOCATIONS = 'GET_SETTINGS_HORSE_LOCATIONS',
  GET_ALL_SETTINGS_HORSE_LOCATIONS = 'GET_ALL_SETTINGS_HORSE_LOCATIONS',
  SET_SETTINGS_HORSE_LOCATIONS_FILTER = 'SET_SETTINGS_HORSE_LOCATIONS_FILTER',
  EXPORT_LOOKUPS = 'EXPORT_LOOKUPS',
  DELETE_SETTING_HOLIDAY = 'DELETE_SETTING_HOLIDAY',
  UPDATE_SETTING_HOLIDAY = 'UPDATE_SETTING_HOLIDAY',
  UPDATE_SETTINGS_HOLIDAYS_CURRENT_PAGE = 'UPDATE_SETTINGS_HOLIDAYS_CURRENT_PAGE',
  GET_SETTING_HOLIDAY = 'GET_SETTING_HOLIDAY',
  GET_SETTINGS_HOLIDAY = 'GET_SETTINGS_HOLIDAY',
  GET_ALL_SETTINGS_HOLIDAYS = 'GET_ALL_SETTINGS_HOLIDAYS',
  RESET_SETTINGS_HOLIDAYS_STORE = 'RESET_SETTINGS_HOLIDAYS_STORE',
  CREATE_SETTING_HOLIDAY = 'CREATE_SETTING_HOLIDAY',
  UPDATE_SETTINGS_HOLIDAYS_FILTER = 'UPDATE_SETTINGS_HOLIDAYS_FILTER',
  RESET_SETTINGS_CLASS_LEVEL_STORE = 'RESET_SETTINGS_CLASS_LEVEL_STORE',
  UPDATE_SETTINGS_CLASS_LEVEL_FILTER = 'UPDATE_SETTINGS_CLASS_LEVEL_FILTER',
  CREATE_SETTING_CLASS_LEVEL = 'CREATE_SETTING_CLASS_LEVEL',
  DELETE_SETTING_CLASS_LEVEL = 'DELETE_SETTING_CLASS_LEVEL',
  UPDATE_SETTING_CLASS_LEVEL = 'UPDATE_SETTING_CLASS_LEVEL',
  UPDATE_SETTINGS_CLASS_LEVEL_CURRENT_PAGE = 'UPDATE_SETTINGS_CLASS_LEVEL_CURRENT_PAGE',
  GET_SETTING_CLASS_LEVEL = 'GET_SETTING_CLASS_LEVEL',
  GET_SETTINGS_CLASS_LEVEL = 'GET_SETTINGS_CLASS_LEVEL',
  GET_ALL_SETTINGS_CLASS_LEVEL = 'GET_ALL_SETTINGS_CLASS_LEVEL',
  RESET_SETTINGS_CLASS_CATEGORY_STORE = 'RESET_SETTINGS_CLASS_CATEGORY_STORE',
  UPDATE_SETTINGS_CLASS_CATEGORY_FILTER = 'UPDATE_SETTINGS_CLASS_CATEGORY_FILTER',
  CREATE_SETTING_CLASS_CATEGORY = 'CREATE_SETTING_CLASS_CATEGORY',
  DELETE_SETTING_CLASS_CATEGORY = 'DELETE_SETTING_CLASS_CATEGORY',
  UPDATE_SETTING_CLASS_CATEGORY = 'UPDATE_SETTING_CLASS_CATEGORY',
  UPDATE_SETTINGS_CLASS_CATEGORY_CURRENT_PAGE = 'UPDATE_SETTINGS_CLASS_CATEGORY_CURRENT_PAGE',
  GET_SETTING_CLASS_CATEGORY = 'GET_SETTING_CLASS_CATEGORY',
  GET_SETTINGS_CLASS_CATEGORY = 'GET_SETTINGS_CLASS_CATEGORY',
  GET_ALL_SETTINGS_CLASS_CATEGORY = 'GET_ALL_SETTINGS_CLASS_CATEGORY',
  GET_ALL_ARENAS = 'GET_ALL_ARENAS',
  RESET_SETTINGS_CUSTOMER_AGE_STORE = 'RESET_SETTINGS_CUSTOMER_AGE_STORE',
  UPDATE_SETTINGS_CUSTOMER_AGE_FILTER = 'UPDATE_SETTINGS_CUSTOMER_AGE_FILTER',
  CREATE_SETTING_CUSTOMER_AGE = 'CREATE_SETTING_CUSTOMER_AGE',
  DELETE_SETTING_CUSTOMER_AGE = 'DELETE_SETTING_CUSTOMER_AGE',
  UPDATE_SETTING_CUSTOMER_AGE = 'UPDATE_SETTING_CUSTOMER_AGE',
  UPDATE_SETTINGS_CUSTOMER_AGE_CURRENT_PAGE = 'UPDATE_SETTINGS_CUSTOMER_AGE_CURRENT_PAGE',
  GET_SETTING_CUSTOMER_AGE = 'GET_SETTING_CUSTOMER_AGE',
  GET_SETTINGS_CUSTOMER_AGE = 'GET_SETTINGS_CUSTOMER_AGE',
  GET_ALL_SETTINGS_CUSTOMER_AGE = 'GET_ALL_SETTINGS_CUSTOMER_AGE',
  GET_GROOMERS_OPERATIONS = 'getGroomersOperations',
  CREATE_GROOMERS_OPERATIONS = 'createGroomersOperations',
  GET_GROOMER = 'getGroomer',
  GET_ALL_GROOMERS_OPERATIONS = 'getAllGroomersOperation',
  UPDATE_GROOMER = 'updateGroomer',
  UPLOAD_GROOMER_ATTACHMENT = 'uploadGroomerAttachment',
  DELETE_GROOMER = 'deleteGroomer',
  RESET_GROOMER_STORE = 'resetGroomerStore',
  UPDATE_GROOMER_FILTER = 'updateGroomerFilter',
  RESET_TRAINERS_STORE = 'RESET_TRAINERS_STORE',
  UPDATE_TRAINERS_FILTER = 'UPDATE_TRAINERS_FILTER',
  CREATE_TRAINER = 'CREATE_TRAINER',
  DELETE_TRAINER = 'DELETE_TRAINER',
  UPDATE_TRAINER = 'UPDATE_TRAINER',
  UPDATE_TRAINERS_CURRENT_PAGE = 'UPDATE_TRAINERS_CURRENT_PAGE',
  GET_TRAINER = 'GET_TRAINER',
  GET_TRAINERS = 'GET_TRAINERS',
  GET_ALL_TRAINERS = 'GET_ALL_TRAINERS',
  EXPORT_TRAINERS = 'EXPORT_TRAINERS',
  GET_COOST_MATRIX = 'GET_COOST_MATRIX',
  CREATE_TRAINER_ATTACHMENT = 'CREATE_TRAINER_ATTACHMENT',
  RESET_ARENAS_STORE = 'RESET_ARENAS_STORE',
  UPDATE_ARENA = 'UPDATE_ARENA',
  UPDATE_ARENA_FILTER = 'UPDATE_ARENA_FILTER',
  CREATE_ARENA = 'CREATE_ARENA',
  DELETE_ARENA = 'DELETE_ARENA',
  UPDATE_ARENA_CURRENT_PAGE = 'UPDATE_ARENA_CURRENT_PAGE',
  GET_ARENA = 'GET_ARENA',
  GET_ARENAS = 'GET_ARENAS',
  RESET_CUSTOMERS_STORE = 'RESET_CUSTOMERS_STORE',
  UPDATE_CUSTOMERS_FILTER = 'UPDATE_CUSTOMERS_FILTER',
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  DELETE_CUSTOMER = 'DELETE_CUSTOMER',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  UPDATE_CUSTOMER_CURRENT_PAGE = 'UPDATE_CUSTOMER_CURRENT_PAGE',
  GET_CUSTOMER = 'GET_CUSTOMER',
  GET_CUSTOMERS = 'GET_CUSTOMERS',
  CREATE_CUSTOMER_ATTACHMENT = 'CREATE_CUSTOMER_ATTACHMENT',
  GET_HORSE = 'getHorser',
  GET_HORSES_OPERATIONS = 'getHorsesOperations',
  GET_ALL_HORSES_OPERATIONS = 'getAllHorsesOperations',
  CREATE_HORSES_OPERATIONS = 'createHorsesOperations',
  RESET_HORSE_STORE = '`RESET_HORSE_STORE`',
  UPDATE_HORSES_FILTER = 'UPDATE_GROOMER_FILTER',
  UPDATE_HORSES_CURRENT_PAGE = 'UPDATE_HORSES_CURRENT_PAGE',
  UPDATE_HORSE = 'update_horse',
  GET_LESSONS_OPERATIONS = 'GET_LESSONS_OPERATIONS',
  GET_ALL_LESSONS_OPERATIONS = 'GET_ALL_LESSONS_OPERATIONS',
  UPDATE_LESSONS = 'UPDATE_LESSONS',
  GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS',
  CHECK_ATTENDENCE = 'CHECK_ATTENDENCE',
  COMPLETE_ATTENDENCE = 'COMPLETE_ATTENDENCE',
  GET_ALL_LESSONS_HORSE = 'GET_ALL_LESSONS_HORSE',
  CREATE_LESSON = 'CREATE_LESSON',
  RESET_LESSON_STORE = 'RESET_LESSON_STORE',
  UPDATE_STAFF_FILTER = 'UPDATE_STAFF_FILTER',
  RESET_STAFF_STORE = 'RESET_STAFF_STORE',
  DELETE_STAFF = 'DELETE_STAFF',
  UPDATE_STAFF = 'UPDATE_STAFF',
  UPDATE_STAFF_CURRENT_PAGE = 'UPDATE_STAFF_CURRENT_PAGE',
  GET_STAFF = 'GET_STAFF',
  GET_STAFF_LIST = 'GET_STAFF_LIST',
  GET_ALL_STAFF_LIST = 'GET_ALL_STAFF_LIST',
  CREATE_STAFF = 'CREATE_STAFF',
  UPLOAD_STAFF = 'UPLOAD_STAFF',
  UPLOAD_HORSE_ATTACHMENT = 'UPLOAD_HORSE_ATTACHMENT',
  SET_SEARCH = 'SET_SEARCH',
  RESET_SEARCH = 'RESET_SEARCH',
  GET_REPORT_CUSTOMER_ATTENDENCE = 'GET_REPORT_CUSTOMER_ATTENDENCE',
  DELETE_HORSE_ATTACHMENT = 'DELETE_HORSE_ATTACHMENT',
  DELETE_GROOMER_ATTACHMENT = 'DELETE_GROOMER_ATTACHMENT',
  GET_FINANCE_INVOICE = 'GET_FINANCE_INVOICE',
  CREATE_FINANCE_INVOICE = ' CREATE_FINANCE_INVLICE',
  RESET_FINANCE_INVOICE_STORE = 'RESET_FINANCE_INVOICE_STORE',
  UPDATE_FINANCE_INVOICE_CURRENT_PAGE = 'UPDATE_FINANCE_INVOICE_CURRENT_PAGE',
  GET_FINANCE_CUSTOMER_CREDIT = 'GET_FINANCE_CUSTOMER_CREDIT',
  UPDATE_FINANCE_CUSTOMER_CREDIT_CURRENT_PAGE = 'UPDATE_FINANCE_CUSTOMER_CREDIT_CURRENT_PAGE',
  RESET_FINANCE_CUSTOMER_CREDIT_STORE = 'RESET_FINANCE_CUSTOMER_CREDIT_STORE',
  GET_ALL_FINANCE_INVOICE = 'GET_ALL_FINANCE_INVOICE',
  CREATE_FINANCE_CUSTOMER_CREDIT = 'CREATE_FINANCE_CUSTOMER_CREDIT',
  RESET_FINANCE_CUSTOMER_SERVICE_STATMENT_STORE = 'RESET_FINANCE_CUSTOMER_SERVICE_STATMENT_STORE',
  UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE = 'UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE',
  GET_FINANCE_CUSTOMER_SERVICE_STATMENT = 'GET_FINANCE_CUSTOMER_SERVICE_STATMENT',
  CREATE_FINANCE_CUSTOMER_SERVICE_STATMENT = 'CREATE_FINANCE_CUSTOMER_SERVICE_STATMENT',
  GENERATE_OUTTO_FINANCE_INVOICE = 'GENERATE_OUTTO_FINANCE_INVOICE',
  GENERATE_MANUAL_OUTTO_FINANCE_INVOICE = 'GENERATE_MANUAL_OUTTO_FINANCE_INVOICE',
  GET_CUSTOMER_ATTENDANCE = 'GET_CUSTOMER_ATTENDANCE',
  GET_ALL_CUSTOMER_ACCOUNT = 'GET_ALL_CUSTOMER_ACCOUNT',
  UPDATE_LESSONS_CURRENT_PAGE = 'UPDATE_LESSONS_CURRENT_PAGE',
  UPDATE_LESSONS_FILTER = 'UPDATE_LESSONS_FILTER',
  GET_FINANCE_CUSTOMER_BALANCE_STATMENT = 'GET_FINANCE_CUSTOMER_BALANCE_STATMENT',
  UPDATE_FINANCE_CUSTOMER_BALANCE_STATMENT_CURRENT_PAGE = 'UPDATE_FINANCE_CUSTOMER_BALANCE_STATMENT_CURRENT_PAGE',
  RESET_FINANCE_CUSTOMER_BALANCE_STATMENT_STORE = 'RESET_FINANCE_CUSTOMER_BALANCE_STATMENT_STORE',
  GET_ALL_FINANCE_CUSTOMER_CREDIT = 'GET_ALL_FINANCE_CUSTOMER_CREDIT',
  GET_ALL_FINANCE_CUSTOMER_SERVICE = 'GET_ALL_FINANCE_CUSTOMER_SERVICE',
  UPDATE_CUSTOMER_CREDIT_FILTER = 'UPDATE_CUSTOMER_CREDIT_FILTER',
  GET_FINANCE_CUSTOMER_PAYMENT_HISTORY = 'GET_FINANCE_CUSTOMER_PAYMENT_HISTORY',
  UPDATE_CUSTOMER_SERVICE_FILTER = 'UPDATE_CUSTOMER_SERVICE_FILTER',
  UPDATE_CUSTOMER_BALANCE_FILTER = 'UPDATE_CUSTOMER_BALANCE_FILTER',
  GET_LESSONS = 'GET_LESSONS',
  GET_TRAINER_BY_GROUP = 'GET_TRAINER_BY_GROUP',
  GET_HORSE_LESSONS = 'GET_HORSE_LESSONS',
  GET_HORSE_LESSONS_COUNT = 'GET_HORSE_LESSONS_COUNT',
  GET_GROOMER_LESSONS_COUNT = 'GET_GROOMER_LESSONS_COUNT',
  GET_CUSTOMER_BALANCE_REPORT = 'GET_CUSTOMER_BALANCE_REPORT',
  GET_CUSTOMER_PAYMENT_REPORT = 'GET_CUSTOMER_PAYMENT_REPORT',
  GET_CUSTOMER_STATMENT_REPORT = 'GET_CUSTOMER_STATMENT_REPORT',
  GET_HORSE_BY_GROUP = 'GET_HORSE_BY_GROUP',
  GET_HORSES_TREATMENT = 'GET_HORSES_TREATMENT',
  UPLOAD_MEDICAL_ATTACHMENT = 'UPLOAD_MEDICAL_ATTACHMENT',
  GET_LESSONS_STUDENT_COUNT = 'GET_LESSONS_STUDENT_COUNT',
  CHANGE_TRAINER_PASSWORD = 'CHANGE_TRAINER_PASSWORD',
  CHANGE_ADMIN_PASSWORD = 'CHANGE_ADMIN_PASSWORD',
  GET_LESSONS_GROUP_BY_DATE = 'GET_LESSONS_GROUP_BY_DATE',
  UPDATE_TRAINER_IMAGE = 'UPDATE_TRAINER_IMAGE',
  CHECK_BALANCS = 'CHECK_BALANCS',
  CHECK_GROOMER = 'CHECK_GROOMER',
  GET_HORSE_DAILY_LESSON = 'GET_HORSE_DAILY_LESSON',
  GET_GROOMS_DAILY_LESSON = 'GET_GROOMS_DAILY_LESSON',
  GET_ARENA_DAILY_LESSON = 'GET_ARENA_DAILY_LESSON',
  GET_ALL_HORSES_TAGS = 'GET_ALL_HORSES_TAGS',
  UPDATE_GROOMER_CURRENT_PAGE = 'UPDATE_GROOMER_CURRENT_PAGE',
  SWAP_LESSONS = 'SWAP_LESSONS',
  DELETE_LESSONS = 'DELETE_LESSONS',
  GET_COST_MATRIX_V2 = 'GET_COST_MATRIX_V2',
  UPDATE_COST_MATRIX_V2 = 'UPDATE_COST_MATRIX_V2',
  DELETE_TRAINER_ATTACHMENT = 'DELETE_TRAINER_ATTACHMENT',
  DELETE_STAFF_ATTACHMENT = 'DELETE_STAFF_ATTACHMENT',
  DELETE_CUSTOMER_ATTACHMENT = 'DELETE_CUSTOMER_ATTACHMENT',
  GET_CONTENT_PAGES = 'GET_CONTENT_PAGES',
  DELETE_CONTENT_PAGE = 'DELETE_CONTENT_PAGE',
  CREATE_CONTENT_PAGES = 'CREATE_CONTENT_PAGES',
  GET_CONTENT_PAGE = 'GET_CONTENT_PAGE',
  UPDATE_CONTENT_PAGE = 'UPDATE_CONTENT_PAGE',
  UPDATE_CONTENT_PAGE_FILTER = 'UPDATE_CONTENT_PAGE_FILTER',
  UPLOAD_CLASS_LEVEL_ATTACHMENT = 'UPLOAD_CLASS_LEVEL_ATTACHMENT',
  RECOVER_ALL_LESSONS_OPERATIONS = 'RECOVER_ALL_LESSONS_OPERATIONS',
  RESET_ANNOUNCEMENT_STORE = 'RESET_ANNOUNCEMENT_STORE',
  UPDATE_ANNOUNCEMENT_FILTER = 'UPDATE_ANNOUNCEMENT_FILTER',
  CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT',
  UPLOAD_ANNOUNCEMENT_ATTACHMENT = 'UPLOAD_ANNOUNCEMENT_ATTACHMENT',
  DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT',
  UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT',
  UPDATE_ANNOUNCEMENT_CURRENT_PAGE = 'UPDATE_ANNOUNCEMENT_CURRENT_PAGE',
  GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS',
  GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT',
  GET_ALL_ANNOUNCEMENT = 'GET_ALL_ANNOUNCEMENT',
  GET_LESSON = 'GET_LESSON',
  RESET_SERVICES_STORE = 'RESET_SERVICES_STORE',
  UPDATE_SERVICES_FILTER = 'UPDATE_SERVICES_FILTER',
  CREATE_SERVICE = 'CREATE_SERVICE',
  UPLOAD_SERVICES_ATTACHMENT = 'UPLOAD_SERVICES_ATTACHMENT',
  DELETE_SERVICE = 'DELETE_SERVICE',
  UPDATE_SERVICE = 'UPDATE_SERVICE',
  UPDATE_SERVICES_CURRENT_PAGE = 'UPDATE_SERVICES_CURRENT_PAGE',
  GET_SERVICE = 'GET_SERVICE',
  GET_SERVICES = 'GET_SERVICES',
  GET_ALL_SERVICES = 'GET_ALL_SERVICES',
  GET_CUSTOMER_HISTORY = 'GET_CUSTOMER_HISTORY',
  RESET_LESSONS_PENDING_STORE = 'RESET_LESSONS_PENDING_STORE',
  UPDATE_LESSONS_PENDING_FILTER = 'UPDATE_LESSONS_PENDING_FILTER',
  CREATE_LESSONS_PENDING = 'CREATE_LESSONS_PENDING',
  DELETE_LESSONS_PENDING = 'DELETE_LESSONS_PENDING',
  UPDATE_LESSONS_PENDING = 'UPDATE_LESSONS_PENDING',
  UPDATE_LESSONS_PENDING_CURRENT_PAGE = 'UPDATE_LESSONS_PENDING_CURRENT_PAGE',
  GET_LESSONS_PENDING = 'GET_LESSONS_PENDING',
  GET_LESSONS_PENDING_LIST = 'GET_LESSONS_PENDING_LIST',
  GET_ALL_LESSONS_PENDING = 'GET_ALL_LESSONS_PENDING',
  GENERATE_LESSON_INVOICE = 'GENERATE_LESSON_INVOICE',
  RESET_SHOP_STORE = 'RESET_SHOP_STORE',
  UPDATE_SHOP_FILTER = 'UPDATE_SHOP_FILTER',
  CREATE_SHOP = 'CREATE_SHOP',
  UPLOAD_SHOP_ATTACHMENT = 'UPLOAD_SHOP_ATTACHMENT',
  DELETE_SHOP = 'DELETE_SHOP',
  UPDATE_SHOP = 'UPDATE_SHOP',
  UPDATE_SHOP_CURRENT_PAGE = 'UPDATE_SHOP_CURRENT_PAGE',
  GET_SHOP = 'GET_SHOP',
  GET_SHOPS = 'GET_SHOPS',
  GET_ALL_SHOP = 'GET_ALL_SHOP',
  RESET_CATEGORY_STORE = 'RESET_CATEGORY_STORE',
  UPDATE_CATEGORY_FILTER = 'UPDATE_CATEGORY_FILTER',
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  UPDATE_CATEGORY_CURRENT_PAGE = 'UPDATE_CATEGORY_CURRENT_PAGE',
  GET_CATEGORY = 'GET_CATEGORY',
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_ALL_CATEGORY = 'GET_ALL_CATEGORY',
  UPLOAD_CATEGORY_ATTACHMENT = 'UPLOAD_CATEGORY_ATTACHMENT',
  RESET_PRODUCT_STORE = 'RESET_PRODUCT_STORE',
  UPDATE_PRODUCT_FILTER = 'UPDATE_PRODUCT_FILTER',
  CREATE_PRODUCT = 'CREATE_PRODUCT',
  UPLOAD_PRODUCT_ATTACHMENT = 'UPLOAD_PRODUCT_ATTACHMENT',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  UPDATE_PRODUCT_CURRENT_PAGE = 'UPDATE_PRODUCT_CURRENT_PAGE',
  GET_PRODUCT = 'GET_PRODUCT',
  GET_PRODUCTS = 'GET_PRODUCTS',
  GET_ALL_PRODUCT = 'GET_ALL_PRODUCT',
  DELETE_PRODUCT_ATTACHMENT = 'DELETE_PRODUCT_ATTACHMENT',
  RESET_OFFER_STORE = 'RESET_OFFER_STORE',
  UPDATE_OFFER_FILTER = 'UPDATE_OFFER_FILTER',
  CREATE_OFFER = 'CREATE_OFFER',
  UPLOAD_OFFER_ATTACHMENT = 'UPLOAD_OFFER_ATTACHMENT',
  DELETE_OFFER = 'DELETE_OFFER',
  UPDATE_OFFER = 'UPDATE_OFFER',
  UPDATE_OFFER_CURRENT_PAGE = 'UPDATE_OFFER_CURRENT_PAGE',
  GET_OFFER = 'GET_OFFER',
  GET_OFFERS = 'GET_OFFERS',
  GET_ALL_OFFER = 'GET_ALL_OFFER',
  RESET_CANCELLATION_STORE = 'RESET_CANCELLATION_STORE',
  UPDATE_CANCELLATION_FILTER = 'UPDATE_CANCELLATION_FILTER',
  DELETE_CANCELLATION = 'DELETE_CANCELLATION',
  UPDATE_CANCELLATION_CURRENT_PAGE = 'UPDATE_CANCELLATION_CURRENT_PAGE',
  GET_CANCELLATION = 'GET_CANCELLATION',
  GET_CANCELLATIONS = 'GET_CANCELLATIONS',
  GET_ALL_CANCELLATION = 'GET_ALL_CANCELLATION',
  RESET_TICKET_STORE = 'RESET_TICKET_STORE',
  UPDATE_TICKET_FILTER = 'UPDATE_TICKET_FILTER',
  DELETE_TICKET = 'DELETE_TICKET',
  UPDATE_TICKET_CURRENT_PAGE = 'UPDATE_TICKET_CURRENT_PAGE',
  UPDATE_TICKET = 'UPDATE_TICKET',
  GET_TICKET = 'GET_TICKET',
  GET_TICKETS = 'GET_TICKETS',
  GET_ALL_TICKET = 'GET_ALL_TICKET',
  GET_TICKETS_MESSAGES = 'GET_TICKETS_MESSAGES',
  UPDATE_TICKET_MESSAGE_CURRENT_PAGE = 'UPDATE_TICKET_MESSAGE_CURRENT_PAGE',
  CREATE_TICKETS_MESSAGES = 'CREATE_TICKETS_MESSAGES',
  GET_ALL_STATIC_QUESTIONS = 'GET_ALL_STATIC_QUESTIONS',
  UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION',
  UPDATE_NOTIFICATIONS_CURRENT_PAGE = 'UPDATE_NOTIFICATIONS_CURRENT_PAGE',
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
  MARK_ALL_AS_READ = 'MARK_ALL_AS_READ',
  RESET_PENDINGACCOUNT_STORE = 'RESET_PENDINGACCOUNT_STORE',
  DELETE_PENDINGACCOUNT = 'DELETE_PENDINGACCOUNT',
  UPDATE_PENDINGACCOUNT_CURRENT_PAGE = 'UPDATE_PENDINGACCOUNT_CURRENT_PAGE',
  GET_PENDINGACCOUNTS = 'GET_PENDINGACCOUNTS',
  GET_HORSE_ALL = 'GET_HORSE_ALL',
  UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT = 'UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT',
  RESET_NOTIFICATION_STORE = 'RESET_NOTIFICATION_STORE',
  GET_NOTIFICATIONS_PARENT = 'GET_NOTIFICATIONS_PARENT',
  RESET_NOTIFICATION_PARENT_STORE = 'RESET_NOTIFICATION_PARENT_STORE',
  UPDATE_NOTIFICATIONS_PARENT_CURRENT_PAGE = 'UPDATE_NOTIFICATIONS_PARENT_CURRENT_PAGE',
  DELETE_NOTIFICATION_PARENT = 'DELETE_NOTIFICATION_PARENT',
  CREATE_NOTIFICATIONS_PARENT = 'CREATE_NOTIFICATIONS_PARENT',
  GET_NOTIFICATIONS_PARENT_BY_ID = 'GET_NOTIFICATIONS_PARENT_BY_ID',
  UPDATE_NOTIFICATION_PARENT = 'UPDATE_NOTIFICATION_PARENT',
  RESET_ALL_FINANCE_INVOICE = 'RESET_ALL_FINANCE_INVOICE',
  RESET_ALL_FINANCE_CUSTOMER_CREDIT = 'RESET_ALL_FINANCE_CUSTOMER_CREDIT',
  RESET_ALL_FINANCE_CUSTOMER_SERVICE = 'RESET_ALL_FINANCE_CUSTOMER_SERVICE',
  GET_DAILY_SERVICE_REPORT = 'GET_DAILY_SERVICE_REPORT',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_LOCALE = 'setLocale',
  SET_ROLES = 'setRoles',
  SET_ROLES_GROUPS = 'setRolesGroups',
  RESET_ROLES_LIST = 'resetRolesList',
  SET_ROLE_CURRENT_PAGE = 'setRoleCurrentPage',
  RESET_PERMISSIONS = 'resetPermissions',
  SET_PERMISSIONS = 'setPermission',
  SET_GROUPS = 'setGroups',
  SET_GROUPS_CURRENT_PAGE = 'setGroupsCurrentPage',
  RESET_GROUPS_LIST = 'resetGroupsList',
  SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS',
  SET_PASSWORD = 'SET_PASSWORD',
  SET_CREATED_GROUP_ID = 'SET_CREATED_GROUP_ID',
  RESET_ADMINS_LIST = 'RESET_ADMINS_LIST',
  SET_ADMIN_CURRENT_PAGE = 'SET_ADMIN_CURRENT_PAGE',
  SET_ADMIN_USERS = 'SET_ADMIN_USERS',
  SET_SETTINGS_LOOKUPS = 'SET_SETTINGS_LOOKUPS',
  SET_ALL_SETTINGS_LOOKUPS = 'SET_ALL_SETTINGS_LOOKUPS',
  SET_SETTING_LOOKUPS_CURRENT_PAGE = 'SET_SETTING_LOOKUPS_CURRENT_PAGE',
  RESET_SETTINGS_LOOKUPS_LIST = 'RESET_SETTINGS_LOOKUPS_LIST',
  SET_SETTINGS_LOOKUPS_FILTER = 'SET_SETTINGS_LOOKUPS_FILTER',
  SET_SETTINGS_HORSE_LOCATIONS = 'SET_SETTINGS_HORSE_LOCATIONS',
  SET_ALL_SETTINGS_HORSE_LOCATIONS = 'SET_ALL_SETTINGS_HORSE_LOCATIONS',
  SET_SETTING_HORSE_LOCATIONS_CURRENT_PAGE = 'SET_SETTING_HORSE_LOCATIONS_CURRENT_PAGE',
  RESET_SETTINGS_HORSE_LOCATIONS_LIST = 'RESET_SETTINGS_HORSE_LOCATIONS_LIST',
  SET_SETTINGS_HORSE_LOCATIONS_FILTER = 'SET_SETTINGS_HORSE_LOCATIONS_FILTER',
  SET_EXPORT_LOOKUPS = 'SET_EXPORT_LOOKUPS',
  SET_SETTINGS_HOLIDAYS = 'SET_SETTINGS_HOLIDAYS',
  RESET_SETTINGS_HOLIDAYS_LIST = 'RESET_SETTINGS_HOLIDAYS_LIST',
  SET_SETTINGS_HOLIDAYS_FILTER = 'SET_SETTINGS_HOLIDAYS_FILTER',
  SET_SETTING_HOLIDAYS_CURRENT_PAGE = 'SET_SETTING_HOLIDAYS_CURRENT_PAGE',
  SET_ALL_SETTINGS_HOLIDAYS = 'SET_ALL_SETTINGS_HOLIDAYS',
  SET_SETTING_HOLIDAY_CURRENT_PAGE = 'SET_SETTING_HOLIDAY_CURRENT_PAGE',
  SET_SETTINGS_CLASS_LEVEL = 'SET_SETTINGS_CLASS_LEVEL',
  SET_ALL_SETTINGS_CLASS_LEVEL = 'SET_ALL_SETTINGS_CLASS_LEVEL',
  SET_SETTING_CLASS_LEVEL_CURRENT_PAGE = 'SET_SETTING_CLASS_LEVEL_CURRENT_PAGE',
  RESET_SETTINGS_CLASS_LEVEL_LIST = 'RESET_SETTINGS_CLASS_LEVEL_LIST',
  SET_SETTINGS_CLASS_LEVEL_FILTER = 'SET_SETTINGS_CLASS_LEVEL_FILTER',
  SET_SETTINGS_CLASS_CATEGORY = 'SET_SETTINGS_CLASS_CATEGORY',
  SET_ALL_SETTINGS_CLASS_CATEGORY = 'SET_ALL_SETTINGS_CLASS_CATEGORY',
  SET_SETTING_CLASS_CATEGORY_CURRENT_PAGE = 'SET_SETTING_CLASS_CATEGORY_CURRENT_PAGE',
  RESET_SETTINGS_CLASS_CATEGORY_LIST = 'RESET_SETTINGS_CLASS_CATEGORY_LIST',
  SET_SETTINGS_CLASS_CATEGORY_FILTER = 'SET_SETTINGS_CLASS_CATEGORY_FILTER',
  SET_ALL_ARENAS = 'SET_ALL_ARENAS',
  SET_SETTINGS_CUSTOMER_AGE = 'SET_SETTINGS_CUSTOMER_AGE',
  SET_ALL_SETTINGS_CUSTOMER_AGE = 'SET_ALL_SETTINGS_CUSTOMER_AGE',
  SET_SETTING_CUSTOMER_AGE_CURRENT_PAGE = 'SET_SETTING_CUSTOMER_AGE_CURRENT_PAGE',
  RESET_SETTINGS_CUSTOMER_AGE_LIST = 'RESET_SETTINGS_CUSTOMER_AGE_LIST',
  SET_SETTINGS_CUSTOMER_AGE_FILTER = 'SET_SETTINGS_CUSTOMER_AGE_FILTER',
  SET_GROOMERS_OPERATIONS = 'setGroomersOperations',
  RESET_GROOMERS_LIST = 'resetGroomerList',
  SET_ALL_GROOMERS_OPERATIONS = 'setAllGroomerOperations',
  SET_GROOMER_FILTER = 'setGroomerFilter',
  SET_TRAINERS = 'SET_TRAINERS',
  SET_ALL_TRAINERS = 'SET_ALL_TRAINERS',
  SET_TRAINER_CURRENT_PAGE = 'SET_TRAINER_CURRENT_PAGE',
  RESET_TRAINERS_LIST = 'RESET_TRAINERS_LIST',
  SET_TRAINERS_FILTER = 'SET_TRAINERS_FILTER',
  SET_EXPORT_TRAINERS = 'SET_EXPORT_TRAINERS',
  SET_COOST_MATRIX = 'SET_COOST_MATRIX',
  SET_TRAINER_ATTACHMENTS = 'SET_TRAINER_ATTACHMENTS',
  SET_ARENAS = 'SET_ARENAS',
  SET_ARENA_CURRENT_PAGE = 'SET_ARENA_CURRENT_PAGE',
  RESET_ARENAS_LIST = 'RESET_ARENAS_LIST',
  SET_ARENAS_FILTER = 'SET_ARENAS_FILTER',
  SET_CUSTOMERS = 'SET_CUSTOMERS',
  SET_CUSTOMER_CURRENT_PAGE = 'SET_CUSTOMER_CURRENT_PAGE',
  RESET_CUSTOMERS_LIST = 'RESET_CUSTOMERS_LIST',
  SET_CUSTOMERS_FILTER = 'SET_CUSTOMERS_FILTER',
  SET_CUSTOMER_ATTACHMENTS = 'SET_CUSTOMER_ATTACHMENTS',
  SET_HORSES_OPERATIONS = 'SET_HORSES_OPERATIONS',
  SET_ALL_HORSES_OPERATIONS = 'SET_ALL_HORSES_OPERATIONS',
  SET_ALL_LESSONS_OPERATIONS = 'SET_ALL_LESSONS_OPERATIONS',
  RESET_LESSONS_LIST = 'RESET_LESSONS_LIST',
  SET_LESSONS_OPERATIONS = 'SET_LESSONS_OPERATIONS',
  SET_ALL_CUSTOMERS = 'SET_ALL_CUSTOMERS',
  RESET_HORSES_LIST = 'RESET_HORSES_LIST',
  SET_HORSES_FILTER = 'SET_HORSES_FILTER',
  SET_HORSES_CURRENT_PAGE = 'SET_HORSES_CURRENT_PAGE',
  SET_STAFF = 'SET_STAFF',
  SET_ALL_STAFF = 'SET_ALL_STAFF',
  SET_STAFF_CURRENT_PAGE = 'SET_STAFF_CURRENT_PAGE',
  RESET_STAFF_LIST = 'RESET_STAFF_LIST',
  SET_STAFF_FILTER = 'SET_STAFF_FILTER',
  SET_REPORT_CUSTOMER_ATTENDENCE = 'SET_REPORT_CUSTOMER_ATTENDENCE',
  RESET_FINANCE_INVOICE_LIST = 'RESET_FINANCE_INVOICE_LIST',
  SET_FINANCE_INVOICE_CURRENT_PAGE = 'SET_FINANCE_INVOICE_CURRENT_PAGE',
  SET_FINANCE_INVOICE = 'SET_FINANCE_INVOICE',
  SET_FINANCE_CUSTOMER_CREDIT = 'SET_FINANCE_CUSTOMER_CREDIT',
  SET_FINANCE_CUSTOMER_CREDIT_CURRENT_PAGE = 'SET_FINANCE_CUSTOMER_CREDIT_CURRENT_PAGE',
  RESET_FINANCE_CUSTOMER_CREDIT_LIST = 'RESET_FINANCE_CUSTOMER_CREDIT_LIST',
  SET_ALL_FINANCE_CUSTOMER_CREDIT = 'SET_ALL_FINANCE_CUSTOMER_CREDIT',
  SET_ALL_FINANCE_INVOICE = 'SET_ALL_FINANCE_INVOICE',
  SET_FINANCE_CUSTOMER_SERVICE_STATMENT = 'SET_FINANCE_CUSTOMER_SERVICE_STATMENT',
  SET_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE = 'SET_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE',
  RESET_FINANCE_CUSTOMER_SERVICE_STATMENT_LIST = 'RESET_FINANCE_CUSTOMER_SERVICE_STATMENT_LIST',
  SET_CUSTOMER_ATTENDANCE = 'SET_CUSTOMER_ATTENDANCE',
  SET_LESSONS_CURRENT_PAGE = 'SET_LESSONS_CURRENT_PAGE',
  SET_LESSONS_FILTER = 'SET_LESSONS_FILTER',
  RESET_FINANCE_CUSTOMER_BALANCE_STATMENT_LIST = 'RESET_FINANCE_CUSTOMER_BALANCE_STATMENT_LIST',
  SET_FINANCE_CUSTOMER_BALANCE_STATMENT_CURRENT_PAGE = 'SET_FINANCE_CUSTOMER_BALANCE_STATMENT_CURRENT_PAGE',
  SET_FINANCE_CUSTOMER_BALANCE_STATMENT = 'SET_FINANCE_CUSTOMER_BALANCE_STATMENT',
  SET_ALL_FINANCE_CUSTOMER_SERVICE = 'SET_ALL_FINANCE_CUSTOMER_SERVICE',
  SET_CUSTOMER_CREDIT_FILTER = 'SET_CUSTOMER_CREDIT_FILTER',
  SET_FINANCE_CUSTOMER_PAYMENT_HISTORY = 'SET_FINANCE_CUSTOMER_PAYMENT_HISTORY',
  SET_CUSTOMER_SERVICE_FILTER = 'SET_CUSTOMER_SERVICE_FILTER',
  SET_CUSTOMER_BALANCE_FILTER = 'SET_CUSTOMER_BALANCE_FILTER',
  SET_LESSONS = 'SET_LESSONS',
  SET_HORSE_LESSONS = 'SET_HORSE_LESSONS',
  SET_HORSE_LESSONS_COUNT = 'SET_HORSE_LESSONS_COUNT',
  SET_GROOMER_LESSONS_COUNT = 'SET_GROOMER_LESSONS_COUNT',
  SET_CUSTOMER_BALANCE_REPORT = 'SET_CUSTOMER_BALANCE_REPORT',
  SET_CUSTOMER_PAYMENT_REPORT = 'SET_CUSTOMER_PAYMENT_REPORT',
  SET_CUSTOMER_STATMENT_REPORT = 'SET_CUSTOMER_STATMENT_REPORT',
  SET_LESSONS_HORSES = 'SET_LESSONS_HORSES',
  SET_HORSE_BY_GROUP = 'SET_HORSE_BY_GROUP',
  SET_LESSONS_STUDENT_COUNT = 'SET_LESSONS_STUDENT_COUNT',
  SET_LESSONS_STUDENT_GROUP_BY_DATE = 'SET_LESSONS_STUDENT_GROUP_BY_DATE',
  SET_ALL_MEDICAL_HISTORY = 'SET_ALL_MEDICAL_HISTORY',
  SET_HORSE_DAILY_LESSON = 'SET_HORSE_DAILY_LESSON',
  SET_GROOMS_DAILY_LESSON = 'SET_GROOMS_DAILY_LESSON',
  SET_ARENA_DAILY_LESSON = 'SET_ARENA_DAILY_LESSON',
  SET_GROOMER_CURRENT_PAGE = 'SET_GROOMER_CURRENT_PAGE',
  RESET_CONTENT_PAGES_LIST = 'RESET_CONTENT_PAGES_LIST',
  SET_CONTENT_PAGE_FILTER = 'SET_CONTENT_PAGE_FILTER',
  SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT',
  SET_ALL_ANNOUNCEMENT = 'SET_ALL_ANNOUNCEMENT',
  SET_ANNOUNCEMENT_CURRENT_PAGE = 'SET_ANNOUNCEMENT_CURRENT_PAGE',
  RESET_ANNOUNCEMENT_LIST = 'RESET_ANNOUNCEMENT_LIST',
  SET_ANNOUNCEMENT_FILTER = 'SET_ANNOUNCEMENT_FILTER',
  SET_SERVICES = 'SET_SERVICES',
  SET_ALL_SERVICES = 'SET_ALL_SERVICES',
  SET_SERVICES_CURRENT_PAGE = 'SET_SERVICES_CURRENT_PAGE',
  RESET_SERVICES_LIST = 'RESET_SERVICES_LIST',
  SET_SERVICES_FILTER = 'SET_SERVICES_FILTER',
  SET_LESSONS_PENDING = 'SET_LESSONS_PENDING',
  SET_ALL_LESSONS_PENDING = 'SET_ALL_LESSONS_PENDING',
  SET_LESSONS_PENDING_CURRENT_PAGE = 'SET_LESSONS_PENDING_CURRENT_PAGE',
  RESET_LESSONS_PENDING_LIST = 'RESET_LESSONS_PENDING_LIST',
  SET_LESSONS_PENDING_FILTER = 'SET_LESSONS_PENDING_FILTER',
  SET_SHOPS = 'SET_SHOPS',
  SET_ALL_SHOPS = 'SET_ALL_SHOPS',
  SET_SHOP_CURRENT_PAGE = 'SET_SHOP_CURRENT_PAGE',
  RESET_SHOP_LIST = 'RESET_SHOP_LIST',
  SET_SHOP_FILTER = 'SET_SHOP_FILTER',
  SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER',
  RESET_CATEGORY_LIST = 'RESET_CATEGORY_LIST',
  SET_CATEGORY_CURRENT_PAGE = 'SET_CATEGORY_CURRENT_PAGE',
  SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS',
  SET_PRODUCT_CURRENT_PAGE = 'SET_PRODUCT_CURRENT_PAGE',
  RESET_PRODUCT_LIST = 'RESET_PRODUCT_LIST',
  SET_PRODUCT_FILTER = 'SET_PRODUCT_FILTER',
  SET_OFFERS = 'SET_OFFERS',
  SET_ALL_OFFERS = 'SET_ALL_OFFERS',
  SET_OFFER_CURRENT_PAGE = 'SET_OFFER_CURRENT_PAGE',
  RESET_OFFER_LIST = 'RESET_OFFER_LIST',
  SET_OFFER_FILTER = 'SET_OFFER_FILTER',
  SET_CANCELLATIONS = 'SET_CANCELLATIONS',
  SET_ALL_CANCELLATIONS = 'SET_ALL_CANCELLATIONS',
  SET_CANCELLATION_CURRENT_PAGE = 'SET_CANCELLATION_CURRENT_PAGE',
  RESET_CANCELLATION_LIST = 'RESET_CANCELLATION_LIST',
  SET_CANCELLATION_FILTER = 'SET_CANCELLATION_FILTER',
  SET_TICKET = 'SET_TICKET',
  SET_ALL_TICKET = 'SET_ALL_TICKET',
  SET_TICKET_CURRENT_PAGE = 'SET_TICKET_CURRENT_PAGE',
  RESET_TICKET_LIST = 'RESET_TICKET_LIST',
  SET_TICKET_FILTER = 'SET_TICKET_FILTER',
  SET_TICKET_MESSAGE_CURRENT_PAGE = 'SET_TICKET_MESSAGE_CURRENT_PAGE',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_NOTIFICATIONS_CURRENT_PAGE = 'SET_NOTIFICATIONS_CURRENT_PAGE',
  SET_PENDINGACCOUNT = 'SET_PENDINGACCOUNT',
  SET_ALL_PENDINGACCOUNTS = 'SET_ALL_PENDINGACCOUNTS',
  SET_PENDINGACCOUNT_CURRENT_PAGE = 'SET_PENDINGACCOUNT_CURRENT_PAGE',
  RESET_PENDINGACCOUNT_LIST = 'RESET_PENDINGACCOUNT_LIST',
  RESET_NOTIFICATION_LIST = 'RESET_NOTIFICATION_LIST',
  SET_NOTIFICATIONS_PARENT = 'SET_NOTIFICATIONS_PARENT',
  RESET_NOTIFICATION_PARENT_LIST = 'RESET_NOTIFICATION_PARENT_LIST',
  SET_NOTIFICATIONS_PARENT_CURRENT_PAGE = 'SET_NOTIFICATIONS_PARENT_CURRENT_PAGE',
  SET_DAILY_SERVICES_REPORT = 'SET_DAILY_SERVICES_REPORT',
}

export { Actions, Mutations };
