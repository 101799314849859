
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default defineComponent({
    name: 'admins-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('adminUsersListing'), [
          translate('members'),
        ]);

        onBeforeUnmount(() => {
          store.dispatch(Actions.RESET_ADMINS_STORE);
        });
        loading.value = true;
        await store.dispatch(Actions.GET_ADMIN_USERS);
        loading.value = false;
      });

      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('name'),
          key: 'name',
          sortable: true,
        },
        {
          name: translate('email'),
          key: 'email',
          sortable: true,
        },
        {
          name: translate('Admin Role'),
          key: 'roleName',
          sortable: false,
        },
        {
          name: translate('status'),
          key: 'isActive',
          sortable: false,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusUser = async (admin) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_ADMIN_ACCOUNT, admin.id);
          await store.dispatch(Actions.GET_ADMIN_USERS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editUser = (id) => {
        router.push({ name: 'admins-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val, fromSearch) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_ADMIN_CURRENT_PAGE, val);
        await store.dispatch(
          Actions.GET_ADMIN_USERS,
          fromSearch ? val : undefined
        );
        loading.value = false;
      };

      return {
        tableData: computed(() => store.getters.adminsList),
        tableHeader,
        toggleStatusUser,
        translate,
        totalItems: computed(() => store.getters.listCount),
        pageChanged,
        goTo,
        editUser,
        loading,
        can,
      };
    },
  });
