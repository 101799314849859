import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-8" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_6 = {
  class: "card-toolbar",
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "top",
  "data-bs-trigger": "hover",
  title: "Click to clear all permissions"
}
const _hoisted_7 = { class: "svg-icon svg-icon-3" }
const _hoisted_8 = { class: "card-body py-3" }
const _hoisted_9 = { class: "table-responsive" }
const _hoisted_10 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_11 = { class: "fw-bolder text-muted" }
const _hoisted_12 = { class: "text-uppercase" }
const _hoisted_13 = { class: "text-uppercase" }
const _hoisted_14 = { class: "text-uppercase" }
const _hoisted_15 = { class: "text-uppercase" }
const _hoisted_16 = { class: "text-uppercase" }
const _hoisted_17 = { class: "text-dark fw-bolder d-block fs-6" }
const _hoisted_18 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_19 = ["value"]
const _hoisted_20 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_21 = ["value"]
const _hoisted_22 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_23 = ["value"]
const _hoisted_24 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_25 = ["value"]
const _hoisted_26 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_27 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translate('selectPermissions')), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate('permissionsCount', { count: Object.keys(_ctx.perms).length })), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("a", {
          href: "#",
          class: _normalizeClass(["btn btn-sm btn-light", _ctx.selectedPerms.length ? '' : 'disabled']),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.resetSelected && _ctx.resetSelected(...args)), ["prevent"]))
        }, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr011.svg" })
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.translate('clearAll')), 1)
        ], 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("table", _hoisted_10, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", _hoisted_11, [
              _createElementVNode("th", null, _toDisplayString(_ctx.translate('permission')), 1),
              _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.translate('read')), 1),
              _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.translate('create')), 1),
              _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.translate('update')), 1),
              _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.translate('delete')), 1),
              _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.translate('menu')), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.perms, (item, key, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(key), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_18, [
                    _withDirectives((_openBlock(), _createElementBlock("input", {
                      class: "form-check-input widget-9-check",
                      type: "checkbox",
                      value: _ctx.getCheckboxValue(item, 'read'),
                      key: `${index}-read`,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedPerms) = $event))
                    }, null, 8, _hoisted_19)), [
                      [_vModelCheckbox, _ctx.selectedPerms]
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_20, [
                    _withDirectives((_openBlock(), _createElementBlock("input", {
                      class: "form-check-input widget-9-check",
                      type: "checkbox",
                      value: _ctx.getCheckboxValue(item, 'create'),
                      key: `${index}-create`,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedPerms) = $event))
                    }, null, 8, _hoisted_21)), [
                      [_vModelCheckbox, _ctx.selectedPerms]
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_22, [
                    _withDirectives((_openBlock(), _createElementBlock("input", {
                      class: "form-check-input widget-9-check",
                      type: "checkbox",
                      value: _ctx.getCheckboxValue(item, 'update'),
                      key: `${index}-update`,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedPerms) = $event))
                    }, null, 8, _hoisted_23)), [
                      [_vModelCheckbox, _ctx.selectedPerms]
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_24, [
                    _withDirectives((_openBlock(), _createElementBlock("input", {
                      class: "form-check-input widget-9-check",
                      type: "checkbox",
                      value: _ctx.getCheckboxValue(item, 'remove'),
                      key: `${index}-remove`,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedPerms) = $event))
                    }, null, 8, _hoisted_25)), [
                      [_vModelCheckbox, _ctx.selectedPerms]
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_26, [
                    _withDirectives((_openBlock(), _createElementBlock("input", {
                      class: "form-check-input widget-9-check",
                      type: "checkbox",
                      value: _ctx.getCheckboxValue(item, 'menu'),
                      key: `${index}-menu`,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedPerms) = $event))
                    }, null, 8, _hoisted_27)), [
                      [_vModelCheckbox, _ctx.selectedPerms]
                    ])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}