
  import { defineComponent, ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import { useStore } from 'vuex';
  import SearchField from '../common/SearchField.vue';

  export default defineComponent({
    name: 'invoice-filter',
    emit: ['filter-selected', 'reset'],
    components: {
      SearchField,
    },
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const selectedItems = ref({
        balanceFrom: '',
        balanceTo: '',
        customerId: '',
        isPaid: '',
        paidAt: '',
      });
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmit = () => {
        emit('filter-selected', selectedItems);
      };

      const onReset = () => {
        selectedItems.value = {
          balanceFrom: '',
          balanceTo: '',
          customerId: '',
          isPaid: '',
          paidAt: '',
        };
        emit('reset', selectedItems.value);
      };

      const onPaidAtChange = (date) => {
        selectedItems.value.paidAt = date;
      };

      reinitializeComponents();

      return {
        onSubmit,
        onReset,
        selectedItems,
        translate,
        customers: computed(() => store.getters.allCustomersList),
        onPaidAtChange,
      };
    },
  });
