
  import { defineComponent, ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import { useStore } from 'vuex';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import SearchField from '@/components/common/SearchField.vue';

  export default defineComponent({
    name: 'staff-filter',
    emit: ['filter-selected', 'reset'],
    components: {
      SearchField,
    },
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const selectedItems = ref({
        statusId: '',
        staffId: '',
      });
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmit = () => {
        emit('filter-selected', selectedItems.value);
      };

      const onReset = () => {
        selectedItems.value = {
          statusId: '',
          staffId: '',
        };
        emit('reset', selectedItems.value);
      };
      const lookupsList = computed(() => store.getters.allLookupsList);

      const statuses = computed(() =>
        lookupsList.value.filter((lookup) => {
          return lookup.lookupNameId === LookupNameIds.STATUS;
        })
      );

      reinitializeComponents();

      return {
        onSubmit,
        onReset,
        selectedItems,
        translate,
        statuses,
        staffList: store.getters.allStaffList,
      };
    },
  });
