
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Multiselect from '@vueform/multiselect';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'class-level-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const selectedCategory = ref<Array<string>>([]);

      const imageFile = ref<File | null>(null);
      const imagePreview = ref<string | null>(null);

      const onImageChange = (event: Event) => {
        const file = (event.target as HTMLInputElement).files?.[0] || null;
        if (file) {
          imageFile.value = file;
          const reader = new FileReader();
          reader.onload = (e) => {
            imagePreview.value = e.target?.result as string;
          };
          reader.readAsDataURL(file);
        }
      };
      const removeImage = () => {
        imageFile.value = null;
        imagePreview.value = null;
      };

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('classLevelCreating'), [
          translate('settings'),
        ]);
      });

      const classCategory = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      );
      const classCategoryTitle = () => {
        return classCategory.map((i) => {
          return { title: i.nameEn, id: i.id };
        });
      };

      const validationSchema = Yup.object().shape({
        name: Yup.string()
          .required(() => translate('NAME_IS_REQUIRED_FIELD'))
          .label('name'),
        color: Yup.string()
          .required(() => translate('COLOR_CODE_IS_REQUIRED_FIELD'))
          .label('color'),
        classCategory: Yup.array()
          .required(() => translate('CLASS_CATEGORY_IS_REQUIRED_FIELD'))
          .min(1, () => translate('CLASS_CATEGORY_IS_REQUIRED_FIELD')),
      });
      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const data = await store.dispatch(
          Actions.CREATE_SETTING_CLASS_LEVEL,
          values
        );
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          const formData = new FormData();
          if (imageFile.value) {
            formData.append('attachments', imageFile.value);
            await store.dispatch(Actions.UPLOAD_CLASS_LEVEL_ATTACHMENT, {
              data: formData,
              id: data.data.id,
            });
            const [errorName] = Object.keys(store.getters.getErrors);
            const error = store.getters.getErrors[errorName];
            if (error) {
              Swal.fire({
                text: translate(error[0]),
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: translate('tryAgainExcl'),
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger',
                },
              });
            } else {
              Swal.fire({
                text: translate('SUCCESSFULLY_CREATED_CLASS_LEVEL'),
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: translate('ok'),
                customClass: {
                  confirmButton: 'btn btn-light-primary',
                },
              });
            }
          } else {
            Swal.fire({
              text: translate('SUCCESSFULLY_CREATED_CLASS_LEVEL'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }

          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
      };

      return {
        onSubmitCreate,
        classCategoryTitle,
        selectedCategory,
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        imagePreview,
        onImageChange,
        removeImage,
      };
    },
  });
