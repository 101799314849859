
  import {
    defineComponent,
    onMounted,
    ref,
    computed,
    reactive,
    nextTick,
  } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import printJs from 'print-js';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import {} from '@/assets/ts/arabic-font';
  import moment from 'moment';
  import { injectSearch } from '@/core/plugins/search-service';
  import SearchField from '@/components/common/SearchField.vue';
  import { loadImage } from '../../../utils/loadImage';

  export default defineComponent({
    name: 'customer-account-report',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const loadingPage = ref(true);
      const searchService = injectSearch();

      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const reportData = ref();
      const selectedCustomer = ref<number>(0);
      const selectedTrainer = ref<number>(0);
      const selectedHorse = ref<number>(0);
      const selectedArena = ref<number>(0);
      const selectedAttendance = ref<number>(0);
      const selecteDuration = ref<number>(0);
      const selecteType = ref<number>(0);
      const selectedDay = ref<number>(0);
      const selectedGroomer = ref<number>(0);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const startDate = ref(moment().startOf('day')._d);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const endDate = ref(moment().startOf('day')._d);
      const startTime = ref();
      const endTime = ref();
      const jsonExcel = ref();

      const firstDateOfSelectedMonth = moment(startDate.value)
        .startOf('month')
        .format('YYYY-MM-DD');
      const lastDateOfSelectedMonth = moment(endDate.value)
        .endOf('month')
        .format('YYYY-MM-DD');
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('All Customer Account'), [
          translate('reports'),
        ]);
        store.dispatch(Actions.GET_ALL_CUSTOMERS);
        store.dispatch(Actions.GET_ALL_TRAINERS, {});
        store.dispatch(Actions.GET_ALL_ARENAS);
        store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS);
        const getSaveData = searchService.getSearchValue(
          'AllCustomerAccountReport'
        );
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(
                searchService.getSearchValue('AllCustomerAccountReport')
              )
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.startDate) startDate.value = dataSaved.startDate;
          if (dataSaved.endDate) endDate.value = dataSaved.endDate;
          if (dataSaved.startTime) startTime.value = dataSaved.startTime;
          if (dataSaved.endTime) endTime.value = dataSaved.endTime;
          if (dataSaved.groomerId) selectedGroomer.value = dataSaved.groomerId;
          if (dataSaved.dayId) selectedDay.value = dataSaved.dayId;
          if (dataSaved.typeId) selecteType.value = dataSaved.typeId;
          if (dataSaved.durationId)
            selecteDuration.value = dataSaved.durationId;
          if (dataSaved.attendanceId)
            selectedAttendance.value = dataSaved.attendanceId;
          if (dataSaved.arenaId) selectedArena.value = dataSaved.arenaId;
          if (dataSaved.horseId) selectedHorse.value = dataSaved.horseId;
          if (dataSaved.trainerId) selectedTrainer.value = dataSaved.trainerId;
          if (dataSaved.customerId)
            selectedCustomer.value = dataSaved.customerId;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });
      const clearSearch = () => {
        searchService.removeSearchValue('AllCustomerAccountReport');
        selectedCustomer.value = 0;
        selectedTrainer.value = 0;
        selectedHorse.value = 0;
        selectedArena.value = 0;
        selectedAttendance.value = 0;
        selecteDuration.value = 0;
        selecteType.value = 0;
        selectedDay.value = 0;
        selectedGroomer.value = 0;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        startDate.value = moment().startOf('day')._d;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        endDate.value = moment().startOf('day')._d;
        startTime.value = null;
        endTime.value = null;
      };

      const print = async (values) => {
        values.startDate = moment(values.startDate).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        );
        values.endDate = moment(values.endDate).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        );

        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
        searchService.setSearchValue('AllCustomerAccountReport', values);

        const user = getUser();
        const userName = user ? user['name'] : '';

        await store.dispatch(Actions.GET_ALL_CUSTOMER_ACCOUNT, values);

        reportData.value = store.getters.getCustomersAttendance;

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'portrait',
        });
        const fontSize = doc.getFontSize();

        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;

        const dataLength = reportData.value.data.length;
        if (dataLength === 0) {
          doc.text('No Result Found', 120, 70);
          return;
        }
        let startY = 45;

        let pageCount = 0;
        let totalAmountDueTo = 0;
        let totalFees = 0;
        let totalDueUpTo = 0;
        let totalPaymentReceived = 0;
        let totalBalanceDue = 0;
        const resTabelBody: any[] = [];
        reportData.value.data.forEach(function (element, index) {
          const datesOfAttended = element.lessons.map((e) => {
            const day = moment(e.startDate).date();
            return `${day}(${e.type.nameEn[0]})`;
          });
          totalAmountDueTo += element['balanceStartDate'];
          totalFees += element['totalPrice'];
          totalDueUpTo += element['remainingBalance'];
          totalPaymentReceived += element['totalReceipt'];
          totalBalanceDue += element['balance'];
          resTabelBody.push([
            index + 1,
            element['name'],
            element['balanceStartDate'],
            datesOfAttended.join(','),
            element['totalPrice'],
            element['remainingBalance'],
            element['totalReceipt'],
            element['balance'],
          ]);
        });
        resTabelBody.push([
          '',
          'Total :',
          `${totalAmountDueTo.toFixed(3)}`,
          '',
          `${totalFees.toFixed(3)}`,
          `${totalDueUpTo.toFixed(3)}`,
          `${totalPaymentReceived.toFixed(3)}`,
          `${totalBalanceDue.toFixed(3)}`,
        ]);
        autoTable(doc, {
          columnStyles: {
            // reduce the width for numbers
            0: { cellWidth: 10, overflow: 'ellipsize' },
            1: { cellWidth: 45, overflow: 'linebreak' },
            2: { cellWidth: 20, overflow: 'linebreak' },
            3: { cellWidth: 40, overflow: 'linebreak' },
            4: { cellWidth: 20, overflow: 'linebreak' },
            5: { cellWidth: 20, overflow: 'linebreak' },
            6: { cellWidth: 20, overflow: 'linebreak' },
            7: { cellWidth: 20, overflow: 'linebreak' },
          },
          head: [
            [
              'id',
              'Name',
              `Amount due to ${firstDateOfSelectedMonth}`,
              'Dates Of attended',
              'Total fees',
              `Total due up to ${lastDateOfSelectedMonth}`,
              'Payment receive this month',
              `Balance due as on ${lastDateOfSelectedMonth}`,
            ],
          ],
          body: resTabelBody,
          startY: startY,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          margin: { horizontal: 8, top: 45 },
          horizontalPageBreak: false,
          theme: 'grid',
          headStyles: {
            fillColor: [0, 72, 115],
            halign: 'left',
            fontSize: 7.5,
          },
          bodyStyles: {
            fontStyle: 'bold',
            halign: 'left',
            fontSize: 7.5,
          },
          styles: {
            fontSize: 12,
          },
          didDrawPage: function () {
            doc.setFontSize(10);
            pageCount++;
            const pageCountWidth = doc.getTextWidth(String(pageCount));

            doc.text(
              'Page ' + pageCount,
              textX - pageCountWidth + 10,
              doc.internal.pageSize.height - 9
            );
            doc.setFontSize(fontSize);
          },
        });
        const logoImage = await loadImage('/media/logos/empty-krc.png');

        Array.from({ length: pageCount }, (x, i) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          doc.addImage(logoImage, 'png', 5, 3, 35, 35);
          doc.setFontSize(14);
          doc.setFontSize(14);
          doc.setFont('helvetica', 'bold');
          doc.text('Kuwait Riding Center', textX, 15);
          doc.setFontSize(12);
          doc.text('All Customer Account', textX - 7, 20);
          doc.setFontSize(10);
          doc.text(
            'Start Date: ' + moment(values.startDate).format('LLL'),
            textX - 30,
            26
          );
          doc.text(
            'End Date: ' + moment(values.endDate).format('LLL'),
            textX + 40,
            26
          );

          doc.text('Print DateTime: ' + moment(new Date()).format('LLL'), 5, 8);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          doc.setPage(i + 1);
          doc.text(userName, 5, doc.internal.pageSize.height - 9);
          doc.setFont('helvetica', 'normal');

          doc.text(
            '     of ' + doc.getNumberOfPages(),
            textX + 15,
            doc.internal.pageSize.height - 9
          );
          doc.setFontSize(10);
        });

        // doc.save('table.pdf');
        // doc.autoPrint();
        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
        window.open(blobUrl, '_blank');

        // printJs({ printable: blobUrl, type: 'pdf' });
      };

      const isFetching = ref(false);
      const dataReport = ref();
      const excelData = ref();
      const fetchAndExportData = async () => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
        try {
          // Gather form data into an object
          const formData = {
            startDate: moment(startDate.value).format(
              'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
            ),
            endDate: moment(endDate.value).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            startTime: startTime.value,
            endTime: endTime.value,
            groomerId: selectedGroomer.value,
            dayId: selectedDay.value,
            typeId: selecteType.value,
            durationId: selecteDuration.value,
            attendanceId: selectedAttendance.value,
            arenaId: selectedArena.value,
            horseId: selectedHorse.value,
            trainerId: selectedTrainer.value,
            customerId: selectedCustomer.value,
          };

          // Pass formData to the fetch request
          const response = await store.dispatch(
            Actions.GET_ALL_CUSTOMER_ACCOUNT,
            formData
          );
          dataReport.value = response.data;

          let totalAmountDueTo = 0;
          let totalFees = 0;
          let totalDueUpTo = 0;
          let totalPaymentReceived = 0;
          let totalBalanceDue = 0;
          const resTabelBody: any = [];
          dataReport.value.forEach(function (element, index) {
            const datesOfAttended = element.lessons.map((e) => {
              const day = moment(e.startDate).date();
              return `${day}(${e.type.nameEn[0]})`;
            });
            totalAmountDueTo += element['balanceStartDate'];
            totalFees += element['totalPrice'];
            totalDueUpTo += element['remainingBalance'];
            totalPaymentReceived += element['totalReceipt'];
            totalBalanceDue += element['balance'];
            resTabelBody.push({
              ID: index + 1,
              Name: element['name'],
              [`Amount due to ${firstDateOfSelectedMonth}`]:
                element['balanceStartDate'],
              'Dates Of attended': datesOfAttended.join(','),
              'Total fees': element['totalPrice'],
              [`Total due up to ${lastDateOfSelectedMonth}`]:
                element['remainingBalance'],
              'Payment receive this month': element['totalReceipt'],
              [`Balance due as on ${lastDateOfSelectedMonth}`]:
                element['balance'],
            });
          });
          resTabelBody.push({
            ID: '',
            Name: 'Total :',
            [`Amount due to ${firstDateOfSelectedMonth}`]:
              totalAmountDueTo.toFixed(3),
            'Dates Of attended': '',
            'Total fees': totalFees.toFixed(3),
            [`Total due up to ${lastDateOfSelectedMonth}`]:
              totalDueUpTo.toFixed(3),
            'Payment receive this month': totalPaymentReceived.toFixed(3),
            [`Balance due as on ${lastDateOfSelectedMonth}`]:
              totalBalanceDue.toFixed(3),
          });

          console.log(resTabelBody, 'resTabelBody');
          excelData.value = resTabelBody;

          console.log(excelData.value, 'excelData.value');
          nextTick(() => {
            jsonExcel.value.generate();
            jsonExcel.value.$el.style.display = 'none';
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          isFetching.value = false;
        }
      };
      const validationSchema = Yup.object().shape({});

      const excelHeader = reactive({
        ID: 'ID',
        Name: 'Name',
        [`Amount due to ${String(
          firstDateOfSelectedMonth
        )}`]: `Amount due to ${String(firstDateOfSelectedMonth)}`,
        'Dates Of attended': 'Dates Of attended',
        'Total fees': 'Total fees',
        [`Total due up to ${String(
          lastDateOfSelectedMonth
        )}`]: `Total due up to ${String(lastDateOfSelectedMonth)}`,
        'Payment receive this month': 'Payment receive this month',
        [`Balance due as on ${String(
          lastDateOfSelectedMonth
        )}`]: `Balance due as on ${String(lastDateOfSelectedMonth)}`,
      });

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.DAYS,
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.DURATION,
        LookupNameIds.STATUS,
        LookupNameIds.ATTENDEND,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.ATTENDEND;
      });
      const classType = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
      });

      const dayList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DAYS;
      });

      const durationList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DURATION;
      });

      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        reportData,
        customers: computed(() => store.getters.allCustomersList),
        trainers: computed(() => store.getters.allTrainersList),
        arenas: computed(() => store.getters.allArenasList),
        horses: computed(() => store.getters.allHorsesList),
        groomers: computed(() => store.getters.allGroomerList),
        statusList,
        classType,
        // categoryList,
        dayList,
        durationList,
        selectedCustomer,
        selectedTrainer,
        selectedArena,
        selectedHorse,
        selecteDuration,
        selectedAttendance,
        selecteType,
        selectedDay,
        loadingPage,
        clearSearch,
        excelHeader,
        excelData,
        fetchAndExportData,
        jsonExcel,
      };
    },
  });
