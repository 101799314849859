// SearchService.ts

import { reactive, provide, inject, Ref, ref } from 'vue';

interface SearchState {
  searchValue: Ref<any>;
}

const SearchSymbol = Symbol();

export function createSearchService() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const state: SearchState = reactive({
    searchValue: ref({}),
  });

  const setSearchValue = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  const getSearchValue = (value) => {
    if (localStorage.getItem(value)) return localStorage.getItem(value);
    else return {};
  };
  const removeSearchValue = (value) => {
    localStorage.removeItem(value);
  };

  return {
    state,
    setSearchValue,
    getSearchValue,
    removeSearchValue,
  };
}

export function provideSearch() {
  provide(SearchSymbol, createSearchService());
}

export function injectSearch() {
  return inject(SearchSymbol) as SearchService;
}

interface SearchService {
  searchValue: Ref<any>;
  setSearchValue: (key, value) => void;

  removeSearchValue: (value) => void;
  getSearchValue: (value) => any;
}
