
  import { defineComponent, ref, computed, watch } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import { Field } from 'vee-validate';
  import LookupNameIds from '@/store/enums/settingsEnums';

  export default defineComponent({
    name: 'treatment-modal',
    components: {
      Field,
    },
    props: {
      horseId: { type: String, default: '' },
      treatmentId: { type: Number, default: 0 },
      index: { type: Number, default: 1 },
      editTreatiment: { type: Boolean, default: false },
    },
    emit: ['create'],

    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const { can } = useAbility();

      const submitButton = ref<HTMLButtonElement | null>(null);

      const treatmentDate = ref('');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const medicalRecords = ref();

      const editMedical = async (id) => {
        const data = await store.dispatch(Actions.GET_HORSE, id);
        medicalRecords.value = data[0].medicalRecords;
      };

      const submitBtn = () => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        if (props.editTreatiment) {
          emit('edit', treatmentPayload.value);
        } else {
          emit('create', treatmentPayload.value);
        }
      };
      const lookupsList = computed(() => store.getters.allLookupsList);

      const treatmantLIst = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.TREATMENT_TYPE;
      });
      const documentsType = computed(() =>
        lookupsList.value.filter((lookup) => {
          return lookup.lookupNameId === LookupNameIds.DOCUMENT_TYPE;
        })
      );
      const treatmentPayload = ref({
        horseId: props.horseId,
        treatmentId: props.treatmentId,
        treatmentDate: '',
        docuemntTypeId: '',
        attachments: [],
        index: props.index,
      });

      const disabledSubmitButton = () => {
        if (props.editTreatiment) {
          return treatmentPayload.value.attachments.length === 0;
        } else {
          return (
            treatmentPayload.value.treatmentDate === '' ||
            treatmentPayload.value.treatmentId === 0 ||
            treatmentPayload.value.horseId === '' ||
            (treatmentPayload.value.attachments.length > 0 &&
              !treatmentPayload.value.docuemntTypeId)
          );
        }
      };

      watch(
        () => props.horseId,
        (first) => {
          treatmentPayload.value.horseId = first;
        },
        { immediate: true }
      );
      return {
        translate,
        totalItems: computed(() => store.getters.horseslistCount),
        editMedical,
        can,
        allHorsesList: computed(() => store.getters.allHorsesList),
        treatmantLIst,
        documentsType,
        treatmentDate,
        submitBtn,
        submitButton,
        medicalRecords,
        treatmentPayload,
        disabledSubmitButton,
      };
    },
  });
