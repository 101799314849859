
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import GroomerFilter from '@/components/dropdown/GroomerFilter.vue';
  import LookupNameIds from '@/store/enums/settingsEnums';

  export default defineComponent({
    name: 'admins-listing',
    components: {
      GroomerFilter,
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const inputText = ref('');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('groomerListing'), [
          translate('operations'),
        ]);

        onBeforeUnmount(() => {
          store.dispatch(Actions.RESET_GROOMER_STORE);
        });
        loading.value = true;
        await store.dispatch(Actions.GET_GROOMERS_OPERATIONS);
        loading.value = false;
      });

      const loading = ref(false);

      const excelHeader = reactive({
        Name: 'name',
        Email: 'email',
        Phone: 'phone',
        Address: 'address',
      });
      const tableHeader = ref([
        {
          name: translate('name'),
          key: 'name',
          sortable: true,
        },
        {
          name: translate('phone Number'),
          key: 'phone',
          sortable: false,
        },

        {
          name: translate('gendedr'),
          key: 'genderName',
          sortable: false,
        },
        {
          name: translate('status'),
          key: 'statusName',
          sortable: false,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusUser = async (groomer) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_GROOMER, groomer.id);
          await store.dispatch(Actions.GET_GROOMERS_OPERATIONS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editUser = (id) => {
        router.push({ name: 'operation-groomers-editing', params: { id } });
      };

      const searchItems = async () => {
        await store.dispatch(Actions.UPDATE_GROOMER_FILTER, {
          name: inputText.value,
        });
        await store.dispatch(Actions.GET_GROOMERS_OPERATIONS);
      };
      const searchGroomer = async (query) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_GROOMER_FILTER, {
          ...query,
        });
        await store.dispatch(Actions.UPDATE_GROOMER_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_GROOMERS_OPERATIONS);
        await store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS, false);
        loading.value = false;
      };
      const onReset = async (query) => {
        await store.dispatch(Actions.UPDATE_GROOMER_FILTER, query);
        await store.dispatch(Actions.UPDATE_GROOMER_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_GROOMERS_OPERATIONS);
        await store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS, false);
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val, fromSearch) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_GROOMER_CURRENT_PAGE, val);
        await store.dispatch(
          Actions.GET_GROOMERS_OPERATIONS,
          fromSearch ? val : undefined
        );
        loading.value = false;
      };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.STATUS,
      ]);

      await store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS, false);

      return {
        tableData: computed(() => store.getters.groomerList),
        tableHeader,
        toggleStatusUser,
        translate,
        totalItems: computed(() => store.getters.groomerlistCount),
        pageChanged,
        goTo,
        editUser,
        loading,
        can,
        excelData: computed(() => store.getters.allGroomerList),
        excelHeader,
        searchItems,
        inputText,
        searchGroomer,
        onReset,
      };
    },
  });
