
  import { defineComponent, onMounted, computed } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';

  export default defineComponent({
    name: 'dashboard-trainer',
    components: {},
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('trainerDashboard'), []);
        await store.dispatch(Actions.GET_LESSONS_STUDENT_COUNT);
      });

      const BG_COLOR = [
        { bgColor: 'bg-light', textColor: 'text-dark' },
        { bgColor: 'bg-success', textColor: 'text-light' },
        { bgColor: 'bg-info', textColor: 'text-light' },
        { bgColor: 'bg-danger', textColor: 'text-light' },
      ];
      const lessonsStudentCountList = computed(
        () => store.getters.lessonsStudentCountList
      );
      const lessonsStudentCountListWithFallback = computed(() => {
        const existingObjects = lessonsStudentCountList.value.slice(0, 4);
        const fallbackArray = Array.from({
          length: Math.max(4 - existingObjects.length, 0),
        }).map((_, index) => ({ durationId: index + existingObjects.length }));

        return [...existingObjects, ...fallbackArray];
      });

      return {
        translate,
        can,
        BG_COLOR,
        lessonsStudentCountListWithFallback,
      };
    },
  });
