
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
    onBeforeMount,
  } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { Field } from 'vee-validate';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import LessonsFilter from '@/components/dropdown/LessonsFilter.vue';
  import ReportAttendence from '@/components/Reports/Attendence.vue';
  import { getUser } from '@/core/services/AuthService';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import { convertDateDDMMYYYY } from '@/utils/dateFormat';
  import moment from 'moment';
  import SwapLessons from '@/components/dropdown/SwapLessons.vue';
  import SearchField from '@/components/common/SearchField.vue';

  export default defineComponent({
    name: 'lessons-listing',
    components: {
      Datatable,
      Field,
      ReportAttendence,
      LessonsFilter,
      SwapLessons,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const user = getUser();
      const isTrainer = user?.userType === 'trainer';

      const loading = ref(false);
      const showModal = ref(false);
      const showReasonModal = ref(false);
      const showMenu = ref(false);

      const horsesList = ref({});
      const classLevelList = ref();

      const searchQuery = ref({
        startDate: new Date()
          .toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          .replace(/\//g, '-'),
        endDate: new Date()
          .toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          .replace(/\//g, '-'),
        lessonId: route.query.lessonId,
      });

      const checkAttendenceResult = ref();

      const freezeDate = ref();
      const showFreezeDate = ref(false);
      const showCancellByKRC = ref(false);
      const cancellationReasonId = ref(0);
      const KRCCancelDate = ref();
      const showCancellationReason = ref(false);
      const showCommentModal = ref();

      const lessonComment = ref('');
      const lessonId = ref('');

      const dataReport = ref();

      const selectedRecored = ref<Array<string>>([]);
      const lessons = ref(
        [] as { lessonId: string; [key: string]: string | null | number }[]
      );

      let lookupsList;
      const disabledFilterButton = ref(true);
      const categoryList = ref();

      const tableMinHeight = () => {
        if (store.getters.lessonsList.length === 1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          document.querySelector('.table-responsive')!.style.minHeight =
            '290px';
        }
      };

      onBeforeMount(async () => {
        if (route.query.lessonId) {
          searchQuery.value.lessonId = route.query.lessonId;
          searchQuery.value.startDate = '';
          searchQuery.value.endDate = '';
        }

        await store.dispatch(Actions.UPDATE_LESSONS_FILTER, searchQuery.value);
      });

      onMounted(async () => {
        loading.value = true;
        setCurrentPageBreadcrumbs(translate('lessonListing'), [
          translate('operations'),
        ]);
        await store.dispatch(Actions.GET_LESSONS_OPERATIONS, searchQuery.value);
        tableMinHeight();
        await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
          LookupNameIds.ATTENDEND,
          LookupNameIds.STATUS,
          LookupNameIds.HORSE_CATEGORY,
          LookupNameIds.CLASS_TYPE,
          LookupNameIds.CANCEL_REASON,
          LookupNameIds.DURATION,
          LookupNameIds.DAYS,
        ]);
        await store.dispatch(Actions.GET_ALL_ARENAS, { active: true });
        await store.dispatch(Actions.GET_ALL_CUSTOMERS);
        await store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        await store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS);
        categoryList.value = await store.dispatch(
          Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
        );

        classLevelList.value = await store.dispatch(
          Actions.GET_ALL_SETTINGS_CLASS_LEVEL
        );

        if (!isTrainer) {
          await store.dispatch(Actions.GET_ALL_TRAINERS, {});
          dataReport.value = await store.dispatch(
            Actions.GET_REPORT_CUSTOMER_ATTENDENCE
          );
        }

        lookupsList = computed(() => store.getters.allLookupsList);

        loading.value = false;
        disabledFilterButton.value = false;
        reinitializeComponents();
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_LESSON_STORE);
      });
      const disabledField = ref([{}]);
      const disabledFieldOrStartTime = (id) => {
        const lesson = lessons.value.find((lesson) => lesson.lessonId === id);
        if (lesson && lesson.startDate) {
          console.log('disabled field');
          disabledField.value[id] = false;
          return true;
        } else {
          console.log('disabled time');
          disabledField.value[id] = true;
          return false;
        }
      };
      const handleSelectedField = (
        lessonId: string,
        fieldId: string,
        fieldName: string,
        time?: string
      ) => {
        const lessonIndex = lessons.value.findIndex(
          (lesson) => lesson.lessonId === lessonId
        );

        if (lessonIndex !== -1 && time) {
          lessons.value[lessonIndex].time = time;
        }

        let newLesson;
        if (lessonIndex === -1) {
          if (time) {
            newLesson = {
              lessonId: lessonId,
              [fieldName]: fieldId,
              time,
            };
          } else {
            newLesson = {
              lessonId: lessonId,
              [fieldName]: fieldId,
            };
          }

          lessons.value.push(newLesson);
        } else {
          lessons.value[lessonIndex][fieldName] = fieldId;
        }
        console.log(lessons.value);
        disabledFieldOrStartTime(lessonId);
      };

      const handleCategoryChanged = (lesson, categoryId) => {
        const categoryObj = categoryList.value.find((category) => {
          return category.id === categoryId;
        });
        matchedClassLevelList.value[lesson.id] = classLevelList.value.filter(
          (classLevel) => categoryObj.classLevelId?.includes(classLevel.id)
        );
        const lessonIndex = lessons.value.findIndex(
          (l) => l.lessonId === lesson.id
        );
        lessons.value[lessonIndex].classLevelId = null;
        console.log(lessons.value);
      };

      const isRecordSelected = (id: string) => {
        return selectedRecored.value.includes(id);
      };

      const enableEditing = (id: string) => {
        if (!isTrainer) {
          return isRecordSelected(id);
        }
        return false;
      };

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const excelHeader = reactive({
        Horse: 'horseName',
        Trainer: 'trainerName',
        Customer: 'customerName',
        Groomer: 'groomerName',
        Arena: 'arenaName',
        Duration: 'durationName',
        Status: 'attendance',
        Balance: 'price',
        Phone: 'phone',
        Time: 'time.startTime',
        'Start Date': 'startDate',
        Category: 'classCategoryName',
        'Class Level': 'classLevelName',
        'Lesson Type': 'lessonType',
      });
      const tableHeader = ref([
        {
          key: 'checkbox',
          sortable: true,
          cellWidth: '30px',
        },
        {
          name: translate('start Date'),
          key: 'startDate',
          sortable: true,
        },
        {
          name: translate('time'),
          key: 'time',
          sortable: true,
          cellWidth: '100px',
        },
        {
          name: translate('trainer Name'),
          key: 'trainerName',
          sortable: true,
          cellWidth: '125px',
        },
        {
          name: translate('customer Name'),
          key: 'customerName',
          sortable: true,
          cellWidth: '125px',
        },
        {
          name: translate('balance'),
          key: 'balance',
          sortable: true,
          cellWidth: '80px',
        },
        {
          name: translate('horse Name'),
          key: 'horseName',
          sortable: true,
          cellWidth: '125px',
        },
        {
          name: translate('arena Name'),
          key: 'arenaName',
          sortable: true,
        },

        {
          name: translate('groomer'),
          key: 'groomerName',
          sortable: true,
          cellWidth: '125px',
        },
        {
          name: translate('day'),
          key: 'dayName',
          sortable: true,
          cellWidth: '100px',
        },
        {
          name: translate('duration'),
          key: 'durationName',
          sortable: true,
          cellWidth: '80px',
        },
        {
          name: translate('category'),
          key: 'classCategory',
          sortable: true,
          cellWidth: '125px',
        },
        {
          name: translate('classLevel'),
          key: 'classLevelName',
          sortable: true,
        },
        {
          name: translate('type'),
          key: 'classType',
          sortable: true,
        },
        {
          name: translate('horse Location'),
          key: 'horseLocation',
          sortable: true,
        },
        {
          name: translate('comment'),
          key: 'comment',
          sortable: true,
        },
        {
          name: translate('Price'),
          key: 'price',
          sortable: true,
          cellWidth: '60px',
        },
        {
          name: translate('status'),
          key: 'attendanceName',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const toggleStatusUser = async (lesson) => {
        const result = await Swal.fire({
          title: translate('You are about to delete this lesson'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_LESSONS, lesson.id);
          await store.dispatch(
            Actions.GET_LESSONS_OPERATIONS,
            searchQuery.value
          );
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_LESSONS_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_LESSONS_OPERATIONS, searchQuery.value);
        tableMinHeight();
        loading.value = false;
      };

      const updateLessonsInformation = async () => {
        lessons.value = lessons.value.map((lesson) => {
          if (lesson.startDate) {
            return {
              startDate: moment(lesson.startDate).format('YYYY-MM-DD'),
              lessonId: lesson.lessonId,
            };
          } else {
            return lesson;
          }
        });
        const checkClassLevelId = lessons.value.filter(
          (lesson) => lesson.classCategoryId && !lesson.classLevelId
        );
        if (checkClassLevelId.length > 0) {
          Swal.fire({
            text: translate('please select class level'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          return;
        }

        const data = await store.dispatch(Actions.UPDATE_LESSONS, {
          data: { lessons: [...lessons.value] },
        });

        if (Array.isArray(data.data)) {
          Swal.fire({
            text: translate(data.data[0].reason),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          selectedRecored.value = [];
          swappedLessons.value = [];
          data.data.map((record) => {
            record.lesson.map((lesson) => {
              selectedRecored.value.push(lesson.id);
            });
          });

          await store.dispatch(
            Actions.GET_LESSONS_OPERATIONS,
            searchQuery.value
          );
          tableMinHeight();
          return;
        }
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          selectedRecored.value = [];
          swappedLessons.value = [];

          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_LESSONS'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          loading.value = true;
          await store.dispatch(
            Actions.GET_LESSONS_OPERATIONS,
            searchQuery.value
          );
          tableMinHeight();

          loading.value = false;
          selectedRecored.value = [];
          lessons.value = [];
          checkLessosns.value = [];
          swappedLessons.value = [];
        }
      };

      interface ICheckLesson {
        lessonId: string;
        customerId: string;
        referenceId: string;
        dayId: string;
      }
      const checkLessosns = ref<Array<ICheckLesson>>([]);
      const swappedLessons = ref(
        [] as Array<{
          id: string;
          trainerId: string;
          horseId: string;
          groomerId: string;
          arenaId: string;
        }>
      );

      const recoverData = async (lessonId) => {
        const { data } = await store.dispatch(Actions.GET_LESSON, lessonId);
        console.log(store.getters.lessonsList, 'store.getters.lessonList');
        const lessonIndex = store.getters.lessonsList.findIndex(
          (lesson) => lesson.id === lessonId
        );
        if (lessonIndex !== -1) {
          store.getters.lessonsList[lessonIndex] = data.data;
        }
      };

      const matchedClassLevelList = ref({});
      const prepareLessonToCheck = async (lessonObj) => {
        const horsePayload = {
          time: lessonObj.time,
          startDate: lessonObj.startDate,
          numberOfLessons: 1,
          durationId: lessonObj.durationId,
          customerId: lessonObj.customerId,
          days: [lessonObj.dayId],
        };

        const lessonIndex = checkLessosns.value.findIndex(
          (lesson: { lessonId: string }) => lesson.lessonId === lessonObj.id
        );
        if (lessonIndex === -1) {
          delete horsesList[lessonObj.id];
          delete matchedClassLevelList.value[lessonObj.id];

          const categoryObj = categoryList.value.find((category) => {
            return category.id === lessonObj.classCategoryId;
          });
          matchedClassLevelList.value[lessonObj.id] =
            classLevelList.value.filter((classLevel) =>
              categoryObj.classLevelId?.includes(classLevel.id)
            );
          let data = await store.dispatch(
            Actions.GET_ALL_LESSONS_HORSE,
            horsePayload
          );
          data = [
            {
              nameEn: lessonObj.horseName,
              nameAr: lessonObj.horseName,
              id: lessonObj.horseId,
            },
            ...data,
          ];

          horsesList.value[lessonObj.id] = data;
          const newLesson: ICheckLesson = {
            lessonId: lessonObj.id,
            customerId: lessonObj.customerId,
            referenceId: lessonObj.referenceId,
            dayId: lessonObj.dayId,
          };

          checkLessosns.value.push(newLesson);
          if (swappedLessons.value.length < 2) {
            swappedLessons.value.push({
              id: lessonObj.id,
              trainerId: lessonObj.trainerId,
              horseId: lessonObj.horseId,
              groomerId: lessonObj.groomerId,
              arenaId: lessonObj.arenaId,
            });
          }
        } else {
          checkLessosns.value.splice(lessonIndex, 1);
          swappedLessons.value.length > 2 &&
            swappedLessons.value.splice(lessonIndex, 1);
          lessons.value = lessons.value.filter(
            (lesson) => lesson.lessonId !== lessonObj.id
          );
          recoverData(lessonObj.id);
        }
      };

      const payload = ref();
      const disableCompleteButton = () => {
        const totalLengthLesson = checkAttendenceResult?.value?.reduce(
          (accumulator, obj) => {
            if (obj.lesson && Array.isArray(obj.lesson)) {
              return accumulator + obj.lesson.length;
            }
            return accumulator;
          },
          0
        );

        if (checkAttendenceResult?.value && checkLessosns.value.length > 0)
          if (totalLengthLesson === checkLessosns.value.length) {
            return false;
          }
        if (showFreezeDate.value) {
          if (!freezeDate.value) {
            return false;
          }
        }
        if (showCancellationReason.value) {
          if (!cancellationReasonId.value) {
            return false;
          }
        }
        if (showCancellByKRC.value) {
          if (!KRCCancelDate.value) {
            return false;
          }
        }

        return true;
      };
      let attendanceParam = ref();
      const checkLessonsAttendence = async (
        attendence = attendanceParam.value,
        ignoreCancelCondition = false
      ) => {
        attendanceParam.value = attendence;
        showFreezeDate.value = false;
        showCancellationReason.value = false;
        showCancellByKRC.value = false;
        showReasonModal.value = false;

        const isFreez = attendence.slug === 'freeze';
        const isCancellation = attendence.slug === 'cancelled';
        const isCancellByKRC = attendence.slug === 'cancelled-by-krc';
        console.log(isCancellByKRC, KRCCancelDate.value);
        if (isFreez && !freezeDate.value) {
          showFreezeDate.value = true;
          showReasonModal.value = true;
          return;
        } else if (isCancellation && !cancellationReasonId.value) {
          showCancellationReason.value = true;
          showReasonModal.value = true;
          return;
        } else if (isCancellByKRC && !ignoreCancelCondition) {
          showCancellByKRC.value = true;
          showReasonModal.value = true;
          return;
        }
        payload.value = {
          lessons: checkLessosns.value,
          attendance: attendence.id,
        };
        isFreez ? (payload.value.startDate = freezeDate.value) : '';
        isCancellByKRC ? (payload.value.startDate = KRCCancelDate) : '';

        isCancellation
          ? (payload.value.cancellationReasonId = cancellationReasonId.value)
          : '';

        const { data } = await store.dispatch(
          Actions.CHECK_ATTENDENCE,
          payload.value
        );

        if (Array.isArray(data.data)) {
          checkAttendenceResult.value = data.data;
          showModal.value = true;
          return;
        } else {
          await completeUpdateAttendence();
          showModal.value = false;
        }

        resetAttendence();
      };
      const completeUpdateAttendence = async () => {
        const result = await Swal.fire({
          title: translate('Are you sure you want to update the attendance?'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          await store.dispatch(Actions.COMPLETE_ATTENDENCE, payload.value);
          payload.value = {};
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            Swal.fire({
              text: translate('SUCCESSFULLY_UPDATED_LESSONS'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
            loading.value = true;
            await store.dispatch(
              Actions.GET_LESSONS_OPERATIONS,
              searchQuery.value
            );
            tableMinHeight();

            loading.value = false;
          }
          showModal.value = false;
          selectedRecored.value = [];
          checkLessosns.value = [];
        } else {
          resetAttendence();
        }
      };
      const resetAttendence = () => {
        freezeDate.value = undefined;
        cancellationReasonId.value = 0;
        showModal.value = false;
        showReasonModal.value = false;
        showCancellByKRC.value = false;
        KRCCancelDate.value = undefined;
      };

      const onReset = async (query) => {
        loading.value = true;
        selectedRecored.value = [];
        lessons.value = [];
        await store.dispatch(Actions.UPDATE_LESSONS_FILTER, {
          startDate: new Date().toISOString().slice(0, 10),
          endDate: new Date().toISOString().slice(0, 10),
        });
        await store.dispatch(Actions.UPDATE_LESSONS_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_LESSONS_OPERATIONS);
        selectedRecored.value = [];
        tableMinHeight();
        loading.value = false;
      };
      const searchLessons = async (query) => {
        console.log(query);
        searchQuery.value = query;
        loading.value = true;
        selectedRecored.value = [];
        lessons.value = [];
        await store.dispatch(Actions.UPDATE_LESSONS_FILTER, query);
        await store.dispatch(Actions.UPDATE_LESSONS_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_LESSONS_OPERATIONS);
        tableMinHeight();
        loading.value = false;
      };
      const swapLesson = async (swapType) => {
        loading.value = true;
        const payload = {
          lessons: swappedLessons.value,
          swapType,
        };
        await store.dispatch(Actions.SWAP_LESSONS, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_LESSONS'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          await store.dispatch(
            Actions.GET_LESSONS_OPERATIONS,
            searchQuery.value
          );
          tableMinHeight();
        }
        swappedLessons.value = [];
        selectedRecored.value = [];
        checkLessosns.value = [];
        loading.value = false;
      };

      const excelData = computed(() => {
        const flattenedLessons = dataReport.value?.data.reduce(
          (result, item) => {
            return result.concat(item.lessons);
          },
          []
        );

        return flattenedLessons?.map((lesson) => {
          return {
            ...lesson,
            startDate: lesson.startDate.slice(0, 10), // Slice the date string
          };
        });
      });

      const format12 = (time) => {
        return moment(time, 'HH:mm').format('hh:mm A');
      };

      return {
        tableData: computed(() => store.getters.lessonsList),
        arenas: computed(() => store.getters.allArenasList),
        horsesList,
        customers: computed(() => store.getters.allCustomersList),
        trainers: computed(() => store.getters.allTrainersList),
        groomers: computed(() => store.getters.allGroomerList),
        totalItems: computed(() => store.getters.lessonslistCount),
        dayList: computed(() => {
          return lookupsList.value.filter(
            (lookup) => lookup.lookupNameId === LookupNameIds.DAYS
          );
        }),
        attendncies: computed(() => {
          return lookupsList.value.filter(
            (lookup) => lookup.lookupNameId === LookupNameIds.ATTENDEND
          );
        }),
        cancelReason: computed(() => {
          return lookupsList.value.filter(
            (lookup) => lookup.lookupNameId === LookupNameIds.CANCEL_REASON
          );
        }),
        durationList: computed(() => {
          return lookupsList.value.filter(
            (lookup) => lookup.lookupNameId === LookupNameIds.DURATION
          );
        }),

        excelData,
        tableHeader,
        toggleStatusUser,
        translate,
        pageChanged,
        goTo,
        loading,
        can,
        excelHeader,
        selectedRecored,
        isRecordSelected,
        handleSelectedField,
        updateLessonsInformation,
        prepareLessonToCheck,
        checkLessonsAttendence,
        showModal,
        checkAttendenceResult,
        completeUpdateAttendence,
        showMenu,
        onReset,
        searchLessons,
        payload,
        freezeDate,
        showFreezeDate,
        showCancellByKRC,
        KRCCancelDate,
        cancellationReasonId,
        showCancellationReason,
        showCommentModal,
        disableCompleteButton,
        showReasonModal,
        attendanceParam,
        resetAttendence,
        lessonComment,
        lessonId,
        disabledFilterButton,
        lessons,
        isTrainer,
        enableEditing,
        convertDateDDMMYYYY,
        format12,
        swapLesson,
        disabledFieldOrStartTime,
        disabledField,
        categoryList,
        classLevelList,
        matchedClassLevelList,
        handleCategoryChanged,
      };
    },
  });
