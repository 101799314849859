
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';

  export default defineComponent({
    name: 'announcement-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const imageFile = ref<File | null>(null);
      const imagePreview = ref<string | null>(null);
      const selectedCategoryId = ref();
      const status = ref(false);

      const onImageChange = (event: Event) => {
        const file = (event.target as HTMLInputElement).files?.[0] || null;
        if (file) {
          imageFile.value = file;
          const reader = new FileReader();
          reader.onload = (e) => {
            imagePreview.value = e.target?.result as string;
          };
          reader.readAsDataURL(file);
        }
      };
      const removeImage = () => {
        imageFile.value = null;
        imagePreview.value = null;
      };

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('Announcement Creating'), [
          translate('settings'),
        ]);
      });

      const validationSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('titleEn'),
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('titleAr'),
        contentEn: Yup.string()
          .required(() => translate('EN_CONTENT_IS_REQUIRED_FIELD'))
          .label('contentEn'),
        contentAr: Yup.string()
          .required(() => translate('AR_CONTENT_IS_REQUIRED_FIELD'))
          .label('contentAr'),
        categoryId: Yup.string()
          .notOneOf(['0'], () => translate('Category Is Required field'))
          .required(() => translate('Category Is Required field'))
          .label('Statuses'),
      });

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.ANNOUNCEMENT_CATEGORY,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);
      const AnnouncementCategory = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.ANNOUNCEMENT_CATEGORY;
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          submitButton.value.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        console.log(values);
        console.log(status.value);
        values.status = status.value;

        const data = await store.dispatch(Actions.CREATE_ANNOUNCEMENT, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          submitButton.value?.removeAttribute('data-kt-indicator');
          submitButton.value!.disabled = false;
        } else {
          const formData = new FormData();
          if (imageFile.value) {
            formData.append('attachments', imageFile.value);
            await store.dispatch(Actions.UPLOAD_ANNOUNCEMENT_ATTACHMENT, {
              data: formData,
              id: data.data.id,
            });
            const [errorName] = Object.keys(store.getters.getErrors);
            const error = store.getters.getErrors[errorName];
            if (error) {
              Swal.fire({
                text: translate(error[0]),
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: translate('tryAgainExcl'),
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger',
                },
              });
            } else {
              Swal.fire({
                text: translate('SUCCESSFULLY_CREATED_ANNOUNCEMENT'),
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: translate('ok'),
                customClass: {
                  confirmButton: 'btn btn-light-primary',
                },
              });
            }
          } else {
            Swal.fire({
              text: translate('SUCCESSFULLY_CREATED_ANNOUNCEMENT'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }

          submitButton.value?.removeAttribute('data-kt-indicator');
          submitButton.value!.disabled = false;
        }
      };

      return {
        onSubmitCreate,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        imagePreview,
        onImageChange,
        removeImage,
        AnnouncementCategory,
        selectedCategoryId,
        status,
      };
    },
  });
