
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import printJs from 'print-js';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { injectSearch } from '@/core/plugins/search-service';
  import SearchField from '@/components/common/SearchField.vue';
  import { loadImage } from '../../../utils/loadImage';
  import moment from 'moment';
  import { end } from '@popperjs/core';

  export default defineComponent({
    name: 'customer-account-report',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const loadingPage = ref(true);
      const searchService = injectSearch();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const reportData = ref();
      const selectedCustomer = ref<number>(0);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const startDate = ref(moment().startOf('day')._d);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const endDate = ref(moment().startOf('day')._d);
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('CustomerStatementReport'), [
          translate('reports'),
        ]);
        store.dispatch(Actions.GET_ALL_CUSTOMERS);
        const getSaveData = searchService.getSearchValue(
          'CustomerStatementReport'
        );
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(
                searchService.getSearchValue('CustomerStatementReport')
              )
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.startDate) startDate.value = dataSaved.startDate;
          if (dataSaved.endDate) endDate.value = dataSaved.endDate;
          if (dataSaved.customerId)
            selectedCustomer.value = dataSaved.customerId;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });
      const clearSearch = () => {
        searchService.removeSearchValue('CustomerStatementReport');
        selectedCustomer.value = 0;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        startDate.value = moment().startOf('day')._d;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        endDate.value = moment().startOf('day')._d;
      };

      const print = async () => {
        await store.dispatch(Actions.UPDATE_CUSTOMER_BALANCE_FILTER, {
          startDate: startDate.value,
          endDate: endDate.value,
        });
        const dataReports = await store.dispatch(
          Actions.GET_FINANCE_CUSTOMER_PAYMENT_HISTORY,
          selectedCustomer.value
        );

        const startD =
          moment(startDate.value).format('DD-MM-YYYY') ||
          moment(dataReports.data[0]?.date).format('DD-MM-YYYY') ||
          '';
        const endD =
          moment(endDate.value).format('DD-MM-YYYY') ||
          moment(dataReports.data[dataReports.data.length - 1]?.date).format(
            'DD-MM-YYYY'
          ) ||
          '';

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'portrait',
        });
        const fontSize = doc.getFontSize();
        doc.setFontSize(10);

        doc.text('Print DateTime: ' + moment(new Date()).format('LLL'), 5, 8);

        const logoImage = await loadImage('/media/logos/empty-krc.png');
        console.log(doc.internal.pageSize.getWidth());
        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        doc.addImage(logoImage, 'png', 5, 3, 35, 35);
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.text('Kuwait Riding Center', textX, 15);
        doc.setFontSize(12);
        doc.text('Customer Account Statement', textX - 5, 20);
        doc.setFontSize(10);
        doc.text('Start Date : ' + startD, 100, 50);
        doc.text('End Date : ' + endD, 140, 50);

        console.log(dataReports);

        doc.text('Customer : ' + dataReports.customerData.name, 15, 60);
        doc.text('Mobile : ' + dataReports.customerData.phoneNumber, 15, 70);
        doc.text(
          'Guardian Name : ' + dataReports.customerData.gardianName,
          100,
          60
        );
        doc.text(
          'Guardian Mobile : ' + dataReports.customerData.gardianPhoneNumber,
          100,
          70
        );
        let pageCount = 0;

        if (dataReports.data.length === 0) {
          doc.text('There is No Data', 90, 100);
        }

        const resTabelBody: any[] = [];
        dataReports.data.forEach((elementb) => {
          const balance = elementb['balance'];
          const formattedBalance = /^\d+$/.test(balance)
            ? balance.toString().slice(0, 3)
            : balance;
          resTabelBody.push([
            elementb['date'],
            elementb['slipNo'],
            elementb['particular'],
            elementb['debit'],
            elementb['credit'],
            formattedBalance,
          ]);
        });

        // put the total under the table
        resTabelBody.push([
          '',
          '',
          '',
          '',
          'Total Discount ',
          dataReports.totalDiscount,
        ]);
        autoTable(doc, {
          head: [
            ['Date', 'Slip No', 'Particular', 'Debit', 'Credit', 'Balance'],
          ],
          body: resTabelBody,
          showHead: 'firstPage', // Only show the header on the first page of the nested table
          startY: doc.getCurrentPageInfo().pageNumber == 1 ? 75 : 20,
          horizontalPageBreak: false,
          theme: 'grid',
          headStyles: {
            fillColor: [0, 72, 115],
            fontStyle: 'bold',
            halign: 'left',
            fontSize: 8,
          },
          bodyStyles: {
            fontStyle: 'bold',
            halign: 'left',
            fontSize: 8,
          },
          styles: {
            fontSize: 10,
          },
          didDrawPage: function () {
            doc.setFontSize(10);
            doc.setFont('helvetica', 'normal');
            pageCount++;
            const pageCountWidth = doc.getTextWidth(String(pageCount));

            doc.text(
              'Page ' + pageCount,
              textX - pageCountWidth,
              doc.internal.pageSize.height - 9
            );
            doc.setFontSize(fontSize);
          },
        });

        Array.from({ length: pageCount }, (x, i) => {
          doc.setPage(i + 1);
          doc.setFont('helvetica', 'normal');
          doc.text(
            ' of ' + doc.getNumberOfPages(),
            textX + 15,
            doc.internal.pageSize.height - 9
          );
        });

        //doc.save(`customer-balance_${new Date()}.pdf`);
        // doc.autoPrint();
        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        window.open(blobUrl, '_blank');
        // printJs({ printable: blobUrl, type: 'pdf' });
      };

      const validationSchema = Yup.object().shape({
        customerId: Yup.string()
          .notOneOf(['0'], () => translate('CUSTOMER_IS_REQUIRED_FIELD'))
          .required(() => translate('CUSTOMER_IS_REQUIRED_FIELD'))
          .label('Customer'),
      });
      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        reportData,
        customers: computed(() => store.getters.allCustomersList),
        selectedCustomer,
        startDate,
        endDate,
        loadingPage,
        clearSearch,
      };
    },
  });
