
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import printJs from 'print-js';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { injectSearch } from '@/core/plugins/search-service';
  import SearchField from '@/components/common/SearchField.vue';
  import { loadImage } from '../../../utils/loadImage';
  import moment from 'moment';

  export default defineComponent({
    name: 'trainer-lessons-summary-report',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const loadingPage = ref(true);
      const searchService = injectSearch();
      const reportData = ref();
      const selectedCustomer = ref<number>(0);
      const selectedTrainer = ref<number>(0);
      const selectedHorse = ref<number>(0);
      const selectedArena = ref<number>(0);
      const selectedAttendance = ref<number>(0);
      const selecteDuration = ref<number>(0);
      const selecteType = ref<number>(0);
      const selectedDay = ref<number>(0);
      const selectedGroomer = ref<number>(0);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const startDate = ref(moment().startOf('day')._d);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const endDate = ref(moment().startOf('day')._d);
      const startTime = ref();
      const endTime = ref();
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('Trainer Lesson Summary'), [
          translate('reports'),
        ]);
        store.dispatch(Actions.GET_ALL_CUSTOMERS);
        store.dispatch(Actions.GET_ALL_TRAINERS, {});
        store.dispatch(Actions.GET_ALL_ARENAS);
        store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS);
        const getSaveData = searchService.getSearchValue(
          'TrainerLessonSummary'
        );
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(searchService.getSearchValue('TrainerLessonSummary'))
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.startDate) startDate.value = dataSaved.startDate;
          if (dataSaved.endDate) endDate.value = dataSaved.endDate;
          if (dataSaved.startTime) startTime.value = dataSaved.startTime;
          if (dataSaved.endTime) endTime.value = dataSaved.endTime;
          if (dataSaved.groomerId) selectedGroomer.value = dataSaved.groomerId;
          if (dataSaved.dayId) selectedDay.value = dataSaved.dayId;
          if (dataSaved.typeId) selecteType.value = dataSaved.typeId;
          if (dataSaved.durationId)
            selecteDuration.value = dataSaved.durationId;
          if (dataSaved.attendanceId)
            selectedAttendance.value = dataSaved.attendanceId;
          if (dataSaved.arenaId) selectedArena.value = dataSaved.arenaId;
          if (dataSaved.horseId) selectedHorse.value = dataSaved.horseId;
          if (dataSaved.trainerId) selectedTrainer.value = dataSaved.trainerId;
          if (dataSaved.customerId)
            selectedCustomer.value = dataSaved.customerId;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });
      const clearSearch = () => {
        searchService.removeSearchValue('TrainerLessonSummary');
        selectedCustomer.value = 0;
        selectedTrainer.value = 0;
        selectedHorse.value = 0;
        selectedArena.value = 0;
        selectedAttendance.value = 0;
        selecteDuration.value = 0;
        selecteType.value = 0;
        selectedDay.value = 0;
        selectedGroomer.value = 0;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        startDate.value = moment().startOf('day')._d;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        endDate.value = moment().startOf('day')._d;
        startTime.value = null;
        endTime.value = null;
      };

      const print = async (values) => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');

        values.startDate = String(values.startDate);
        values.endDate = String(values.endDate);

        searchService.setSearchValue('TrainerLessonSummary', values);
        const user = getUser();
        const userName = user ? user['name'] : '';

        await store.dispatch(Actions.GET_TRAINER_BY_GROUP, values);

        reportData.value = store.getters.getLessons;

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'portrait',
        });
        const fontSize = 10;
        const footerHeight = 5;
        const pageHeight = doc.internal.pageSize.getHeight();

        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;

        let pageCount = 0;
        let currentY = 40;

        reportData.value.data.forEach(function (element, index) {
          const resTabelBody: any = [];

          const headerHeight = 8;
          const rowHeight = 5;
          const estimatedTableHeight = headerHeight + rowHeight + 10;

          if (currentY + estimatedTableHeight + footerHeight > pageHeight) {
            doc.addPage();
            currentY = 40; // Reset Y position after adding a new page
          }

          doc.setFontSize(10);
          doc.text('Trainer: ' + element['name'], 10, currentY);

          currentY += headerHeight;

          const lessonGrouped: any = [
            {
              groupTitle: 'Total group 15 min lesson',
              privateTitle: 'Total private 15 min lesson',
              semiPrivateTitle: 'Total semi private 15 min lesson',
              groupResult: 0,
              privateResult: 0,
              semiPrivateResult: 0,
            },
            {
              groupTitle: 'Total group 30 min lesson',
              privateTitle: 'Total private 30 min lesson',
              semiPrivateTitle: 'Total semi private 30 min lesson',
              groupResult: 0,
              privateResult: 0,
              semiPrivateResult: 0,
            },
            {
              groupTitle: 'Total group 45 min lesson',
              privateTitle: 'Total private 45 min lesson',
              semiPrivateTitle: 'Total semi private 45 min lesson',
              groupResult: 0,
              privateResult: 0,
              semiPrivateResult: 0,
            },
            {
              groupTitle: 'Total group 60 min lesson',
              privateTitle: 'Total private 60 min lesson',
              semiPrivateTitle: 'Total semi private 60 min lesson',
              groupResult: 0,
              privateResult: 0,
              semiPrivateResult: 0,
            },
            {
              groupTitle: 'Total group all lesson',
              privateTitle: 'Total private all lesson',
              semiPrivateTitle: 'Total semi all lesson',
              groupResult: 0,
              privateResult: 0,
              semiPrivateResult: 0,
            },
          ];

          lessonGrouped.forEach((group, index) => {
            const duration = String((index + 1) * 15);

            Object.keys(element.lessons.byTypeAndDuration).forEach(
              (lessonType) => {
                if (lessonType.toLowerCase().endsWith(duration)) {
                  if (lessonType.toLowerCase().startsWith('group')) {
                    group.groupResult =
                      element.lessons.byTypeAndDuration[lessonType];
                  }
                  if (lessonType.toLowerCase().startsWith('private')) {
                    group.privateResult =
                      element.lessons.byTypeAndDuration[lessonType];
                  }
                  if (lessonType.startsWith('semiPrivate')) {
                    group.semiPrivateResult =
                      element.lessons.byTypeAndDuration[lessonType];
                  }
                }
              }
            );

            if (index === lessonGrouped.length - 1) {
              Object.keys(element.lessons.totalByType).forEach(
                (totalByType) => {
                  if (totalByType.toLowerCase().startsWith('group')) {
                    lessonGrouped[index].groupResult =
                      element.lessons.totalByType[totalByType];
                  }
                  if (totalByType.toLowerCase().startsWith('private')) {
                    lessonGrouped[index].privateResult =
                      element.lessons.totalByType[totalByType];
                  }
                  if (totalByType.startsWith('semiPrivate')) {
                    lessonGrouped[index].semiPrivateResult =
                      element.lessons.totalByType[totalByType];
                  }
                }
              );
            }
          });

          const resTableBody: any[] = [];
          lessonGrouped.forEach((elementb) => {
            resTableBody.push([
              elementb['groupTitle'],
              elementb['groupResult'],
              elementb['privateTitle'],
              elementb['privateResult'],
              elementb['semiPrivateTitle'],
              elementb['semiPrivateResult'],
            ]);
          });

          autoTable(doc, {
            body: resTableBody,
            startY: currentY,
            margin: { horizontal: 5, top: 35 },
            horizontalPageBreak: false,
            theme: 'grid',
            headStyles: {
              fillColor: [0, 72, 115],
              fontStyle: 'bold',
              halign: 'left',
            },
            bodyStyles: {
              fontStyle: 'bold',
              halign: 'left',
            },
            styles: {
              fontSize: 10,
            },
            didDrawPage: function (data) {
              currentY = data.cursor!.y;
              pageCount++;
            },
          });

          currentY += 5;
        });

        const logoImage = await loadImage('/media/logos/empty-krc.png');

        Array.from({ length: pageCount }, (x, i) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          doc.addImage(logoImage, 'png', 5, 3, 35, 35);
          doc.setFontSize(14);
          doc.setFont('helvetica', 'bold');
          doc.text('Kuwait Riding Center', textX, 15);
          doc.setFontSize(12);
          doc.text('Trainer Lesson Summary', textX, 22);
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(10);

          doc.text(
            'Start Date: ' + moment(values.startDate).format('LL'),
            60,
            30
          );
          doc.text('End Date: ' + moment(values.endDate).format('LL'), 110, 30);
          doc.text('Print DateTime: ' + moment(new Date()).format('LLL'), 5, 8);

          doc.setPage(i + 1);
          doc.text(userName, 5, doc.internal.pageSize.height - 9);

          const pageCountWidth = doc.getTextWidth(
            String(doc.getNumberOfPages() - doc.getNumberOfPages() + i + 1)
          );
          doc.text(
            'Page ' + (doc.getNumberOfPages() - doc.getNumberOfPages() + i + 1),
            textX - pageCountWidth - 2,
            doc.internal.pageSize.height - 9
          );
          doc.text(
            '   of ' + doc.getNumberOfPages(),
            textX + 5,
            doc.internal.pageSize.height - 9
          );
          doc.setFontSize(fontSize);
        });

        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
        window.open(blobUrl, '_blank');
        //printJs({ printable: blobUrl, type: 'pdf' });
      };

      const validationSchema = Yup.object().shape({});
      // const onSubmitCreate = async (values) => {
      //   debugger;
      //   if (submitButton.value) {
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = true;
      //     submitButton.value.setAttribute('data-kt-indicator', 'on');
      //   }
      //   values.duration = Number(values.duration);
      //   values.numberOfRider = Number(values.numberOfRider);

      //   const { data } = await store.dispatch(
      //     Actions.GET_CUSTOMER_ATTENDANCE,
      //     values
      //   );
      //   const [errorName] = Object.keys(store.getters.getErrors);
      //   const error = store.getters.getErrors[errorName];

      //   if (error) {
      //     Swal.fire({
      //       text: translate(error[0]),
      //       icon: 'error',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('tryAgainExcl'),
      //       customClass: {
      //         confirmButton: 'btn fw-bold btn-light-danger',
      //       },
      //     });
      //     //Deactivate indicator
      //     submitButton.value?.removeAttribute('data-kt-indicator');
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = false;
      //   } else {
      //     Swal.fire({
      //       text: translate('SUCCESSFULLY_CREATED_CLASS_CATEGORY'),
      //       icon: 'success',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('ok'),
      //       customClass: {
      //         confirmButton: 'btn btn-light-primary',
      //       },
      //     }).then(() => {
      //       router.push({ name: 'class-category-listing' });
      //     });
      //   }
      // };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.DAYS,
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.DURATION,
        LookupNameIds.STATUS,
        LookupNameIds.ATTENDEND,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.ATTENDEND;
      });

      // const categoryList = await store.dispatch(
      //   Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      // );
      const classType = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
      });

      const dayList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DAYS;
      });

      const durationList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DURATION;
      });

      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        reportData,
        customers: computed(() => store.getters.allCustomersList),
        trainers: computed(() => store.getters.allTrainersList),
        arenas: computed(() => store.getters.allArenasList),
        horses: computed(() => store.getters.allHorsesList),
        groomers: computed(() => store.getters.allGroomerList),
        statusList,
        classType,
        // categoryList,
        dayList,
        durationList,
        selectedCustomer,
        selectedTrainer,
        selectedArena,
        selectedHorse,
        selecteDuration,
        selectedAttendance,
        selecteType,
        selectedDay,
        selectedGroomer,
        startDate,
        endDate,
        startTime,
        endTime,
        loadingPage,
        clearSearch,
      };
    },
  });
