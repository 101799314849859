
  import { defineComponent, onBeforeMount, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';
  enum ContentPageNames {
    aboutUs = 'about-us-editing',
    terms = 'terms-of-use-editing',
    privacy = 'privacy-editing',
    rules = 'rules-regulations-editing',
    complaints = 'complaints-editing',
  }

  export default defineComponent({
    name: 'horse-location-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const lookupId = ref<null | number>(null);
      const language = ref({
        pageTitle: '',
        buttonTitle: '',
      });

      const getPageContent = () => {
        const routeName = router.currentRoute.value.name;
        if (routeName === ContentPageNames.aboutUs) {
          lookupId.value = LookupNameIds.ABOUT_US;
        } else if (routeName === ContentPageNames.terms) {
          lookupId.value = LookupNameIds.TERMS_OF_USE;
        } else if (routeName === ContentPageNames.privacy) {
          lookupId.value = LookupNameIds.PRIVACY;
        } else if (routeName === ContentPageNames.rules) {
          lookupId.value = LookupNameIds.RULES_REQULATIONS;
        } else if (routeName === ContentPageNames.complaints) {
          lookupId.value = LookupNameIds.COMPLAINTS;
        }
        getTitleName();
      };
      onBeforeMount(() => {
        getPageContent();
      });

      const getTitleName = () => {
        if (lookupId.value === LookupNameIds.ABOUT_US) {
          language.value.pageTitle = 'About Us';
        } else if (lookupId.value === LookupNameIds.TERMS_OF_USE) {
          language.value.pageTitle = 'Terms Of Us';
        } else if (lookupId.value === LookupNameIds.PRIVACY) {
          language.value.pageTitle = 'Privacy';
        } else if (lookupId.value === LookupNameIds.RULES_REQULATIONS) {
          language.value.pageTitle = 'Rules Regulations';
        } else if (lookupId.value === LookupNameIds.COMPLAINTS) {
          language.value.pageTitle = 'Complaints';
        }
        console.log(language.value.pageTitle);
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(
          translate(`${language.value.pageTitle} Editing`),
          [translate('settings')]
        );
      });

      const { data } = await store.dispatch(
        Actions.GET_CONTENT_PAGE,
        route.params.id
      );

      const contentPage = ref(data.data);

      const goBack = () => {
        router.back();
      };
      const validationSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('Title En'),
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ar'),
        descriptionEn: Yup.string()
          .required(() => translate('EN_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description En'),
        descriptionAr: Yup.string()
          .required(() => translate('AR_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description Ar'),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
        submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_CONTENT_PAGE, {
          data: values,
          id: contentPage.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
        submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate(`Successfully updated ${language.value.pageTitle}`),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            const routeEditPageName = String(route.name).replace(
              'editing',
              'listing'
            );
            router.push({ name: routeEditPageName });
          });
        }
      };

      return {
        onSubmitCreate,
        contentPage,
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        language,
      };
    },
  });
