
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import StaffFilter from '@/components/dropdown/StaffFilter.vue';
  import LookupNameIds from '@/store/enums/settingsEnums';

  export default defineComponent({
    name: 'staff-listing',
    components: {
      StaffFilter,
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('staffListing'), [
          translate('operations'),
        ]);

        onBeforeUnmount(() => {
          store.dispatch(Actions.RESET_STAFF_STORE);
        });
        loading.value = true;
        await store.dispatch(Actions.GET_STAFF_LIST);
        loading.value = false;
      });

      const loading = ref(false);

      const excelHeader = reactive({
        Name: 'name',
        'Phone Number': 'phone',
        Gender: 'genderName',
        Status: 'statusName',
      });
      const tableHeader = ref([
        {
          name: translate('name'),
          key: 'name',
          sortable: true,
        },
        {
          name: translate('phoneNumber'),
          key: 'phone',
          sortable: true,
        },
        {
          name: translate('gender'),
          key: 'genderName',
          sortable: false,
        },
        {
          name: translate('status'),
          key: 'statusName',
          sortable: false,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const deleteStaff = async (staff) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_STAFF, staff.id);
          await store.dispatch(Actions.GET_STAFF_LIST);
          await store.dispatch(Actions.GET_ALL_STAFF_LIST, false);

          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editStaff = (id) => {
        router.push({ name: 'staff-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val, fromSearch) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_STAFF_CURRENT_PAGE, val);
        await store.dispatch(
          Actions.GET_STAFF_LIST,
          fromSearch ? val : undefined
        );
        loading.value = false;
      };

      const searchStaff = async (query) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_STAFF_FILTER, {
          ...query,
        });
        await store.dispatch(Actions.UPDATE_STAFF_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_STAFF_LIST);
        await store.dispatch(Actions.GET_ALL_STAFF_LIST, false);
        loading.value = false;
      };
      const onReset = async (query) => {
        await store.dispatch(Actions.UPDATE_STAFF_FILTER, query);
        await store.dispatch(Actions.UPDATE_STAFF_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_STAFF_LIST);
        await store.dispatch(Actions.GET_ALL_STAFF_LIST, false);
      };

      await store.dispatch(Actions.GET_ALL_STAFF_LIST, false);
      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.STATUS,
      ]);

      return {
        tableData: computed(() => store.getters.staffList),
        tableHeader,
        deleteStaff,
        translate,
        totalItems: computed(() => store.getters.stafflistCount),
        pageChanged,
        goTo,
        editStaff,
        loading,
        can,
        excelData: computed(() => store.getters.allStaffList),
        excelHeader,
        searchStaff,
        onReset,
      };
    },
  });
