import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface ArenasObject {
  id: string;
  nameEn: string;
  nameAr: string;
  numberOfHorses: number;
}

export interface ArenasListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  Arenas: Array<ArenasObject>;
  errors: unknown;
}

export interface ArenasFilterInfo {
  statusId: string;
  arenaId: string;
}

@Module
export default class ArenasModule extends VuexModule implements ArenasListInfo {
  Arenas = [] as Array<ArenasObject>;
  allArenas = [] as Array<ArenasObject>;
  arenaFilter = {} as ArenasFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get Arenas list
   * @returns Array<ArenasObject>
   */
  get arenasList(): Array<ArenasObject> {
    return this.Arenas;
  }

  get allArenasList(): Array<ArenasObject> {
    return this.allArenas;
  }

  /**
   * Get total items
   * @returns number
   */
  get arenasListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_ARENAS](data) {
    this.Arenas = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_ARENAS](data) {
    this.allArenas = data.data;
  }

  @Mutation
  [Mutations.SET_ARENA_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_ARENAS_LIST]() {
    this.Arenas = [] as Array<ArenasObject>;
    this.arenaFilter = {} as ArenasFilterInfo;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ARENAS_FILTER](obj) {
    this.arenaFilter.statusId = obj.statusId;
    this.arenaFilter.arenaId = obj.arenaId;
  }

  @Action
  [Actions.RESET_ARENAS_STORE]() {
    this.context.commit(Mutations.RESET_ARENAS_LIST);
  }

  @Action
  [Actions.UPDATE_ARENA_FILTER](val) {
    this.context.commit(Mutations.SET_ARENAS_FILTER, val);
  }

  @Action
  [Actions.CREATE_ARENA](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/arena', data)
      .then(() => {
        this.context.commit(Mutations.RESET_ARENAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_ARENA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/arena', id)
      .then(() => {
        this.context.commit(Mutations.RESET_ARENAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_ARENA]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/arena', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ARENAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_ARENA_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_ARENA_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_ARENA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/arena', id);
  }

  @Action
  [Actions.GET_ARENAS]() {
    const params = {
      page: this.page,
      statusId: this.arenaFilter.statusId ? this.arenaFilter.statusId : null,
      arenaId: this.arenaFilter.arenaId ? this.arenaFilter.arenaId : null,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/arena', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ARENAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_ARENAS](payload: any = { active: true }) {
    ApiService.setAuthorizationHeader();
    const params = {
      statusId: this.arenaFilter.statusId ? this.arenaFilter.statusId : null,
      arenaId: this.arenaFilter.arenaId ? this.arenaFilter.arenaId : null,
      active: payload.active,
      classCategoryId: payload.classCategoryId,
    };
    console.log(params, 'params');
    return ApiService.query(`/arena/all`, { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_ARENAS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
