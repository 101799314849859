import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5d63834"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card mb-5 mb-xxl-8" }
const _hoisted_2 = { class: "card-body pt-9 pb-0" }
const _hoisted_3 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_4 = { class: "me-7 mb-4 position-relative" }
const _hoisted_5 = { class: "d-flex flex-column symbol symbol-100px symbol-lg-160px symbol-fixed" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "flex-grow-1" }
const _hoisted_8 = { class: "d-flex justify-content-between align-items-start flex-wrap mb-2" }
const _hoisted_9 = { class: "d-flex flex-column" }
const _hoisted_10 = { class: "d-flex align-items-center mb-2" }
const _hoisted_11 = {
  href: "#",
  class: "text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
}
const _hoisted_12 = { href: "#" }
const _hoisted_13 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_14 = { class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2" }
const _hoisted_15 = { class: "text-primary" }
const _hoisted_16 = { class: "svg-icon svg-icon-4 me-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.profileImage,
              alt: "image"
            }, null, 8, _hoisted_6)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.trainer?.name), 1),
                _createElementVNode("a", _hoisted_12, [
                  _createElementVNode("span", _hoisted_13, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen026.svg" })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("span", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com011.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.trainer?.email), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}