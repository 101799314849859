<template>
  <div>
    <div id="content-attendence">
      <div
        class="pdf-content bg-primary d-flex align-items-center justify-content-between"
      >
        <div class="logo">
          <img src="/media/logos/logo.png" class="h-75px" />
          <span class="fw-bold fs-3">Kuwit Riding Center</span>
        </div>
        <div class="title">Attendence</div>
        <div class="date">Date : {{ formattedDate }} / {{ dayName }}</div>
      </div>

      <div class="table-responsive" style="margin-top: 15px">
        <table class="table table-bordered">
          <thead>
            <tr class="fw-bold text-gray-800">
              <th>Start Time</th>
              <th>End Time</th>
              <th>Horse type</th>
              <th>Horse Color</th>
              <th>Arena</th>
              <th>Groomer</th>
              <th>Trainer</th>
              <th>Customer</th>
              <th>Status</th>
              <th>Lesson Type</th>
              <th>Duration</th>
              <th>Phone number</th>
            </tr>
          </thead>
          <tbody v-if="lessons">
            <tr v-for="lesson in lessons" :key="lesson.id">
              <td>{{ lesson.time.startTime }}</td>
              <td>{{ lesson.time.endTime }}</td>
              <td>{{ lesson.horseName }}</td>
              <td>{{ lesson.horseColor }}</td>
              <td>{{ lesson.arenaName }}</td>
              <td>{{ lesson.groomerName }}</td>
              <td>{{ lesson.trainerName }}</td>
              <td>{{ lesson.customerName }}</td>
              <td>{{ lesson.attendance }}</td>
              <td>{{ lesson.lessonType }}</td>
              <td>{{ lesson.durationName }}</td>
              <td>{{ lesson.phone }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button
      @click="convertToPdf"
      type="button"
      class="btn btn-light-primary me-3"
      data-bs-toggle="modal"
      data-bs-target="#kt_customers_export_modal"
    >
      <span class="svg-icon svg-icon-2">
        <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
      </span>
      Print Attendence today
    </button>
  </div>
</template>

<script setup>
  import html2pdf from 'html2pdf.js';
  import { onMounted, onUnmounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';

  const store = useStore();

  onMounted(() => {
    const element = document.getElementById('content-attendence');
    document.documentElement.appendChild(element);
  });
  onUnmounted(() => {
    const element = document.getElementById('content-attendence');
    if (element) {
      element.remove();
    }
  });

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  const formattedDate = ref(`${year}-${month}-${day}`);
  const dayName = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(
    today
  );
  const convertToPdf = async () => {
    const element = document.getElementById('content-attendence');
    element.style.display = 'block';

    let opt = {
      filename: 'attendence.pdf',
      html2canvas: { scale: 2 },
      jsPDF: { format: 'a4', orientation: 'landscape' },
      image: { type: 'jpeg', quality: 0.98 },
    };

    html2pdf(element, opt);
    setTimeout(() => {
      element.style.display = 'none';
    }, 0);
  };

  const data = await store.dispatch(Actions.GET_REPORT_CUSTOMER_ATTENDENCE, {
    startDate: formattedDate.value,
    endDate: formattedDate.value,
  });
  const lessons = ref([]);

  data.data.forEach((lessonObject) => {
    lessons.value.push(...lessonObject.lessons);
  });
</script>

<style lang="scss">
  .pdf-content {
    height: 100px;
    color: #fff;

    > div {
      margin: 0 15px;
    }

    .logo img {
      margin-right: 20px;
    }
  }

  #content-attendence {
    display: none;
    position: relative;
    th,
    td {
      font-size: 11px !important;
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
      text-align: center;
    }
    thead {
      border-top: 1px solid #000;
    }
    tbody {
      border-bottom: 1px solid #000 !important;
    }
    th {
      background: #3699ff !important;
    }
  }
</style>
