
  import { defineComponent, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import { getUser, saveUser } from '@/core/services/AuthService';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default defineComponent({
    name: 'app',
    async setup() {
      const store = useStore();
      const { t, te } = useI18n();

      const imageChanged = ref(false);
      const submitButton = ref<HTMLButtonElement | null>(null);

      const user = getUser();
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('Profile'), []);
      });

      const { data } = await store.dispatch(Actions.GET_TRAINER, user?.id);

      const trainer = ref(data.data);
      const profileImage = ref(trainer.value.image);
      const fileToUpload = ref();

      const handleFileChange = (event) => {
        const file = event.target.files[0];
        fileToUpload.value = event.target.files[0];
        if (file) {
          profileImage.value = URL.createObjectURL(file);
          imageChanged.value = true;
        }
      };
      const setProfileImage = async () => {
        console.log(fileToUpload.value);
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const formData = new FormData();
        formData.append(`attachments`, fileToUpload.value);
        console.log(formData.getAll('attachments'));
        await store.dispatch(Actions.UPDATE_TRAINER_IMAGE, {
          id: user?.id,
          data: formData,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ATTACHMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;

        const newUser = {
          ...user,
          image: profileImage.value,
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        saveUser(newUser);
      };

      return {
        user,
        trainer,
        profileImage,
        handleFileChange,
        imageChanged,
        setProfileImage,
        submitButton,
      };
    },
  });
