import { App } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import AuthService from '@/core/services/AuthService';
import store from '@/store';
import { Actions } from '@/store/enums/StoreEnums';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import i18n from '@/core/plugins/i18n';
import router from '@/router';
import { getUser } from '@/core/services/AuthService';
import moment from 'moment';

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.defaults.headers.common['Accept'] =
      'application/json';
    ApiService.vueInstance.axios.defaults.headers.common['x-localization'] =
      i18n.global.locale.value; // todo this doesnt return the ture localization value, need to create a store for localization
    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        store.dispatch(Actions.CLEAR_ERRORS);
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          const user = getUser();
          let signInPage;
          if (user?.userType === 'admin') {
            signInPage = 'sign-in';
          } else {
            signInPage = 'sign-in-trainer';
          }
          store.dispatch(Actions.LOGOUT);
          router.push({ name: signInPage });
        } else {
          return Promise.reject(error);
        }
      }
    );
    /**
     * @description this interceptor is used to format the date to YYYY-MM-DD to solve time Zoon issue , It was done in a hurry,
     * the better solution to create component for datePicker and format  the date in the component
     *
     */
    ApiService.vueInstance.axios.interceptors.request.use(
      (config) => {
        // Check if params exist and contain start or end date
        if (config.params) {
          if (config.params.startDate) {
            config.params.startDate = moment(config.params.startDate).format(
              'YYYY-MM-DD'
            );
          }
          if (config.params.endDate) {
            config.params.endDate = moment(config.params.endDate).format(
              'YYYY-MM-DD'
            );
          }
        }

        return config;
      },
      (error) => {
        // Handle request error
        return Promise.reject(error);
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setAuthorizationHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${AuthService.getUser()?.token}`;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setMultipartHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common['Content-Type'] =
      'multipart/form-data';
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = '' as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}/${slug}`, params);
  }
  public static updateBulk(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string, slug: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(`${resource}/${slug}`);
  }
}

export default ApiService;
