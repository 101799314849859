
  import { defineComponent, ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n/index';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { getUser } from '@/core/services/AuthService';
  import { useRoute } from 'vue-router';
  import SearchField from '../common/SearchField.vue';

  export default defineComponent({
    name: 'lessons-filter',
    emit: ['type-selected', 'reset'],
    components: {
      SearchField,
    },
    setup: async function (props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const user = getUser();
      const isTrainer = user?.userType === 'trainer';
      const route = useRoute();
      const selectedItems = ref<any>({
        customerId: '',
        classCategoryId: '',
        classLevelId: '',
        requestStatusId: '',
        horseTypeId: '',
        startDate: '',
        endDate: '',
        typeId: '',
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      const highlightedDates = {
        dates: [new Date()],
        years: [],
        months: [],
        quarters: [],
        weekdays: [],
        options: { highlightDisabled: false },
      };

      const onStartDateChange = (date) => {
        selectedItems.value.startDate = date;
      };
      const onEndDateChange = (date) => {
        selectedItems.value.endDate = date;
      };

      const onSubmit = () => {
        emit('type-selected', selectedItems.value);
      };
      const onReset = () => {
        selectedItems.value = {
          customerId: '',
          classCategoryId: '',
          typeId: '',
          classLevelId: '',
          requestStatusId: '',
          horseTypeId: '',
          startDate: '',
          endDate: '',
        };
        emit('reset', selectedItems.value);
      };

      const lookupsList = computed(() => store.getters.allLookupsList);

      const horseTypeList = computed(() =>
        lookupsList.value.filter((lookup) => {
          return lookup.lookupNameId === LookupNameIds.HORSE_TYPE;
        })
      );
      const requestStatusList = computed(() =>
        lookupsList.value.filter((lookup) => {
          return lookup.lookupNameId === LookupNameIds.REQUEST_LESSON_STATUS;
        })
      );

      const categoryList = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      );
      const classLevelList = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_LEVEL
      );
      const classType = computed(() =>
        lookupsList.value.filter((lookup) => {
          return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
        })
      );

      reinitializeComponents();
      return {
        customers: computed(() => store.getters.allCustomersList),
        horseTypeList,
        requestStatusList,
        categoryList,
        classLevelList,
        classType,
        onSubmit,
        onReset,
        translate,
        selectedItems,
        isTrainer,
        highlightedDates,
        onStartDateChange,
        onEndDateChange,
      };
    },
  });
