
  import { ref, defineComponent, computed } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';

  export default defineComponent({
    name: 'kt-user-menu',
    components: {},
    setup() {
      const router = useRouter();
      const i18n = useI18n();
      const store = useStore();

      i18n.locale.value = localStorage.getItem('lang')
        ? (localStorage.getItem('lang') as string)
        : 'en';

      if (i18n.locale.value === 'ar') {
        document.documentElement.setAttribute('lang', 'ar');
        document.documentElement.setAttribute('dir', 'rtl');
        document.documentElement.setAttribute('direction', 'rtl');
        document.documentElement.setAttribute('style', 'direction: rtl;');
        setTimeout(() => {
          // eslint-disable-next-line
          // @ts-ignore
          document.getElementById('appStyle')?.href = '/css/style.rtl.css';
        }, 500);
      }

      const countries = {
        en: {
          flag: '/media/flags/united-states.svg',
          name: 'English',
        },
        ar: {
          flag: '/media/flags/kuwait.svg',
          name: 'العربية',
        },
      };

      const signOut = () => {
        store.dispatch(Actions.LOGOUT).then(() => {
          if (user.userType === 'trainer') router.push('/sign-in-trainer');
          else router.push({ name: 'sign-in' });
        });
      };

      const setLang = (lang) => {
        localStorage.setItem('lang', lang);
        i18n.locale.value = lang;
      };

      const currentLanguage = (lang) => {
        return i18n.locale.value === lang;
      };

      const currentLangugeLocale = computed(() => {
        return countries[i18n.locale.value];
      });
      const user = store.getters.currentUser;
      const profileImage = ref(user?.image);

      return {
        user,
        signOut,
        setLang,
        currentLanguage,
        currentLangugeLocale,
        countries,
        profileImage,
      };
    },
  });
