
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    onUnmounted,
  } from 'vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import WeeklyFilter from '@/components/filter/weeklyFilter.vue';
  import { convertDateDDMMYYYY } from '@/utils/dateFormat';
  import moment from 'moment';

  export default defineComponent({
    name: 'class-category-listing',
    components: {
      Datatable,
      WeeklyFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const { can } = useAbility();
      const disabledRangeDate = ref(false);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const getLessonsGroupByDate = async (payload) => {
        loading.value = true;
        disabledRangeDate.value = true;
        await store.dispatch(Actions.GET_LESSONS_GROUP_BY_DATE, payload);
        loading.value = false;
        disabledRangeDate.value = false;
        console.log(store.getters.lessonByGroupDateList, 'group');
      };
      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('weeklyLessonsListing'), []);
        const today = new Date();
        const nextWeek = new Date(today);
        nextWeek.setDate(today.getDate() + 6);
        console.log(today, nextWeek);

        await store.dispatch(Actions.GET_LESSONS_GROUP_BY_DATE, {
          startDate: moment(today).format('YYYY-MM-DD'),
          endDate: moment(nextWeek).format('YYYY-MM-DD'),
        });
      });
      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_SETTINGS_CLASS_CATEGORY_STORE);
      });
      onUnmounted(() => {
        store.dispatch(Actions.RESET_LESSON_STORE);
      });
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('Class Type'),
          key: 'classType',
          sortable: true,
        },
        {
          name: translate('time'),
          key: 'time',
          sortable: true,
        },
        {
          name: translate('arena Name'),
          key: 'arenaName',
          sortable: true,
        },
      ]);

      return {
        tableData: computed(() => store.getters.lessonByGroupDateList),
        groupedByWeekList: computed(() => store.getters.groupedByWeekList),
        tableHeader,
        inputText: '',
        translate,
        studentsCount: computed(() => store.getters.studentsCountForGroup),
        loading,
        can,
        getLessonsGroupByDate,
        disabledRangeDate,
        convertDateDDMMYYYY,
      };
    },
  });
