import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface ServiceObject {
  titleEn: string;
  titleAR: string;
  contentEn: string;
  contentAr: string;
  categoryId: string;
  id: string;
}

export interface ServiceListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  services: Array<ServiceObject>;
  errors: unknown;
}

export interface ServicesFilterInfo {
  title: string;
}

@Module
export default class AnnouncementModule
  extends VuexModule
  implements ServiceListInfo
{
  services = [] as Array<ServiceObject>;
  allServices = [] as Array<ServiceObject>;
  servicesFilterInfo = {} as ServicesFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get service list
   * @returns Array<ServicesFilterInfo>
   */
  get serviceList(): Array<ServiceObject> {
    return this.services;
  }

  get allServiceList(): Array<ServiceObject> {
    return this.allServices;
  }

  /**
   * Get total items
   * @returns number
   */
  get serviceListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_SERVICES](data) {
    this.services = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_SERVICES](data) {
    this.allServices = data.data;
  }

  @Mutation
  [Mutations.SET_SERVICES_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_SERVICES_LIST]() {
    this.services = [] as Array<ServiceObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SERVICES_FILTER](obj) {
    this.servicesFilterInfo.title = obj.title;
  }

  @Action
  [Actions.RESET_SERVICES_STORE]() {
    this.context.commit(Mutations.RESET_SERVICES_LIST);
  }

  @Action
  [Actions.UPDATE_SERVICES_FILTER](val) {
    this.context.commit(Mutations.SET_SERVICES_FILTER, val);
  }

  @Action
  [Actions.CREATE_SERVICE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/services', data)
      .then((data) => {
        this.context.commit(Mutations.RESET_SERVICES_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPLOAD_SERVICES_ATTACHMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/services/attachments/${id}`, data)
      .then(() => {
        this.context.commit(Mutations.RESET_SERVICES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_SERVICE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/services', id)
      .then(() => {
        this.context.commit(Mutations.RESET_SERVICES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SERVICE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/services', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_SERVICES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SERVICES_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_SERVICES_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_SERVICE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/services', id);
  }

  @Action
  [Actions.GET_SERVICES]() {
    const params = {
      page: this.page,
      title: this.servicesFilterInfo.title,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/services', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SERVICES, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_ALL_SERVICES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/services/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_SERVICES, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
}
