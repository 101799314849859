import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface CustomerBalanceStatmentsObject {
  id: string;
  customerName: string;
  status: boolean;
  invoiceNo: number;
  customerPhoneNumber: number;
  createdAt: string;
}

export interface CustomerBalanceStatmentListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  customerBalanceStatments: Array<CustomerBalanceStatmentsObject>;
  customerPaymentHistory: Array<CustomerBalanceStatmentsObject>;
  errors: unknown;
  balanceFilter: unknown;
}

@Module
export default class customerBalanceModule
  extends VuexModule
  implements CustomerBalanceStatmentListInfo
{
  customerBalanceStatments = [] as Array<CustomerBalanceStatmentsObject>;
  customerPaymentHistory = [] as Array<CustomerBalanceStatmentsObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};
  balanceFilter = {
    startDate: '',
    endDate: '',
  };

  /**
   * Get customerBalanceStatments list
   * @returns Array<CustomerBalanceStatmentsObject>
   */
  get customerBalanceStatmentsList(): Array<CustomerBalanceStatmentsObject> {
    if (this.customerBalanceStatments.length > 0)
      this.customerBalanceStatments[0]['page'] = this.page;
    return this.customerBalanceStatments;
  }

  get customerPaymentHistoryList(): Array<CustomerBalanceStatmentsObject> {
    return this.customerPaymentHistory;
  }

  /**
   * Get total items
   * @returns number
   */
  get customerBalanceStatmentsListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_FINANCE_CUSTOMER_BALANCE_STATMENT](data) {
    this.customerBalanceStatments = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_FINANCE_CUSTOMER_PAYMENT_HISTORY](data) {
    this.customerPaymentHistory = data.data;
  }

  @Mutation
  [Mutations.SET_FINANCE_CUSTOMER_BALANCE_STATMENT_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_FINANCE_CUSTOMER_BALANCE_STATMENT_LIST]() {
    this.customerBalanceStatments = [] as Array<CustomerBalanceStatmentsObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_CUSTOMER_BALANCE_FILTER](obj) {
    this.balanceFilter = obj;
  }

  @Action
  [Actions.UPDATE_CUSTOMER_BALANCE_FILTER](val) {
    this.context.commit(Mutations.SET_CUSTOMER_BALANCE_FILTER, val);
  }

  @Action
  [Actions.RESET_FINANCE_CUSTOMER_BALANCE_STATMENT_STORE]() {
    this.context.commit(Mutations.RESET_FINANCE_CUSTOMER_BALANCE_STATMENT_LIST);
  }

  @Action
  [Actions.UPDATE_FINANCE_CUSTOMER_BALANCE_STATMENT_CURRENT_PAGE](val) {
    this.context.commit(
      Mutations.SET_FINANCE_CUSTOMER_BALANCE_STATMENT_CURRENT_PAGE,
      val
    );
  }

  @Action
  [Actions.GET_FINANCE_CUSTOMER_BALANCE_STATMENT]() {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.page,
      ...this.balanceFilter,
    };
    return ApiService.query('finance/customer-payment-details', { params })
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_FINANCE_CUSTOMER_BALANCE_STATMENT,
          data
        );
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
  @Action
  [Actions.GET_FINANCE_CUSTOMER_PAYMENT_HISTORY](customerId: string) {
    ApiService.setAuthorizationHeader();
    const params = {
      startDate: this.balanceFilter.startDate || '',
      endDate: this.balanceFilter.endDate || '',
    };

    return ApiService.query(`finance/customer-payment-history/${customerId}`, {
      params,
    })
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_FINANCE_CUSTOMER_PAYMENT_HISTORY,
          data
        );
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
