
  import { defineComponent, onMounted, ref, watch, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import Multiselect from '@vueform/multiselect';
  import { IHorses } from '@/store/modules/operations/HorsesModule';
  import SearchField from '@/components/common/SearchField.vue';

  export default defineComponent({
    name: 'lesson-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedCategory = ref<number | string>(0);
      const selectedTrainer = ref<number>(0);
      const selectedGroomer = ref<number>(0);
      const selectedLevel = ref<number | string>(0);
      const levelColor = ref();
      const selectedClassType = ref<number | string>(0);
      const selectedDuration = ref<number>(0);
      const selectedArenaId = ref<number>(0);
      const dynamicSchema = ref({});
      const numberOfRiders = ref(1);
      const isUpdating = ref(false);
      const matchedClassLevelList = ref();
      const resetCustomer = ref(true);

      const horseQuery = ref({
        days: [],
        startDate: '',
        time: '',
        numberOfLessons: '',
        durationId: 0,
      });

      const riders = ref<any>([
        {
          horseId: 0,
          customerId: 0,
          groomerId: 0,
          haveBalance: null,
          validGroomer: '',
        },
      ]);
      const selectedCustomers = ref(['']);

      const horsesList = ref<IHorses[]>([]);

      const addNewRider = () => {
        if (numberOfRiders.value > riders.value.length) {
          selectedCustomers.value.push('');
          riders.value.push({
            horseId: 0,
            customerId: 0,
            groomerId: 0,
            haveBalance: null,
            validGroomer: '',
          });
        }
      };
      const removeRider = (index: number) => {
        riders.value.splice(index, 1);
        selectedCustomers.value.splice(index, 1);
      };

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };
      const fillFormFromQuery = () => {
        resetCustomer.value = false;
        const query = route.query;
        if (query) {
          if (query.numberOfLessons) {
            horseQuery.value.numberOfLessons = String(query.numberOfLessons);
          }
          if (query.customerId) {
            selectedCustomers.value[0] = String(query.customerId);
            console.log(selectedCustomers.value);
          }
          if (query.typeId) {
            selectedClassType.value = String(query.typeId);
          }
          if (query.categoryId) {
            console.log(query.categoryId);
            selectedCategory.value = String(query.categoryId);
          }
          if (query.levelId) {
            selectedLevel.value = String(query.levelId);
          }
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('lessonCreating'), [
          translate('operations'),
        ]);
      });
      watch(
        riders.value,
        async () => {
          dynamicSchema.value = {};
          riders.value.forEach((v, index) => {
            dynamicSchema.value[`horseId_${index}`] = Yup.string()
              .notOneOf(['0'], () => translate('Horse is Required Feild'))
              .required(() => translate('Horse is Required Feild'))
              .label(`horse_${index}`);
            dynamicSchema.value[`customerId_${index}`] = Yup.string()
              .required(() => translate('Customer is Required Feild'))
              .label(`customer_${index}`);
          });
          hideSelectedItem();
        },
        { immediate: true }
      );
      riders.value.forEach((rider, index) => {
        watch(
          () => rider.groomerId,
          (newGroomerId) => {
            checkGroomer(newGroomerId, index);
          }
        );
      });

      const validationSchema = computed(() => {
        const baseSchema = {
          days: Yup.array()
            .required(() => translate('DAYS_IS_REQUIRED_FIELD'))
            .min(1, () => translate('DAYS_IS_REQUIRED_FIELD')),
          classCategoryId: Yup.string()
            .notOneOf(['0'], () => translate('CATEGORY_IS_REQUIRED_FIELD'))
            .required(() => translate('CATEGORY_IS_REQUIRED_FIELD'))
            .label('category'),
          durationId: Yup.string()
            .notOneOf(['0'], () => translate('DURATION_IS_REQUIRED_FIELD'))
            .required(() => translate('DURATION_IS_REQUIRED_FIELD'))
            .label('category'),
          trainerId: Yup.string()
            .notOneOf(['0'], () => translate('TRAINER_IS_REQUIRED_FIELD'))
            .required(() => translate('TRAINER_IS_REQUIRED_FIELD'))
            .label('trainer'),
          arenaId: Yup.string()
            .notOneOf(['0'], () => translate('ARENA_IS_REQUIRED_FIELD'))
            .required(() => translate('ARENA_IS_REQUIRED_FIELD'))
            .label('arena'),
          typeId: Yup.string()
            .notOneOf(['0'], () => translate('CLASS_TYPE_IS_REQUIRED_FIELD'))
            .required(() => translate('CLASS_TYPE_IS_REQUIRED_FIELD'))
            .label('arena'),
          classLevelId: Yup.string()
            .notOneOf(['0'], () => translate('Class Level is required field'))
            .required(() => translate('Class Level is required field'))
            .label('arena'),
          numberOfLessons: Yup.number()
            .typeError('Please enter a valid number')
            .required('Number of Lesson is required field')
            .label('class limit'),
          startDate: Yup.string()
            .required(() => translate('DATE_IS_REQUIRED_FIELD'))
            .label('Date Of Birth'),
          time: Yup.string()
            .required(() => translate('Time is required field'))
            .label('time'),
        };
        return Yup.object().shape({
          ...baseSchema,
          ...dynamicSchema.value,
        });
      });

      const checkBalance = async (horseId: string, index: number) => {
        setTimeout(async () => {
          const isFreeRegistration = customerList.find((customer) => {
            if (customer.id === selectedCustomers.value[index]) {
              return customer.freeRegistration;
            }
            return true;
          }).freeRegistration;
          if (isFreeRegistration) {
            riders.value[index].haveBalance = '';
            return;
          }
          // we need to send dayId , startDate , time to this api
          const payload = {
            customerId: selectedCustomers.value[index],
            trainerId: selectedTrainer.value,
            horseId: riders.value[index].horseId,
            classTypeId: selectedClassType.value,
            durationId: horseQuery.value.durationId,
            numberOfLessons: horseQuery.value.numberOfLessons,
            days: horseQuery.value.days,
            startDate: horseQuery.value.startDate,
            time: horseQuery.value.time,
            classCategoryId: selectedCategory.value,
          };
          console.log(payload);

          const isPayloadValid = Object.values(payload).every(
            (value) => value && value !== 0
          );

          if (!isPayloadValid) {
            return;
          }

          await store.dispatch(Actions.CHECK_BALANCS, payload);

          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];

          //'No price for some trainer'

          if (error) {
            if (error[0] === 'No price for some trainer') {
              Swal.fire({
                text: translate(error[0]),
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: translate('tryAgainExcl'),
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger',
                },
              });
              riders.value[index].haveBalance = '';
            } else {
              riders.value[index].haveBalance = error[0];
            }
          } else {
            console.log('run here');
            riders.value[index].haveBalance = '';
          }
        }, 10);
      };

      const checkAllBalance = () => {
        if (riders.value.length > 0) {
          riders.value.map((rider, index) => {
            checkBalance(String(rider.horseId), index);
          });
        }
      };

      const checkGroomer = async (groomerId: string, index: number) => {
        const isFreeRegistration = customerList.find((customer) => {
          if (customer.id === selectedCustomers.value[index]) {
            return customer.freeRegistration;
          }
          return true;
        }).freeRegistration;
        if (isFreeRegistration) {
          riders.value[index].haveBalance = '';
          return;
        }
        const payload = {
          durationId: horseQuery.value.durationId,
          numberOfLessons: horseQuery.value.numberOfLessons,
          days: horseQuery.value.days,
          groomerId,
          startDate: horseQuery.value.startDate,
          time: horseQuery.value.time,
        };

        const isPayloadValid = Object.values(payload).every((value) => value);

        if (!isPayloadValid || groomerId == '0') {
          return;
        }

        await store.dispatch(Actions.CHECK_GROOMER, payload);

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          riders.value[index].validGroomer = error[0];
        } else {
          riders.value[index].validGroomer = '';
        }
      };

      const checkAllGroomer = () => {
        if (riders.value.length > 0) {
          riders.value.map((rider, index) => {
            checkGroomer(String(rider.groomerId), index);
          });
        }
      };

      const disabledSubmit = computed(
        () =>
          !!riders.value.find(
            (rider) =>
              rider.haveBalance !== '' ||
              rider.haveBalance === null ||
              rider.validGroomer !== '' ||
              rider.validGroomer === null
          )
      );

      const resetRiders = () => {
        riders.value.forEach((rider) => {
          rider.horseId = 0;
          rider.customerId = 0;
          rider.groomerId = 0;
        });
        if (resetCustomer.value) {
          selectedCustomers.value = selectedCustomers.value.map(() => '');
        }
        resetCustomer.value = true;
      };

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
        submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        delete values.numberOfRiders;
        values.isUpdating = isUpdating.value;
        values.riders = riders.value.map((rider, index) => {
          return {
            horseId: rider.horseId,
            customerId: selectedCustomers.value[index],
            groomerId: rider.groomerId,
          };
        });

        values.riders.forEach(function (rider) {
          if (rider.groomerId === 0) {
            delete rider.groomerId;
          }
        });

        values.days = horseQuery.value.days;
        delete values.classLevel;
        riders.value.map((rider, index) => {
          delete values[`horseId_${index}`];
          delete values[`customerId_${index}`];
          delete values[`groomerId${index}`];
        });
        values.numberOfLessons = Number(values.numberOfLessons);
        values.classCategoryId = selectedCategory.value;
        await store.dispatch(Actions.CREATE_LESSON, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_LESSONS'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            horseQuery.value.time = '';
            resetRiders();
          });
        }
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
      submitButton.value!.disabled = false;
      };
      const trainerList = ref();
      const arenaList = ref();

      watch(selectedCategory, async () => {
        const trainers = await store.dispatch(Actions.GET_ALL_TRAINERS, {
          categrgoyId: selectedCategory.value,
        });
        arenaList.value = await store.dispatch(Actions.GET_ALL_ARENAS, {
          classCategoryId: selectedCategory.value,
          active: true,
        });

        trainerList.value = trainers;
        selectedTrainer.value = 0;
        const categoryId = selectedCategory.value;

        const categoryObj = categoryList.find((category) => {
          return category.id === categoryId;
        });
        matchedClassLevelList.value = classLevelList.filter((classLevel) =>
          categoryObj.classLevelId?.includes(classLevel.id)
        );
      });

      const readyToGetHorses = ref(false);

      const isEmpty = (value) => {
        return value === undefined || value === null || value === '';
      };
      watch(horseQuery.value, () => {
        if (
          !isEmpty(horseQuery.value.startDate) &&
          !isEmpty(horseQuery.value.time) &&
          !isEmpty(horseQuery.value.numberOfLessons) &&
          horseQuery.value.durationId !== 0
        ) {
          readyToGetHorses.value = true;
          selectedCustomers.value.map(async (customerId, index) => {
            const payload = {
              ...horseQuery.value,
              customerId,
            };
            const data = await store.dispatch(
              Actions.GET_ALL_LESSONS_HORSE,
              payload
            );
            horsesList.value.splice(index, 0, data);
          });
        } else {
          resetRiders();
          readyToGetHorses.value = false;
        }
      });
      const handleChangedCustomer = async (customerId, index, horseId) => {
        setTimeout(async () => {
          if (readyToGetHorses.value) {
            const payload = {
              ...horseQuery.value,
              customerId: selectedCustomers.value[index],
            };
            const data = await store.dispatch(
              Actions.GET_ALL_LESSONS_HORSE,
              payload
            );
            horsesList.value.splice(index, 0, data);
            checkBalance(horseId, index);
          }
        }, 0);
      };

      const lookups = await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.DAYS,
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.DURATION,
        LookupNameIds.STATUS,
      ]);
      const categoryList = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      );
      const groomerList = await store.dispatch(
        Actions.GET_ALL_GROOMERS_OPERATIONS
      );
      const classLevelList = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_LEVEL
      );

      const customerList = await store.dispatch(Actions.GET_ALL_CUSTOMERS);
      fillFormFromQuery();
      const dayList = lookups.filter(
        (day) => day.lookupNameId === LookupNameIds.DAYS
      );
      const durationList = lookups.filter(
        (duration) => duration.lookupNameId === LookupNameIds.DURATION
      );

      const classTypeList = lookups.filter(
        (classType) => classType.lookupNameId === LookupNameIds.CLASS_TYPE
      );
      const statusList = lookups.filter(
        (classType) => classType.lookupNameId === LookupNameIds.STATUS
      );

      const handleLevelColor = (e) => {
        levelColor.value = classLevelList.find((classLevel) => {
          if (e.target.value === classLevel.id) {
            return classLevel.color;
          }
        }).color;
      };
      const hiddenGroomerIds = ref([]);
      const hiddenHorsesIds = ref([]);

      const hideSelectedItem = () => {
        hiddenGroomerIds.value = riders.value.map((rider) => rider.groomerId);
        hiddenHorsesIds.value = riders.value.map((rider) => rider.horseId);
      };

      const handleDateChange = () => {
        console.log('date changed');
        checkAllBalance();
        checkAllGroomer();
      };

      return {
        onSubmitCreate,
        selectedCategory,
        selectedTrainer,
        selectedGroomer,
        selectedLevel,
        selectedClassType,
        selectedDuration,
        selectedArenaId,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        dayList,
        durationList,
        classTypeList,
        categoryList,
        trainerList,
        groomerList,
        classLevelList,
        customerList,
        statusList,
        matchedClassLevelList,
        riders,
        addNewRider,
        removeRider,
        horseQuery,
        selectedCustomers,
        handleChangedCustomer,
        horsesList,
        arenaList,
        numberOfRiders,
        isUpdating,
        checkBalance,
        checkAllBalance,
        checkGroomer,
        checkAllGroomer,
        disabledSubmit,
        levelColor,
        handleLevelColor,
        hideSelectedItem,
        hiddenGroomerIds,
        hiddenHorsesIds,
        handleDateChange,
      };
    },
  });
