import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface CustomerCreditsObject {
  id: string;
  customerName: string;
  status: boolean;
  invoiceNo: number;
  customerPhoneNumber: number;
  createdAt: string;
}

export interface CustomerCreditListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  customerCredits: Array<CustomerCreditsObject>;
  allCustomerCredits: Array<CustomerCreditsObject>;
  errors: unknown;
  creditFilter: unknown;
}

@Module
export default class CustomerCreditModule
  extends VuexModule
  implements CustomerCreditListInfo
{
  customerCredits = [] as Array<CustomerCreditsObject>;
  allCustomerCredits = [] as Array<CustomerCreditsObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};
  creditFilter = {};

  /**
   * Get customerCredits list
   * @returns Array<CustomerCreditsObject>
   */
  get customerCreditsList(): Array<CustomerCreditsObject> {
    if (this.customerCredits.length > 0)
      this.customerCredits[0]['page'] = this.page;
    return this.customerCredits;
  }
  /**
   * Get all customerCredits list
   * @returns Array<CustomerCreditsObject>
   */
  get allCustomerCreditsList(): Array<CustomerCreditsObject> {
    return this.allCustomerCredits;
  }

  /**
   * Get total items
   * @returns number
   */
  get customerCreditsListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_FINANCE_CUSTOMER_CREDIT](data) {
    this.customerCredits = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_FINANCE_CUSTOMER_CREDIT](data) {
    this.allCustomerCredits = data.data;
  }

  @Mutation
  [Mutations.SET_FINANCE_CUSTOMER_CREDIT_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_FINANCE_CUSTOMER_CREDIT_LIST]() {
    this.customerCredits = [] as Array<CustomerCreditsObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_CUSTOMER_CREDIT_FILTER](obj) {
    this.creditFilter = obj;
  }

  @Action
  [Actions.UPDATE_CUSTOMER_CREDIT_FILTER](val) {
    this.context.commit(Mutations.SET_CUSTOMER_CREDIT_FILTER, val);
  }
  @Action
  [Actions.RESET_ALL_FINANCE_CUSTOMER_CREDIT]() {
    this.context.commit(Mutations.SET_ALL_FINANCE_CUSTOMER_CREDIT, {
      data: [],
    });
  }
  @Action
  [Actions.RESET_FINANCE_CUSTOMER_CREDIT_STORE]() {
    this.context.commit(Mutations.RESET_FINANCE_CUSTOMER_CREDIT_LIST);
  }

  @Action
  [Actions.UPDATE_FINANCE_CUSTOMER_CREDIT_CURRENT_PAGE](val) {
    this.context.commit(
      Mutations.SET_FINANCE_CUSTOMER_CREDIT_CURRENT_PAGE,
      val
    );
  }

  @Action
  [Actions.GET_FINANCE_CUSTOMER_CREDIT]() {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.page,
      ...this.creditFilter,
    };
    return ApiService.query('/finance/customer-statment', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FINANCE_CUSTOMER_CREDIT, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_FINANCE_CUSTOMER_CREDIT](params = {}) {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/finance/customer-statment/all', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_FINANCE_CUSTOMER_CREDIT, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.CREATE_FINANCE_CUSTOMER_CREDIT](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/finance/customer-statment', data)
      .then(() => {
        this.context.commit(Mutations.RESET_FINANCE_CUSTOMER_CREDIT_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
}
