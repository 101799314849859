
  import { defineComponent } from 'vue';
  import { useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n/index';
  import MainMenuConfig from '@/core/config/MainMenuConfig';
  import { headerMenuIcons } from '@/core/helpers/config';
  import { useAbility } from '@casl/vue';

  export default defineComponent({
    name: 'KTMenu',
    components: {},
    setup() {
      const { t, te } = useI18n();
      const route = useRoute();
      const { can } = useAbility();

      const hasActiveChildren = (match) => {
        return route.path.indexOf(match) !== -1;
      };
      const checkAbility = (method, resource) => {
        let members = resource instanceof Array ? resource : [resource];
        let valid = false;
        members.some((currentValue) => {
          valid = can(method, currentValue);
          return valid;
        });
        return valid;
      };

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      return {
        hasActiveChildren,
        headerMenuIcons,
        MainMenuConfig,
        translate,
        checkAbility,
      };
    },
  });
