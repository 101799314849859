import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { calculateStartAndEndTime } from '@/utils/dateFormat';
import moment from 'moment';
import { getUser } from '@/core/services/AuthService';

export interface ILessons {
  id: number;
  name: string;
  phone: string;
  email: string;
  address: string;
  dateOfBirth: string;
  passportNumber: string;
  civilId: string;
  residencyNumber: string;
  classLimityPerDay: number;
  nationalityId: string;
  genderId: string;
  statusId: string;
  createdAt: string;
  updatedAt: string;
  statusName: string;
  nationalityName: string;
  genderName: string;
  attendanceName: string;
  isEditable: boolean;
}

export interface LessonStudentCountObject {
  duration: string;
  duurationId: string;
  studentCount: string;
}

export interface LessonGroupByDate {
  [date: string]: [
    {
      id: string;
      attendance: string;
      time: string;
      startDate: string;
      discount: number | null;
      price: number;
      comment: string | null;
      trainerName: string;
      customerName: string;
      horseName: string;
      arenaName: string;
      classType: string;
      durationName: string;
      dayName: string;
      attendanceName: string;
      classCategory: string;
      classLevelName: string | null;
      groomerName: string;
      cancellationReason: string | null;
      horseLocation: string;
    }
  ];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  studentsCount: number;
}

export interface LessonsListInfo {
  lessons: Array<ILessons>;
  allLessons: Array<ILessons>;
  lessonHorses: Array<ILessons>;
  lessonStudentCount: Array<LessonStudentCountObject>;
  studentsCount: number;
  weeklygroupedData: Array<LessonGroupByDate>;
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  errors: unknown;
  searchQuery: unknown;
}

@Module
export default class LessonsModule
  extends VuexModule
  implements LessonsListInfo
{
  lessons = [] as Array<ILessons>;
  allLessons = [] as Array<ILessons>;
  lessonHorses = [] as Array<ILessons>;
  lessonStudentCount = [] as Array<LessonStudentCountObject>;
  lessonsGroupByDate = [] as Array<LessonGroupByDate>;
  lessonFilter = {
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  };
  studentsCount = 0;
  weeklygroupedData = [] as Array<LessonGroupByDate>;
  page = 1;
  take = 100;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};
  searchQuery = {};

  /**
   * Get lessons list
   * @returns Array<ILessons>
   */
  get allLessonsList(): Array<ILessons> {
    return this.allLessons;
  }
  /**
   * Get lessons list
   * @returns Array<ILessons>
   */
  get lessonsList(): Array<ILessons> {
    if (this.lessons.length > 0) this.lessons[0]['page'] = this.page;
    return this.lessons;
  }

  /**
   * Get lessons horse  list
   * @returns Array<ILessons>
   */
  get lessonsHorsesList(): Array<ILessons> {
    return this.lessonHorses;
  }

  /**
   * Get lessons student count  list
   * @returns Array<ILessons>
   */
  get lessonsStudentCountList(): Array<LessonStudentCountObject> {
    return this.lessonStudentCount;
  }
  /**
   * Get lessons student count  list
   * @returns Array<ILessons>
   */
  get groupedByWeekList(): Array<any> {
    return this.weeklygroupedData;
  }

  /**
   * Get lesson count
   * @returns number
   */
  get lessonslistCount(): number {
    return this.itemCount;
  }

  /**
   * Get lesson by group
   * @returns Array<LessonGroupByDate
   */
  get lessonByGroupDateList(): Array<LessonGroupByDate> {
    return this.lessonsGroupByDate;
  }

  /**
   * Get studentCount
   * @returns number
   */
  get studentsCountForGroup(): number {
    return this.studentsCount;
  }

  get itemsPerPage(): number {
    return this.take;
  }

  @Mutation
  [Mutations.SET_LESSONS_OPERATIONS](data) {
    const user = getUser();
    if (user?.userType === 'trainer') {
      this.lessons = Object.values(data.data.groupedData);
    } else {
      this.lessons = data.data;
    }

    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_ALL_LESSONS_OPERATIONS](data) {
    this.allLessons = data.data;
  }
  @Mutation
  [Mutations.SET_LESSONS_STUDENT_COUNT](data) {
    this.lessonStudentCount = data.data;
  }
  @Mutation
  [Mutations.SET_LESSONS_STUDENT_GROUP_BY_DATE](data) {
    const { studentsCount, groupedData, ...lessons } = data.data;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    this.weeklygroupedData = [Object.values(groupedData)[0]];
    this.lessonsGroupByDate = lessons;
    this.studentsCount = studentsCount;
  }
  @Mutation
  [Mutations.SET_LESSONS_HORSES](data) {
    this.lessonHorses = data.data;
  }

  @Mutation
  [Mutations.SET_LESSONS_CURRENT_PAGE](page) {
    this.page = page;
  }
  @Mutation
  [Mutations.SET_LESSONS_FILTER](value) {
    this.lessonFilter = value;
  }

  @Mutation
  [Mutations.RESET_LESSONS_LIST]() {
    this.lessons = [] as Array<ILessons>;
    this.studentsCount = 0;
    this.lessonsGroupByDate = [] as Array<LessonGroupByDate>;
    this.lessonFilter = {
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
    };
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.UPDATE_LESSONS_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_LESSONS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.RESET_LESSON_STORE]() {
    this.context.commit(Mutations.RESET_LESSONS_LIST);
  }

  @Action
  [Actions.UPDATE_LESSONS_FILTER](val) {
    this.context.commit(Mutations.SET_LESSONS_FILTER, val);
  }

  @Action
  [Actions.UPDATE_LESSONS]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.updateBulk('lesson', data)
      .then((data) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.GET_LESSON](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('lesson', id);
  }

  @Action
  [Actions.GET_LESSONS_OPERATIONS]() {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.page,
      take: 100,
      ...this.lessonFilter,
    };

    console.log(params, 'params');
    return ApiService.query('/lesson', { params })
      .then(({ data }) => {
        const groupedData = {};

        const user = getUser();
        if (user?.userType === 'trainer') {
          data.data.forEach((item) => {
            const { startTime, endTime } = calculateStartAndEndTime(
              item.time,
              item.durationName
            );
            const dt = moment(item.startDate, 'YYYY-MM-DD HH:mm:ss');
            const key = `${
              item.classType
            }_${startTime}_${endTime}_${item.startDate.slice(0, 10)}`;
            item.time = `${moment(startTime, 'hh:mm A').format(
              'hh:mm A'
            )} - ${moment(endTime, 'hh:mm A').format('hh:mm A')}`;
            item.date =
              `${moment(item.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')} ` +
              ' ' +
              dt.format('dddd');

            if (!groupedData[key]) {
              groupedData[key] = [];
            }

            groupedData[key].push(item);
          });
          data.data.groupedData = groupedData;
        }

        this.context.commit(Mutations.SET_LESSONS_OPERATIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_LESSONS_HORSE](payload) {
    ApiService.setAuthorizationHeader();
    const params = {
      ...payload,
    };

    return ApiService.query('/lesson/horse', {
      params,
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LESSONS_HORSES, data);

        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
  @Action
  [Actions.CHECK_ATTENDENCE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/lesson/check-attendance', data)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
  @Action
  [Actions.COMPLETE_ATTENDENCE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.updateBulk('/lesson/attendance', data)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
  @Action
  [Actions.CREATE_LESSON](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/lesson', data)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
  @Action
  [Actions.GET_LESSONS_STUDENT_COUNT]() {
    ApiService.setAuthorizationHeader();

    return ApiService.get('/lesson/student-count')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LESSONS_STUDENT_COUNT, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
  @Action
  [Actions.GET_LESSONS_GROUP_BY_DATE](params) {
    ApiService.setAuthorizationHeader();

    return ApiService.query('lesson/grouped-by-date', { params })
      .then(({ data }) => {
        const groupedData = {};

        for (const date in data.data) {
          if (date !== 'studentsCount') {
            data.data[date].forEach((item) => {
              const { startTime, endTime } = calculateStartAndEndTime(
                item.time,
                item.durationName
              );
              const dt = moment(item.startDate, 'YYYY-MM-DD HH:mm:ss');
              const key = `${item.classType}_${startTime}_${endTime}`;
              item.time = `${moment(startTime, 'hh:mm A').format(
                'hh:mm A'
              )} - ${moment(endTime, 'hh:mm A').format('hh:mm A')}`;

              item.date =
                `${moment(item.startDate, 'YYYY-MM-DD').format(
                  'YYYY-MM-DD'
                )} ` +
                ' ' +
                dt.format('dddd');

              if (!groupedData[key]) {
                groupedData[key] = [];
              }

              groupedData[key].push(item);
            });
          }
        }
        data.data.groupedData = groupedData;
        // this.context.commit(Mutations.SET_LESSONS_STUDENT_GROUP_BY_DATE, {
        //   data: groupedData,
        //   studentsCount: data.data.studentsCount,
        // });
        this.context.commit(Mutations.SET_LESSONS_STUDENT_GROUP_BY_DATE, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.CHECK_BALANCS](payload) {
    ApiService.setAuthorizationHeader();
    const params = {
      ...payload,
    };

    return ApiService.query('/lesson/check-balance', {
      params,
    })
      .then(({ data }) => {
        // this.context.commit(Mutations.SET_LESSONS_HORSES, data);

        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
  @Action
  [Actions.CHECK_GROOMER](payload) {
    ApiService.setAuthorizationHeader();
    const params = {
      ...payload,
    };

    return ApiService.query('/lesson/check-groomer', {
      params,
    })
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.SWAP_LESSONS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('lesson/swap', '', data)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_LESSONS](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('lesson', id)
      .then(() => {
        return;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
}
