import ApiService from '@/core/services/ApiService';
import AbilityService from '@/core/services/AbilityService';
import AuthService from '@/core/services/AuthService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export type Methods = 'manage' | 'create' | 'read' | 'update' | 'remove';

export interface PermissionObject {
  id: number;
  resource: string;
  method: Methods;
}

export interface UserRole {
  id: number;
  name: string;
  role_permission: {
    [key: string]: Array<PermissionObject>;
  };
}

export interface User {
  id: number;
  email: string;
  name: string;
  role: UserRole;
  role_id: number;
  token: string;
  userType: string;
  image: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!AuthService.getUser();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    // return true;
    return this.isAuthenticated;
  }

  /**
   * Get authentication errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_PASSWORD](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    AuthService.saveUser(user);
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    AuthService.saveUser(user);
    AbilityService.refresh();
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    AuthService.destroyUser();
    AbilityService.refresh();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post('auth', credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data.data);
        return data.data;
      })
      .catch(({ response }) => {
        console.log(response);

        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.LOGIN_TRAINER](credentials) {
    return ApiService.post('auth/trainer', credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data.data);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.RESET_PASSWORD](credentials) {
    return ApiService.post(`auth/reset-password/${credentials.id}`, credentials)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        console.log(response);
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.CLEAR_ERRORS]() {
    this.context.commit(Mutations.SET_ERROR, {});
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    const user: User | null = AuthService.getUser();
    if (!user) {
      this.context.commit(Mutations.PURGE_AUTH);
    } else if (!Object.keys(this.user).length) {
      this.context.commit(Mutations.SET_AUTH, user);
    }
  }
}
