
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default defineComponent({
    name: 'roles-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const tableData = ref([]);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('rolesListing'), [
          translate('members'),
        ]);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_ROLES_STORE);
      });

      const response = await store.dispatch(Actions.GET_GROUP, route.params.id);
      tableData.value = response;

      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('title'),
          key: 'title',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusRole = async (roleItem) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_ROLE, roleItem.id);
          await store.dispatch(Actions.GET_GROUP, route.params.id);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const goBack = () => {
        router.back();
      };

      const editRole = (id) => {
        router.push({ name: 'roles-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_ROLE_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_GROUP, route.params.id);
        loading.value = false;
      };

      return {
        tableData,
        tableHeader,
        goBack,
        toggleStatusRole,
        translate,
        totalItems: computed(() => store.getters.rolesListCount),
        pageChanged,
        goTo,
        editRole,
        loading,
        can,
      };
    },
  });
