import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface StaffObject {
  id: number;
  name: string;
  phone: string;
  email: string;
  address: string;
  dateOfBirth: string;
  passportNumber: string;
  civilId: string;
  residencyNumber: string;
  classLimityPerDay: number;
  nationalityId: string;
  genderId: string;
  statusId: string;
  createdAt: string;
  updatedAt: string;
  statusName: string;
  nationalityName: string;
  genderName: string;
}

export interface StaffListInfo {
  staffs: Array<StaffObject>;
  allStaff: Array<StaffObject>;
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  errors: unknown;
}

@Module
export default class StaffModule extends VuexModule implements StaffListInfo {
  staffs = [] as Array<StaffObject>;
  allStaff = [] as Array<StaffObject>;
  staffFilter = {} as { statusId: string; staffId };

  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get staff list
   * @returns Array<StaffObject>
   */
  get allStaffList(): Array<StaffObject> {
    return this.allStaff;
  }
  /**
   * Get admins list
   * @returns Array<AdminUser>
   */
  get staffList(): Array<StaffObject> {
    return this.staffs;
  }

  /**
   * Get total items
   * @returns number
   */
  get staffListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_STAFF](data) {
    this.staffs = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_ALL_STAFF](data) {
    this.allStaff = data.data;
  }

  @Mutation
  [Mutations.SET_STAFF_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_STAFF_LIST]() {
    this.staffs = [] as Array<StaffObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_STAFF_FILTER](obj) {
    this.staffFilter.statusId = obj.statusId;
    this.staffFilter.staffId = obj.staffId;
  }

  @Action
  [Actions.UPDATE_STAFF_FILTER](val) {
    this.context.commit(Mutations.SET_STAFF_FILTER, val);
  }

  @Action
  [Actions.RESET_STAFF_STORE]() {
    this.context.commit(Mutations.RESET_STAFF_LIST);
  }

  @Action
  [Actions.DELETE_STAFF](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('staff', id)
      .then(() => {
        this.context.commit(Mutations.RESET_STAFF_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_STAFF]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('staff', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_STAFF_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_STAFF_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_STAFF_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_STAFF](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('staff', id);
  }

  @Action
  [Actions.GET_STAFF_LIST]() {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.page,
      statusId: this.staffFilter.statusId ? this.staffFilter.statusId : null,
      staffId: this.staffFilter.staffId ? this.staffFilter.staffId : null,
    };
    return ApiService.query('/staff', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_STAFF, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_STAFF_LIST](active = true) {
    console.log(active);
    ApiService.setAuthorizationHeader();
    const params = {
      statusId: this.staffFilter.statusId ? this.staffFilter.statusId : null,
      staffId: this.staffFilter.staffId ? this.staffFilter.staffId : null,
      active,
    };
    return ApiService.query('/staff/all', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_STAFF, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.CREATE_STAFF](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/staff', data)
      .then((data) => {
        this.context.commit(Mutations.RESET_STAFF_LIST);
        return data.data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
  @Action
  [Actions.UPLOAD_STAFF]({ id, data }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/staff/attachments/${id}`, data)
      .then(() => {
        this.context.commit(Mutations.RESET_STAFF_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_STAFF_ATTACHMENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/staff/attachments', id)
      .then(() => {
        this.context.commit(Mutations.RESET_STAFF_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
}
