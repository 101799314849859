
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import TreatmentModal from '@/components/modal/TreatmentModal.vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import printJs from 'print-js';
  import { getUser } from '@/core/services/AuthService';

  export default defineComponent({
    name: 'horse-overview',
    components: {
      TreatmentModal,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();

      const submitButton = ref<HTMLButtonElement | null>(null);

      const treatmentId = ref(0);
      const treatmentDate = ref('');
      const horseId = ref(0);
      const documentTreatment = ref();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      const treatmantList = ref();

      const excelHeader = reactive({
        Name: 'horse.nameEn',
        Treatment: 'treatment.nameEn',
        'Treatment Date': 'treatmentDate',
      });

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('treatmentOverview'), [
          translate('operations'),
        ]);

        loading.value = true;
        const data = await store.dispatch(
          Actions.GET_HORSES_TREATMENT,
          route.params.id
        );
        treatmantList.value = data.data;
        loading.value = false;
      });
      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_HORSE_STORE);
      });

      const loading = ref(false);

      const medicalRecords = ref();

      const addNewTreatment = async (values) => {
        const data = {
          treatmentDate: values.treatmentDate,
          treatmentId: values.treatmentId,
        };
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const newHorseData = await store.dispatch(Actions.UPDATE_HORSE, {
          id: values.horseId,
          data,
        });
        let [errorName] = Object.keys(store.getters.getErrors);
        let error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
          return;
        }
        if (values.attachments.length > 0) {
          const formData = new FormData();
          values.attachments.forEach((attachment) => {
            formData.append(`attachments`, attachment);
          });
          formData.append('documentTypeId', values.docuemntTypeId);

          await store.dispatch(Actions.UPLOAD_MEDICAL_ATTACHMENT, {
            id: newHorseData.id,
            data: formData,
          });
        }

        [errorName] = Object.keys(store.getters.getErrors);
        error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_HORSE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            location.reload();
          });
        }
      };
      const uploadNewTreatmentAttachment = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const formData = new FormData();
        values.attachments.forEach((attachment) => {
          formData.append(`attachments`, attachment);
        });
        formData.append('documentTypeId', values.docuemntTypeId);

        await store.dispatch(Actions.UPLOAD_MEDICAL_ATTACHMENT, {
          id: values.index,
          data: formData,
        });

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_HORSE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            location.reload();
          });
        }
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      await store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.TREATMENT_TYPE,
        LookupNameIds.DOCUMENT_TYPE,
      ]);

      const excelData = computed(() => {
        return treatmantList.value?.map((treatment) => {
          return {
            ...treatment,
            treatmentDate: treatment.treatmentDate.slice(0, 10),
          };
        });
      });
      const reportData = ref();

      const print = async (values) => {
        const user = getUser();
        const userName = user ? user['name'] : '';

        await store.dispatch(Actions.GET_LESSONS, values);

        reportData.value = treatmantList.value?.map((treatment) => {
          return {
            ...treatment,
            treatmentDate: treatment.treatmentDate.slice(0, 10),
          };
        });

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'portrait',
        });

        doc.text(
          'Print DateTime: ' + new Date().toLocaleString('en-GB').split(','),
          50,
          12
        );

        doc.addImage('/media/logos/krc.jpg', 'jpg', 1, 9, 40, 40);

        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;

        doc.text('Kuwait Riding Center', textX, 25);
        doc.text('Treatment Overview', textX + 10, 40);
        let pageCount = 0;
        const resTabelBody: any[] = [];
        reportData.value.forEach(function (element, index) {
          resTabelBody.push([
            index + 1,
            element.treatment.nameEn,
            element.treatment.nameAr,
            element.treatmentDate,
          ]);
        });
        autoTable(doc, {
          head: [['Sr', 'Name English', 'Name Arabic', 'Treatment Date']],
          body: resTabelBody,
          showHead: 'firstPage', // Only show the header on the first page of the nested table
          startY: doc.getCurrentPageInfo().pageNumber == 1 ? 55 : 20,
          horizontalPageBreak: false,
          theme: 'grid',
          headStyles: {
            fillColor: [0, 72, 115],
            fontStyle: 'bold',
            halign: 'left',
          },
          bodyStyles: {
            fontStyle: 'bold',
            halign: 'left',
          },
          styles: {
            fontSize: 10,
          },
          didDrawPage: function () {
            pageCount++;
            doc.text(
              'Page ' + pageCount,
              textX,
              doc.internal.pageSize.height - 10
            );
          },
        });
        Array.from({ length: pageCount }, (x, i) => {
          doc.setPage(i + 1);
          doc.text(userName, 5, doc.internal.pageSize.height - 10);

          doc.text(
            '     of ' + doc.getNumberOfPages(),
            textX + 15,
            doc.internal.pageSize.height - 10
          );
        });

        // doc.save('table.pdf');
        // doc.autoPrint();
        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        printJs({ printable: blobUrl, type: 'pdf' });
      };

      return {
        translate,
        goTo,
        loading,
        can,
        treatmantList,
        excelData,
        allHorsesList: computed(() => store.getters.allHorsesList),
        treatmentId,
        treatmentDate,
        documentTreatment,
        submitButton,
        medicalRecords,
        horseId,
        addNewTreatment,
        uploadNewTreatmentAttachment,
        excelHeader,
        print,
      };
    },
  });
