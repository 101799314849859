import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface PendingAccountObject {
  titleEn: string;
  titleAR: string;
  contentEn: string;
  contentAr: string;
  categoryId: string;
  id: string;
}

export interface PendingAccountListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  pendingAccount: Array<PendingAccountObject>;
  errors: unknown;
}

@Module
export default class PendingAccountModule
  extends VuexModule
  implements PendingAccountListInfo
{
  pendingAccount = [] as Array<PendingAccountObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get pendingAccount list
   * @returns Array<PendingAccountObject>
   */
  get pendingAccountList(): Array<PendingAccountObject> {
    return this.pendingAccount;
  }

  /**
   * Get total items
   * @returns number
   */
  get pendingAccountListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_PENDINGACCOUNT](data) {
    this.pendingAccount = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_PENDINGACCOUNT_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_PENDINGACCOUNT_LIST]() {
    this.pendingAccount = [] as Array<PendingAccountObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_PENDINGACCOUNT_STORE]() {
    this.context.commit(Mutations.RESET_PENDINGACCOUNT_LIST);
  }

  @Action
  [Actions.DELETE_PENDINGACCOUNT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/customer/pending-account', id)
      .then(() => {
        this.context.commit(Mutations.RESET_PENDINGACCOUNT_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_PENDINGACCOUNT_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_PENDINGACCOUNT_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_PENDINGACCOUNTS]() {
    const params = {
      page: this.page,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/customer/pending-account', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PENDINGACCOUNT, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
}
