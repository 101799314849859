
  import { defineComponent, onMounted, ref } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import { AnnouncementObject } from '@/store/modules/settings/AnnouncementModule';

  export default defineComponent({
    name: 'announcement-editing',
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('Package Details'), [
          translate('settings'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_CUSTOMER_HISTORY,
        route.params.id
      );
      const bgClasses = [
        'bg-primary',
        'bg-danger',
        'bg-success',
        'bg-info',
        'bg-warning',
        'bg-danger',
      ];

      const packages = ref<AnnouncementObject>(data.data);
      console.log(packages.value);
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const showPackageTitle = (title) => {
        switch (title) {
          case 'attended':
            return 'Attended Lessons';
          case 'absent':
            return 'Absent Lessons';
          case 'cancelled':
            return 'Cancelled Lessons';
          case 'freeze':
            return 'Freeze Lessons';
          case 'cancelled by krc':
            return 'Cancelled by KRC';
          default:
            return title;
        }
      };
      const showPackageColor = (title) => {
        switch (title) {
          case 'attended':
            return 'bg-success';
          case 'absent':
            return 'bg-danger';
          case 'cancelled':
            return 'bg-warning';
          case 'freeze':
            return 'bg-info';
          case 'cancelled by krc':
            return 'bg-primary';
          default:
            return title;
        }
      };

      return {
        translate,
        can,
        packages,
        bgClasses,
        showPackageTitle,
        showPackageColor,
      };
    },
  });
