
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { Field } from 'vee-validate';
  import LessonPendingListing from '@/components/dropdown/lessonPendingFilter.vue';

  export default defineComponent({
    name: 'lesson-pending-listing',
    components: {
      LessonPendingListing,
      Datatable,
      Field,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const selectedStatus = ref('');
      const showStatusField = ref(false);
      const selectedRecored = ref([]);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('Lesson Pending List'), [
          translate('operations'),
        ]);
        loading.value = true;

        if (route.query.requestId) {
          await store.dispatch(Actions.UPDATE_LESSONS_PENDING_FILTER, {
            pendingRequestId: route.query.requestId,
          });
        }

        await store.dispatch(Actions.GET_LESSONS_PENDING_LIST);
        await store.dispatch(Actions.GET_ALL_CUSTOMERS);
        loading.value = false;
      });
      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_ARENAS_STORE);
      });

      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate(''),
          key: 'checkbox',
          sortable: true,
        },
        {
          name: translate('customer'),
          key: 'customerName',
          sortable: true,
        },
        {
          name: translate('lesson Type'),
          key: 'lessonType',
          sortable: true,
        },
        {
          name: translate('horse Type'),
          key: 'horseTypeName',
          sortable: true,
        },
        {
          name: translate('class Category'),
          key: 'classCategoryName',
          sortable: true,
        },
        {
          name: translate('class Level'),
          key: 'classLevelName',
          sortable: true,
        },
        {
          name: translate('request Status'),
          key: 'requestStatusName',
          sortable: true,
        },
        {
          name: translate('price'),
          key: 'price',
          sortable: true,
        },
        {
          name: translate('numberOfLessons'),
          key: 'numberOfLessons',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const deletePendingLesson = async (arenas) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_LESSONS_PENDING, arenas.id);
          await store.dispatch(Actions.GET_LESSONS_PENDING_LIST);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };
      const generateInvoice = async (lesson) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.GENERATE_LESSON_INVOICE, lesson.id);
          await store.dispatch(Actions.GET_LESSONS_PENDING_LIST);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            Swal.fire({
              text: translate('Invoice Generated Successfully'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-success',
              },
            });
          }
        }
      };
      const goToCreateLesson = (lesson) => {
        console.log(lesson);
        router.push({
          name: 'lessons-creating',
          query: {
            numberOfLessons: lesson.numberOfLessons,
            customerId: lesson.customerId,
            typeId: lesson.typeId,
            levelId: lesson.classLevelId,
            categoryId: lesson.classCategoryId,
          },
        });
      };

      const editArena = (id) => {
        router.push({ name: 'arena-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_LESSONS_PENDING_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_LESSONS_PENDING_LIST);
        loading.value = false;
      };

      const searchLessons = async (query) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_LESSONS_PENDING_FILTER, query);
        await store.dispatch(Actions.UPDATE_LESSONS_PENDING_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_LESSONS_PENDING_LIST);
        loading.value = false;
      };
      const onReset = async (query) => {
        await store.dispatch(Actions.UPDATE_LESSONS_PENDING_FILTER, query);
        await store.dispatch(Actions.UPDATE_LESSONS_PENDING_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_LESSONS_PENDING_LIST);
      };
      const toggleStatusField = () => {
        selectedStatus.value = '';
        if (selectedRecored.value.length === 0) {
          showStatusField.value = false;
          return;
        }
        selectedRecored.value = [
          selectedRecored.value[selectedRecored.value.length - 1],
        ];
        showStatusField.value = selectedRecored.value.length > 0;
      };
      const updateStatus = async () => {
        const payload = {
          pendingRequestId: selectedStatus.value,
        };
        loading.value = true;
        await store.dispatch(Actions.UPDATE_LESSONS_PENDING, {
          id: selectedRecored.value[0],
          data: payload,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        //'No price for some trainer'

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          loading.value = false;
        } else {
          Swal.fire({
            text: translate('Status Updated Successfully'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-success',
            },
          });
          await store.dispatch(Actions.GET_LESSONS_PENDING_LIST);
          loading.value = false;
          showStatusField.value = false;
          selectedStatus.value = '';
          selectedRecored.value = [];
        }
      };

      const lookups = await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.REQUEST_LESSON_STATUS,
        LookupNameIds.HORSE_TYPE,
        LookupNameIds.CLASS_TYPE,
      ]);
      const requestStatus = lookups.filter(
        (status) => status.lookupNameId === LookupNameIds.REQUEST_LESSON_STATUS
      );
      return {
        tableData: computed(() => store.getters.lessonPendingList),
        tableHeader,
        deletePendingLesson,
        translate,
        totalItems: computed(() => store.getters.lessonPendingListCount),
        pageChanged,
        goTo,
        editArena,
        loading,
        can,
        onReset,
        generateInvoice,
        goToCreateLesson,
        requestStatus,
        selectedStatus,
        showStatusField,
        selectedRecored,
        toggleStatusField,
        updateStatus,
        searchLessons,
      };
    },
  });
