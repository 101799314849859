
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import ArenaFilter from '@/components/dropdown/ArenaFilter.vue';
  import LookupNameIds from '@/store/enums/settingsEnums';

  export default defineComponent({
    name: 'arenas-listing',
    components: {
      ArenaFilter,
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('arenasListing'), [
          translate('operations'),
        ]);
        loading.value = true;
        await store.dispatch(Actions.GET_ARENAS);
        await store.dispatch(Actions.GET_ALL_ARENAS, false);
        loading.value = false;
      });
      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_ARENAS_STORE);
      });

      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('nameAr'),
          key: 'nameAr',
          sortable: true,
        },
        {
          name: translate('nameEn'),
          key: 'nameEn',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const deleteArena = async (arenas) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_ARENA, arenas.id);
          await store.dispatch(Actions.GET_ARENAS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editArena = (id) => {
        router.push({ name: 'arena-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_ARENA_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_ARENAS);
        loading.value = false;
      };

      const searchArena = async (query) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_ARENA_FILTER, {
          ...query,
        });
        await store.dispatch(Actions.UPDATE_ARENA_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_ARENAS);
        await store.dispatch(Actions.GET_ALL_ARENAS, false);
        loading.value = false;
      };
      const onReset = async (query) => {
        await store.dispatch(Actions.UPDATE_ARENA_FILTER, query);
        await store.dispatch(Actions.UPDATE_ARENA_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_ARENAS);
        await store.dispatch(Actions.GET_ALL_ARENAS, false);
      };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.STATUS,
      ]);

      return {
        tableData: computed(() => store.getters.arenasList),
        tableHeader,
        deleteArena,
        translate,
        totalItems: computed(() => store.getters.arenasListCount),
        pageChanged,
        goTo,
        editArena,
        loading,
        can,
        searchArena,
        onReset,
      };
    },
  });
