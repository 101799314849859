
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'class-category-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.push('/');
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('ChangeYourPassword'), []);
      });

      const validationSchema = Yup.object().shape({
        currentPassword: Yup.string()
          .required(() => translate('CURRENT_PASSWORD_IS_REQUIRED_FIELD'))
          .min(6, () => translate('PASSWORD_MIN_LENGTH_ERROR'))
          .label('Password'),
        newPassword: Yup.string()
          .required(() => translate('PASSWORD_IS_REQUIRED_FIELD'))
          .min(6, () => translate('PASSWORD_MIN_LENGTH_ERROR'))
          .label('Password'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('newPassword'), null], () =>
            translate('PASSWORDS_DO_NOT_MATCH')
          )
          .required(() => translate('CONFIRM_PASSWORD_IS_REQUIRED_FIELD'))
          .label('Confirm Password'),
      });
      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.CHANGE_ADMIN_PASSWORD, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        console.log(error);

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_YOUR_PASSWORD'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'admins-listing' });
          });
        }
      };

      return {
        onSubmitCreate,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
      };
    },
  });
