
  import { defineComponent, computed, onMounted, ref } from 'vue';
  import KTUserMenu from '@/layout/header/partials/UserMenu.vue';
  import { useStore } from 'vuex';
  import KTNotificationsMenu from '@/layout/header/partials/NotificationsMenu.vue';
  import { Actions } from '@/store/enums/StoreEnums';
  import { Mutation } from 'vuex-module-decorators';

  export default defineComponent({
    name: 'topbar',
    components: {
      KTUserMenu,
      KTNotificationsMenu,
    },
    setup() {
      const store = useStore();
      const allSeen = ref(false);

      const allNotificationSeen = computed(
        () => store.getters.notificationSeen
      );

      onMounted(async () => {
        const data = await store.dispatch(Actions.GET_NOTIFICATIONS);
        allSeen.value = data.allNotificationsSeen;
      });
      const toggleModal = ref(false);

      const fetchNotifications = async () => {
        toggleModal.value = !toggleModal.value;
        await store.dispatch(Actions.RESET_NOTIFICATION_STORE);
        const data = await store.dispatch(Actions.GET_NOTIFICATIONS);
        allSeen.value = data.allNotificationsSeen;
      };

      return {
        allNotificationSeen,
        fetchNotifications,
        allSeen,
        toggleModal,
      };
    },
  });
