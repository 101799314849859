import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';
import AuthModule from '@/store/modules/AuthModule';
import BodyModule from '@/store/modules/Layout/BodyModule';
import BreadcrumbsModule from '@/store/modules/Layout/BreadcrumbsModule';
import ConfigModule from '@/store/modules/Layout/ConfigModule';
import RolesModule from '@/store/modules/members/RolesModule';
import GroupsModule from '@/store/modules/members/GroupsModule';
import PermissionModule from '@/store/modules/PermissionModule';
import AdminsModule from './modules/members/AdminsModule';
import LookupModule from './modules/settings/LookupModule';
import SettingsHosreLocationModule from './modules/settings/HorseLocationModule';
import SettingsHolidayModule from './modules/settings/HolidayModule';
import SettingsClassLevelModule from './modules/settings/ClassLevelModule';
import SettingsClassCategoryModule from './modules/settings/ClassCategoryModule';
import SettingsCustomerAgeModule from './modules/settings/CustomerAgeModule';
import OperationsGroomersModule from './modules/operations/GroomersModule';
import TrainersModule from './modules/operations/TrainersModule';
import ArenasModule from './modules/operations/ArenasModule';
import CustomersModule from './modules/operations/CutomersModule';
import HorsesModule from './modules/operations/HorsesModule';
import LessonsModule from '@/store/modules/operations/LessonsModule';
import StaffModule from './modules/operations/StaffModule';
import ReportModule from '@/store/modules/operations/ReportModule';
import InvoiceModule from '@/store/modules/finance/InvoiceModule';
import CustomerCreditModule from '@/store/modules/finance/CustomerCreditModule';
import CustomerServiceStatmentModule from '@/store/modules/finance/CustomerServiceStatmentModule';
import ReportsModule from '@/store/modules/reports/ReportModule';
import CustomerBalanceModule from '@/store/modules/finance/CustomerBalanceModule';
import ContentPagesModule from '@/store/modules/settings/ContentPagesModule';
import AnnouncementModule from '@/store/modules/settings/AnnouncementModule';
import ServiceModule from '@/store/modules/settings/ServicesModule';
import LessonPendingModule from '@/store/modules/operations/LessonPendingModule';
import ShopModule from '@/store/modules/operations/ShopModule';
import CategoryModule from '@/store/modules/operations/CategoryModule';
import ProductModule from '@/store/modules/operations/ProductModule';
import OfferModule from '@/store/modules/operations/OfferModule';
import CancellationModule from '@/store/modules/operations/CancellationModule';
import TicketModule from '@/store/modules/operations/TicketModule';
import NotificationModule from '@/store/modules/NotificationModule';
import PendingAccountModule from '@/store/modules/operations/PendingAccount';

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    RolesModule,
    GroupsModule,
    PermissionModule,
    AdminsModule,
    LookupModule,
    SettingsHosreLocationModule,
    SettingsHolidayModule,
    SettingsClassLevelModule,
    SettingsClassCategoryModule,
    SettingsCustomerAgeModule,
    OperationsGroomersModule,
    TrainersModule,
    ArenasModule,
    CustomersModule,
    HorsesModule,
    LessonsModule,
    StaffModule,
    ReportModule,
    InvoiceModule,
    CustomerCreditModule,
    CustomerServiceStatmentModule,
    ReportsModule,
    CustomerBalanceModule,
    ContentPagesModule,
    AnnouncementModule,
    ServiceModule,
    LessonPendingModule,
    ShopModule,
    CategoryModule,
    ProductModule,
    OfferModule,
    CancellationModule,
    TicketModule,
    NotificationModule,
    PendingAccountModule,
  },
});

export default store;
