
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default defineComponent({
    name: 'announcement-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const searchItems = async (inputText) => {
        await store.dispatch(Actions.UPDATE_ANNOUNCEMENT_FILTER, {
          title: inputText,
        });
        await store.dispatch(Actions.GET_ANNOUNCEMENTS);
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('Announcement Listing'), [
          translate('settings'),
        ]);

        onBeforeUnmount(() => {
          store.dispatch(Actions.RESET_ANNOUNCEMENT_STORE);
        });
        loading.value = true;
        await store.dispatch(Actions.GET_ANNOUNCEMENTS);
        loading.value = false;
      });
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('TitleEn'),
          key: 'titleEn',
          sortable: true,
        },
        {
          name: translate('TitleAr'),
          key: 'titleAr',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const deleteAnnouncement = async (announcement) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_ANNOUNCEMENT, announcement.id);
          await store.dispatch(Actions.GET_ANNOUNCEMENTS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };
      const toggleAnnouncement = async (announcement) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        announcement.status = !announcement.status;
        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.UPDATE_ANNOUNCEMENT, {
            data: announcement,
            id: announcement.id,
          });
          await store.dispatch(Actions.GET_ANNOUNCEMENTS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editAnnouncement = (id) => {
        router.push({ name: 'announcement-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_ANNOUNCEMENT_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_ANNOUNCEMENTS);
        loading.value = false;
      };

      return {
        tableData: computed(() => store.getters.announcementList),
        tableHeader,
        inputText: '',
        searchItems,
        deleteAnnouncement,
        toggleAnnouncement,
        translate,
        totalItems: computed(() => store.getters.announcementListCount),
        pageChanged,
        goTo,
        editAnnouncement,
        loading,
        can,
      };
    },
  });
