import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';
import { getUser } from '@/core/services/AuthService';
import { User, Methods } from '@/store/modules/AuthModule';

/**
 * @permissions
   [
    "read",
    "create",
    "update",
    "remove",
   ]
 **/

/**
 * @group Super Admin 
   [
    "registered-trades",
    "users",
    "permission",
    "roles",
    "members",
    "assets",
    "auctions",
    "official-appraisal",
    "ads",
    "ive",
    "instant-rent-estimator",
    "appraisers",
   ]
 **/

/**
 * @group Appraiser 
   [
    "appraisal-request",
    "own-appraiser",
   ]
 **/

export type AppAbility = Ability<[Methods, string]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

const ability = new AppAbility();

export function refresh() {
  const user: User | null = getUser();
  const { can, rules } = new AbilityBuilder(AppAbility);

  if (user) {
    for (const key of Object.keys(user.role.role_permission)) {
      user.role.role_permission[key].forEach((permission) => {
        can(permission.method, permission.resource);
      });
    }
  }
  return ability.update(rules);
}

export default { refresh, ability };
