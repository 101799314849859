
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import Multiselect from '@vueform/multiselect';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import Attachment from '@/components/common/Attachments.vue';
  import moment from 'moment';

  export default defineComponent({
    name: 'customer-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
      Attachment,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const dynamicGuardiansSchema = ref();
      const dateOfBirth = ref();
      const tableData = ref([
        {
          name: '',
          email: '',
          phone: '',
          typeId: '',
        },
      ]);
      const primaryContactIndex = ref(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('customerEditing'), [
          translate('operations'),
        ]);
      });

      const validationSchema = Yup.object().shape({
        name: Yup.string()
          .required(() => translate('NAME_IS_REQUIRED_FIELD'))
          .label('Name'),
        email: Yup.string().email('Invalid email format').notRequired(),
        nationalityId: Yup.string()
          .notOneOf(['0'], () => translate('NATIONALITY_IS_REQUIRED_FIELD'))
          .required(() => translate('NATIONALITY_IS_REQUIRED_FIELD'))
          .label('nationalityId'),
        genderId: Yup.string()
          .notOneOf(['0'], () => translate('GENDER_IS_REQUIRED_FIELD'))
          .required(() => translate('GENDER_IS_REQUIRED_FIELD'))
          .label('genderId'),
        statusId: Yup.string()
          .notOneOf(['0'], () => translate('STATUS_IS_REQUIRED_FIELD'))
          .required(() => translate('STATUS_IS_REQUIRED_FIELD'))
          .label('Statuses'),
        civilId: Yup.string()
          .matches(/^\d{12}$/, {
            message: () => translate('Civil id must be 12 digits'),
          })
          .required(() => translate('CIVIL_IS_REQUIRED_FIELD'))
          .label('civil'),
        address: Yup.string()
          .required(() => translate('ADDRESS_IS_REQUIRED_FIELD'))
          .label('address'),
        typeId: Yup.string()
          .required(() => translate('CUSTOMER_TYPE_IS_REQUIRED_FIELD'))
          .label('class category'),
        dateOfBirth: Yup.string()
          .required(() => translate('DATE_OF_BIRTH_IS_REQUIRED_FIELD'))
          .label('Date Of Birth'),
        classCategoryId: Yup.string()
          .notOneOf(['0'], () => translate('CATEGORY_IS_REQUIRED_FIELD'))
          .required(() => translate('CATEGORY_IS_REQUIRED_FIELD'))
          .label('category'),
        classLevelId: Yup.string()
          .notOneOf(['0'], () => translate('Class Level is required field'))
          .required(() => translate('Class Level is required field'))
          .label('arena'),
        contactList: Yup.array().of(
          Yup.object().shape({
            phone: Yup.string()
              .matches(/^\d{8}$/, {
                message: () => translate('Phone number is not valid'),
              })
              .required(() => translate('PHONE_NUMBER_IS_REQUIRED_FIELD')),
          })
        ),
      });

      const guardianValidation = computed(() => {
        return Yup.object().shape({
          ...dynamicGuardiansSchema.value,
        });
      });
      const changeGuardiansSchema = () => {
        dynamicGuardiansSchema.value = {};
        tableData.value.forEach((v, index) => {
          dynamicGuardiansSchema.value[`phone_${index}`] = Yup.string()
            .test('required', 'Phone number is required', function (value) {
              if (!value || value.trim() === '') {
                return this.createError({
                  message: translate('PHONE_NUMBER_IS_REQUIRED_FIELD'),
                });
              }
              return true;
            })
            .test('valid-phone', 'Phone number is not valid', function (value) {
              if (value && !/^\d{8}$/.test(value)) {
                return this.createError({
                  message: translate('Phone number is not valid'),
                });
              }
              return true;
            })
            .test(
              'unique-phone',
              'Phone number must be unique',
              function (value) {
                const phoneCount = tableData.value.reduce((count, item) => {
                  if (item.phone === value) {
                    return count + 1;
                  }
                  return count;
                }, 0);

                const isPhoneExist = phoneCount > 1;

                if (isPhoneExist && value !== '') {
                  return false;
                }

                return true;
              }
            );

          dynamicGuardiansSchema.value[`typeId_${index}`] =
            Yup.string().required(() =>
              translate('GUARDIAN_TYPE_IS_REQUIRED_FIELD')
            );
          dynamicGuardiansSchema.value[`email_${index}`] = Yup.string()
            .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
            .test('unique-email', 'Email must be unique', function (value) {
              const emailCount = tableData.value.reduce((count, item) => {
                if (item.email === value) {
                  return count + 1;
                }
                return count;
              }, 0);

              const isEmailExist = emailCount > 1;

              if (isEmailExist && value !== '') {
                return false;
              }

              return true;
            })
            .test('valid-email', 'Invalid email format', function (value) {
              return Yup.string().email().isValidSync(value);
            });

          dynamicGuardiansSchema.value[`name_${index}`] = Yup.string()
            .required(() => translate('NAME_IS_REQUIRED_FIELD'))
            .label('Full Name');
        });
      };
      const deleteCustomer = (index) => {
        tableData.value.splice(index, 1);
      };

      const addNewRow = () => {
        tableData.value.push({
          name: '',
          email: '',
          phone: '',
          typeId: '',
        });
        changeGuardiansSchema();
      };
      const selectedCategory = ref(0);
      const selectedLevel = ref(0);
      const matchedClassLevelList = ref<any>([]);
      const init = ref(true);
      const customerObject = ref();

      watch(dateOfBirth, (value) => {
        customerObject.value.dateOfBirth = value;
        calculateAge(value);
      });

      watch(selectedCategory, async () => {
        const categoryId = selectedCategory.value;

        const categoryObj = categoryList.find((category) => {
          return category.id === categoryId;
        });
        matchedClassLevelList.value = classLevelList.filter((classLevel) =>
          categoryObj.classLevelId?.includes(classLevel.id)
        );

        const index = matchedClassLevelList.value.findIndex(
          (level) => level.id === selectedLevel.value
        );
        if (index == -1) {
          selectedLevel.value = 0;
        }
      });

      const { data } = await store.dispatch(
        Actions.GET_CUSTOMER,
        route.params.id
      );

      customerObject.value = data.data;
      dateOfBirth.value = customerObject.value.dateOfBirth;

      const selectedDayes = ref<Array<string>>(
        customerObject.value.preferredDays.map((i) => {
          return i.dayId;
        })
      );
      const selectedGender = ref<string>(customerObject.value.genderId);
      const selectedDoc = ref<string>(customerObject.value.documenttypeId);
      const selectedNationality = ref<string>(
        customerObject.value.nationalityId
      );
      const selectedStatuses = ref<string>(customerObject.value.statusId);
      const selectedCustomers = ref<string>(customerObject.value.typeId);

      const contactList = ref([
        {
          phone: '',
          isPrimary: false,
        },
      ]);

      const goBack = () => {
        router.back();
      };
      const ageInYear = ref(0);

      const calculateAge = (date) => {
        const currentDate = moment(date); // Current date, change as needed
        const age = currentDate.diff(moment(), 'years');
        if (age > 0) {
          ageInYear.value = 0;
        } else {
          ageInYear.value = Math.abs(age);
        }
      };

      calculateAge(customerObject.value.dateOfBirth);

      const attachmentSchema = Yup.object().shape({
        documenttypeId: Yup.string()
          .required(() => translate('DOCUMENT_IS_REQUIRED_FIELD'))
          .label('Doc'),
      });

      const onSubmitCreate = async (values) => {
        if (!isInternationalPhoneValid.value.every((value) => value)) {
          return;
        }
        console.log(values);
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.freeRegistration = customerObject.value.freeRegistration;
        values.horseOwner = customerObject.value.horseOwner;

        delete values.contactList;
        delete values.interNationalContactList;
        const payload = {
          ...values,
          contact: contactList.value.map((contact, index) => {
            const phoneNumber =
              contact.phone.length > 8 ? contact.phone : '965' + contact.phone;
            return {
              phone: phoneNumber,
              isPrimary: primaryContactIndex.value === index,
            };
          }),
        };

        console.log(payload);
        values.passportNumber = String(values.passportNumber);

        await store.dispatch(Actions.UPDATE_CUSTOMER, {
          data: payload,
          id: customerObject.value?.id,
        });

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_CUSTOMER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
      };

      const deleteAttachment = async (id) => {
        const result = await Swal.fire({
          title: translate('You are about to delete this attachment'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          await store.dispatch(Actions.DELETE_CUSTOMER_ATTACHMENT, id);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            Swal.fire({
              text: translate('Successfully deleted attachment'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
            location.reload();
          }
        }
      };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.GENDER,
        LookupNameIds.NATIONALITY,
        LookupNameIds.STATUS,
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.HORSE_TYPE,
        LookupNameIds.CUSTOMER_TYPE,
        LookupNameIds.DOCUMENT_TYPE,
        LookupNameIds.DAYS,
        LookupNameIds.GUARDIAN_TYPE,
      ]);

      const categoryList = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      );
      const classLevelList = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_LEVEL
      );

      const lookupsList = computed(() => store.getters.allLookupsList);

      const genders = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.GENDER;
      });

      const nationalities = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.NATIONALITY;
      });

      const statuses = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.STATUS;
      });

      const documents = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DOCUMENT_TYPE;
      });

      const guardians = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.GUARDIAN_TYPE;
      });

      const classes = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
      });

      const horses = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_TYPE;
      });

      const customers = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CUSTOMER_TYPE;
      });
      const preferredDayes = lookupsList.value
        .filter((lookup) => {
          return lookup.lookupNameId === LookupNameIds.DAYS;
        })
        .map((lookup) => {
          return { title: lookup.nameEn, id: lookup.id };
        });

      await store.dispatch(Actions.GET_ALL_SETTINGS_CUSTOMER_AGE);
      const attachmentsObj = customerObject.value.attachments.reduce(
        (acc, attachment) => {
          const documentType = attachment.documentType;

          if (!acc[documentType]) {
            acc[documentType] = [];
          }

          acc[documentType].push(attachment);

          return acc;
        },
        {}
      );

      selectedCategory.value = customerObject.value.classCategoryId;
      selectedLevel.value = customerObject.value.classLevelId;
      console.log(selectedLevel.value);
      const onSubmitCreateAttachment = async (values, cb) => {
        await store.dispatch(Actions.CREATE_CUSTOMER_ATTACHMENT, {
          data: values,
          id: customerObject.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ATTACHMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          location.reload();
        }
        cb();
      };

      if (customerObject.value && customerObject.value.gardians) {
        tableData.value = customerObject.value.gardians.map((guardian) => {
          return {
            name: guardian.name,
            email: guardian.email,
            phone: guardian.phone.substring(3),
            typeId: guardian.typeId,
          };
        });
      }

      const HandleDateOfBirth = (dateInput) => {
        customerObject.value.dateOfBirth = dateInput;
      };

      const removeContact = (index) => {
        contactList.value.splice(index, 1);
      };

      const addContact = () => {
        contactList.value.push({ phone: '', isPrimary: false });
      };

      if (customerObject.value && customerObject.value.contacts.length > 0) {
        contactList.value = customerObject.value.contacts.map(
          (customer, index) => {
            if (customer.isPrimary) {
              primaryContactIndex.value = index;
            }
            if (customer.phone.startsWith('965')) {
              return {
                phone: customer.phone.substring(3),
                isPrimary: customer.isPrimary,
              };
            } else {
              return {
                phone: customer.phone,
                isPrimary: customer.isPrimary,
              };
            }
          }
        );
      }

      const onSubmitCreateGuardian = async () => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          gardians: tableData.value.map((obj) => ({
            ...obj,
            phone: `965${obj.phone}`,
          })),
        };

        await store.dispatch(Actions.UPDATE_CUSTOMER, {
          data: payload,
          id: customerObject.value?.id,
        });

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_GUARDIANS'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
      };
      const isInternationalPhoneValid = ref<any>([]);
      const validateNumbers = (t, i) => {
        isInternationalPhoneValid.value[i] = t.valid;
      };

      return {
        onSubmitCreate,
        removeContact,
        guardians,
        addContact,
        guardianValidation,
        addNewRow,
        HandleDateOfBirth,
        deleteCustomer,
        customerObject,
        tableData,
        selectedCustomers,
        onSubmitCreateGuardian,
        selectedStatuses,
        selectedGender,
        selectedDayes,
        selectedNationality,
        documents,
        statuses,
        attachmentSchema,
        genders,
        nationalities,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        classes,
        customers,
        horses,
        onSubmitCreateAttachment,
        shipmentData: [],
        selectedDoc,
        contactList,
        preferredDayes,
        attachmentsObj,
        calculateAge,
        ageInYear,
        isInternationalPhoneValid,
        validateNumbers,
        categoryList,
        classLevelList,
        selectedCategory,
        selectedLevel,
        matchedClassLevelList,
        deleteAttachment,
        primaryContactIndex,
        dateOfBirth,
      };
    },
  });
