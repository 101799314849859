
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import Attachment from '@/components/common/Attachments.vue';

  export default defineComponent({
    name: 'groomer-location-creating',
    components: {
      Attachment,
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const nationality = ref<number | string>(0);
      const gender = ref<number | string>(0);
      const status = ref<number | string>(0);
      const docType = ref<number | string>(0);
      const groomerId = ref<string>('');
      const expiryDate = ref('');
      const dateOfirth = ref('');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('groomerCreating'), [
          translate('settings'),
        ]);
      });
      const files = ref<{ file: File }[]>([]);
      const previewFiles = ref<string[]>([]);

      const previewImage = (event) => {
        const selectedFiles = event.target.files;
        for (let i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i];
          files.value.push({ file });
          const fileURL = URL.createObjectURL(file);
          previewFiles.value!.push(fileURL);
        }
      };

      const removeImage = (index: number) => {
        files.value.splice(index, 1);
        previewFiles.value.splice(index, 1);
      };

      const validationSchema = Yup.object().shape({
        name: Yup.string()
          .required(() => translate('NAME_IS_REQUIRED_FIELD'))
          .label('Full Name'),
        nationalityId: Yup.string()
          .notOneOf(['0'], () => translate('NATIONALITY_IS_REQUIRED_FIELD'))
          .required(() => translate('NATIONALITY_IS_REQUIRED_FIELD'))
          .label('Nationality'),
        genderId: Yup.string()
          .notOneOf(['0'], () => translate('GENDER_IS_REQUIRED_FIELD'))
          .required(() => translate('GENDER_IS_REQUIRED_FIELD'))
          .label('Gender'),
        statusId: Yup.string()
          .notOneOf(['0'], () => translate('STATUS_IS_REQUIRED_FIELD'))
          .required(() => translate('STATUS_IS_REQUIRED_FIELD'))
          .label('Status'),
        phone: Yup.string()
          .notOneOf(['0'], () => translate('Mobile number is not valid'))
          .required(() => translate('PHONE_NUMBER_IS_REQUIRED_FIELD'))
          .matches(/^\d{8}$/, 'Mobile number is not valid')
          .label('Mobile number'),
        civilId: Yup.string()
          .notOneOf(['0'], () => translate('CIVIL_ID_IS_REQUIRED_FIELD'))
          .required(() => translate('CIVIL_ID_IS_REQUIRED_FIELD'))
          .matches(/^\d{12}$/, () => translate('CIVIL_ID_MUST_BE_12_DIGITS'))
          .label('Civil Id'),
        passportNumber: Yup.string()
          .required(() => translate('PASSPORT_NUMBER_IS_REQUIRED_FIELD'))
          .label('Passport Number'),
        address: Yup.string()
          .required(() => translate('ADDRESS_IS_REQUIRED_FIELD'))
          .label('Address'),
        dateOfBirth: Yup.string()
          .required(() => translate('DATE_IS_REQUIRED_FIELD'))
          .label('Date Of Birth'),
        email: Yup.string().email('Invalid email format').notRequired(),
      });
      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.classLimityPerDay = Number(values.classLimityPerDay);
        values.phone = `965${values.phone}`;
        const { data } = await store.dispatch(
          Actions.CREATE_GROOMERS_OPERATIONS,
          values
        );
        console.log(data);
        if (data) {
          groomerId.value = data.id;
        }
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_GROOMERS_OPERATION'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };
      const onSubmitCreateAttachment = async (values, cb) => {
        if (expiryDate.value) {
          values.append('expiryDate', expiryDate.value);
        }
        await store.dispatch(Actions.UPLOAD_GROOMER_ATTACHMENT, {
          data: values,
          id: groomerId.value,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ATTACHMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({
              name: 'operation-groomers-editing',
              params: { id: groomerId.value },
            });
          });
        }
        cb();
      };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.NATIONALITY,
        LookupNameIds.STATUS,
        LookupNameIds.GENDER,
        LookupNameIds.DOCUMENT_TYPE,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const nationalityList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.NATIONALITY;
      });

      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.STATUS;
      });
      const genderList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.GENDER;
      });
      const docTypeList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DOCUMENT_TYPE;
      });

      return {
        onSubmitCreate,
        onSubmitCreateAttachment,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        nationalityList,
        statusList,
        genderList,
        nationality,
        gender,
        status,
        docType,
        previewImage,
        files,
        previewFiles,
        docTypeList,
        removeImage,
        groomerId,
        expiryDate,
        dateOfirth,
      };
    },
  });
