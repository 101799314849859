
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import HorseFilter from '@/components/dropdown/HorseFilter.vue';
  import TreatmentModal from '@/components/modal/TreatmentModal.vue';
  import HorseTag from '@/components/Reports/HorseTag.vue';

  export default defineComponent({
    name: 'horse-listing',
    components: {
      Datatable,
      TreatmentModal,
      HorseFilter,
      HorseTag,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const submitButton = ref<HTMLButtonElement | null>(null);

      const inputText = ref('');

      const treatmentId = ref(0);
      const treatmentDate = ref('');
      const horseId = ref('');
      const documentTreatment = ref();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('horsesListing'), [
          translate('operations'),
        ]);

        onBeforeUnmount(() => {
          store.dispatch(Actions.RESET_HORSE_STORE);
        });
        loading.value = true;
        await store.dispatch(Actions.GET_HORSES_OPERATIONS);
        loading.value = false;
      });

      const loading = ref(false);

      const excelHeader = reactive({
        Name: 'nameEn',
        chipNumber: 'chipNumber',
        Category: 'category.nameEn',
        'Passport Number': 'passport',
        'Horse Type': 'type.nameEn',
        Gender: 'genderName',
        Status: 'status.nameEn',
      });
      const tableHeader = ref([
        {
          name: translate('English Name'),
          key: 'nameEn',
          sortable: true,
        },
        {
          name: translate('category'),
          key: 'category',
          sortable: true,
        },
        {
          name: translate('passport Number'),
          key: 'passport',
          sortable: true,
        },
        {
          name: translate('Horse Type'),
          key: 'type',
          sortable: true,
        },
        {
          name: translate('gender'),
          key: 'gender',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: true,
        },
        {
          name: translate('Horse Tag'),
          key: 'horseTag',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusUser = async () => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          //   await store.dispatch(Actions.DELETE_GROOMER, horses.id);
          // await store.dispatch(Actions.GET_GROOMERS_OPERATIONS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };
      const medicalRecords = ref();

      const editMedical = async (id) => {
        horseId.value = id;
        const data = await store.dispatch(Actions.GET_HORSE, id);
        medicalRecords.value = data[0].medicalRecords;
      };

      const searchItems = async () => {
        await store.dispatch(Actions.UPDATE_HORSES_FILTER, {
          name: inputText.value,
        });
        await store.dispatch(Actions.GET_HORSES_OPERATIONS);
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };
      const searchHorse = async (query) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_HORSES_FILTER, {
          ...query,
        });
        await store.dispatch(Actions.UPDATE_HORSES_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_HORSES_OPERATIONS);
        await store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS, false);

        loading.value = false;
      };
      const onReset = async (query) => {
        await store.dispatch(Actions.UPDATE_HORSES_FILTER, query);
        await store.dispatch(Actions.UPDATE_HORSES_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_HORSES_OPERATIONS);
        await store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS, false);
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_HORSES_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_HORSES_OPERATIONS);
        loading.value = false;
      };

      const addNewTreatment = async (values) => {
        const data = {
          treatmentDate: values.treatmentDate,
          treatmentId: values.treatmentId,
        };
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const newHorseData = await store.dispatch(Actions.UPDATE_HORSE, {
          id: horseId.value,
          data,
        });
        let [errorName] = Object.keys(store.getters.getErrors);
        let error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
          return;
        }
        if (values.attachments.length > 0) {
          const formData = new FormData();
          console.log(values.attachments);
          values.attachments.forEach((attachment) => {
            formData.append(`attachments`, attachment);
          });
          formData.append('documentTypeId', values.docuemntTypeId);

          await store.dispatch(Actions.UPLOAD_MEDICAL_ATTACHMENT, {
            id: newHorseData.id,
            data: formData,
          });
        }

        [errorName] = Object.keys(store.getters.getErrors);
        error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_HORSE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            location.reload();
          });
        }
      };

      await store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS, false);
      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.TREATMENT_TYPE,
        LookupNameIds.DOCUMENT_TYPE,
        LookupNameIds.STATUS,
        LookupNameIds.HORSE_CATEGORY,
        LookupNameIds.HORSE_TYPE,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const treatmantLIst = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.TREATMENT_TYPE;
      });
      console.log(store.getters.allHorsesList);

      return {
        tableData: computed(() => store.getters.horsesList),
        tableHeader,
        toggleStatusUser,
        translate,
        totalItems: computed(() => store.getters.horseslistCount),
        pageChanged,
        goTo,
        editMedical,
        loading,
        can,
        excelData: computed(() => store.getters.allHorsesList),
        excelHeader,
        searchItems,
        inputText,
        treatmantLIst,
        treatmentId,
        treatmentDate,
        addNewTreatment,
        documentTreatment,
        submitButton,
        medicalRecords,
        horseId,
        onReset,
        searchHorse,
      };
    },
  });
