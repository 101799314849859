
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  enum SwapType {
    Trainer = 'trainer',
    Horse = 'horse',
    Arena = 'arena',
    Groomer = 'groomer',
  }
  export default defineComponent({
    name: 'swap-lessons',
    emit: ['type-selected'],
    components: {},
    setup: async function (props, { emit }) {
      const { t, te } = useI18n();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmit = (type: string) => {
        emit('type-selected', type);
      };

      reinitializeComponents();
      return {
        onSubmit,
        translate,
        SwapType,
      };
    },
  });
