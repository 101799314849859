import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_8 = { class: "svg-icon svg-icon-2" }
const _hoisted_9 = { class: "card-body pt-0" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "svg-icon svg-icon-3" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'settings'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputText) = $event)),
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems(_ctx.inputText))),
                class: "form-control form-control-solid w-250px",
                placeholder: "Search Holiday Type"
              }, null, 544), [
                [_vModelText, _ctx.inputText]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.can('create', 'settings'))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-primary",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goTo('holiday-creating')))
                  }, [
                    _createElementVNode("span", _hoisted_8, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Plus.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate('addNewHolidayType')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            loading: _ctx.loading,
            "rows-per-page": 10,
            total: _ctx.totalItems,
            "enable-items-per-page-dropdown": false,
            onCurrentChange: _ctx.pageChanged
          }, {
            "cell-nameAr": _withCtx(({ row: holidayType }) => [
              _createTextVNode(_toDisplayString(holidayType.nameAr), 1)
            ]),
            "cell-nameEn": _withCtx(({ row: holidayType }) => [
              _createTextVNode(_toDisplayString(holidayType.nameEn), 1)
            ]),
            "cell-holidayStartDate": _withCtx(({ row: holidayType }) => [
              _createTextVNode(_toDisplayString(holidayType.holidayStartDate), 1)
            ]),
            "cell-holidayEndDate": _withCtx(({ row: holidayType }) => [
              _createTextVNode(_toDisplayString(holidayType.holidayEndDate), 1)
            ]),
            "cell-actions": _withCtx(({ row: holidayType }) => [
              (_ctx.can('update', 'settings'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: "#",
                    class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
                    onClick: _withModifiers(($event: any) => (_ctx.editHolidayType(holidayType.id)), ["prevent"])
                  }, [
                    _createElementVNode("span", _hoisted_11, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotune/art/art005.svg" })
                    ])
                  ], 8, _hoisted_10))
                : _createCommentVNode("", true),
              (_ctx.can('remove', 'settings'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: "#",
                    class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                    onClick: _withModifiers(($event: any) => (_ctx.deleteHolidayType(holidayType)), ["prevent"])
                  }, [
                    _createElementVNode("span", _hoisted_13, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen027.svg" })
                    ])
                  ], 8, _hoisted_12))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["table-data", "table-header", "loading", "total", "onCurrentChange"])
        ])
      ]))
    : _createCommentVNode("", true)
}