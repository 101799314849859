
  import {
    computed,
    defineComponent,
    onMounted,
    ref,
    defineExpose,
    ComponentPublicInstance,
  } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { useAbility } from '@casl/vue';

  export default defineComponent({
    name: 'groups-creating',
    components: {
      ErrorMessage,
      Field,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const formGroup = ref<ComponentPublicInstance | null>(null);
      const selectedPermissions = ref([]);
      const selectedGroup = ref<number>(0);
      const { can } = useAbility();
      const title = ref('');
      const description = ref('');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('groupsCreating'), [
          translate('members'),
        ]);
      });

      await store.dispatch(Actions.GET_ALL_PERMISSIONS, { limit: 50 });
      const permissions = computed(() => store.getters.allpermissionsList);

      interface Option {
        key: number;
        label: string;
        disabled: boolean;
      }

      const generateData = () => {
        const data: Option[] = [];
        const permissionsList = permissions.value;
        if (Array.isArray(permissionsList)) {
          for (let i = 0; i < permissionsList.length; i++) {
            const permission = permissionsList[i];
            data.push({
              key: permission,
              label: permission.toString(),
              disabled: Boolean(permission.disabled),
            });
          }
        }
        return data;
      };

      const data = ref<Option[]>(generateData());
      const value = ref([]);

      const goBack = () => {
        router.back();
      };

      const groupPayload = async () => {
        if (title.value.trim() === '' || value.value.length === 0) {
          return;
        }

        const values = {
          title: title.value,
          description: description.value,
          permissions: value.value,
        };

        await store.dispatch(Actions.CREATE_GROUP, values);

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_GROUP'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
      };

      await store.dispatch(Actions.GET_ROLES_GROUPS);

      const updatePermissionsList = (list) => {
        selectedPermissions.value = list;
      };

      defineExpose({
        groupPayload,
      });

      return {
        groups: computed(() => store.getters.rolesGroupsList),
        updatePermissionsList,
        title,
        description,
        groupPayload,
        formGroup,
        permissions,
        data,
        value,
        submitButton,
        translate,
        goBack,
        selectedGroup,
        can,
      };
    },
  });
