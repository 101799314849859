
  import { defineComponent, onMounted, ref } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { useRoute } from 'vue-router';
  import { asideMenuIcons } from '@/core/helpers/config';
  import MainMenuConfig from '@/core/config/MainMenuConfig';
  import { useAbility } from '@casl/vue';
  export default defineComponent({
    name: 'kt-menu',
    components: {},
    setup() {
      const { t, te } = useI18n();
      const route = useRoute();
      const scrollElRef = ref<null | HTMLElement>(null);
      const { can } = useAbility();

      onMounted(() => {
        if (scrollElRef.value) {
          scrollElRef.value.scrollTop = 0;
        }
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const hasActiveChildren = (match) => {
        return route.path.indexOf(match) !== -1;
      };
      const checkAbility = (method, resource) => {
        // return true;
        let members = resource instanceof Array ? resource : [resource];
        let valid = false;
        members.some((currentValue) => {
          valid = can(method, currentValue);
          return valid;
        });
        return valid;
      };

      return {
        hasActiveChildren,
        MainMenuConfig,
        asideMenuIcons,
        translate,
        can,
        checkAbility,
      };
    },
  });
