
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    onBeforeMount,
    watch,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import LookupNameIds from '@/store/enums/settingsEnums';

  /*
  *   PRIVACY = 27,
  RULES_REQULATIONS = 28,
  COMPLAINTS = 29,**/
  enum ContentPageNames {
    aboutUs = 'about-us-listing',
    terms = 'terms-of-use-listing',
    privacy = 'privacy-listing',
    rules = 'rules-regulations-listing',
    complaints = 'complaints-listing',
  }
  export default defineComponent({
    name: 'content-page-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const lookupId = ref<null | number>(null);
      const language = ref({
        pageTitle: '',
        buttonTitle: '',
      });
      const routeCreatePageName = ref('');
      const routeEditPageName = ref('');

      const getPageContent = () => {
        const routeName = router.currentRoute.value.name;
        routeCreatePageName.value = String(routeName).replace(
          'listing',
          'creating'
        );
        routeEditPageName.value = String(routeName).replace(
          'listing',
          'editing'
        );
        if (routeName === ContentPageNames.aboutUs) {
          lookupId.value = LookupNameIds.ABOUT_US;
        } else if (routeName === ContentPageNames.terms) {
          lookupId.value = LookupNameIds.TERMS_OF_USE;
        } else if (routeName === ContentPageNames.privacy) {
          lookupId.value = LookupNameIds.PRIVACY;
        } else if (routeName === ContentPageNames.rules) {
          lookupId.value = LookupNameIds.RULES_REQULATIONS;
        } else if (routeName === ContentPageNames.complaints) {
          lookupId.value = LookupNameIds.COMPLAINTS;
        }
        getTitleName();
      };
      onBeforeMount(() => {
        getPageContent();
      });

      const getTitleName = () => {
        if (lookupId.value === LookupNameIds.ABOUT_US) {
          language.value.pageTitle = 'About Us Listing';
          language.value.buttonTitle = 'Add New About Us';
        } else if (lookupId.value === LookupNameIds.TERMS_OF_USE) {
          language.value.pageTitle = 'Terms Of Us  Listing';
          language.value.buttonTitle = 'Add New Terms Of Use';
        } else if (lookupId.value === LookupNameIds.PRIVACY) {
          language.value.pageTitle = 'Privacy Listing';
          language.value.buttonTitle = 'Add New Privacy';
        } else if (lookupId.value === LookupNameIds.RULES_REQULATIONS) {
          language.value.pageTitle = 'Rules Regulations Listing';
          language.value.buttonTitle = 'Add New Rules Regulations';
        } else if (lookupId.value === LookupNameIds.COMPLAINTS) {
          language.value.pageTitle = 'Complaints Listing';
          language.value.buttonTitle = 'Add New Complaints';
        }
      };
      const searchItems = async (inputText) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_CONTENT_PAGE_FILTER, {
          name: inputText,
        });
        await store.dispatch(Actions.GET_CONTENT_PAGES, lookupId.value);
        loading.value = false;
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate(language.value.pageTitle), [
          translate('settings'),
        ]);

        loading.value = true;
        await store.dispatch(Actions.GET_CONTENT_PAGES, lookupId.value);
        console.log(store.getters.contentPageList);
        loading.value = false;
      });
      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_SETTINGS_LOOKUPS_STORE);
      });

      const loading = ref(false);

      watch(
        () => router.currentRoute.value.fullPath,
        () => {
          const routeName = router.currentRoute.value.name;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (Object.values(ContentPageNames).includes(routeName)) {
            loading.value = true;
            getPageContent();
            getTitleName();
            store
              .dispatch(Actions.GET_CONTENT_PAGES, lookupId.value)
              .then(() => {
                setCurrentPageBreadcrumbs(translate(language.value.pageTitle), [
                  translate('settings'),
                ]);
                loading.value = false;
              });
          }
        }
      );
      const tableHeader = ref([
        {
          name: translate('titleEn'),
          key: 'titleEn',
          sortable: true,
        },
        {
          name: translate('titleAr'),
          key: 'titleAr',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const deleteContentPageRecord = async (record) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_CONTENT_PAGE, record.id);
          await store.dispatch(Actions.GET_CONTENT_PAGES, lookupId.value);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editContentPage = (id) => {
        router.push({ name: routeEditPageName.value, params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_CONTENT_PAGE_FILTER, val);
        await store.dispatch(Actions.GET_CONTENT_PAGES, lookupId.value);
        loading.value = false;
      };

      return {
        tableData: computed(() => store.getters.contentPageList),
        tableHeader,
        inputText: '',
        searchItems,
        deleteContentPageRecord,
        translate,
        totalItems: computed(() => store.getters.locationsListCount),
        pageChanged,
        goTo,
        editContentPage,
        loading,
        can,
        language,
        routeCreatePageName,
      };
    },
  });
