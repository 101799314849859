
  import {
    defineComponent,
    computed,
    ref,
    onMounted,
    watch,
    getCurrentInstance,
  } from 'vue';
  import arraySort from 'array-sort';

  interface IPagination {
    page: number;
    total: number;
    rowsPerPage: number;
  }

  interface IHeaderConfiguration {
    name?: string;
    key: string;
    sortingField?: string;
    sortable?: boolean;
    cellWidth?: string;
  }

  export default defineComponent({
    name: 'kt-datatable',
    emit: ['current-change', 'sort', 'items-per-page-change'],
    props: {
      tableHeader: {
        type: Array as () => Array<IHeaderConfiguration>,
        required: true,
      },
      tableData: { type: Array, required: true },
      emptyTableText: { type: String, default: 'No data found' },
      loading: { type: Boolean, default: false },
      currentPage: { type: Number, default: 1 },
      enableItemsPerPageDropdown: { type: Boolean, default: true },
      total: { type: Number, default: 0 },
      rowsPerPage: { type: Number, default: 10 },
      order: { type: String, default: 'asc' },
      sortLabel: { type: String, default: '' },
      enableAccordion: { type: Boolean, default: false },
      accordionButtomIndex: { type: Number, default: 0 },
      stickyHeader: { type: Boolean, default: false },
    },
    components: {},
    setup(props, { emit }) {
      const data = ref(props.tableData);
      const listCount = ref(props.total);
      const currentSort = ref<string>('');
      const order = ref(props.order);
      const label = ref(props.sortLabel);
      const pagination = ref<IPagination>({
        page: 1,
        total: listCount.value,
        rowsPerPage: props.rowsPerPage,
      });

      const vnodeProps = getCurrentInstance()?.vnode.props || {};

      // pagination.value.total = data.value.length;

      watch(
        () => props.tableData,
        (newList) => {
          data.value = newList;
        }
      );

      watch(
        () => props.total,
        (newList) => {
          pagination.value.total = newList;
        }
      );

      const openAccordionIndex = ref(null);

      const toggleAccordion = (index) => {
        if (openAccordionIndex.value === index) {
          openAccordionIndex.value = null;
        } else {
          openAccordionIndex.value = index;
        }
      };

      onMounted(() => {
        currentSort.value = label.value + order.value;
        pagination.value.total = props.total ? props.total : data.value.length;
        pagination.value.rowsPerPage = props.rowsPerPage;
        if (props.stickyHeader) {
          const dataTable = document.querySelector('.dataTable');
          const thead = dataTable!.querySelector('thead');
          console.log(thead);
          if (thead) {
            thead.classList.add('sticky');
          }
        }
      });

      const getItems = computed(() => {
        if ('onCurrentChange' in vnodeProps) {
          const clone = JSON.parse(JSON.stringify(data.value));
          if (clone && clone.length > 0 && clone[0].page) {
            if (clone[0].page != pagination.value.page) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              pagination.value = {
                page: clone[0].page,
                total: pagination.value.total,
                rowsPerPage: pagination.value.rowsPerPage,
              };
            }
          }
          return data.value;
        } else {
          const clone = JSON.parse(JSON.stringify(data.value));
          const startFrom =
            pagination.value.page * pagination.value.rowsPerPage -
            pagination.value.rowsPerPage;
          return clone.splice(startFrom, pagination.value.rowsPerPage);
        }
      });

      const currentPageChange = (val) => {
        if ('onCurrentChange' in vnodeProps) {
          emit('current-change', val);
        } else {
          pagination.value.page = val;
        }
      };

      const sort = (columnName, sortable) => {
        if (sortable === false) {
          return;
        }

        if ('onSort' in vnodeProps) {
          if (order.value === 'asc') {
            order.value = 'desc';
            emit('sort', { columnName: columnName, order: 'desc' });
          } else {
            order.value = 'asc';
            emit('sort', { columnName: columnName, order: 'asc' });
          }
        } else {
          if (order.value === 'asc') {
            order.value = 'desc';
            arraySort(data.value, columnName, { reverse: false });
          } else {
            order.value = 'asc';
            arraySort(data.value, columnName, { reverse: true });
          }
        }
        currentSort.value = columnName + order.value;
      };

      const setItemsPerPage = (event) => {
        if ('onItemsPerPageChange' in vnodeProps) {
          emit('items-per-page-change', parseInt(event.target.value));
        } else {
          pagination.value.rowsPerPage = parseInt(event.target.value);
        }
      };

      return {
        pagination,
        currentPageChange,
        getItems,
        sort,
        currentSort,
        setItemsPerPage,
        toggleAccordion,
        openAccordionIndex,
      };
    },
  });
