import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface SettingsHorseLocationObject {
  id: string;
  nameEn: string;
  nameAr: string;
  numberOfHorses: number;
}

export interface SettingsHorseLocationListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  locations: Array<SettingsHorseLocationObject>;
  errors: unknown;
}

export interface locationFilterInfo {
  name: string;
}

@Module
export default class SettingsHosreLocationModule
  extends VuexModule
  implements SettingsHorseLocationListInfo
{
  locations = [] as Array<SettingsHorseLocationObject>;
  allLocations = [] as Array<SettingsHorseLocationObject>;
  locationFilter = {} as locationFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get locations list
   * @returns Array<SettingsHorseLocationObject>
   */
  get locationsList(): Array<SettingsHorseLocationObject> {
    return this.locations;
  }

  get allLocationsList(): Array<SettingsHorseLocationObject> {
    return this.allLocations;
  }

  /**
   * Get total items
   * @returns number
   */
  get locationsListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_SETTINGS_HORSE_LOCATIONS](data) {
    this.locations = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_SETTINGS_HORSE_LOCATIONS](data) {
    this.allLocations = data.data;
  }

  @Mutation
  [Mutations.SET_SETTING_HORSE_LOCATIONS_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_SETTINGS_HORSE_LOCATIONS_LIST]() {
    this.locations = [] as Array<SettingsHorseLocationObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SETTINGS_HORSE_LOCATIONS_FILTER](obj) {
    this.locationFilter.name = obj.name;
  }

  @Action
  [Actions.RESET_SETTINGS_HORSE_LOCATIONS_STORE]() {
    this.context.commit(Mutations.RESET_SETTINGS_HORSE_LOCATIONS_LIST);
  }

  @Action
  [Actions.UPDATE_SETTINGS_HORSE_LOCATION](val) {
    this.context.commit(Mutations.SET_SETTINGS_HORSE_LOCATIONS_FILTER, val);
  }

  @Action
  [Actions.CREATE_SETTING_HORSE_LOCATION](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/settings/horse-location', data)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_HORSE_LOCATIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_HORSE_LOCATION](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/settings/horse-location', id)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_HORSE_LOCATIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_HORSE_LOCATION]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/horse-location', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_HORSE_LOCATIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_HORSE_LOCATIONS_CURRENT_PAGE](val) {
    this.context.commit(
      Mutations.SET_SETTING_HORSE_LOCATIONS_CURRENT_PAGE,
      val
    );
  }

  @Action
  [Actions.GET_SETTING_HORSE_LOCATION](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/horse-location', id);
  }

  @Action
  [Actions.GET_SETTINGS_HORSE_LOCATIONS](lookupNameIds) {
    console.log(this.page);
    const params = {
      page: this.page,
      name: this.locationFilter.name,
      lookupNameId: lookupNameIds,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/settings/horse-location', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SETTINGS_HORSE_LOCATIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_SETTINGS_HORSE_LOCATIONS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/settings/horse-location/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_SETTINGS_HORSE_LOCATIONS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
