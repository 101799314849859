
  import { ref, onMounted } from 'vue';

  export default {
    props: {
      disabledRange: Boolean,
    },

    setup(props, { emit }) {
      const currentDate = ref(new Date());
      const startDate = ref(null);
      const endDate = ref(null);

      const prevWeek = () => {
        currentDate.value.setDate(currentDate.value.getDate() - 7);
        updateWeekRange();
        emit('date-range-changed', {
          startDate: startDate.value,
          endDate: endDate.value,
        });
      };

      const nextWeek = () => {
        currentDate.value.setDate(currentDate.value.getDate() + 7);
        updateWeekRange();
        emit('date-range-changed', {
          startDate: startDate.value,
          endDate: endDate.value,
        });
      };

      const updateWeekRange = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const startOfWeek = new Date(currentDate.value);
        startOfWeek.setDate(currentDate.value.getDate());

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        startDate.value = formatDate(startOfWeek);
        endDate.value = formatDate(endOfWeek);
      };

      const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
      };

      onMounted(() => {
        updateWeekRange();
      });

      return {
        startDate,
        endDate,
        prevWeek,
        nextWeek,
      };
    },
  };
