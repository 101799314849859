import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface CancellationObject {
  nameEn: string;
  nameAe: string;
  phone: string;
  latitude: string;
  longitude: string;
  id: string;
}

export interface CancellationListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  cancellation: Array<CancellationObject>;
  errors: unknown;
}

export interface CancellationFilterInfo {
  title: string;
}

@Module
export default class CancellationModule
  extends VuexModule
  implements CancellationListInfo
{
  cancellation = [] as Array<CancellationObject>;
  allCancellation = [] as Array<CancellationObject>;
  cancellationFilter = {} as CancellationFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get cancellation list
   * @returns Array<CancellationObject>
   */
  get cancellationList(): Array<CancellationObject> {
    return this.cancellation;
  }

  get allCancellationList(): Array<CancellationObject> {
    return this.allCancellation;
  }

  /**
   * Get total items
   * @returns number
   */
  get cancellationListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_CANCELLATIONS](data) {
    this.cancellation = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_CANCELLATIONS](data) {
    this.allCancellation = data.data;
  }

  @Mutation
  [Mutations.SET_CANCELLATION_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_CANCELLATION_LIST]() {
    this.cancellation = [] as Array<CancellationObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_CANCELLATION_FILTER](obj) {
    this.cancellationFilter = obj;
  }

  @Action
  [Actions.RESET_CANCELLATION_STORE]() {
    this.context.commit(Mutations.RESET_CANCELLATION_LIST);
  }

  @Action
  [Actions.UPDATE_CANCELLATION_FILTER](val) {
    this.context.commit(Mutations.SET_CANCELLATION_FILTER, val);
  }

  @Action
  [Actions.DELETE_CANCELLATION](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/lesson/request-cancel', id)
      .then(() => {
        this.context.commit(Mutations.RESET_CANCELLATION_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_CANCELLATION_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_CANCELLATION_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_CANCELLATION](id) {
    ApiService.setAuthorizationHeader();
    console.log(id);
    return ApiService.get('/lesson/request-cancel', id);
  }

  @Action
  [Actions.GET_CANCELLATIONS]() {
    const params = {
      page: this.page,
      ...this.cancellationFilter,
    };
    console.log(params);
    ApiService.setAuthorizationHeader();
    return ApiService.query('/lesson/request-cancel', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CANCELLATIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_ALL_CANCELLATION]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/lesson/request-cancel/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_CANCELLATIONS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
}
