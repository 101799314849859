
  import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
  import { StepperComponent } from '@/assets/ts/components';
  import { useForm } from 'vee-validate';
  import * as Yup from 'yup';
  import GroupsCreating from '@/components/wizard/steps/GroupsCreating.vue';
  import RolesCreating from '@/components/wizard/steps/RolesCreating.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';

  interface IStep1 {
    title: string;
    description: string;
  }

  const sumbitGroup = ref();
  const submitRoles = ref();

  interface IStep2 {
    title: string;
    description: string;
  }

  interface CreateRoleGroup extends IStep1, IStep2 {}

  export default defineComponent({
    name: 'kt-horizontal-wizard',
    components: {
      GroupsCreating,
      RolesCreating,
    },
    setup() {
      const _stepperObj = ref<StepperComponent | null>(null);
      const horizontalWizardRef = ref<HTMLElement | null>(null);
      const currentStepIndex = ref(0);

      const { t, te } = useI18n();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const formData = ref<CreateRoleGroup>({
        title: '',
        description: '',
      });

      onMounted(() => {
        _stepperObj.value = StepperComponent.createInsance(
          horizontalWizardRef.value as HTMLElement
        );

        setCurrentPageBreadcrumbs('Horizontal', ['Pages', 'Wizards']);
      });

      const createRoleGroupSchema = [
        Yup.object({
          title: Yup.string().required().label('Group Name'),
          description: Yup.string().required().label('Description'),
        }),
      ];

      const currentSchema = computed(() => {
        console.log(currentStepIndex.value);
        return createRoleGroupSchema[currentStepIndex.value];
      });

      const { resetForm, handleSubmit } = useForm<IStep1 | IStep2>({
        validationSchema: currentSchema,
      });

      const totalSteps = computed(() => {
        if (!_stepperObj.value) {
          return;
        }
        return _stepperObj.value.totatStepsNumber;
      });

      resetForm({
        values: {
          ...formData.value,
        },
      });

      const handleStep = handleSubmit((values) => {
        formData.value = {
          ...formData.value,
          ...values,
        };

        currentStepIndex.value++;
        setCurrentPageBreadcrumbs(translate('rolesCreating'), [
          translate('members'),
        ]);

        if (!_stepperObj.value) {
          return;
        }

        _stepperObj.value.goNext();
      });

      const previousStep = () => {
        if (!_stepperObj.value) {
          return;
        }

        currentStepIndex.value--;
        setCurrentPageBreadcrumbs(translate('groupsCreating'), [
          translate('members'),
        ]);

        _stepperObj.value.goPrev();
      };

      const groups = async () => {
        await sumbitGroup.value.groupPayload();
      };

      const roles = async () => {
        await submitRoles.value.rolesPayload();
      };

      onUnmounted(() => {
        previousStep();
      });

      return {
        horizontalWizardRef,
        sumbitGroup,
        submitRoles,
        groups,
        roles,
        previousStep,
        handleStep,
        totalSteps,
        currentStepIndex,
      };
    },
  });
