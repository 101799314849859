import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface InvoiceObject {
  id: string;
  customerName: string;
  status: boolean;
  invoiceNo: number;
  customerPhoneNumber: number;
  createdAt: string;
}

export interface InvoiceListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  invoices: Array<InvoiceObject>;
  allInvoices: Array<InvoiceObject>;
  errors: unknown;
}

@Module
export default class InvoiceModule
  extends VuexModule
  implements InvoiceListInfo
{
  invoices = [] as Array<InvoiceObject>;
  allInvoices = [] as Array<InvoiceObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get invoices list
   * @returns Array<InvoiceObject>
   */
  get financecInvoiceList(): Array<InvoiceObject> {
    if (this.invoices.length > 0) this.invoices[0]['page'] = this.page;
    return this.invoices;
  }

  /**
   * Get allInvoices list
   * @returns Array<InvoiceObject>
   */
  get allFinancecInvoice(): Array<InvoiceObject> {
    return this.allInvoices;
  }

  /**
   * Get total items
   * @returns number
   */
  get financecInvoiceListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_FINANCE_INVOICE](data) {
    this.invoices = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_FINANCE_INVOICE](data) {
    this.allInvoices = data.data;
  }

  @Mutation
  [Mutations.SET_FINANCE_INVOICE_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_FINANCE_INVOICE_LIST]() {
    this.invoices = [] as Array<InvoiceObject>;
    this.allInvoices = [] as Array<InvoiceObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_FINANCE_INVOICE_STORE]() {
    this.context.commit(Mutations.RESET_FINANCE_INVOICE_LIST);
  }

  @Action
  [Actions.UPDATE_FINANCE_INVOICE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_FINANCE_INVOICE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.RESET_ALL_FINANCE_INVOICE]() {
    this.context.commit(Mutations.SET_ALL_FINANCE_INVOICE, { data: [] });
  }

  @Action
  [Actions.GET_FINANCE_INVOICE](query = {}) {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.page,
      ...query,
    };
    return ApiService.query('/finance/invoice', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FINANCE_INVOICE, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_FINANCE_INVOICE](params = {}) {
    ApiService.setAuthorizationHeader();

    return ApiService.query('/finance/invoice/all', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_FINANCE_INVOICE, data);
        return data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.CREATE_FINANCE_INVOICE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/finance/invoice', data)
      .then(() => {
        this.context.commit(Mutations.RESET_FINANCE_INVOICE_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.GENERATE_OUTTO_FINANCE_INVOICE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(`/finance/generate-ottu-invoice/${data.id}`, data)
      .then((data) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.GENERATE_MANUAL_OUTTO_FINANCE_INVOICE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('finance/outo-manual-confirmaion', data)
      .then((data) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
}
