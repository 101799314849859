import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface IHorses {
  id: number;
  name: string;
  phone: string;
  email: string;
  address: string;
  dateOfBirth: string;
  passportNumber: string;
  civilId: string;
  residencyNumber: string;
  classLimityPerDay: number;
  nationalityId: string;
  genderId: string;
  statusId: string;
  createdAt: string;
  updatedAt: string;
  statusName: string;
  nationalityName: string;
  genderName: string;
}

export enum HorseType {
  PRIVATE = 'Private-horse-type',
  KRC_PRIVATE = 'KRC-Private-horse-type',
}

export interface HorseListInfo {
  horses: Array<IHorses>;
  allHorses: Array<IHorses>;
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  errors: unknown;
}
export interface HorseFilterInfo {
  horseId: string;
  statusId: string;
  passportNumber: string;
}

@Module
export default class HorsesModule extends VuexModule implements HorseListInfo {
  horses = [] as Array<IHorses>;
  allHorses = [] as Array<IHorses>;
  horsesFilter = {} as HorseFilterInfo;

  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get horses list
   * @returns Array<IHorses>
   */
  get allHorsesList(): Array<IHorses> {
    return this.allHorses;
  }
  /**
   * Get horses list
   * @returns Array<IHorses>
   */
  get horsesList(): Array<IHorses> {
    return this.horses;
  }

  /**
   * Get total items
   * @returns number
   */
  get horseslistCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_HORSES_OPERATIONS](data) {
    this.horses = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_ALL_HORSES_OPERATIONS](data) {
    this.allHorses = data.data;
  }

  @Mutation
  [Mutations.SET_HORSES_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_HORSES_LIST]() {
    this.horses = [] as Array<IHorses>;
    this.horsesFilter = {} as HorseFilterInfo;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_HORSES_FILTER](obj) {
    this.horsesFilter = obj;
  }

  @Action
  [Actions.UPDATE_HORSES_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_HORSES_CURRENT_PAGE, val);
  }

  @Action
  [Actions.UPDATE_HORSES_FILTER](val) {
    this.context.commit(Mutations.SET_HORSES_FILTER, val);
  }

  @Action
  [Actions.RESET_HORSE_STORE]() {
    this.context.commit(Mutations.RESET_HORSES_LIST);
  }

  @Action
  [Actions.DELETE_GROOMER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('groomer', id)
      .then(() => {
        this.context.commit(Mutations.RESET_HORSES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_HORSE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('horse', id, data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_HORSES_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.GET_HORSE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`horse`, id)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_HORSES_OPERATIONS]() {
    ApiService.setAuthorizationHeader();
    console.table(this.horsesFilter);
    const params = {
      ...this.horsesFilter,
      page: this.page,
    };
    console.log(params);
    return ApiService.query('/horse', {
      params,
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_HORSES_OPERATIONS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_HORSES_TREATMENT](horseId) {
    ApiService.setAuthorizationHeader();
    const params = {
      horseId,
    };
    return ApiService.query('/horse/medical-history/all', { params })
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_HORSES_OPERATIONS](active = true) {
    ApiService.setAuthorizationHeader();
    const params = {
      ...this.horsesFilter,
      active,
    };
    return ApiService.query('/horse/all', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_HORSES_OPERATIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_HORSES_TAGS](horseId) {
    ApiService.setAuthorizationHeader();
    const params = {
      horseId,
    };
    return ApiService.query('/report/horse-tag', { params })
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.CREATE_HORSES_OPERATIONS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/horse', data)
      .then((data) => {
        this.context.commit(Mutations.RESET_HORSES_LIST);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPLOAD_MEDICAL_ATTACHMENT](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(
      `/horse/horse-medical-attachments/${data.id}`,
      data.data
    )
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPLOAD_HORSE_ATTACHMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/horse/attachments/${id}`, data)
      .then(() => {
        this.context.commit(Mutations.RESET_HORSES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_HORSE_ATTACHMENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/horse/attachments', id)
      .then(() => {
        this.context.commit(Mutations.RESET_HORSES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
}
