
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { injectSearch } from '@/core/plugins/search-service';
  import SearchField from '@/components/common/SearchField.vue';
  import { loadImage } from '../../../utils/loadImage';
  import { format12 } from '@/utils/dateFormat';
  import moment from 'moment/moment';

  export default defineComponent({
    name: 'customer-attendance-report',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const loadingPage = ref(true);
      const searchService = injectSearch();
      const selectedCustomer = ref<number>(0);
      const selectedHorse = ref<number>(0);
      const selectedHorseType = ref<number>(0);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const startDate = ref(moment().startOf('day')._d);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const endDate = ref(moment().startOf('day')._d);
      const startTime = ref();
      const endTime = ref();
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('Horse Details Report'), [
          translate('reports'),
        ]);
        store.dispatch(Actions.GET_ALL_CUSTOMERS);
        store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        const getSaveData = searchService.getSearchValue('horseDetailsReport');
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(searchService.getSearchValue('horseDetailsReport'))
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.horseId) selectedHorse.value = dataSaved.horseId;
          if (dataSaved.customerId)
            selectedCustomer.value = dataSaved.customerId;
        }
        if (dataSaved.horseTypeId) {
          selectedHorseType.value = dataSaved.horseTypeId;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });
      const clearSearch = () => {
        searchService.removeSearchValue('horseDetailsReport');
        selectedCustomer.value = 0;
        selectedHorse.value = 0;
        selectedHorseType.value = 0;
      };

      const print = async (values) => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');

        values.startDate = String(values.startDate);
        values.endDate = String(values.endDate);

        searchService.setSearchValue('horseDetailsReport', values);
        const user = getUser();
        const userName = user ? user['name'] : '';

        const reportData = await store.dispatch(Actions.GET_HORSE_ALL, values);

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'landscape',
        });
        const fontSize = 10;

        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;

        let pageCount = 0;
        let reportKeys = Object.keys(reportData.data);

        const columnStyles = {};

        columnStyles[3] = {
          halign: 'left',
          cellWidth: 25,
          overflow: 'linebreak',
        };
        columnStyles[5] = {
          halign: 'left',
          cellWidth: 30,
          overflow: 'linebreak',
        };
        columnStyles[7] = {
          halign: 'left',
          cellWidth: 40,
          overflow: 'linebreak',
        };

        reportKeys.forEach((key, index) => {
          let data: any = reportData.data[key];
          let resTableBody: any = [];
          //moment(new Date()).format('LLL')
          data.forEach((element, index) => {
            resTableBody.push([
              index + 1,
              element['horseName'],
              element['genderName'],
              moment(element['dateOfBirth']).format('LL'),
              element['passport'],
              element['chipNumber'],
              element['colorName'],
              element['breedName'],
              element['nationalityName'],
              element['sire'],
              element['dam'],
              element['owner'],
            ]);
          });
          if (index > 0) {
            doc.addPage();
          }
          autoTable(doc, {
            head: [
              [
                'Sr',
                'Name',
                'Gender',
                'Birth Date',
                'PassportNo',
                'ChipNo',
                'Color',
                'Breed',
                'Nationality Name',
                'Sire',
                'Dam',
                'Owner',
              ],
            ],
            body: resTableBody,
            startY: 45,
            margin: { horizontal: 5, top: 45 },
            horizontalPageBreak: false,
            theme: 'grid',
            columnStyles: columnStyles,
            headStyles: {
              fillColor: [0, 72, 115],
              fontStyle: 'bold',
              halign: 'left',
              cellWidth: 'auto',
              fontSize: 7.5,
            },
            bodyStyles: {
              fontStyle: 'bold',
              halign: 'left',
              cellWidth: 'auto',
              fontSize: 7.5,
            },
            didDrawPage: function () {
              doc.setFontSize(10);
              doc.setFont('helvetica', 'bold');
              doc.text(key, 15, 40);
              doc.setFont('helvetica', 'normal');

              pageCount++;
              const pageCountWidth = doc.getTextWidth(String(pageCount));

              doc.text(
                'Page ' + pageCount,
                textX - pageCountWidth,
                doc.internal.pageSize.height - 9
              );
              doc.setFontSize(fontSize);
            },
          });
        });
        const logoImage = await loadImage('/media/logos/empty-krc.png');

        Array.from({ length: pageCount }, (x, i) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          doc.addImage(logoImage, 'png', 5, 3, 35, 35);

          doc.setFontSize(14);
          doc.setFont('helvetica', 'bold');
          doc.text('Kuwait Riding Center', textX, 18);
          doc.setFontSize(12);
          doc.text('Horse Details Report', textX + 3, 25);
          doc.setFontSize(10);
          doc.text('Print DateTime: ' + moment(new Date()).format('LLL'), 5, 8);

          doc.setFontSize(8);

          // doc.text(
          //   'Start Date: ' + moment(values.startDate).format('LL'),
          //   100,
          //   40
          // );
          // doc.text('End Date: ' + moment(values.endDate).format('LL'), 150, 40);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          doc.setPage(i + 1);
          doc.text(userName, 5, doc.internal.pageSize.height - 9);
          doc.setFontSize(10);
          doc.setFont('helvetica', 'normal');

          doc.text(
            '   of ' + doc.getNumberOfPages(),
            textX + 10,
            doc.internal.pageSize.height - 9
          );
          doc.setFontSize(fontSize);
        });

        // doc.save('table.pdf');
        // doc.autoPrint();
        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
        window.open(blobUrl, '_blank');

        //printJs({ printable: blobUrl, type: 'pdf' });
      };

      const validationSchema = Yup.object().shape({});

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.HORSE_TYPE,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const horseTypeList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_TYPE;
      });

      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        customers: computed(() => store.getters.allCustomersList),
        horses: computed(() => store.getters.allHorsesList),
        horseTypeList,
        selectedCustomer,
        selectedHorseType,
        selectedHorse,
        startDate,
        endDate,
        startTime,
        endTime,
        loadingPage,
        clearSearch,
      };
    },
  });
