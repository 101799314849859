
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'customer-age-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('customerAgeGroupCreating'), [
          translate('settings'),
        ]);
      });

      const validationSchema = Yup.object().shape({
        maxAge: Yup.number()
          .required(() => translate('MAX_AGE_IS_REQUIRED_FIELD'))
          .typeError(() => translate('MAX_AGE_IS_REQUIRED_FIELD'))
          .label('Max age')
          .test(
            'maxAgeGreaterThanMinAge',
            'Max age must be greater than min age',
            function (this: Yup.TestContext, maxAge) {
              const minAge = this.parent?.minAge;
              return (
                typeof minAge === 'number' &&
                typeof maxAge === 'number' &&
                maxAge > minAge
              );
            }
          ),
        minAge: Yup.number()
          .required(() => translate('MIN_AGE_NAME_IS_REQUIRED_FIELD'))
          .typeError(() => translate('MIN_AGE_NAME_IS_REQUIRED_FIELD'))
          .label('Min age'),
      });
      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.maxAge = Number(values.maxAge);
        values.minAge = Number(values.minAge);
        await store.dispatch(Actions.CREATE_SETTING_CUSTOMER_AGE, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_CUSTOMER_AGE_GROUP'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
      };

      return {
        onSubmitCreate,
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
      };
    },
  });
