
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import SearchField from '@/components/common/SearchField.vue';

  export default defineComponent({
    name: 'offer-editing',
    components: {
      SearchField,
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const imageFile = ref<File | null>(null);
      const imagePreview = ref<string | null>(null);
      const selectedAudieance = ref('');
      const status = ref(false);
      const customers = ref([]);
      const products = ref([]);
      const expiryDate = ref();

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('Offer Editing'), [
          translate('operations'),
        ]);
      });

      watch(selectedAudieance, (newValue) => {
        if (newValue === 'Custom') {
          customers.value = offer.value.customerOffers.map(
            (customer) => customer.customerId
          );
        }
      });

      const { data } = await store.dispatch(Actions.GET_OFFER, route.params.id);
      const offer = ref(data.data);
      status.value = data.data.status;
      products.value = offer.value.products.map((product) => product.id);
      customers.value = offer.value.customerOffers.map(
        (customer) => customer.customerId
      );

      console.log(customers.value);
      const previewImage = ref<string>(offer.value.image);

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.AUDIEANCE,
      ]);
      const customerList = await store.dispatch(Actions.GET_ALL_CUSTOMERS);
      const productList = await store.dispatch(Actions.GET_ALL_PRODUCT);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const audieanceList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.AUDIEANCE;
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      const onImageChange = (event: Event) => {
        const file = (event.target as HTMLInputElement).files?.[0] || null;
        if (file) {
          imageFile.value = file;
          const reader = new FileReader();
          reader.onload = (e) => {
            previewImage.value = e.target?.result as string;
          };
          reader.readAsDataURL(file);
        }
      };
      const removeImage = () => {
        imageFile.value = null;
        imagePreview.value = null;
      };
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const validationSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('titleEn'),
        titleAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('titleEn'),
        descriptionEn: Yup.string()
          .required(() => translate('EN_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description En'),
        descriptionAr: Yup.string()
          .required(() => translate('AR_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description Ar'),
        audieance: Yup.string()
          .notOneOf(['0'], () => translate('Audience Is Required field'))
          .required(() => translate('Audience Is Required field'))
          .label('Audience'),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          submitButton.value.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.status = status.value;
        await store.dispatch(Actions.UPDATE_OFFER, {
          data: values,
          id: offer.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (previewImage.value !== offer.value.image) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            console.log('here', previewImage.value, offer.value.image);
            const formData = new FormData();
            if (previewImage.value) {
              if (imageFile.value) {
                formData.append('attachments', imageFile.value);
              }
              await store.dispatch(Actions.UPLOAD_OFFER_ATTACHMENT, {
                data: formData,
                id: data.data.id,
              });
              const [errorName] = Object.keys(store.getters.getErrors);
              const error = store.getters.getErrors[errorName];
              if (error) {
                Swal.fire({
                  text: translate(error[0]),
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: translate('tryAgainExcl'),
                  customClass: {
                    confirmButton: 'btn fw-bold btn-light-danger',
                  },
                });
              } else {
                console.log('here');
                Swal.fire({
                  text: translate('Successfully Updated Offer'),
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: translate('ok'),
                  customClass: {
                    confirmButton: 'btn btn-light-primary',
                  },
                }).then(() => {
                  router.push({ name: 'offer-listing' });
                });
              }
            }
          } else {
            console.log('here');
            Swal.fire({
              text: translate('Successfully Updated Offer'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            }).then(() => {
              router.push({ name: 'offer-listing' });
            });
          }
        }
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };

      return {
        onSubmitCreate,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        imagePreview,
        onImageChange,
        removeImage,
        selectedAudieance,
        status,
        audieanceList,
        customerList,
        productList,
        customers,
        products,
        expiryDate,
        offer,
        previewImage,
      };
    },
  });
