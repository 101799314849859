<template>
  <div>
    <Datepicker
      v-model="selectedItems.startDate"
      :placeholder="translate('dd/mm/yyyy')"
      :highlighted="highlightedDates"
      ref="datepicker"
      :class="computedClass"
    >
    </Datepicker>
  </div>
</template>

<script>
  import Datepicker from 'vuejs3-datepicker';
  import moment from 'moment'; // Import moment.js

  export default {
    components: {
      Datepicker,
    },
    props: {
      modelValue: String,
      fieldId: String,
      class: String,
      reset: Boolean, // new prop
      todayOnly: Boolean, // new prop
      ignoreModel: Boolean,
      defaultDate: String,
    },
    data() {
      return {
        selectedItems: {
          startDate: this.modelValue || this.defaultDate || null, // Use modelValue
        },
        highlightedDates: {
          dates: [new Date()],
          years: [],
          months: [],
          quarters: [],
          weekdays: [],
          options: { highlightDisabled: false },
          timeOut: null,
        },
      };
    },
    watch: {
      selectedItems: {
        deep: true,
        handler(newSelectedItems) {
          if (this.timeOut) {
            clearTimeout(this.timeOut);
          }
          this.timeOut = setTimeout(() => {
            console.log(newSelectedItems);
            let formattedDate;
            if (!newSelectedItems.startDate) formattedDate = null;
            else
              formattedDate = moment(newSelectedItems.startDate).format(
                'YYYY-MM-DD'
              );
            if (formattedDate === this.modelValue) return;
            if (!this.ignoreModel)
              this.$emit('update:modelValue', formattedDate || null);
            this.$emit('change', formattedDate);
          }, 500);
        },
      },
      defaultDate(newDefaultDate) {
        if (newDefaultDate === this.selectedItems.startDate) return;
        this.selectedItems.startDate = newDefaultDate;
      },
      modelValue(newDate) {
        console.log(newDate);
        if (newDate === this.selectedItems.startDate) return;
        this.selectedItems.startDate = newDate;
      },
    },
    methods: {
      updateValue(date) {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        this.selectedItems.startDate = formattedDate;
        this.$emit('update:modelValue', formattedDate); // Emit formatted date
      },
      setTodayDate() {
        const today = moment().format('YYYY-MM-DD');
        this.selectedItems.startDate = today;
        this.$emit('update:modelValue', today); // Emit today's date formatted
      },
      clearDate() {
        this.selectedItems.startDate = null;
        this.$emit('update:modelValue', null); // Emit null when date is cleared
      },
      translate(key) {
        return key;
      },
    },
    mounted() {
      this.$nextTick(() => {
        const calendarActionArea = document.querySelector(
          `#${this.fieldId} .vuejs3-datepicker__calendar-actionarea`
        );
        if (calendarActionArea) {
          const buttonContainer = document.createElement('div');
          buttonContainer.className = 'button-container';

          if (this.todayOnly) {
            const todayButton = document.createElement('button');
            todayButton.type = 'button';
            todayButton.className = 'btn btn-sm btn-secondary action-button';
            todayButton.textContent = 'Today';
            todayButton.addEventListener('click', this.setTodayDate);

            buttonContainer.appendChild(todayButton);
          } else if (this.reset) {
            // if reset prop is true
            const resetButton = document.createElement('button');
            resetButton.type = 'button';
            resetButton.className = 'btn btn-sm btn-primary action-button';
            resetButton.textContent = 'Reset';
            resetButton.addEventListener('click', this.setTodayDate);

            buttonContainer.appendChild(resetButton);
          } else {
            const clearButton = document.createElement('button');
            clearButton.type = 'button';
            clearButton.className = 'btn btn-sm btn-danger action-button';
            clearButton.textContent = 'Clear';
            clearButton.addEventListener('click', this.clearDate);

            buttonContainer.appendChild(clearButton);

            const todayButton = document.createElement('button');
            todayButton.type = 'button';
            todayButton.className = 'btn btn-sm btn-secondary action-button';
            todayButton.textContent = 'Today';
            todayButton.addEventListener('click', this.setTodayDate);

            buttonContainer.appendChild(todayButton);
          }

          calendarActionArea.appendChild(buttonContainer);
        }
      });
    },
    computed: {
      computedClass() {
        return this.class || '';
      },
    },
  };
</script>

<style>
  .button-container {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    justify-content: center;
  }

  .action-button {
    display: inline-block;
    text-align: center;
    padding: 5px 10px;
  }
</style>
