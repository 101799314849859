import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createBlock(_component_Multiselect, {
    modelValue: _ctx.selectedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
    mode: _ctx.mode,
    "close-on-select": false,
    label: _ctx.trackBy,
    trackBy: _ctx.trackBy,
    searchable: true,
    canDeselect: false,
    options: _ctx.arrOptions,
    valueProp: "id",
    "allow-empty": false,
    required: true,
    placeholder: _ctx.label,
    class: _normalizeClass(["form-select form-select-solid", _ctx.className])
  }, null, 8, ["modelValue", "mode", "label", "trackBy", "options", "placeholder", "class"]))
}