
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import printJs from 'print-js';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { injectSearch } from '@/core/plugins/search-service';
  import SearchField from '@/components/common/SearchField.vue';
  import { loadImage } from '../../../utils/loadImage';
  import moment from 'moment';

  export default defineComponent({
    name: 'horse-lessons-count-report',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const loadingPage = ref(true);
      const searchService = injectSearch();
      const reportData = ref();
      const selectedCustomer = ref<number>(0);
      const selectedTrainer = ref<number>(0);
      const selectedHorse = ref<number>(0);
      const selectedArena = ref<number>(0);
      const selectedAttendance = ref<number>(0);
      const selecteDuration = ref<number>(0);
      const selecteType = ref<number>(0);
      const selectedDay = ref<number>(0);
      const selectedGroomer = ref<number>(0);
      const selectedMonth = ref<string>('');
      const selectedYear = ref<string>('');
      const selectedHorseType = ref<number>(0);
      const startDate = ref(
        moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      );
      const endDate = ref(
        moment()
          .startOf('month')
          .add(1, 'month')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      );
      const years: any = [];
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('HorseLessonCountReport'), [
          translate('reports'),
        ]);
        const currentYear = new Date().getFullYear();
        for (let year = 2020; year <= currentYear; year++) {
          years.push(year);
        }
        store.dispatch(Actions.GET_ALL_CUSTOMERS);
        store.dispatch(Actions.GET_ALL_TRAINERS, {});
        store.dispatch(Actions.GET_ALL_ARENAS);
        store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS);
        const getSaveData = searchService.getSearchValue(
          'HorseLessonCountReport'
        );
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(searchService.getSearchValue('HorseLessonCountReport'))
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.startDate) startDate.value = dataSaved.startDate;
          if (dataSaved.endDate) endDate.value = dataSaved.endDate;
          if (dataSaved.horseId) selectedHorse.value = dataSaved.horseId;
          if (dataSaved.customerId)
            selectedCustomer.value = dataSaved.customerId;

          if (dataSaved.year) selectedYear.value = dataSaved.year;
          if (dataSaved.month) selectedMonth.value = dataSaved.month;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });
      const clearSearch = () => {
        searchService.removeSearchValue('HorseLessonCountReport');
        selectedCustomer.value = 0;
        selectedHorse.value = 0;
        selectedMonth.value = '';
        selectedYear.value = '';
        startDate.value = moment()
          .startOf('month')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        endDate.value = moment()
          .startOf('month')
          .add(1, 'month')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      };

      const print = async (values) => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');

        searchService.setSearchValue('HorseLessonCountReport', values);

        const user = getUser();
        const userName = user ? user['name'] : '';
        console.log(values);

        await store.dispatch(Actions.GET_HORSE_LESSONS_COUNT, values);

        reportData.value = store.getters.getHorseLessonsCount;

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'landscape',
        });
        const fontSize = doc.getFontSize();

        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;

        let pageCount = 0;
        let header: any[] = [];
        const resTabelBody: any[] = [];
        header.push('horse');

        reportData.value.data.forEach(function (element, index) {
          resTabelBody.push([]);
          resTabelBody[index].push(element.horse);

          // let idx = 0;
          for (const id in element.lessonCounts) {
            // if (idx < 100) {
            const lesson = element.lessonCounts[id];
            if (index == 0) {
              header.push(new Date(id).getDate());
            }

            // idx++;

            // if (idx % 20 == 0) {
            //   doc.addPage();
            // }
            // resTabelBody.push(lesson);
            resTabelBody[index].push(lesson);

            // }
          }
          if (index == 0) {
            header.push('Cnt');

            header.push('Hrs');

            header.push('30');
            header.push('45');
          }
          resTabelBody[index].push(element.totalCount['total']);
          resTabelBody[index].push(element.totalCount['totalMinutes'] / 60);
          resTabelBody[index].push(element.totalCount['30']);
          resTabelBody[index].push(element.totalCount['45']);
        });

        console.log(header);
        console.log(resTabelBody);
        autoTable(doc, {
          head: [header],
          body: resTabelBody,
          horizontalPageBreak: true,
          styles: {
            fontSize: 8,
            overflow: 'hidden',
          },
          startY: 45,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          margin: { horizontal: 'auto', top: 50 },
          headStyles: {
            fillColor: [0, 72, 115],

            fontStyle: 'bold',
            halign: 'left',
          },
          bodyStyles: {
            fontStyle: 'bold',
            halign: 'left',
          },
          columnStyles: {
            0: { cellWidth: 20, overflow: 'hidden' },
          },
          didDrawPage: function () {
            doc.setFontSize(10);
            pageCount++;
            const pageCountWidth = doc.getTextWidth(String(pageCount));

            doc.text(
              'Page ' + pageCount,
              textX - pageCountWidth,
              doc.internal.pageSize.height - 9
            );
            doc.setFontSize(fontSize);
          },
        });
        const logoImage = await loadImage('/media/logos/empty-krc.png');

        Array.from({ length: pageCount }, (x, i) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          doc.addImage(logoImage, 'png', 5, 3, 35, 35);
          doc.setFontSize(14);
          doc.setFont('helvetica', 'bold');
          doc.text('Kuwait Riding Center', textX, 12);
          doc.setFontSize(12);
          doc.text('Horses Utilization Report ', textX, 20);
          doc.setFontSize(10);
          doc.text(
            'Start Date: ' + moment(values.startDate).format('LLL'),
            80,
            30
          );
          doc.text(
            'End Date: ' + moment(values.endDate).format('LLL'),
            160,
            30
          );

          doc.text('Print DateTime: ' + moment(new Date()).format('LLL'), 5, 8);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore

          doc.setPage(i + 1);
          doc.text(userName, 5, doc.internal.pageSize.height - 9);

          doc.setFont('helvetica', 'normal');

          doc.text(
            '   of ' + doc.getNumberOfPages(),
            textX + 15,
            doc.internal.pageSize.height - 9
          );
          doc.setFontSize(fontSize);
        });

        // doc.save('table.pdf');
        // doc.autoPrint();
        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
        window.open(blobUrl, '_blank');
        //printJs({ printable: blobUrl, type: 'pdf' });
      };

      const validationSchema = Yup.object().shape({});

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.DAYS,
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.DURATION,
        LookupNameIds.STATUS,
        LookupNameIds.ATTENDEND,
        LookupNameIds.HORSE_TYPE,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.ATTENDEND;
      });

      // const categoryList = await store.dispatch(
      //   Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      // );
      const classType = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
      });

      const dayList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DAYS;
      });

      const durationList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DURATION;
      });

      const horseTypeList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_TYPE;
      });
      console.log(horseTypeList);

      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        reportData,
        customers: computed(() => store.getters.allCustomersList),
        trainers: computed(() => store.getters.allTrainersList),
        arenas: computed(() => store.getters.allArenasList),
        horses: computed(() => store.getters.allHorsesList),
        groomers: computed(() => store.getters.allGroomerList),
        horseTypeList,
        statusList,
        classType,
        // categoryList,
        dayList,
        durationList,
        selectedCustomer,
        selectedTrainer,
        selectedArena,
        selectedHorse,
        selecteDuration,
        selectedAttendance,
        selectedHorseType,
        selecteType,
        selectedDay,
        selectedGroomer,
        years,
        months,
        selectedMonth,
        selectedYear,
        startDate,
        endDate,
        loadingPage,
        clearSearch,
      };
    },
  });
