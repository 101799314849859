import { createI18n } from 'vue-i18n/index';

const messages = {
  en: {
    signInTitle: 'Sign In to KRC Admin',
    signInTrainer: 'Sign In to KRC Trainer',
    signInClients: 'Sign In Clients',
    email: 'Email',
    emailAddress: 'Email Address',
    password: 'Password',
    continue: 'Continue',
    driverType: 'Driver Type',
    title: 'Title',
    vehicle: 'Vehicle',
    alternativeLocale: 'KRC is also offered in: {0}',
    alternativeLocaleVal: 'العربية',
    pleaseWait: 'Please wait...',
    tryAgainExcl: 'Try again!',
    backToShipmentUpdating: 'Back To Shipment Updating',
    licantEmail: 'Licant Email',
    packageOverview: 'Package Overview',
    packageDetails: 'Package Details',
    customerId: 'Customer ID',
    customerSignature: 'Customer Signature',
    enterPassword: 'Enter Password',
    areYouSureQ: 'Are you sure?',
    confirmActive: 'Are you sure you want to activate it',
    packageDelete:
      'Are you sure you want to delete this package from this shipment ? By removing this package from this shipment it will be moved to the unassigned packages.',
    confirmInActive: ' Are you sure you want to inactivate it ?',
    AREA_REQUIRED_FIELD: 'Area Required Field',
    EMAIL_NOT_VALID: 'Email must be a valid email',
    AR_NAME_IS_REQUIRED_FIELD: 'Arabic name is a required field',
    EN_NAME_IS_REQUIRED_FIELD: 'English name is a required field',
    EMAIL_IS_REQUIRED_FIELD: 'Email is a required field',
    ROLE_IS_REQUIRED_FIELD: 'Role is a required field',
    CUSTOMER_AGE_IS_REQUIRED_FIELD: 'Customer age is a required field',
    START_DATE_IS_REQUIRED_FIELD: 'Start date is a required field',
    END_DATE_IS_REQUIRED_FIELD: 'End date is a required field',
    MAX_AGE_IS_REQUIRED_FIELD: 'Max age is  a required field',
    MIN_AGE_NAME_IS_REQUIRED_FIELD: 'Min Age is a required field',
    COLOR_CODE_IS_REQUIRED_FIELD: 'Color Code is a required field',
    CLASS_CATEGORY_IS_REQUIRED_FIELD: 'Class category is a required field',
    NUMBER_OF_RIDER_IS_REQUIRED_FIELD: 'Number of rider is a required field',
    DURATION_IS_REQUIRED_FIELD: 'Duration is a required field',
    ADDRESS_IS_REQUIRED_FIELD: 'Address is a required field',
    CLASS_LIMIT_IS_REQUIRED_FIELD: 'Class Limit is a required field',
    DATE_IS_REQUIRED_FIELD: 'Date is a required field',
    DATE_OF_BIRTH_IS_REQUIRED_FIELD: 'Date of birth is a required field',
    PASSPORT_IS_REQUIRED_FIELD: 'Passport is a required field',
    CIVIL_IS_REQUIRED_FIELD: 'Civil is a required field',
    GENDER_IS_REQUIRED_FIELD: 'Gender is a required field',
    NATIONALITY_IS_REQUIRED_FIELD: 'Nationality is a required field',
    CATEGORY_IS_REQUIRED_FIELD: 'Category is a required field',
    TRAINER_IS_REQUIRED_FIELD: 'Trainer is required field',
    GROOMER_IS_REQUIRED_FIELD: 'Groomer is required field',
    COLOR_IS_REQUIRED_FIELD: 'Color is required field',
    BREED_IS_REQUIRED_FIELD: 'Breed is required field',
    JOB_TITLE_IS_REQUIRED_FIELD: 'Job title is required field',
    TYPE_HORSE_IS_REQUIRED_FIELD: 'Type horse is required field',
    LOCATION_IS_REQUIRED_FIELD: 'Location is required field',
    CUSTOMER_IS_REQUIRED_FIELD: 'Customer is required field',
    NAME_IS_REQUIRED_FIELD: 'Name is a required field',
    ARENA_IS_REQUIRED_FIELD: 'Arena is a required field',
    PASSPORT_NUMBER_IS_REQUIRED_FIELD: 'Passport Number is required field',
    PHONE_NUMBER_IS_REQUIRED_FIELD: 'Phone Number is required field',
    DOCUMENT_IS_REQUIRED_FIELD: 'Document is a required field',
    CIVIL_ID_IS_REQUIRED_FIELD: 'Civil Id is required field',
    STATUS_IS_REQUIRED_FIELD: 'Status is required field',
    CIVIL_ID_MUST_BE_12_DIGITS: 'Civil id must be 12 digits',
    RIDERS_IS_REQUIRED_FIELD: 'Riders is required field',
    INVOICE_IS_REQUIRED_FIELD: 'Invoice is required field',
    DAYS_IS_REQUIRED_FIELD: 'Days is required field',
    GUARDIAN_TYPE_IS_REQUIRED_FIELD: ' Guardian type is required field',
    CURRENT_PASSWORD_IS_REQUIRED_FIELD: 'Current Password is required field',
    PASSWORD_IS_REQUIRED_FIELD: 'Password is required field',
    CONFIRM_PASSWORD_IS_REQUIRED_FIELD: 'Confirm Password is required field',
    CUSTOMER_TYPE_IS_REQUIRED_FIELD: 'Customer type is required field',
    PASSWORD_MIN_LENGTH_ERROR:
      'Password must consist of a minimum of 6 characters',
    PASSWORDS_DO_NOT_MATCH: 'Password is not match',
    DOC_TYPE_IS_REQUIRED_FIELD: 'Docuemnt Type is requrid field',
    CLASS_TYPE_IS_REQUIRED_FIELD: 'Class Type is requrid field',
    SUCCESSFULLY_CREATED_ADMIN: 'Successfully created a new admin!',
    SUCCESSFULLY_CREATED_CUSTOMER: 'Successfully created a new customer',
    SUCCESSFULLY_CREATED_COST_MATRIX: 'Successfully created a new cost matrix!',
    createCostMatrix: 'Create Cost Matrix',
    costMatrixUpdating: 'Cost Matrix Updating',
    SUCCESSFULLY_CREATED_ROLE: 'Successfully created a new role!',
    SUCCESSFULLY_CREATED_GROOMERS_OPERATION:
      'Successfully created a new groomers operations',
    SUCCESSFULLY_CREATED_HORSE_OPERATION:
      'Successfully created a new horse operatons',
    SUCCESSFULLY_UPDATED_ROLE: 'Successfully updated role!',
    SUCCESSFULLY_CREATED_GROUP: 'Successfully created a new group!',
    SUCCESSFULLY_UPDATED_GROUP: 'Successfully updated group!',
    SUCCESSFULLY_UPDATED_AREA: 'Successfully updated area!',
    SUCCESSFULLY_UPDATED_ADMIN: 'Successfully updated admin!',
    SUCCESSFULLY_UPDATED_GROOMER: 'Successfully updated Groomer',
    SUCCESSFULLY_UPDATED_TERMS_AND_CONDITIONS:
      'Successfully updated terms and conditions!',
    SUCCESSFULLY_UPDATED_STAFF: 'Successfully updated staff',
    SUCCESSFULLY_UPDATED_TRAINER: 'Successfully updated Trainer',
    SUCCESSFULLY_UPDATED_LESSONS: 'Successfully updated lessons',
    SUCCESSFULLY_UPDATED_JOB_TITLE: 'Successfully updated Job Title',
    SUCCESSFULLY_UPDATED_YOUR_PASSWORD: 'Successfully updated your password',
    lessonCreating: 'Lesson Creating',
    groomerCreating: 'Groomer Creating',
    customerCreditCreating: 'Customer Credit Creating',
    customerCreating: 'Customer Creating',
    customerEditing: 'Customer Editing',
    lessonListing: 'Lesson Listing',
    SUCCESSFULLY_UPDATED_DOCUMENT: 'Successfully updated document!',
    SUCCESSFULLY_UPDATED_CANCEL_REASON: 'Successfully updated cancel reason!',
    SUCCESSFULLY_UPDATED_COURSE_STATUS: 'Successfully updated course status!',
    SUCCESSFULLY_UPDATED_CLASS: 'Successfully updated class!',
    SUCCESSFULLY_UPDATED_GENDER: 'Successfully updated gender!',
    SUCCESSFULLY_UPDATED_HORSE_BREED: 'Successfully updated horse breed!',
    SUCCESSFULLY_UPDATED_HORSE_CATEGORY: 'Successfully updated horse category!',
    SUCCESSFULLY_UPDATED_HORSE_COLOR: 'Successfully updated horse color!',
    SUCCESSFULLY_UPDATED_HORSE_GENDER: 'Successfully updated horse gender!',
    SUCCESSFULLY_UPDATED_HORSE_TYPE: 'Successfully updated horse type!',
    SUCCESSFULLY_UPDATED_HORSE: 'Successfully updated horse',
    SUCCESSFULLY_UPDATED_LEAVE_TYPE: 'Successfully updated leave type!',
    SUCCESSFULLY_UPDATED_NATIONALITY: 'Successfully updated nationality!',
    SUCCESSFULLY_UPDATED_PASSPORT: 'Successfully updated passport!',
    SUCCESSFULLY_UPDATED_PAYMENT_METHOD: 'Successfully updated payment method!',
    SUCCESSFULLY_UPDATED_SERVICE: 'Successfully updated service!',
    SUCCESSFULLY_UPDATED_STATUS: 'Successfully updated status!',
    SUCCESSFULLY_UPDATED_TREATMENT: 'Successfully updated treatment type!',
    SUCCESSFULLY_UPDATED_CUSTOMER: 'Successfully updated customer',
    SUCCESSFULLY_CREATED_GUARDIAN: 'Successfully created a new guardian!',
    SUCCESSFULLY_CREATED_DOCUMENT: 'Successfully created a new document!',
    SUCCESSFULLY_CREATED_CANCEL_REASON:
      'Successfully created a new cancel reason!',
    SUCCESSFULLY_CREATED_JOB_TITLE: 'Successfully created a new job title ',

    SUCCESSFULLY_CREATED_COURSE_STATUS:
      'Successfully created a new course status!',
    SUCCESSFULLY_CREATED_CLASS: 'Successfully created a new class!',
    SUCCESSFULLY_CREATED_GENDER: 'Successfully created a new gender!',
    SUCCESSFULLY_CREATED_HORSE_BREED: 'Successfully created a new horse breed!',
    SUCCESSFULLY_CREATED_HORSE_CATEGORY:
      'Successfully created a new horse category!',
    SUCCESSFULLY_CREATED_HORSE_COLOR: 'Successfully created a new horse color!',
    SUCCESSFULLY_CREATED_HORSE_GENDER:
      'Successfully created a new horse gender!',
    SUCCESSFULLY_CREATED_HORSE_TYPE: 'Successfully created a new horse type!',
    SUCCESSFULLY_CREATED_LEAVE_TYPE: 'Successfully created a new leave type!',
    SUCCESSFULLY_CREATED_NATIONALITY: 'Successfully created a new nationality!',
    SUCCESSFULLY_CREATED_PASSPORT: 'Successfully created a new passport!',
    SUCCESSFULLY_CREATED_PAYMENT_METHOD:
      'Successfully created a new payment method!',
    SUCCESSFULLY_CREATED_SERVICE: 'Successfully created a new service!',
    SUCCESSFULLY_CREATED_STATUS: 'Successfully created a new status!',
    SUCCESSFULLY_CREATED_TREATMENT: 'Successfully created a new treatment!',
    SUCCESSFULLY_CREATED_CLASS_LEVEL: 'Successfully created a new class level!',
    SUCCESSFULLY_CREATED_CUSTOMER_AGE_GROUP:
      'Successfully created a new customer age group!',
    SUCCESSFULLY_UPDATED_CLASS_LEVEL: 'Successfully updated class level!',
    SUCCESSFULLY_CREATED_CLASS_CATEGORY:
      'Successfully created a new class category!',
    SUCCESSFULLY_CREATED_CUSTOMER_STATMENT:
      'Successfully created a new customer statment!',
    SUCCESSFULLY_CREATED_LESSONS: 'Successfully created a new lesson',
    SUCCESSFULLY_UPDATED_CLASS_CATEGORY: 'Successfully updated class category!',
    SUCCESSFULLY_UPDATED_HOLIDAY_TYPE: 'Successfully updated holiday type!',
    SUCCESSFULLY_UPDATED_CUSTOMER_AGE_GROUP:
      'Successfully updated customer age group!',
    SUCCESSFULLY_CREATED_HORSE_LOCATION:
      'Successfully created a new horse location!',
    SUCCESSFULLY_CREATED_HOLIDAY_TYPE:
      'Successfully created a new holiday type!',
    SUCCESSFULLY_CREATED_INVOICE: 'Successfully created a new invoice!',
    SUCCESSFULLY_CREATED_TRAINER: 'Successfully created a new trainer!',
    SUCCESSFULLY_CREATED_STAFF: 'Successfully created a new staff!',
    SUCCESSFULLY_CREATED_ATTACHMENT: 'Successfully created a new attachment!',
    status: 'Status',
    createNewCategory: 'Create New Category',
    createNewGroomer: 'Create New Groomer',
    createNewHorse: 'Create New Horse ',
    createNewTrainer: 'Create New Trainer',
    createNewCustomer: 'Create New Customer',
    createNewStaff: 'Create New Staff',
    createFinanceInvoice: 'Create Finance Invoice',
    createCustomerStatment: 'Create Customer Statment',
    createCustomerCredit: 'Create Customer Credit',
    updateGroomer: 'Update Groomer',
    updateCategory: 'Update Category',
    updateGuardian: 'Update Guardian',
    updateCustomer: 'Update Customer',
    updateStaff: 'Update Staff',
    permissionsCount: 'Up to {count} resources',
    mobileNumber: 'Mobile Number',
    confirmPassword: 'Confirm Password',
    resetPassword: 'Reset Password',
    nameEn: 'English Name',
    nameAr: 'Arabic Name',
    roleName: 'Role Name',
    shipmentType: 'Shipment Type',
    passportNumber: 'Passport Number',
    dateOfBirth: 'Date Of Birth',
    staffListing: 'Staff Listing',
    staffUpdating: 'Staff Updating',
    staffCreating: 'Staff Creating',
    addNewGroup: 'Add New Group',
    totalProperties: 'Total Properties',
    addNewStaff: 'Add New Staff',
    addNewAdmin: 'Add New Admin',
    addNewRole: 'Add New Role',
    addNewGuardian: 'Add New Guardian',
    addNewGender: 'Add New Gender',
    addNewHorseGender: 'Add New Horse Gender',
    addNewHorseBreed: 'Add New Horse Breed',
    addNewHorseColor: 'Add New Horse Color',
    addNewHorseCategory: 'Add New Horse Category',
    addNewHorseType: 'Add New Horse Type',
    addNewLeaveType: 'Add New Leave Type',
    addNewCancelReason: 'Add New Cancel Reason',
    addNewNationality: 'Add New Nationality',
    addNewPassport: 'Add New Passport',
    addNewPaymentMethod: 'Add New Payment Method',
    addNewDocument: 'Add New Document',
    addNewTreatment: 'Add New Treatment',
    addNewStatus: 'Add New Status',
    addNewService: 'Add New Service',
    addNewClass: 'Add New Class',
    addNewHorseLocation: 'Add New Horse Location',
    addNewHolidayType: 'Add New Holiday Type',
    addNewCustomer: 'Add New Customer',
    addNewClassCategory: 'Add New Class Category',
    addNewClassLevel: 'Add New Class Level',
    addNewCustomerAgeGroup: 'Add New Customer Age Group',
    addNewGroomer: 'Add New Groomer',
    addNewTrainer: 'Add New Trainer',
    addNewFinance: 'Add New Finance',
    addNewCustomerServicePayment: 'Add New Customer Service Payment',
    addNewCustomerCredit: 'Add New Customer Credit',
    addNewInvoice: 'Add New Invoice',
    addNewJobTitle: 'Add New Job Title',
    addNewArena: 'Add New Arena',
    selectCity: 'Select City',
    selectRole: 'Select Role',
    selectPermissions: 'Select Permissions',
    rolesListing: 'Roles Listing',
    groomerListing: 'Groomer Listing',
    horsesListing: 'Horse Listing',
    jobTitleListing: 'Job Title Listing',
    roleCreating: 'Roles Creating',
    roleUpdating: 'Roles Updating',
    adminUsersListing: 'Admin Users Listing',
    adminUsersUpdating: 'Admin Users Updating',
    adminUsersCreating: 'Admin Users Creating',
    guardianUpdating: 'Guardian Updating',
    guardiansListing: 'Guardians Listing',
    guardianCreating: 'Guardian Creating',
    classesListing: 'Classes Lisitng',
    classCreating: 'Class Creating',
    classUpdating: 'Class Updating',
    groomerOperationUpdating: 'Groomer Operation Updationg',
    horseOperationUpdating: 'Horse Operation Updating',
    gendersListing: 'Genders Listing',
    genderCreating: 'Gender Creating',
    genderUpdating: 'Gender Updating',
    horsesGendersListing: 'Horse Gender Listing',
    horseGenderCreating: 'Horse Gender Creating',
    horseGenderUpdating: 'Horse Gender Updating',
    horseCategoryListing: 'Horse Category Listing',
    horseCategoryCreating: 'Horse Category Creating',
    horseCategoryUpdating: 'Horse Category Updating',
    horseTypeListing: 'Horse Type Listing',
    horseTypeCreating: 'Horse Type Creating',
    horseTypeUpdating: 'Horse Type Updating',
    trainerUpdating: 'Trainer Updating',
    trainersListing: 'Trainers Listing',
    trainerCreating: 'Trainer Creating',
    leaveTypeListing: 'Leave Type Listing',
    leaveTypeCreating: 'Leave Type Creating',
    financeInvoceCreating: 'Finance Invoice Creating',
    leaveTypeUpdating: 'Leave Type Updating',
    cancelReasonListing: 'Cancel Reason Listing',
    cancelReasonCreating: 'Cancel Reason Creating',
    cancelReasonUpdating: 'Cancel Reason Updating',
    nationalitiesListing: 'Nationalities Listing',
    nationalityCreating: 'Nationality Creating',
    nationalityUpdating: 'Nationality Updating',
    passportsListing: 'Passports Listing',
    passportsCreating: 'Passport Creating',
    passportsUpdating: 'Passport Updating',
    paymentMethodsListing: 'Payment Methods Listing',
    paymentMethodCreating: 'Payment Methods Creating',
    paymentMethodUpdating: 'Payment Methods Updating',
    documentsListing: 'Documents Listing',
    documentCreating: 'Document Creating',
    documentUpdating: 'Document Updating',
    treatmentsListing: 'Treatments Listing',
    treatmentCreating: 'Treatment Creating',
    treatmentUpdating: 'Treatment Updating',
    statusesListing: 'Statuses Listing',
    statusCreating: 'Status Creating',
    statusUpdating: 'Status Updating',
    holidaysTypesListing: 'Holidays Types Listing',
    holidayTypeCreating: 'Holiday Type Creating',
    holidayTypeUpdating: 'Holiday Type Updating',
    courseStatusListing: 'Course Status Listing',
    courseStatusCreating: 'Course Status Creating',
    courseStatusUpdating: 'Course Status Updating',
    servicesListing: 'Services Listing',
    serviceCreating: 'Service Creating',
    serviceUpdating: 'Service Updating',
    horseLocationListing: 'Horses Locations Listing',
    horseLocationCreating: 'Horse Location Creating',
    horseLocationUpdating: 'Horse Location Updating',
    financeCustomerServicePaymentListing:
      'Finance Customer Service Payment Listing',
    financeCustomerCreditListing: 'Finance Customer Credit Listing',
    financeCustomerBalanceStatmentListing: 'Finance Customer Balance Listing',
    classCategoryListing: 'Class Category Listing',
    classCategoryUpdating: 'Class Category Updating',
    classCategoryCreating: 'Class Category Creating',
    classLevelListing: 'Class Level Listing',
    classLevelCreating: 'Class Level Creating',
    classLevelUpdating: 'Class Level Updating',
    customerAgeGroupListing: 'Customer Age Group Listing',
    financecInvoiceListing: 'Finance Invoice Listing',
    customersListing: 'Customers Listing',
    customerAgeGroupUpdating: 'Customer Age Group Updating',
    jobTitleUpdating: 'Job Title Updating',
    customerAgeGroupCreating: 'Customer Age Group Creating',
    createNewRole: 'Create New Role',
    createNewClient: 'Create New Client',
    createNewgroup: 'Create New Group',
    createNewAdmin: 'Create New Admin',
    createNewUser: 'Create New User',
    createNewGuardian: 'Create New Guardian',
    createNewGender: 'Create New Gender',
    createNewHorseGender: 'Create New Horse Gender',
    createNewHorseBreed: 'Create New Horse Breed',
    createNewHorseColor: 'Create New Horse Color',
    createNewHorseCategory: 'Create New Horse Category',
    createNewHorseType: 'Create New Horse Type',
    createNewLeaveType: 'Create New Leave Type',
    createNewCancelReason: 'Create New Cancel Reason',
    createNewNationality: 'Create New Nationality',
    createNewPassport: 'Create New Passport',
    createNewPaymentMethod: 'Create New Payment Method',
    createNewDocument: 'Create New Document',
    createNewTreatment: 'Create New Treatment',
    createNewStaatus: 'Create New Status',
    createNewCourseStatus: 'Create New Course Status',
    createNewService: 'Create New Service',
    createNewClass: 'Create New Class',
    createNewHorseLocation: 'Create New Horse Location',
    createNewHolidayType: 'Create New Holiday Type',
    createNewArena: 'Create New Arena',
    createNewLesson: 'Create New Lesson',
    createNewCustomerAgeGroup: 'Create New Customer Age Group',
    createNewClassLevel: 'Create New Class Level',
    createNewClassCategory: 'Create New Class Category',
    createNewJobTitle: 'Create New Job Title',
    createCustomerServicePayment: 'Create New  Customer Service Payment',
    updateGender: 'Update Gender',
    updateHolidayType: 'Update Holiday Type',
    updateHorseGender: 'Update Horse Gender',
    updateHorseBreed: 'Update Horse Breed',
    updateHorseColor: 'Update Horse Color',
    updateHorseCategory: 'Update Horse Category',
    updateHorseType: 'Update Horse Type',
    updateHorses: 'Update Horse',
    updateJobTitle: 'Update Job Title',
    updateLeaveType: 'Update Leave Type',
    updateNationality: 'Update Nationality',
    updatePassport: 'Update Passport',
    updatePaymentMethod: 'Update Payment Method',
    updateDocument: 'Update Document',
    updateTreatment: 'Update Treatment',
    updateStatus: 'Update Status',
    updateCourseStatus: 'Update Course Status',
    updateService: 'Update Service',
    updateClass: 'Update Calss',
    updateHorseLocation: 'Update Horse Location',
    updateClassCategory: 'Update Class Category',
    updateArena: 'Update Arena',
    updateClassLevel: 'Update Calss Level',
    updateCustomerAgeGroup: 'Update Customer Age Group',
    updateTrainer: 'Update Trainer',
    roleGroup: 'Role Group',
    groupsCreating: 'Groups Creating',
    jobTitleCreating: 'Job Title Creating',
    rolesCreating: 'Roles Creating',
    groupsListing: 'Groups Listing',
    groupsUpdating: 'Groups Updating',
    arenasListing: 'Arenas Listing',
    arenaCreating: 'Arena Creating',
    arenaUpdating: 'arena Updating',
    members: 'Members',
    assets: 'Assets',
    nameEnLang: 'Name in English Language',
    nameArLang: 'Name in Arabic Language',
    addNewExchangeRate: 'Add New Exchange Rate',
    exchangeRate: 'Exchange Rate',
    isPrimary: 'Is Primary',
    saveChanges: 'Save Changes',
    arabicName: 'Arabic Name',
    englishName: 'English Name',
    holidayStartDate: 'Holiday Start Date',
    holidayEndDate: 'Holiday End Date',
    numberOfHorses: 'Number of Horses',
    minAge: 'Min Age',
    maxAge: 'Max Age',
    chipNumber: 'Chip Number',
    joiningDate: 'Joining Date',
    workCapacity: 'Work Capacity',
    classLimitPerDay: 'Class Limit Per Day',
    passportType: 'Passport Type',
    nationality: 'Nationality',
    CustomerAttendanceReport: 'Customer Attendance Report',
    LessonReport: 'Lesson Report',
    HorseLessonReport: 'Horse Count',
    HorseLessonCountReport: 'Horses Utilization Report',
    GroomerLessonCountReport: 'Groom Utilization Report ',
    CustomerBalanceReport: 'Customer Balance Report',
    CustomerPaymentReport: 'All Customer Expired Lesson',
    CustomerAccountReport: 'Daily Statement Report',
    passport: 'Passport',
    HorsesReport: 'Daily Schedule Report For Horses',
    LessonByTypeReport: 'Attendance Report GrpByDate',
    civilId: 'Civil Id',
    gender: 'Gender',
    residencyNumber: 'Residency Number',
    address: 'Address',
    fullName: 'Full Name',
    userName: 'User Name',
    phoneNumber: 'Phone Number',
    comment: 'Comment',
    name: 'Name',
    jobTitle: 'Job Title',
    treatmentOverview: 'Treatment Overview',
    trainerDashboard: 'Trainer Dashboard',
    classLevel: 'Class Level',
    ChangeYourPassword: 'Change Your Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    weeklyLessonsListing: 'Weekly Lessons Listing',
    updateRole: 'Update Role',
    shortDescription: 'Short Description',
    startDate: 'Start Date',
    endDate: 'End Date',
    startTime: 'Start Time',
    endTime: 'End Time',
    ArenaDailyLessonReport: 'Arena Timeline Report - Day',
    ArenaDailyLessonNightReport: 'Arena Timeline Report - Night',
    GroomDailyLessonReport: 'Groom Timeline Report - Day',
    GroomDailyLessonNightReport: 'Groom Timeline Report - Night',
    HorseDailyLessonReport: 'Horse Timeline Report - Day',
    HorseDailyLessonNightReport: 'Horse Timeline Report - Night',
    CustomerStatementReport: 'Customer Account Statement',
    titleEn: 'Title in English',
    titleAr: 'Title in Arabic',
    descriptionEn: 'Description in English',
    descriptionAr: 'Description in Arabic',
    EN_TITLE_IS_REQUIRED_FIELD: 'English title is a required field',
    AR_TITLE_IS_REQUIRED_FIELD: 'Arabic title is a required field',
    EN_DESCRIPTION_IS_REQUIRED_FIELD: 'English description is a required field',
    AR_DESCRIPTION_IS_REQUIRED_FIELD: 'Arabic description is a required field',
    EN_CONTENT_IS_REQUIRED_FIELD: 'English content is a required field',
    AR_CONTENT_IS_REQUIRED_FIELD: 'Arabic content is a required field',
  },

  ar: {
    signInTitle: 'Sign In to KRC Admin',
    signInTrainer: 'Sign In to KRC Trainer',
    signInClients: 'Sign In Clients',
    email: 'Email',
    emailAddress: 'Email Address',
    password: 'Password',
    enterPassword: 'Enter Password',
    vehicle: 'المركبة',
    title: 'Title',
    continue: 'Continue',
    nameEn: 'Name - english ',
    nameAr: 'Name - Arabic',
    roleName: 'Role Name',
    EMAIL_IS_REQUIRED_FIELD: 'Email is a required field',
    ROLE_IS_REQUIRED_FIELD: 'Role is a required field',
    AR_NAME_IS_REQUIRED_FIELD: 'Arabic name is a required field',
    EN_NAME_IS_REQUIRED_FIELD: 'English name is a required field',
    NATIONALITY_IS_REQUIRED_FIELD: 'Nationality is required field',
    CATEGORY_IS_REQUIRED_FIELD: 'Category is a required field',
    TRAINER_IS_REQUIRED_FIELD: 'Trainer is required field',
    GROOMER_IS_REQUIRED_FIELD: 'Groomer is required field',
    COLOR_IS_REQUIRED_FIELD: 'Color is required field',
    BREED_IS_REQUIRED_FIELD: 'Breed is required field',
    JOB_TITLE_IS_REQUIRED_FIELD: 'Job title is required field',
    TYPE_HORSE_IS_REQUIRED_FIELD: 'Type horse is required field',
    LOCATION_IS_REQUIRED_FIELD: 'Location is required field',
    CUSTOMER_IS_REQUIRED_FIELD: 'Customer is required field',
    PASSPORT_NUMBER_IS_REQUIRED_FIELD: 'Passport Number is required field',
    PHONE_NUMBER_IS_REQUIRED_FIELD: 'Phone Number is required field',
    GENDER_IS_REQUIRED_FIELD: 'Gender is required field',
    CIVIL_ID_IS_REQUIRED_FIELD: 'Civil Id is required field',
    CIVIL_ID_MUST_BE_12_DIGITS: 'Civil id must be 12 digits',
    ADDRESS_IS_REQUIRED_FIELD: 'Address is required field',
    STATUS_IS_REQUIRED_FIELD: 'Status is required field',
    RIDERS_IS_REQUIRED_FIELD: 'Riders is required field',
    INVOICE_IS_REQUIRED_FIELD: 'Invoice is required field',
    DAYS_IS_REQUIRED_FIELD: 'Days is required field',
    GUARDIAN_TYPE_IS_REQUIRED_FIELD: ' Guardian type is required field',
    CURRENT_PASSWORD_IS_REQUIRED_FIELD: 'Current Password is required field',
    PASSWORD_IS_REQUIRED_FIELD: 'Password is required field',
    CONFIRM_PASSWORD_IS_REQUIRED_FIELD: 'Confirm Password is required field',
    PASSWORD_MIN_LENGTH_ERROR:
      'Password must consist of a minimum of 6 characters',
    PASSWORDS_DO_NOT_MATCH: 'Password is not match',
    DOC_TYPE_IS_REQUIRED_FIELD: 'Docuemnt Type is requrid field',
    CLASS_TYPE_IS_REQUIRED_FIELD: 'Class Type is requrid field',
    DURATION_IS_REQUIRED_FIELD: 'Duration is a required field',
    CLASS_LIMIT_IS_REQUIRED_FIELD: 'Class Limit is a required field',
    DATE_IS_REQUIRED_FIELD: 'Date is a required field',
    DATE_OF_BIRTH_IS_REQUIRED_FIELD: 'Date of birth is a required field',
    PASSPORT_IS_REQUIRED_FIELD: 'Passport is a required field',
    CIVIL_IS_REQUIRED_FIELD: 'Civil is a required field',
    NAME_IS_REQUIRED_FIELD: 'Name is a required field',
    ARENA_IS_REQUIRED_FIELD: 'Arena is a required field',
    SUCCESSFULLY_CREATED_ADMIN: 'Successfully created a new admin!',
    SUCCESSFULLY_CREATED_COST_MATRIX: 'Successfully created a new cost matrix!',
    createCostMatrix: 'Create Cost Matrix',
    costMatrixUpdating: 'Cost Matrix Updating',
    SUCCESSFULLY_CREATED_ROLE: 'Successfully created a new role!',
    SUCCESSFULLY_CREATED_HORSE_OPERATION:
      'Successfully created a new horse operatons',
    SUCCESSFULLY_CREATED_SHIPMENT_SIZE:
      'Successfully created a new shipment size!',
    SUCCESSFULLY_UPDATED_ROLE: 'Successfully updated role!',
    SUCCESSFULLY_CREATED_GROUP: 'Successfully created a new group!',
    SUCCESSFULLY_UPDATED_GROUP: 'Successfully updated group!',
    SUCCESSFULLY_UPDATED_AREA: 'Successfully updated area!',
    SUCCESSFULLY_UPDATED_ADMIN: 'Successfully updated admin!',
    SUCCESSFULLY_UPDATED_GROOMER: 'Successfully updated groomer!',
    SUCCESSFULLY_UPDATED_GUARDIAN: 'Successfully updated Guardian!',
    SUCCESSFULLY_UPDATED_TRAINER: 'Successfully updated Trainer',
    SUCCESSFULLY_UPDATED_STAFF: 'Successfully updated staff',
    SUCCESSFULLY_UPDATED_TERMS_AND_CONDITIONS:
      'Successfully updated terms and conditions!',
    SUCCESSFULLY_UPDATED_YOUR_PASSWORD: 'Successfully updated your password',
    SUCCESSFULLY_UPDATED_LESSONS: 'Successfully updated lessons',
    SUCCESSFULLY_UPDATED_JOB_TITLE: 'Successfully updated Job Title',
    lessonCreating: 'Lesson Creating',
    groomerCreating: 'Groomer Creating',
    customerCreditCreating: 'Customer Credit Creating',
    customerCreating: 'Customer Creating',
    customerEditing: 'Customer Editing',
    lessonListing: 'Lesson Listing',
    SUCCESSFULLY_UPDATED_DOCUMENT: 'Successfully updated document!',
    SUCCESSFULLY_UPDATED_CANCEL_REASON: 'Successfully updated cancel reason!',
    SUCCESSFULLY_UPDATED_COURSE_STATUS: 'Successfully updated course status!',
    SUCCESSFULLY_UPDATED_CLASS: 'Successfully updated class!',
    SUCCESSFULLY_UPDATED_GENDER: 'Successfully updated gender!',
    SUCCESSFULLY_UPDATED_HORSE_BREED: 'Successfully updated horse breed!',
    SUCCESSFULLY_UPDATED_HORSE_CATEGORY: 'Successfully updated horse category!',
    SUCCESSFULLY_UPDATED_HORSE_COLOR: 'Successfully updated horse color!',
    SUCCESSFULLY_UPDATED_HORSE_GENDER: 'Successfully updated horse gender!',
    SUCCESSFULLY_UPDATED_HORSE_TYPE: 'Successfully updated horse type!',
    SUCCESSFULLY_UPDATED_HORSE: 'Successfully updated horse',
    SUCCESSFULLY_UPDATED_LEAVE_TYPE: 'Successfully updated leave type!',
    SUCCESSFULLY_UPDATED_NATIONALITY: 'Successfully updated nationality!',
    SUCCESSFULLY_UPDATED_PASSPORT: 'Successfully updated passport!',
    SUCCESSFULLY_UPDATED_PAYMENT_METHOD: 'Successfully updated payment method!',
    SUCCESSFULLY_UPDATED_SERVICE: 'Successfully updated service!',
    SUCCESSFULLY_UPDATED_STATUS: 'Successfully updated status!',
    SUCCESSFULLY_UPDATED_TREATMENT: 'Successfully updated treatment type!',
    SUCCESSFULLY_UPDATED_CUSTOMER: 'Successfully updated customer',
    SUCCESSFULLY_UPDATED_HORSE_LOCATION: 'Successfully updated horse location!',
    SUCCESSFULLY_CREATED_GUARDIAN: 'Successfully created a new guardian!',
    SUCCESSFULLY_CREATED_DOCUMENT: 'Successfully created a new document!',
    SUCCESSFULLY_UPDATED_HOLIDAY_TYPE: 'Successfully updated holiday type!',
    SUCCESSFULLY_CREATED_CANCEL_REASON:
      'Successfully created a new cancel reason!',
    SUCCESSFULLY_CREATED_JOB_TITLE: 'Successfully created a new job title ',
    SUCCESSFULLY_CREATED_COURSE_STATUS:
      'Successfully created a new course status!',
    SUCCESSFULLY_CREATED_CLASS: 'Successfully created a new class!',
    SUCCESSFULLY_CREATED_GENDER: 'Successfully created a new gender!',
    SUCCESSFULLY_CREATED_HORSE_BREED: 'Successfully created a new horse breed!',
    SUCCESSFULLY_CREATED_HORSE_CATEGORY:
      'Successfully created a new horse category!',
    SUCCESSFULLY_CREATED_HORSE_COLOR: 'Successfully created a new horse color!',
    SUCCESSFULLY_CREATED_HORSE_GENDER:
      'Successfully created a new horse gender!',
    SUCCESSFULLY_CREATED_HORSE_TYPE: 'Successfully created a new horse type!',
    SUCCESSFULLY_CREATED_LEAVE_TYPE: 'Successfully created a new leave type!',
    SUCCESSFULLY_CREATED_NATIONALITY: 'Successfully created a new nationality!',
    SUCCESSFULLY_CREATED_PASSPORT: 'Successfully created a new passport!',
    SUCCESSFULLY_CREATED_PAYMENT_METHOD:
      'Successfully created a new payment method!',
    SUCCESSFULLY_CREATED_SERVICE: 'Successfully created a new service!',
    SUCCESSFULLY_CREATED_STATUS: 'Successfully created a new status!',
    SUCCESSFULLY_CREATED_TREATMENT: 'Successfully created a new treatment!',
    SUCCESSFULLY_CREATED_HORSE_LOCATION:
      'Successfully created a new horse location!',
    SUCCESSFULLY_CREATED_HOLIDAY_TYPE:
      'Successfully created a new holiday type!',
    SUCCESSFULLY_CREATED_INVOICE: 'Successfully created a new invoice!',
    SUCCESSFULLY_CREATED_TRAINER: 'Successfully created a new trainer!',
    updateCategory: 'Update Category',
    updateGroomer: 'Update Groomer',
    updateGuardian: 'Update Guardian',
    updateCustomer: 'Update Customer',
    updateStaff: 'Update Staff',
    addNewGroup: 'Add New Group',
    adminUsersListing: 'Admin Users Listing',
    adminUsersUpdating: 'Admin Users Updating',
    adminUsersCreating: 'Admin Users Creating',
    addNewGuardian: 'Add New Guardian',
    addNewGender: 'Add New Gender',
    addNewHorseGender: 'Add New Horse Gender',
    addNewHorseBreed: 'Add New Horse Breed',
    addNewHorseColor: 'Add New Horse Color',
    addNewHorseCategory: 'Add New Horse Category',
    addNewHorseType: 'Add New Horse Type',
    addNewLeaveType: 'Add New Leave Type',
    addNewCancelReason: 'Add New Cancel Reason',
    addNewNationality: 'Add New Nationality',
    addNewPassport: 'Add New Passport',
    addNewPaymentMethod: 'Add New Payment Method',
    addNewDocument: 'Add New Document',
    addNewTreatment: 'Add New Treatment',
    addNewStatus: 'Add New Status',
    addNewService: 'Add New Service',
    addNewClass: 'Add New Class',
    addNewHorseLocation: 'Add New Horse Location',
    addNewHolidayType: 'Add New Holiday Type',
    addNewCustomer: 'Add New Customer',
    guardiansListing: 'Guardians Listing',
    guardianUpdating: 'Guardian Updating',
    guardianCreating: 'Guardian Creating',
    classesListing: 'Classes Lisitng',
    classCreating: 'Class Creating',
    classUpdating: 'Class Updating',
    groomerOperationUpdating: 'Groomer Operation Updation',
    gendersListing: 'Genders Listing',
    genderCreating: 'Gender Creating',
    genderUpdating: 'Gender Updating',
    horsesGendersListing: 'Horse Gender Listing',
    horseGenderCreating: 'Horse Gender Creating',
    horseGenderUpdating: 'Horse Gender Updating',
    horseCategoryListing: 'Horse Category Listing',
    horseCategoryCreating: 'Horse Category Creating',
    horseCategoryUpdating: 'Horse Category Updating',
    horseTypeListing: 'Horse Type Listing',
    horseTypeCreating: 'Horse Type Creating',
    horseTypeUpdating: 'Horse Type Updating',
    trainerUpdating: 'Trainer Updating',
    trainersListing: 'Trainers Listing',
    trainerCreating: 'Trainer Creating',
    leaveTypeListing: 'Leave Type Listing',
    leaveTypeCreating: 'Leave Type Creating',
    financeInvoceCreating: 'Finance Invoice Creating',
    leaveTypeUpdating: 'Leave Type Updating',
    cancelReasonListing: 'Cancel Reason Listing',
    cancelReasonCreating: 'Cancel Reason Creating',
    cancelReasonUpdating: 'Cancel Reason Updating',
    nationalitiesListing: 'Nationalities Listing',
    nationalityCreating: 'Nationality Creating',
    nationalityUpdating: 'Nationality Updating',
    passportsListing: 'Passports Listing',
    passportsCreating: 'Passport Creating',
    passportsUpdating: 'Passport Updating',
    paymentMethodsListing: 'Payment Methods Listing',
    paymentMethodCreating: 'Payment Methods Creating',
    paymentMethodUpdating: 'Payment Methods Updating',
    documentsListing: 'Documents Listing',
    documentCreating: 'Document Creating',
    documentUpdating: 'Document Updating',
    treatmentsListing: 'Treatments Listing',
    treatmentCreating: 'Treatment Creating',
    treatmentUpdating: 'Treatment Updating',
    statusesListing: 'Statuses Listing',
    statusCreating: 'Status Creating',
    statusUpdating: 'Status Updating',
    courseStatusListing: 'Course Status Listing',
    courseStatusCreating: 'Course Status Creating',
    courseStatusUpdating: 'Course Status Updating',
    servicesListing: 'Services Listing',
    serviceCreating: 'Service Creating',
    serviceUpdating: 'Service Updating',
    horseLocationListing: 'Horses Locations Listing',
    horseLocationCreating: 'Horse Location Creating',
    horseLocationUpdating: 'Horse Location Updating',
    financeCustomerStatmentListing: 'Finance Customer Statment Listing',
    financeCustomerServicePaymentListing:
      'Finance Customer Service Payment Listing',
    financeCustomerBalanceStatmentListing: 'Finance Customer Balance Listing',
    saveChanges: 'Save Changes',
    createNewRole: 'Create New Role',
    createNewClient: 'Create New Client',
    createNewgroup: 'Create New Group',
    createNewAdmin: 'Create New Admin',
    createNewUser: 'Create New User',
    createNewGuardian: 'Create New Guardian',
    createNewGender: 'Create New Gender',
    createNewHorseGender: 'Create New Horse Gender',
    createNewHorseBreed: 'Create New Horse Breed',
    createNewHorseColor: 'Create New Horse Color',
    createNewHorseCategory: 'Create New Horse Category',
    createNewHorseType: 'Create New Horse Type',
    createNewLeaveType: 'Create New Leave Type',
    createNewCancelReason: 'Create New Cancel Reason',
    createNewNationality: 'Create New Nationality',
    createNewPassport: 'Create New Passport',
    createNewPaymentMethod: 'Create New Payment Method',
    createNewDocument: 'Create New Document',
    createNewTreatment: 'Create New Treatment',
    createNewStaatus: 'Create New Status',
    createNewCourseStatus: 'Create New Course Status',
    createNewService: 'Create New Service',
    createNewClass: 'Create New Class',
    createNewHorseLocation: 'Create New Horse Location',
    createNewHolidayType: 'Create New Holiday Type',
    createNewArena: 'Create New Arena',
    createNewLesson: 'Create New Lesson',
    createNewHorse: 'Create New Horse',
    createNewTrainer: 'create New Trainer',
    createNewCustomer: 'Create New Customer',
    createNewStaff: 'Create New Staff',
    createFinanceInvoice: 'Create Finance Invoice',
    createCustomerStatment: 'Create Customer Statment',
    createCustomerCredit: 'Create Customer Credit',
    updateGender: 'Update Gender',
    updateHorseGender: 'Update Horse Gender',
    updateHorseBreed: 'Update Horse Breed',
    updateHorseColor: 'Update Horse Color',
    updateHorseCategory: 'Update Horse Category',
    updateHorseType: 'Update Horse Type',
    updateHorses: 'Update Horse',
    updateJobTitle: 'Update Job Title',
    updateLeaveType: 'Update Leave Type',
    updateNationality: 'Update Nationality',
    updatePassport: 'Update Passport',
    updatePaymentMethod: 'Update Payment Method',
    updateDocument: 'Update Document',
    updateTreatment: 'Update Treatment',
    updateStatus: 'Update Status',
    updateCourseStatus: 'Update Course Status',
    updateService: 'Update Service',
    updateClass: 'Update Calss',
    updateHorseLocation: 'Update Horse Location',
    updateArena: 'Update Arena',
    chipNumber: 'Chip Number',
    dateOfBirth: 'Date Of Birth',
    joiningDate: 'Joining Date',
    workCapacity: 'Work Capacity',
    classLimitPerDay: 'Class Limit Per Day',
    passportType: 'Passport Type',
    nationality: 'Nationality',
    CustomerAttendanceReport: 'Customer Attendance Report',
    LessonReport: 'Lesson Report',
    HorseLessonReport: 'Horse Count',
    HorseLessonCountReport: 'Horses Utilization Report',
    GroomerLessonCountReport: 'Groom Utilization Report ',
    CustomerBalanceReport: 'Customer Balance Report',
    CustomerPaymentReport: 'All Customer Expired Lesson',
    CustomerAccountReport: 'Daily Statement Report',
    passport: 'Passport',
    HorsesReport: 'Daily Schedule Report For Horses',
    LessonByTypeReport: 'Attendance Report GrpByDate',
    civilId: 'Civil Id',
    gender: 'Gender',
    residencyNumber: 'Residency Number',
    address: 'Address',
    fullName: 'Full Name',
    userName: 'User Name',
    phoneNumber: 'Phone Number',
    comment: 'Comment',
    name: 'Name',
    jobTitle: 'Job Title',
    treatmentOverview: 'Treatment Overview',
    trainerDashboard: 'Trainer Dashboard',
    classLevel: 'Class Level',
    ChangeYourPassword: 'Change Your Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    weeklyLessonsListing: 'Weekly Lessons Listing',
    updateRole: 'Update Role',
    shortDescription: 'Short Description',
    startDate: 'Start Date',
    endDate: 'End Date',
    startTime: 'Start Time',
    endTime: 'End Time',
    CustomerStatementReport: 'Customer Account Statement',
    titleEn: 'Title in English',
    titleAr: 'Title in Arabic',
    descriptionEn: 'Description in English',
    descriptionAr: 'Description in Arabic',
  },
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  messages,
});

export default i18n;
