import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface AnnouncementObject {
  titleEn: string;
  titleAR: string;
  contentEn: string;
  contentAr: string;
  categoryId: string;
  id: string;
}

export interface AnnouncementListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  announcement: Array<AnnouncementObject>;
  errors: unknown;
}

export interface AnnouncementFilterInfo {
  title: string;
}

@Module
export default class AnnouncementModule
  extends VuexModule
  implements AnnouncementListInfo
{
  announcement = [] as Array<AnnouncementObject>;
  allAnnouncement = [] as Array<AnnouncementObject>;
  announcementFilter = {} as AnnouncementFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get announcement list
   * @returns Array<AnnouncementObject>
   */
  get announcementList(): Array<AnnouncementObject> {
    return this.announcement;
  }

  get allAnnouncementList(): Array<AnnouncementObject> {
    return this.allAnnouncement;
  }

  /**
   * Get total items
   * @returns number
   */
  get announcementListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_ANNOUNCEMENT](data) {
    this.announcement = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_ANNOUNCEMENT](data) {
    this.allAnnouncement = data.data;
  }

  @Mutation
  [Mutations.SET_ANNOUNCEMENT_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_ANNOUNCEMENT_LIST]() {
    this.announcement = [] as Array<AnnouncementObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ANNOUNCEMENT_FILTER](obj) {
    this.announcementFilter.title = obj.title;
  }

  @Action
  [Actions.RESET_ANNOUNCEMENT_STORE]() {
    this.context.commit(Mutations.RESET_ANNOUNCEMENT_LIST);
  }

  @Action
  [Actions.UPDATE_ANNOUNCEMENT_FILTER](val) {
    this.context.commit(Mutations.SET_ANNOUNCEMENT_FILTER, val);
  }

  @Action
  [Actions.CREATE_ANNOUNCEMENT](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/announcement', data)
      .then((data) => {
        this.context.commit(Mutations.RESET_ANNOUNCEMENT_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPLOAD_ANNOUNCEMENT_ATTACHMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/announcement/attachments/${id}`, data)
      .then(() => {
        this.context.commit(Mutations.RESET_GROOMERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_ANNOUNCEMENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/announcement', id)
      .then(() => {
        this.context.commit(Mutations.RESET_ANNOUNCEMENT_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_ANNOUNCEMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/announcement', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ANNOUNCEMENT_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_ANNOUNCEMENT_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_ANNOUNCEMENT_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_ANNOUNCEMENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/announcement', id);
  }

  @Action
  [Actions.GET_ANNOUNCEMENTS]() {
    const params = {
      page: this.page,
      title: this.announcementFilter.title,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/announcement', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ANNOUNCEMENT, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_ALL_ANNOUNCEMENT]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/announcement/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_ANNOUNCEMENT, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
}
