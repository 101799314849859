
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import CustomerFilter from '@/components/dropdown/CustomerFilter.vue';
  import LookupNameIds from '@/store/enums/settingsEnums';

  export default defineComponent({
    name: 'customers-listing',
    components: {
      Datatable,
      CustomerFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const searchItems = async (inputText) => {
        await store.dispatch(Actions.UPDATE_CUSTOMERS_FILTER, {
          name: inputText,
        });
        await store.dispatch(Actions.GET_CUSTOMERS);
      };
      const loading = ref(false);

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('customersListing'), [
          translate('operations'),
        ]);
        await store.dispatch(Actions.GET_CUSTOMERS);
        await store.dispatch(Actions.GET_ALL_CUSTOMERS, { active: false });
        await store.dispatch(Actions.GET_ALL_SETTINGS_CUSTOMER_AGE);
        await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
          LookupNameIds.STATUS,
        ]);
      });
      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_CUSTOMERS_STORE);
      });
      const tableHeader = ref([
        {
          name: translate('name'),
          key: 'name',
          sortable: true,
        },
        {
          name: translate('Phone number'),
          key: 'mobile',
          sortable: true,
        },
        {
          name: translate('gender'),
          key: 'genderName',
          sortable: true,
        },
        {
          name: translate('age'),
          key: 'age',
          sortable: true,
        },
        {
          name: translate('civil id'),
          key: 'civilId',
          sortable: true,
        },
        {
          name: translate('class category'),
          key: 'classCategoryName',
          sortable: true,
        },
        {
          name: translate('class level'),
          key: 'classLevelName',
          sortable: true,
        },

        {
          name: translate('balance'),
          key: 'balance',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const excelHeader = reactive({
        Name: 'name',
        'Phone Number': 'mobile',
        Gender: 'genderName',
        'Civil id': 'civilId',
        Balance: 'balance',
        Status: 'statusName',
      });
      const deleteCustomer = async (customers) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_CUSTOMER, customers.id);
          await store.dispatch(Actions.GET_CUSTOMERS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editCustomer = (id) => {
        router.push({ name: 'customer-editing', params: { id } });
      };

      const goToPackage = (id) => {
        router.push({ name: 'customer-package-details', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const searchCustomerCredit = async (query) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_CUSTOMERS_FILTER, query);
        await store.dispatch(Actions.UPDATE_CUSTOMER_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_CUSTOMERS);
        query.value.active = false;
        await store.dispatch(Actions.GET_ALL_CUSTOMERS, query.value);

        loading.value = false;
      };
      const onReset = async (query) => {
        await store.dispatch(Actions.UPDATE_CUSTOMERS_FILTER, query);
        await store.dispatch(Actions.UPDATE_CUSTOMER_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_CUSTOMERS);
        query.value.active = false;
        await store.dispatch(Actions.GET_ALL_CUSTOMERS, query.value);
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_CUSTOMER_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_CUSTOMERS);
        loading.value = false;
      };

      const excelData = computed(() => {
        return store.getters.allCustomersList.map((data) => {
          return {
            ...data,
          };
        });
      });

      return {
        tableData: computed(() => store.getters.customersList),
        excelData,
        tableHeader,
        excelHeader,
        inputText: '',
        searchItems,
        deleteCustomer,
        translate,
        totalItems: computed(() => store.getters.customersListCount),
        pageChanged,
        goTo,
        editCustomer,
        goToPackage,
        loading,
        can,
        searchCustomerCredit,
        onReset,
      };
    },
  });
