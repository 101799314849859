
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import LookupNameIds from '@/store/enums/settingsEnums';

  export default defineComponent({
    name: 'horse-color-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const searchItems = async (inputText) => {
        await store.dispatch(Actions.UPDATE_SETTINGS_LOOKUPS_FILTER, {
          name: inputText,
        });
        await store.dispatch(
          Actions.GET_SETTINGS_LOOKUPS,
          LookupNameIds.HORSE_COLOR
        );
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('horseColorListing'), [
          translate('settings'),
        ]);

        onBeforeUnmount(() => {
          store.dispatch(Actions.RESET_SETTINGS_LOOKUPS_STORE);
        });
        loading.value = true;
        await store.dispatch(
          Actions.GET_SETTINGS_LOOKUPS,
          LookupNameIds.HORSE_COLOR
        );
        loading.value = false;
      });
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('nameAr'),
          key: 'nameAr',
          sortable: true,
        },
        {
          name: translate('nameEn'),
          key: 'nameEn',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const deleteHorseColor = async (horseColor) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_SETTING_LOOKUP, horseColor.id);
          await store.dispatch(
            Actions.GET_SETTINGS_LOOKUPS,
            LookupNameIds.HORSE_COLOR
          );
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editHorseColor = (id) => {
        router.push({ name: 'horse-color-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_SETTING_LOOKUPS_CURRENT_PAGE, val);
        await store.dispatch(
          Actions.GET_SETTINGS_LOOKUPS,
          LookupNameIds.HORSE_COLOR
        );
        loading.value = false;
      };

      return {
        tableData: computed(() => store.getters.lookupsList),
        tableHeader,
        inputText: '',
        searchItems,
        deleteHorseColor,
        translate,
        totalItems: computed(() => store.getters.lookupsListCount),
        pageChanged,
        goTo,
        editHorseColor,
        loading,
        can,
      };
    },
  });
