
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'message-out',
    props: {
      name: String,
      image: String,
      time: String,
      text: String,
    },
  });
