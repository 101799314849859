
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Multiselect from '@vueform/multiselect';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { SettingsClassLevelObject } from '@/store/modules/settings/ClassLevelModule';

  export default defineComponent({
    name: 'class-level-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('classLevelUpdating'), [
          translate('settings'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_SETTING_CLASS_LEVEL,
        route.params.id
      );

      const classLevel = ref<SettingsClassLevelObject>(data.data);
      const selectedCategory = ref<Array<string>>(
        classLevel.value.classCategories.map((i) => i.id)
      );

      const previewImage = ref<string>(classLevel.value.badge || '');
      const imageFile = ref<File | null>(null);

      const classCategory = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      );

      const classCategoryTitle = () => {
        return classCategory.map((i) => {
          return { title: i.nameEn, id: i.id };
        });
      };

      const validationSchema = Yup.object().shape({
        name: Yup.string()
          .required(() => translate('NAME_IS_REQUIRED_FIELD'))
          .label('name'),
        color: Yup.string()
          .required(() => translate('COLOR_CODE_IS_REQUIRED_FIELD'))
          .label('color'),
        classCategory: Yup.array()
          .required(() => translate('CLASS_CATEGORY_IS_REQUIRED_FIELD'))
          .min(1, () => translate('CLASS_CATEGORY_IS_REQUIRED_FIELD')),
      });

      const onImageChange = (event: Event) => {
        const file = (event.target as HTMLInputElement).files?.[0] || null;
        if (file) {
          imageFile.value = file;
          const reader = new FileReader();
          reader.onload = (e) => {
            previewImage.value = e.target?.result as string;
          };
          reader.readAsDataURL(file);
        }
      };
      const removeImage = () => {
        if (previewImage.value === classLevel.value.badge) {
          Swal.fire({
            title: translate('areYouSureQ'),
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: translate('confirm'),
            cancelButtonText: translate('cancel'),
            customClass: {
              confirmButton: 'btn fw-bold btn-warning',
              cancelButton: 'btn btn-light',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              previewImage.value = '';
            }
          });
        } else {
          previewImage.value = '';
        }
      };

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          submitButton.value.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_SETTING_CLASS_LEVEL, {
          data: values,
          id: classLevel.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          if (previewImage.value !== classLevel.value.badge) {
            const formData = new FormData();
            if (previewImage.value) {
              console.log(previewImage.value);
              if (imageFile.value) {
                formData.append('attachments', imageFile.value);
              }
              await store.dispatch(Actions.UPLOAD_CLASS_LEVEL_ATTACHMENT, {
                data: formData,
                id: data.data.id,
              });
              const [errorName] = Object.keys(store.getters.getErrors);
              const error = store.getters.getErrors[errorName];
              if (error) {
                Swal.fire({
                  text: translate(error[0]),
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: translate('tryAgainExcl'),
                  customClass: {
                    confirmButton: 'btn fw-bold btn-light-danger',
                  },
                });
              } else {
                Swal.fire({
                  text: translate('SUCCESSFULLY_UPDATED_CLASS_LEVEL'),
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: translate('ok'),
                  customClass: {
                    confirmButton: 'btn btn-light-primary',
                  },
                }).then(() => {
                  router.push({ name: 'class-level-listing' });
                });
              }
            }
          } else {
            Swal.fire({
              text: translate('SUCCESSFULLY_UPDATED_CLASS_LEVEL'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            }).then(() => {
              router.push({ name: 'class-level-listing' });
            });
          }
        }
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };

      return {
        onSubmitCreate,
        classLevel,
        classCategoryTitle,
        selectedCategory,
        previewImage,
        submitButton,
        translate,
        validationSchema,
        onImageChange,
        goBack,
        can,
        removeImage,
      };
    },
  });
