
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { Field } from 'vee-validate';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import LessonsFilter from '@/components/dropdown/LessonsFilter.vue';
  import { getUser } from '@/core/services/AuthService';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import { convertDateDDMMYYYY } from '@/utils/dateFormat';

  export default defineComponent({
    name: 'lessons-listing',
    components: {
      Datatable,
      Field,
      LessonsFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const user = getUser();
      const isTrainer = user?.userType === 'trainer';

      const loading = ref(false);
      const showModal = ref(false);
      const showReasonModal = ref(false);
      const showMenu = ref(false);

      const horsesList = ref({});

      const searchQuery = ref({
        startDate: new Date().toISOString().slice(0, 10),
        endDate: new Date().toISOString().slice(0, 10),
      });

      const checkAttendenceResult = ref();

      const freezeDate = ref();
      const showFreezeDate = ref(false);
      const cancellationReasonId = ref(0);
      const showCancellationReason = ref(false);
      const showCommentModal = ref();

      const lessonComment = ref('');
      const lessonId = ref('');

      const dataReport = ref();

      const selectedRecored = ref<Array<string>>([]);
      const lessons = ref([] as { lessonId: string; [key: string]: string }[]);

      let lookupsList;
      const disabledFilterButton = ref(true);

      onMounted(async () => {
        loading.value = true;
        setCurrentPageBreadcrumbs(translate('lessonListing'), []);
        await store.dispatch(Actions.UPDATE_LESSONS_FILTER, searchQuery.value);

        await store.dispatch(Actions.GET_LESSONS_OPERATIONS);
        await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
          LookupNameIds.ATTENDEND,
          LookupNameIds.STATUS,
          LookupNameIds.HORSE_CATEGORY,
          LookupNameIds.CLASS_TYPE,
          LookupNameIds.CANCEL_REASON,
          LookupNameIds.DURATION,
        ]);
        await store.dispatch(Actions.GET_ALL_ARENAS);
        await store.dispatch(Actions.GET_ALL_CUSTOMERS);
        await store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        await store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS);

        if (!isTrainer) {
          dataReport.value = await store.dispatch(
            Actions.GET_REPORT_CUSTOMER_ATTENDENCE
          );
        }

        lookupsList = computed(() => store.getters.allLookupsList);

        loading.value = false;
        disabledFilterButton.value = false;
        reinitializeComponents();
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_LESSON_STORE);
      });
      const handleSelectedField = (
        lessonId: string,
        fieldId: string,
        fieldName: string,
        time?: string
      ) => {
        const lessonIndex = lessons.value.findIndex(
          (lesson) => lesson.lessonId === lessonId
        );

        let newLesson;
        if (lessonIndex === -1) {
          if (time) {
            newLesson = {
              lessonId: lessonId,
              [fieldName]: fieldId,
              time,
            };
          } else {
            newLesson = {
              lessonId: lessonId,
              [fieldName]: fieldId,
            };
          }

          lessons.value.push(newLesson);
        } else {
          lessons.value[lessonIndex][fieldName] = fieldId;
        }
      };

      const isRecordSelected = (id: string) => {
        return selectedRecored.value.includes(id);
      };

      const enableEditing = (id: string) => {
        if (!isTrainer) {
          return isRecordSelected(id);
        }
        return false;
      };

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const excelHeader = reactive({
        Horse: 'horseName',
        Trainer: 'trainerName',
        Customer: 'customerName',
        Groomer: 'groomerName',
        Arena: 'arenaName',
        Duration: 'durationName',
        Status: 'attendance',
        Balance: 'price',
        Phone: 'phone',
        Time: 'time.startTime',
        'Start Date': 'startDate',
        Category: 'classCategoryName',
        'Class Level': 'classLevelName',
        'Lesson Type': 'lessonType',
      });
      const tableHeader = ref([
        {
          name: translate('Class Type'),
          key: 'classType',
          sortable: true,
        },
        {
          name: translate('Time'),
          key: 'time',
          sortable: true,
        },
        {
          name: translate('date'),
          key: 'date',
          sortable: true,
        },
        {
          name: translate('arena Name'),
          key: 'arenaName',
          sortable: true,
        },
      ]);

      const toggleStatusUser = async () => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          //   await store.dispatch(Actions.DELETE_GROOMER, horses.id);
          // await store.dispatch(Actions.GET_GROOMERS_OPERATIONS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_LESSONS_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_LESSONS_OPERATIONS, searchQuery.value);
        loading.value = false;
      };

      const updateLessonsInformation = async () => {
        const data = await store.dispatch(Actions.UPDATE_LESSONS, {
          data: { lessons: [...lessons.value] },
        });

        if (Array.isArray(data?.data)) {
          Swal.fire({
            text: translate(data.data[0].reason),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          loading.value = true;
          await store.dispatch(
            Actions.GET_LESSONS_OPERATIONS,
            searchQuery.value
          );
          loading.value = false;
          lessons.value = [];
          return;
        }
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          selectedRecored.value = [];

          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_LESSONS'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          loading.value = true;
          await store.dispatch(
            Actions.GET_LESSONS_OPERATIONS,
            searchQuery.value
          );
          loading.value = false;
          selectedRecored.value = [];
          lessons.value = [];
        }
      };

      interface ICheckLesson {
        lessonId: string;
        customerId: string;
        referenceId: string;
        dayId: string;
      }
      const checkLessosns = ref<Array<ICheckLesson>>([]);

      const prepareLessonToCheck = async (lessonObj) => {
        const horsePayload = {
          time: lessonObj.time,
          startDate: lessonObj.startDate,
          numberOfLessons: 1,
          durationId: lessonObj.durationId,
          customerId: lessonObj.customerId,
          days: [lessonObj.dayId],
        };

        const lessonIndex = checkLessosns.value.findIndex(
          (lesson: { lessonId: string }) => lesson.lessonId === lessonObj.id
        );
        if (lessonIndex === -1) {
          delete horsesList[lessonObj.id];
          let data = await store.dispatch(
            Actions.GET_ALL_LESSONS_HORSE,
            horsePayload
          );
          data = [
            {
              nameEn: lessonObj.horseName,
              nameAr: lessonObj.horseName,
              id: lessonObj.horseId,
            },
            ...data,
          ];

          horsesList.value[lessonObj.id] = data;
          const newLesson: ICheckLesson = {
            lessonId: lessonObj.id,
            customerId: lessonObj.customerId,
            referenceId: lessonObj.referenceId,
            dayId: lessonObj.dayId,
          };

          checkLessosns.value.push(newLesson);
        } else {
          checkLessosns.value.splice(lessonIndex, 1);
        }
      };

      const payload = ref();
      const disableCompleteButton = () => {
        const totalLengthLesson = checkAttendenceResult?.value?.reduce(
          (accumulator, obj) => {
            if (obj.lesson && Array.isArray(obj.lesson)) {
              return accumulator + obj.lesson.length;
            }
            return accumulator;
          },
          0
        );

        if (checkAttendenceResult?.value && checkLessosns.value.length > 0)
          if (totalLengthLesson === checkLessosns.value.length) {
            return false;
          }
        if (showFreezeDate.value) {
          if (!freezeDate.value) {
            return false;
          }
        }
        if (showCancellationReason.value) {
          if (!cancellationReasonId.value) {
            return false;
          }
        }

        return true;
      };
      let attendanceParam = ref();
      const checkLessonsAttendence = async (
        attendence = attendanceParam.value
      ) => {
        attendanceParam.value = attendence;
        showFreezeDate.value = false;
        showCancellationReason.value = false;
        showReasonModal.value = false;

        const isFreez = attendence.slug === 'freeze';
        const isCancellation = attendence.slug === 'cancelled';
        if (isFreez && !freezeDate.value) {
          showFreezeDate.value = true;
          showReasonModal.value = true;
          return;
        } else if (isCancellation && !cancellationReasonId.value) {
          showCancellationReason.value = true;
          showReasonModal.value = true;
          return;
        }
        payload.value = {
          lessons: checkLessosns.value,
          attendance: attendence.id,
        };
        isFreez ? (payload.value.startDate = freezeDate.value) : '';
        isCancellation
          ? (payload.value.cancellationReasonId = cancellationReasonId.value)
          : '';

        const { data } = await store.dispatch(
          Actions.CHECK_ATTENDENCE,
          payload.value
        );

        if (Array.isArray(data.data)) {
          checkAttendenceResult.value = data.data;
          showModal.value = true;
          return;
        } else {
          await completeUpdateAttendence();
          showModal.value = false;
        }
      };
      const completeUpdateAttendence = async () => {
        await store.dispatch(Actions.COMPLETE_ATTENDENCE, payload.value);
        payload.value = {};
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_LESSONS'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          loading.value = true;
          await store.dispatch(
            Actions.GET_LESSONS_OPERATIONS,
            searchQuery.value
          );
          loading.value = false;
        }
        showModal.value = false;
        selectedRecored.value = [];
        checkLessosns.value = [];
      };
      const recetAttendence = () => {
        freezeDate.value = undefined;
        cancellationReasonId.value = 0;
        showModal.value = false;
        showReasonModal.value = false;
      };

      const onReset = async () => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_LESSONS_FILTER, {});
        await store.dispatch(Actions.UPDATE_LESSONS_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_LESSONS_OPERATIONS);
        loading.value = false;
      };
      const searchLessons = async (query) => {
        searchQuery.value = query;
        loading.value = true;
        await store.dispatch(Actions.UPDATE_LESSONS_FILTER, query);
        await store.dispatch(Actions.UPDATE_LESSONS_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_LESSONS_OPERATIONS);
        loading.value = false;
      };

      const excelData = computed(() => {
        const flattenedLessons = dataReport.value?.data.reduce(
          (result, item) => {
            return result.concat(item.lessons);
          },
          []
        );

        return flattenedLessons?.map((lesson) => {
          return {
            ...lesson,
            startDate: lesson.startDate.slice(0, 10), // Slice the date string
          };
        });
      });

      return {
        tableData: computed(() => store.getters.lessonsList),
        arenas: computed(() => store.getters.allArenasList),
        horsesList,
        customers: computed(() => store.getters.allCustomersList),
        trainers: computed(() => store.getters.allTrainersList),
        groomers: computed(() => store.getters.allGroomerList),
        totalItems: computed(() => store.getters.lessonslistCount),
        attendncies: computed(() => {
          return lookupsList.value.filter(
            (lookup) => lookup.lookupNameId === LookupNameIds.ATTENDEND
          );
        }),
        cancelReason: computed(() => {
          return lookupsList.value.filter(
            (lookup) => lookup.lookupNameId === LookupNameIds.CANCEL_REASON
          );
        }),
        durationList: computed(() => {
          return lookupsList.value.filter(
            (lookup) => lookup.lookupNameId === LookupNameIds.DURATION
          );
        }),
        excelData,
        tableHeader,
        toggleStatusUser,
        translate,
        pageChanged,
        goTo,
        loading,
        can,
        excelHeader,
        selectedRecored,
        isRecordSelected,
        handleSelectedField,
        updateLessonsInformation,
        prepareLessonToCheck,
        checkLessonsAttendence,
        showModal,
        checkAttendenceResult,
        completeUpdateAttendence,
        showMenu,
        onReset,
        searchLessons,
        payload,
        freezeDate,
        showFreezeDate,
        cancellationReasonId,
        showCancellationReason,
        showCommentModal,
        disableCompleteButton,
        showReasonModal,
        attendanceParam,
        recetAttendence,
        lessonComment,
        lessonId,
        disabledFilterButton,
        lessons,
        isTrainer,
        enableEditing,
        convertDateDDMMYYYY,
      };
    },
  });
