import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface ReportAttendence {
  id: number;
  name: string;
  genderName: string;
}

export interface ReportListInfo {
  reportsAttendnce: Array<ReportAttendence>;
  errors: unknown;
}

@Module
export default class ReportModule extends VuexModule implements ReportListInfo {
  reportsAttendnce = [] as Array<ReportAttendence>;
  errors = {};

  /**
   * Get report list
   * @returns Array<ReportAttendence>
   */
  get reportsAttendnceList(): Array<ReportAttendence> {
    return this.reportsAttendnce;
  }

  @Mutation
  [Mutations.SET_REPORT_CUSTOMER_ATTENDENCE](data) {
    this.reportsAttendnce = data.data;
    this.errors = {};
  }

  @Action
  [Actions.GET_REPORT_CUSTOMER_ATTENDENCE](query = {}) {
    ApiService.setAuthorizationHeader();
    const params = {
      ...Object.entries(query)
        .filter(([key, value]) => value !== '')
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {}),
    };

    return ApiService.query('/report/customer-attendance', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_REPORT_CUSTOMER_ATTENDENCE, data);
        return data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
