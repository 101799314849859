<template>
  <div>
    <div class="recepit-voucher" :id="`recepit-voucher_${customerService.id}`">
      <div
        class="header-recepit d-flex justify-content-around align-items-center my-3"
      >
        <h3>Receipt Voucher</h3>
        <img src="/media/logos/logo.png" class="h-100px" />
        <h3>سند قبض</h3>
      </div>
      <div class="border-bottom"></div>
      <div class="details">
        <table>
          <tr>
            <th>
              <p>رقم</p>
              <p>invoice</p>
            </th>
            <td>{{ customerService.date }}</td>
          </tr>
          <tr>
            <th>
              <p>تاريخ</p>
              <p>Date</p>
            </th>
            <td>{{ customerService.date }}</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>
              <p>المدفوع</p>
              <p>ِAmount Paid</p>
            </th>
            <td style="width: 120px">
              <div class="d-flex align-items-center">
                <p>دينار \KD</p>
                <p class="mx-4">{{ Math.floor(customerService.amount) }}</p>
              </div>
            </td>
            <td style="width: 120px">
              <div class="d-flex align-items-center">
                <p>فلس \Fils</p>
                <p class="mx-4">
                  {{
                    isNaN(customerService.amount)
                      ? '00'
                      : (customerService.amount % 1).toFixed(2).slice(2)
                  }}
                </p>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="information">
        <table>
          <tr>
            <th>
              <p>وصلنا من السيد/السادة</p>
              <p>Reccived From Mr./Mrs</p>
            </th>
            <td>{{ customerService.customerName }}</td>
          </tr>
          <tr>
            <th>
              <p>المجموع بالدينار الكويتي</p>
              <p>The Sum of K.D</p>
            </th>
            <td>{{ customerService.priceBeforeDiscount }}</td>
          </tr>
          <tr>
            <th>
              <p>المجموع بالدينار الكويتي بعد الخصم</p>
              <p>The Sum of K.D after discount</p>
            </th>
            <td>{{ customerService.amount }}</td>
          </tr>
          <tr>
            <th>
              <p>طريقة الدفع</p>
              <p>Payment Method</p>
            </th>
            <td>{{ customerService.paymentMethod }}</td>
          </tr>
          <tr>
            <th>
              <p>وذلك عن</p>
              <p>Being of</p>
            </th>
            <td>{{ customerService.serviceType }}</td>
          </tr>
        </table>
      </div>
      <!---begin::sign-->
      <div
        class="sign-wrapper d-flex justify-content-between align-items-center m-4"
      >
        <div class="sign-box">
          <div class="sign-title d-flex flex-column">
            <span>توقيع المستلم</span>
            <span>Res.sign</span>
          </div>
        </div>
        <div class="sign-box">
          <div class="sign-title d-flex flex-column">
            <span>المحاسب </span>
            <span>accountant</span>
          </div>
        </div>
      </div>
      <!---end::sign-->
    </div>
    <img
      class="recepit-download-icon"
      @click="convertToPdf"
      src="/media/icons/duotune/files/fil017.svg"
    />
  </div>
</template>

<script setup>
  import html2pdf from 'html2pdf.js';
  import { onMounted, onUnmounted, defineProps } from 'vue';

  const props = defineProps({
    customerService: {},
  });

  onMounted(() => {
    const element = document.getElementById(
      `recepit-voucher_${props.customerService.id}`
    );
    document.documentElement.appendChild(element);
  });
  onUnmounted(() => {
    const element = document.getElementById(
      `recepit-voucher_${props.customerService.id}`
    );
    if (element) {
      element.remove();
    }
  });

  const convertToPdf = async () => {
    const element = document.getElementById(
      `recepit-voucher_${props.customerService.id}`
    );
    element.style.display = 'block';

    let opt = {
      filename: 'recepit-voucher.pdf',
      html2canvas: { scale: 1 },
      jsPDF: { format: 'a4', orientation: 'portrait' },
      image: { type: 'jpeg', quality: 0.98 },
    };

    const worker = html2pdf()
      .from(element)
      .set(opt)
      .output('blob')
      .then((pdfBlob) => {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
      });

    setTimeout(() => {
      element.style.display = 'none';
    }, 0);
  };
</script>

<style lang="scss" scoped>
  .recepit-voucher {
    display: none;

    .border-bottom {
      border-bottom: 1px solid #000 !important;
    }

    .details {
      margin: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      table {
        border-collapse: collapse;
      }

      tr {
        height: 60px;

        p {
          margin: 0;
          font-size: 10px;
          text-align: center;
        }
      }

      th,
      td {
        padding: 0 10px;
        border: 1px solid #000;
      }

      td {
        width: 200px;
      }

      th {
        background-color: #ccc;
        white-space: nowrap;
      }
    }

    .information {
      table {
        border-collapse: collapse;
        margin: 20px;
      }

      tr {
        height: 60px;

        p {
          margin: 0;
          font-size: 10px;
          text-align: center;
        }
      }

      th,
      td {
        border: 1px solid #1e1e2d;
        padding: 0 10px;
      }

      td {
        width: 600px;
      }

      th {
        background-color: #ccc;
        white-space: nowrap;
      }
    }

    .sign-wrapper {
      padding-top: 20px;
    }

    .sign-box {
      width: 300px;
      border: 1px solid #1e1e2d;
    }

    .sign-title {
      background: #aaa;
      height: 100%;
      width: 30%;
      text-align: center;
      border: 1px solid #1e1e2d;

      span {
        white-space: nowrap;
      }
    }
  }

  .recepit-download-icon {
    cursor: pointer;
  }
</style>
