
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import printJs from 'print-js';
  import BalanceFilter from '@/components/dropdown/CustomerBalancecFilter.vue';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import moment from 'moment';
  export default defineComponent({
    name: 'customer-balance-statment-listing',
    components: {
      Datatable,
      BalanceFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const loading = ref(false);
      const searchQuery = ref();
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(
          translate('financeCustomerBalanceStatmentListing'),
          [translate('finance')]
        );
        loading.value = true;
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_BALANCE_STATMENT);
        loading.value = false;
        await store.dispatch(Actions.GET_ALL_CUSTOMERS);
        await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
          LookupNameIds.PAYMENT_INDICATOR,
        ]);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_FINANCE_CUSTOMER_BALANCE_STATMENT_STORE);
      });
      loading.value = true;
      await store.dispatch(Actions.GET_FINANCE_CUSTOMER_BALANCE_STATMENT);
      loading.value = false;
      const tableHeader = ref([
        {
          name: translate('name'),
          key: 'name',
          sortable: true,
        },
        {
          name: translate('nationality'),
          key: 'nationalityName',
          sortable: true,
        },

        {
          name: translate('phoneNumber'),
          key: 'phoneNumber',
          sortable: true,
        },
        {
          name: translate('balance'),
          key: 'balance',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'statusName',
          sortable: true,
        },
        {
          name: translate('Number Of Active Lessons'),
          key: 'activeLessonCount',
          sortable: true,
        },

        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const searchBalance = async (query) => {
        loading.value = true;
        searchQuery.value = query.value;
        await store.dispatch(Actions.UPDATE_CUSTOMER_BALANCE_FILTER, query);
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_BALANCE_STATMENT_CURRENT_PAGE,
          1
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_BALANCE_STATMENT);
        loading.value = false;
      };
      const onReset = async (query) => {
        loading.value = true;
        searchQuery.value = query.value;
        await store.dispatch(Actions.UPDATE_CUSTOMER_BALANCE_FILTER, query);
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_BALANCE_STATMENT_CURRENT_PAGE,
          1
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_BALANCE_STATMENT);
        loading.value = false;
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_BALANCE_STATMENT_CURRENT_PAGE,
          val
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_BALANCE_STATMENT);
        loading.value = false;
      };
      const print = async (customerData) => {
        const dataReports = await store.dispatch(
          Actions.GET_FINANCE_CUSTOMER_PAYMENT_HISTORY,
          customerData.id
        );
        const starDate =
          moment(searchQuery.value?.startDate).format('DD-MM-YYYY') ||
          moment(dataReports.data[0]?.date).format('DD-MM-YYYY') ||
          '';
        const endDate =
          moment(searchQuery.value?.endDate).format('DD-MM-YYYY') ||
          moment(dataReports.data[dataReports.data.length - 1]?.date).format(
            'DD-MM-YYYY'
          ) ||
          '';

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'portrait',
        });

        doc.text('Print DateTime: ' + moment(new Date()).format('LLL'), 50, 12);

        doc.addImage('/media/logos/krc.jpg', 'jpg', 1, 9, 40, 40);
        console.log(doc.internal.pageSize.getWidth());
        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;
        doc.text('Kuwait Riding Center', textX, 25);
        doc.text('Horse Lesson Count Report', textX - 10, 30);
        doc.text('Start Date : ' + starDate, 60, 50);
        doc.text('End Date : ' + endDate, 130, 50);

        doc.text('Customer : ' + customerData.name, 15, 60);
        doc.text('Mobile : ' + customerData.phoneNumber, 15, 70);
        doc.text(
          'Gurdian name : ' + dataReports.customerData.gardianName || '',
          200,
          50
        );
        doc.text(
          'Gurdian Type : ' + dataReports.customerData.gardianType || '',
          200,
          60
        );
        doc.text(
          'Mobile : ' + dataReports.customerData.gardianPhoneNumber || '',
          200,
          70
        );
        let pageCount = 0;

        if (dataReports.data.length === 0) {
          doc.text('There is No Data', 120, 100);
        }

        const resTabelBody: any[] = [];
        dataReports.data.forEach((elementb) => {
          resTabelBody.push([
            elementb['date'],
            elementb['slipNo'],
            elementb['particular'],
            elementb['debit'],
            elementb['credit'],
            elementb['balance'],
          ]);
        });
        autoTable(doc, {
          head: [
            ['Date', 'Slip No', 'Particular', 'Debit', 'Credit', 'Balance'],
          ],
          body: resTabelBody,
          showHead: 'firstPage', // Only show the header on the first page of the nested table
          startY: doc.getCurrentPageInfo().pageNumber == 1 ? 75 : 20,
          horizontalPageBreak: false,
          theme: 'grid',
          headStyles: {
            fillColor: [0, 72, 115],
            fontStyle: 'bold',
            halign: 'left',
          },
          bodyStyles: {
            fontStyle: 'bold',
            halign: 'left',
          },
          styles: {
            fontSize: 10,
          },
          didDrawPage: function () {
            pageCount++;
            doc.text(
              'Page ' + pageCount,
              textX,
              doc.internal.pageSize.height - 10
            );
          },
        });

        Array.from({ length: pageCount }, (x, i) => {
          doc.setPage(i + 1);
          doc.text(
            ' of ' + doc.getNumberOfPages(),
            textX + 15,
            doc.internal.pageSize.height - 10
          );
        });

        //doc.save(`customer-balance_${new Date()}.pdf`);
        // doc.autoPrint();
        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        printJs({ printable: blobUrl, type: 'pdf' });
      };

      return {
        tableData: computed(() => store.getters.customerBalanceStatmentsList),
        tableHeader,
        translate,
        print,
        totalItems: computed(
          () => store.getters.customerBalanceStatmentsListCount
        ),
        pageChanged,
        goTo,
        loading,
        can,
        searchBalance,
        onReset,
      };
    },
  });
