
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'admins-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedRole = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const adminSchema = Yup.object().shape({
        name: Yup.string()
          .required(() => translate('NAME_IS_REQUIRED_FIELD'))
          .label('Name'),
        email: Yup.string()
          .email(() => translate('EMAIL_NOT_VALID'))
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
        phone: Yup.string()
          .matches(/^\d{8}$/, {
            message: () => translate('Phone number is not valid'),
          })
          .required(() => translate('PHONE_NUMBER_IS_REQUIRED_FIELD')),
        userName: Yup.string()
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
        roleId: Yup.string()
          .notOneOf(['0'], () => translate('ROLE_IS_REQUIRED_FIELD'))
          .required(() => translate('ROLE_IS_REQUIRED_FIELD'))
          .label('Role'),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        delete values.verifyPassword;
        values.isActive = true;
        values.phone = String('965' + values.phone);

        await store.dispatch(Actions.CREATE_ADMIN_ACCOUNT, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ADMIN'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('adminUsersCreating'), [
          translate('members'),
        ]);
      });

      await store.dispatch(Actions.GET_ALL_ROLES, { limit: 50 });

      return {
        roles: computed(() => store.getters.rolesList),
        onSubmitCreate,
        submitButton,
        translate,
        adminSchema,
        goBack,
        selectedRole,
        can,
      };
    },
  });
