import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface CustomerServiceStatmentsObject {
  id: string;
  customerName: string;
  status: boolean;
  invoiceNo: number;
  customerPhoneNumber: number;
  createdAt: string;
}

export interface CustomerServiceStatmentListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  customerServiceStatments: Array<CustomerServiceStatmentsObject>;
  allCustomerService: Array<CustomerServiceStatmentsObject>;
  errors: unknown;
  serviceFilter: unknown;
}

@Module
export default class CustomerServiceStatmentModule
  extends VuexModule
  implements CustomerServiceStatmentListInfo
{
  customerServiceStatments = [] as Array<CustomerServiceStatmentsObject>;
  allCustomerService = [] as Array<CustomerServiceStatmentsObject>;

  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};
  serviceFilter = {};

  /**
   * Get customerServiceStatments list
   * @returns Array<CustomerServiceStatmentsObject>
   */
  get customerServiceStatmentsList(): Array<CustomerServiceStatmentsObject> {
    if (this.customerServiceStatments.length > 0)
      this.customerServiceStatments[0]['page'] = this.page;
    return this.customerServiceStatments;
  }

  /**
   * Get all customerServiceStatments list
   * @returns Array<CustomerServiceStatmentsObject>
   */
  get allCustomerServiceStatmentsList(): Array<CustomerServiceStatmentsObject> {
    return this.allCustomerService;
  }

  /**
   * Get total items
   * @returns number
   */
  get customerServiceStatmentsListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_FINANCE_CUSTOMER_SERVICE_STATMENT](data) {
    this.customerServiceStatments = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_FINANCE_CUSTOMER_SERVICE](data) {
    this.allCustomerService = data.data;
  }

  @Mutation
  [Mutations.SET_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_FINANCE_CUSTOMER_SERVICE_STATMENT_LIST]() {
    this.customerServiceStatments = [] as Array<CustomerServiceStatmentsObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_CUSTOMER_SERVICE_FILTER](obj) {
    this.serviceFilter = obj;
  }

  @Action
  [Actions.UPDATE_CUSTOMER_SERVICE_FILTER](val) {
    this.context.commit(Mutations.SET_CUSTOMER_SERVICE_FILTER, val);
  }
  @Action
  [Actions.RESET_ALL_FINANCE_CUSTOMER_SERVICE]() {
    this.context.commit(Mutations.SET_ALL_FINANCE_CUSTOMER_SERVICE, {
      data: [],
    });
  }

  @Action
  [Actions.RESET_FINANCE_CUSTOMER_SERVICE_STATMENT_STORE]() {
    this.context.commit(Mutations.RESET_FINANCE_CUSTOMER_SERVICE_STATMENT_LIST);
  }

  @Action
  [Actions.UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE](val) {
    this.context.commit(
      Mutations.SET_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE,
      val
    );
  }

  @Action
  [Actions.GET_FINANCE_CUSTOMER_SERVICE_STATMENT]() {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.page,
      ...this.serviceFilter,
    };
    return ApiService.query('/finance/customer-service-statment', { params })
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_FINANCE_CUSTOMER_SERVICE_STATMENT,
          data
        );
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/finance/customer-service-statment', id, data)
      .then(() => {
        this.context.commit(
          Mutations.RESET_FINANCE_CUSTOMER_SERVICE_STATMENT_LIST
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
  @Action
  [Actions.GET_ALL_FINANCE_CUSTOMER_SERVICE](params = {}) {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/finance/customer-service-statment/all', {
      params,
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_FINANCE_CUSTOMER_SERVICE, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
  @Action
  [Actions.CREATE_FINANCE_CUSTOMER_SERVICE_STATMENT](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/finance/customer-service-statment', data)
      .then(() => {
        this.context.commit(
          Mutations.RESET_FINANCE_CUSTOMER_SERVICE_STATMENT_LIST
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
}
