import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface SettingsContentPageObject {
  id: string;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
}

export interface SettingsHolidayListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  contentPages: Array<SettingsContentPageObject>;
  errors: unknown;
}

export interface contentPageFilterInfo {
  name: string;
}

@Module
export default class SettingsContentPageModule
  extends VuexModule
  implements SettingsHolidayListInfo
{
  contentPages = [] as Array<SettingsContentPageObject>;
  contentPageFilter = {} as contentPageFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get holidays list
   * @returns Array<SettingsHolidayObject>
   */
  get contentPageList(): Array<SettingsContentPageObject> {
    return this.contentPages;
  }

  @Mutation
  [Mutations.SET_SETTINGS_HOLIDAYS](data) {
    this.contentPages = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SETTING_HOLIDAY_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_CONTENT_PAGES_LIST]() {
    this.contentPages = [] as Array<SettingsContentPageObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_CONTENT_PAGE_FILTER](obj) {
    this.contentPageFilter.name = obj.name;
  }

  @Action
  [Actions.RESET_SETTINGS_HOLIDAYS_STORE]() {
    this.context.commit(Mutations.RESET_CONTENT_PAGES_LIST);
  }

  @Action
  [Actions.UPDATE_CONTENT_PAGE_FILTER](val) {
    this.context.commit(Mutations.SET_CONTENT_PAGE_FILTER, val);
  }

  @Action
  [Actions.CREATE_CONTENT_PAGES](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/settings/content-pages', data)
      .then(() => {
        this.context.commit(Mutations.RESET_CONTENT_PAGES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_CONTENT_PAGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/settings/content-pages', id)
      .then(() => {
        this.context.commit(Mutations.RESET_CONTENT_PAGES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SETTINGS_HOLIDAYS_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_SETTING_HOLIDAYS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.UPDATE_CONTENT_PAGE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/content-pages', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_CONTENT_PAGES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.GET_CONTENT_PAGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/content-pages', id);
  }

  @Action
  [Actions.GET_CONTENT_PAGES](lookupNameId) {
    const params = {
      page: this.page,
      title: this.contentPageFilter.name,
      lookupNameId,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/settings/content-pages', { params })
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.SET_SETTINGS_HOLIDAYS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
