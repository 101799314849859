
  import { computed, defineComponent, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n/index';
  import store from '@/store/';
  import Swal from 'sweetalert2/dist/sweetalert2.min.js';
  import * as Yup from 'yup';
  import { getUser } from '@/core/services/AuthService';

  export default defineComponent({
    name: 'sign-in',
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    beforeRouteEnter(to, from, next) {
      const user = getUser();
      if (store.getters.isUserAuthenticated) {
        if (user?.userType === 'trainer') {
          next({ name: 'dashboard-trainer' });
        } else {
          next({ name: 'groups-listing' });
        }
      } else {
        next();
      }
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { t, te, locale } = useI18n();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const pathRoute = ref<string>(route.path);
      const email = ref<string>('');
      const response = ref();
      const password = ref('');

      const userType =
        (pathRoute.value === '/sign-in' && 'admin') ||
        (pathRoute.value === '/sign-in-trainer' && 'trainer');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const changeLang = () => {
        // store.dispatch(Actions.CHANGE_LOCALE, 'ar');
        const switchTo = locale.value === 'ar' ? 'en' : 'ar';
        locale.value = switchTo;
        // eslint-disable-next-line
        // @ts-ignore
        document.getElementById('appStyle')?.href =
          switchTo === 'ar' ? '/css/style.rtl.css' : '/css/style.css';
        localStorage.setItem('lang', switchTo);
        if (switchTo === 'ar') {
          document.documentElement.setAttribute('lang', 'ar');
          document.documentElement.setAttribute('dir', 'rtl');
          document.documentElement.setAttribute('direction', 'rtl');
          document.documentElement.setAttribute('style', 'direction: rtl;');
        } else {
          document.documentElement.setAttribute('lang', 'en');
          document.documentElement.removeAttribute('dir');
          document.documentElement.removeAttribute('direction');
          document.documentElement.removeAttribute('style');
        }
      };

      //Create form validation object
      const loginSchema = Yup.object().shape({
        email: Yup.lazy(() => {
          if (pathRoute.value != '/reset-password') {
            return Yup.string()
              .email(() => translate('EMAIL_NOT_VALID'))
              .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
              .label('Email');
          }
          return Yup.string().label('Email');
        }),
        password: Yup.lazy(() => {
          if (pathRoute.value != '/reset-password') {
            return Yup.string()
              .required(() => translate('PASSWORD_IS_REQUIRED_FIELD'))
              .label('Email');
          }
          return Yup.string().label('Password');
        }),
        resetPassword: Yup.lazy(() => {
          if (pathRoute.value === '/reset-password') {
            return Yup.string().required(() =>
              translate('PASSWORD_IS_REQUIRED_FIELD')
            );
          }
          return Yup.string();
        }),
        confirmPassword: Yup.lazy(() => {
          if (pathRoute.value === '/reset-password') {
            return Yup.string()
              .required(() => translate('CONFIRM_PASSWORD_IS_REQUIRED_FIELD'))
              .oneOf([Yup.ref('resetPassword'), null], () =>
                translate('PASSWORDS_DO_NOT_MATCH')
              )
              .label('Confirm Password');
          }
          return Yup.string();
        }),
      });

      //Form submit function
      const onSubmitLogin = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          // Activate indicator
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        let pageToGo = '';

        // Send login request
        if (userType === 'admin') {
          pageToGo = 'groups-listing';
          email.value = values.email;
          const firstLogin = await store.dispatch(Actions.LOGIN, values);
          response.value = firstLogin;
        } else if (userType === 'trainer') {
          pageToGo = 'dashboard-trainer';
          email.value = values.email;
          const firstLogin = await store.dispatch(
            Actions.LOGIN_TRAINER,
            values
          );
          response.value = firstLogin;
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        }

        if (!error && !response.value.isFirstLogin) {
          return router.push({ name: pageToGo });
        } else if (!error) {
          pathRoute.value = '/reset-password';
          router.push({ path: pathRoute.value });
        }

        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };
      const signInTitle = computed(() => {
        if (userType === 'admin') {
          return translate('signInTitle');
        }
        if (userType === 'trainer') {
          return translate('signInTrainer');
        }
        return translate('signInTitle');
      });

      return {
        onSubmitLogin,
        loginSchema,
        password,
        email,
        pathRoute,
        submitButton,
        changeLang,
        translate,
        signInTitle,
      };
    },
  });
