import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface OfferObject {
  nameEn: string;
  nameAe: string;
  phone: string;
  latitude: string;
  longitude: string;
  id: string;
}

export interface OfferListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  offer: Array<OfferObject>;
  errors: unknown;
}

export interface OfferFilterInfo {
  title: string;
}

@Module
export default class OfferModule extends VuexModule implements OfferListInfo {
  offer = [] as Array<OfferObject>;
  allOffer = [] as Array<OfferObject>;
  offerFilter = {} as OfferFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get offer list
   * @returns Array<OfferObject>
   */
  get offerList(): Array<OfferObject> {
    return this.offer;
  }

  get allOfferList(): Array<OfferObject> {
    return this.allOffer;
  }

  /**
   * Get total items
   * @returns number
   */
  get offerListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_OFFERS](data) {
    this.offer = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_OFFERS](data) {
    this.allOffer = data.data;
  }

  @Mutation
  [Mutations.SET_OFFER_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_OFFER_LIST]() {
    this.offer = [] as Array<OfferObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_OFFER_FILTER](obj) {
    this.offerFilter.title = obj.title;
  }

  @Action
  [Actions.RESET_OFFER_STORE]() {
    this.context.commit(Mutations.RESET_OFFER_LIST);
  }

  @Action
  [Actions.UPDATE_OFFER_FILTER](val) {
    console.log(val);
    this.context.commit(Mutations.SET_OFFER_FILTER, val);
  }

  @Action
  [Actions.CREATE_OFFER](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/offer', data)
      .then((data) => {
        this.context.commit(Mutations.RESET_OFFER_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPLOAD_OFFER_ATTACHMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/offer/attachments/${id}`, data)
      .then(() => {
        this.context.commit(Mutations.RESET_GROOMERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_OFFER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/offer', id)
      .then(() => {
        this.context.commit(Mutations.RESET_OFFER_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_OFFER]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/offer', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_OFFER_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_OFFER_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_OFFER_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_OFFER](id) {
    ApiService.setAuthorizationHeader();
    console.log(id);
    return ApiService.get('/offer', id);
  }

  @Action
  [Actions.GET_OFFERS]() {
    const params = {
      page: this.page,
      title: this.offerFilter.title,
    };
    console.log(params);
    ApiService.setAuthorizationHeader();
    return ApiService.query('/offer', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_OFFERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_ALL_OFFER]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/offer/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_OFFERS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
}
