
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import Multiselect from '@vueform/multiselect';
  import SearchField from '@/components/common/SearchField.vue';
  import LookupNameIds from '@/store/enums/settingsEnums';
  export default defineComponent({
    name: 'product-creating',
    components: {
      SearchField,
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const selectedCategory = ref([]);
      const customers = ref([]);
      const selectedAudieance = ref('');
      const channelTypes = ref([]);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('Notification Creating'), [
          translate('operations'),
        ]);
      });

      watch(selectedAudieance, (newValue) => {
        if (newValue === 'Custom') {
          customers.value = [];
        }
      });

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.AUDIEANCE,
      ]);
      const customerList = await store.dispatch(Actions.GET_ALL_CUSTOMERS);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const audieanceList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.AUDIEANCE;
      });

      const validationSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('titleEn'),
        titleAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('titleEn'),
        descriptionEn: Yup.string()
          .required(() => translate('EN_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description En'),
        descriptionAr: Yup.string()
          .required(() => translate('AR_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description Ar'),
        audieance: Yup.string()
          .notOneOf(['0'], () => translate('Audience Is Required field'))
          .required(() => translate('Audience Is Required field'))
          .label('Audience'),
      });

      const shopList = await store.dispatch(Actions.GET_ALL_SHOP);
      const categoryList = await store.dispatch(Actions.GET_ALL_CATEGORY);

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          submitButton.value.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.channelTypes = channelTypes.value;

        const data = await store.dispatch(
          Actions.CREATE_NOTIFICATIONS_PARENT,
          values
        );
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          submitButton.value?.removeAttribute('data-kt-indicator');
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('Successfully Created Notification'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });

          submitButton.value?.removeAttribute('data-kt-indicator');
          submitButton.value!.disabled = false;
        }
      };

      return {
        onSubmitCreate,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        shopList,
        categoryList,
        selectedCategory,
        customerList,
        audieanceList,
        selectedAudieance,
        customers,
        channelTypes,
      };
    },
  });
