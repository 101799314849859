
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { ArenasObject } from '@/store/modules/operations/ArenasModule';

  export default defineComponent({
    name: 'arena-updating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const selectedStatuses = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('arenaUpdating'), [
          translate('operations'),
        ]);
      });

      const { data } = await store.dispatch(Actions.GET_ARENA, route.params.id);

      const arenas = ref<ArenasObject>(data.data);

      const validationSchema = Yup.object().shape({
        nameAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Name Ar'),
        nameEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('Name En'),
        statusId: Yup.string()
          .notOneOf(['0'], () => translate('STATUS_IS_REQUIRED_FIELD'))
          .required(() => translate('STATUS_IS_REQUIRED_FIELD'))
          .label('Statuses'),
      });
      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_ARENA, {
          data: values,
          id: arenas.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_ARENA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'arenas-listing' });
          });
        }
      };

      const lookups = await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.STATUS,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const statuses = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.STATUS;
      });

      return {
        onSubmitCreate,
        selectedStatuses,
        statuses,
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        arenas,
        can,
      };
    },
  });
