import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface SettingsClassCategoryObject {
  id: string;
  nameAr: string;
  nameEn: string;
  numberOfRider: string;
  duration: number;
  classCategoryArena: string[];
}

export interface SettingsClassCategoryListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  classCategory: Array<SettingsClassCategoryObject>;
  errors: unknown;
}

export interface ClassCategoryFilterInfo {
  name: string;
}

@Module
export default class SettingsClassCategoryModule
  extends VuexModule
  implements SettingsClassCategoryListInfo
{
  classCategory = [] as Array<SettingsClassCategoryObject>;
  allClassCategory = [] as Array<SettingsClassCategoryObject>;
  classCategoryFilter = {} as ClassCategoryFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get classCategory list
   * @returns Array<SettingsClassCategoryObject>
   */
  get classCategoryList(): Array<SettingsClassCategoryObject> {
    return this.classCategory;
  }

  get allClassCategoryList(): Array<SettingsClassCategoryObject> {
    return this.allClassCategory;
  }

  /**
   * Get total items
   * @returns number
   */
  get classCategoryListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_SETTINGS_CLASS_CATEGORY](data) {
    this.classCategory = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_SETTINGS_CLASS_CATEGORY](data) {
    this.allClassCategory = data.data;
  }

  @Mutation
  [Mutations.SET_SETTING_CLASS_CATEGORY_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_SETTINGS_CLASS_CATEGORY_LIST]() {
    this.classCategory = [] as Array<SettingsClassCategoryObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SETTINGS_CLASS_CATEGORY_FILTER](obj) {
    this.classCategoryFilter.name = obj.name;
  }

  @Action
  [Actions.RESET_SETTINGS_CLASS_CATEGORY_STORE]() {
    this.context.commit(Mutations.RESET_SETTINGS_CLASS_CATEGORY_LIST);
  }

  @Action
  [Actions.UPDATE_SETTINGS_CLASS_CATEGORY_FILTER](val) {
    this.context.commit(Mutations.SET_SETTINGS_CLASS_CATEGORY_FILTER, val);
  }

  @Action
  [Actions.CREATE_SETTING_CLASS_CATEGORY](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/settings/class-category', data)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_CLASS_CATEGORY_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_SETTING_CLASS_CATEGORY](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/settings/class-category', id)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_CLASS_CATEGORY_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SETTING_CLASS_CATEGORY]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/class-category', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_CLASS_CATEGORY_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SETTINGS_CLASS_CATEGORY_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_SETTING_CLASS_CATEGORY_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_SETTING_CLASS_CATEGORY](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/class-category', id);
  }

  @Action
  [Actions.GET_SETTINGS_CLASS_CATEGORY]() {
    const params = {
      page: this.page,
      name: this.classCategoryFilter.name,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/settings/class-category', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SETTINGS_CLASS_CATEGORY, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_SETTINGS_CLASS_CATEGORY]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/settings/class-category/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_SETTINGS_CLASS_CATEGORY, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
