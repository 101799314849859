
  import { defineComponent, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { useI18n } from 'vue-i18n/index';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import { convertDateDDMMYYYY } from '../../utils/dateFormat';

  export default defineComponent({
    name: 'attachment',
    props: {
      isDisabled: { type: Boolean, default: false },
      attachments: {
        type: Array,
        default: () => [],
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
        default: 'Attachment',
      },
    },

    emit: ['submit-create', 'delete'],
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const disabledBtn = ref(props.isDisabled);
      const attachmentRef = ref();
      const attachmentsData = ref<any>(props.attachments);
      const previewNewImage = ref<string[]>([]);
      const selectedImages = ref(props.attachments);
      const formKey = ref(1);

      watch(
        () => props.isDisabled,
        (newValue) => {
          disabledBtn.value = newValue;
        }
      );
      const onFileChange = (e) => {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        if (!props.multiple) {
          selectedImages.value = [];
        }
        for (let i = 0; i < files.length; i++) {
          // eslint-disable-next-line
          // @ts-ignore
          selectedImages.value.push({
            name: URL.createObjectURL(files[i]),
            id: i,
          });
        }
      };

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const attachmentSchema = Yup.object().shape({
        attachments: Yup.mixed().required('Attachment is required'),
      });

      const resetAttachment = () => {
        attachmentRef.value.reset();
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };

      const onSubmitCreateAttachment = async (values, { resetForm }) => {
        if (submitButton.value) {
          submitButton.value.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const formData = new FormData();

        // Check if it's a single file or multiple files
        if (!props.multiple) {
          // Single file, directly append it
          formData.append('attachments', values.attachments);
          previewNewImage.value.push(URL.createObjectURL(values.attachments));
        } else {
          // Multiple files, append each one
          for (let i = 0; i < values.attachments.length; i++) {
            console.log(values.attachments[i]);
            formData.append('attachments', values.attachments[i]);
            previewNewImage.value.push(
              URL.createObjectURL(values.attachments[i])
            );
          }
        }

        emit('submit-create', formData, () => {
          resetAttachment();
          resetForm({
            values: {
              attachments: null,
            },
          });
        });
      };
      const removeImage = (imageSrc) => {
        if (isNaN(imageSrc.id)) {
          emit('delete', imageSrc.id, () => {
            const index = selectedImages.value.findIndex(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (image) => image.id === imageSrc.id
            );
            if (index !== -1) {
              selectedImages.value.splice(index, 1);
            }
          });
        } else {
          const index = selectedImages.value.findIndex(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (image) => image.id === imageSrc.id
          );
          if (index !== -1) {
            selectedImages.value.splice(index, 1);
            formKey.value++;
          }
        }
      };

      return {
        submitButton,
        translate,
        can,
        attachmentSchema,
        disabledBtn,
        onSubmitCreateAttachment,
        attachmentRef,
        resetAttachment,
        attachmentsData,
        convertDateDDMMYYYY,
        previewNewImage,
        removeImage,
        selectedImages,
        onFileChange,
        formKey,
      };
    },
  });
