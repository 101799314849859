
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default defineComponent({
    name: 'services-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const searchItems = async (inputText) => {
        await store.dispatch(Actions.UPDATE_SERVICES_FILTER, {
          title: inputText,
        });
        await store.dispatch(Actions.GET_SERVICES);
      };

      onMounted(async () => {
        console.log('mounted');
        setCurrentPageBreadcrumbs(translate('Services Listing'), [
          translate('settings'),
        ]);

        loading.value = true;
        await store.dispatch(Actions.GET_SERVICES);
        loading.value = false;
      });
      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_SERVICES_STORE);
      });
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('English title'),
          key: 'titleEn',
          sortable: true,
        },
        {
          name: translate('Arabic title'),
          key: 'titleAr',
          sortable: true,
        },
        {
          name: translate('Service name'),
          key: 'serviceName',
          sortable: true,
        },
        {
          name: translate('price before discount'),
          key: 'priceBeforeDiscount',
          sortable: true,
        },
        {
          name: translate('price'),
          key: 'price',
          sortable: true,
        },
        {
          name: translate('discount'),
          key: 'discount',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const deleteService = async (service) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_SERVICE, service.id);
          await store.dispatch(Actions.GET_SERVICES);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };
      const toggleService = async (service) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          const payload = {
            status: !service.status,
          };
          await store.dispatch(Actions.UPDATE_SERVICE, {
            data: payload,
            id: service.id,
          });
          await store.dispatch(Actions.GET_SERVICES);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editService = (id) => {
        router.push({ name: 'service-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_SERVICES_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_SERVICES);
        loading.value = false;
      };

      return {
        tableData: computed(() => store.getters.serviceList),
        tableHeader,
        inputText: '',
        searchItems,
        deleteService,
        toggleService,
        translate,
        totalItems: computed(() => store.getters.serviceListCount),
        pageChanged,
        goTo,
        editService,
        loading,
        can,
      };
    },
  });
