import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface PermissionObject {
  id: number;
  resource: string;
  groupId: number;
  description: string;
  method: number;
}

export interface Permissions {
  [key: string]: Array<PermissionObject>;
}

export interface PermissionsListInfo {
  permissions: Permissions;
  errors: unknown;
}

export interface AllPermissions {
  title: string;
}

@Module
export default class PermissionsModule
  extends VuexModule
  implements PermissionsListInfo
{
  permissions = {} as Permissions;
  allPermissions = [] as Array<AllPermissions>;
  errors = {};

  /**
   * Get permissions list
   * @returns Permissions
   */
  get permissionsList(): Permissions {
    return this.permissions;
  }

  get allpermissionsList(): Array<AllPermissions> {
    return this.allPermissions;
  }

  @Mutation
  [Mutations.SET_PERMISSIONS](data) {
    this.permissions = data.data;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_PERMISSIONS](data) {
    this.allPermissions = data.data;
  }

  @Mutation
  [Mutations.RESET_PERMISSIONS]() {
    this.permissions = {} as Permissions;
    this.errors = {};
  }

  @Action
  [Actions.GET_PERMISSIONS](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/permissions/all/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PERMISSIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_PERMISSIONS](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/permissions-resource/all')
      .then(({ data }) => {
        data.data = data.data.map((i) => {
          return i.title;
        });
        this.context.commit(Mutations.SET_ALL_PERMISSIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
