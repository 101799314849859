
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import printJs from 'print-js';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { injectSearch } from '@/core/plugins/search-service';
  import SearchField from '@/components/common/SearchField.vue';
  import { loadImage } from '../../../utils/loadImage';
  import moment from 'moment';

  export default defineComponent({
    name: 'Horse Tag',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const loadingPage = ref(true);
      const searchService = injectSearch();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const reportData = ref();
      const selectedHorse = ref<number>(0);
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('Horse Tag'), [
          translate('reports'),
        ]);
        await store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        const getSaveData = searchService.getSearchValue('horseTagReport');
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(searchService.getSearchValue('horseTagReport'))
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.horseId) selectedHorse.value = dataSaved.horseId;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });

      const clearSearch = () => {
        searchService.removeSearchValue('horseTagReport');
        selectedHorse.value = 0;
      };

      const print = async (values) => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
        const user = getUser();
        const userName = user ? user['name'] : '';

        const treatmentData = await store.dispatch(
          Actions.GET_ALL_HORSES_TAGS,
          values.horseId === 0 ? '' : values.horseId
        );

        reportData.value = treatmentData.data;

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'portrait',
        });

        const logoImage: any = await loadImage('/media/logos/krc.jpg');

        let pageCount = 1;
        const horsesPerPage = 3;
        const topPadding = 20;
        const bottomPadding = 20; // Added bottom padding
        const boxHeight = 60;
        const boxWidth = 120;
        const imageHeight = 50;
        const imageWidth = 60;
        const verticalSpacing = 10; // Padding between boxes
        const imagePadding = 0; // Padding between image and box
        const imageYPosition = 20; // Fixed position for the image on each page

        reportData.value.forEach((element, index) => {
          if (index % horsesPerPage === 0) {
            if (index !== 0) {
              doc.addPage();
              pageCount++;
            }
            // Add the image at the top of the page
            const imageX = (doc.internal.pageSize.getWidth() - imageWidth) / 2;
            doc.addImage(
              logoImage,
              'png',
              imageX,
              imageYPosition,
              imageWidth,
              imageHeight
            );
          }

          const centerX = doc.internal.pageSize.getWidth() / 2;
          const availableHeight =
            doc.internal.pageSize.height -
            topPadding -
            bottomPadding -
            imageHeight -
            imagePadding;
          let boxVerticalPosition =
            (index % horsesPerPage) * (boxHeight + verticalSpacing);

          // Check if the box fits within the available height
          if (boxVerticalPosition + boxHeight > availableHeight) {
            doc.addPage();
            pageCount++;
            // Recalculate positions after adding a new page
            boxVerticalPosition = 0;
            // Add the image at the top of the new page
            const imageX = (doc.internal.pageSize.getWidth() - imageWidth) / 2;
            doc.addImage(
              logoImage,
              'png',
              imageX,
              imageYPosition,
              imageWidth,
              imageHeight
            );
          }

          const centerY =
            topPadding +
            imageHeight +
            imagePadding +
            boxVerticalPosition +
            boxHeight / 2;

          // Container with border
          doc.setDrawColor(0);
          doc.setLineWidth(1);
          doc.rect(
            centerX - boxWidth / 2,
            centerY - boxHeight / 2,
            boxWidth,
            boxHeight,
            'S'
          );

          doc.setFont('helvetica', 'bold');
          doc.setFontSize(18);
          doc.text(element.name, centerX, centerY - 10, { align: 'center' });
          const textWidth = doc.getTextWidth(element.name);

          const underlineY = centerY - 8;
          doc.setLineWidth(0.8);
          doc.line(
            centerX - textWidth / 2,
            underlineY,
            centerX + textWidth / 2,
            underlineY
          );

          doc.text(
            'Date Of Birth: ' +
              moment(element.dateOfBirth).format('DD-MM-YYYY'),
            centerX,
            centerY,
            { align: 'center' }
          );

          let sire = element.sire ? element.sire : '';
          doc.text('Sire : ' + sire, centerX, centerY + 10, {
            align: 'center',
          });
          let dam = element.dam ? element.dam : '';
          doc.text('Dam : ' + dam, centerX, centerY + 20, { align: 'center' });

          if (
            (index + 1) % horsesPerPage === 0 ||
            index === reportData.value.length - 1
          ) {
            doc.setFontSize(10);
            doc.setTextColor(0);
            doc.text(
              'Page ' +
                pageCount +
                ' of ' +
                Math.ceil(reportData.value.length / horsesPerPage),
              10,
              doc.internal.pageSize.height - 10
            );
          }
        });

        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
        window.open(blobUrl, '_blank');
      };

      const validationSchema = Yup.object().shape({});
      // const onSubmitCreate = async (values) => {
      //   debugger;
      //   if (submitButton.value) {
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = true;
      //     submitButton.value.setAttribute('data-kt-indicator', 'on');
      //   }
      //   values.duration = Number(values.duration);
      //   values.numberOfRider = Number(values.numberOfRider);

      //   const { data } = await store.dispatch(
      //     Actions.GET_CUSTOMER_ATTENDANCE,
      //     values
      //   );
      //   const [errorName] = Object.keys(store.getters.getErrors);
      //   const error = store.getters.getErrors[errorName];

      //   if (error) {
      //     Swal.fire({
      //       text: translate(error[0]),
      //       icon: 'error',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('tryAgainExcl'),
      //       customClass: {
      //         confirmButton: 'btn fw-bold btn-light-danger',
      //       },
      //     });
      //     //Deactivate indicator
      //     submitButton.value?.removeAttribute('data-kt-indicator');
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = false;
      //   } else {
      //     Swal.fire({
      //       text: translate('SUCCESSFULLY_CREATED_CLASS_CATEGORY'),
      //       icon: 'success',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('ok'),
      //       customClass: {
      //         confirmButton: 'btn btn-light-primary',
      //       },
      //     }).then(() => {
      //       router.push({ name: 'class-category-listing' });
      //     });
      //   }
      // };

      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        reportData,
        horses: computed(() => store.getters.allHorsesList),
        groomers: computed(() => store.getters.allGroomerList),
        selectedHorse,
        loadingPage,
        clearSearch,
      };
    },
  });
