import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface IGroomer {
  id: number;
  name: string;
  phone: string;
  email: string;
  address: string;
  dateOfBirth: string;
  passportNumber: string;
  civilId: string;
  residencyNumber: string;
  classLimityPerDay: number;
  nationalityId: string;
  genderId: string;
  statusId: string;
  createdAt: string;
  updatedAt: string;
  statusName: string;
  nationalityName: string;
  genderName: string;
}

export interface GroomerListInfo {
  groomers: Array<IGroomer>;
  allGroomers: Array<IGroomer>;
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  errors: unknown;
}

@Module
export default class GroomersModule
  extends VuexModule
  implements GroomerListInfo
{
  groomers = [] as Array<IGroomer>;
  allGroomers = [] as Array<IGroomer>;
  groomerFilter = {} as { statusId: string; groomerId };

  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get groomer list
   * @returns Array<IGroomer>
   */
  get allGroomerList(): Array<IGroomer> {
    return this.allGroomers;
  }
  /**
   * Get admins list
   * @returns Array<AdminUser>
   */
  get groomerList(): Array<IGroomer> {
    return this.groomers;
  }

  /**
   * Get total items
   * @returns number
   */
  get groomerlistCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_GROOMERS_OPERATIONS](data) {
    this.groomers = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_ALL_GROOMERS_OPERATIONS](data) {
    this.allGroomers = data.data;
  }

  @Mutation
  [Mutations.SET_GROOMER_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_GROOMERS_LIST]() {
    this.groomers = [] as Array<IGroomer>;
    this.groomerFilter = {} as { statusId: string; groomerId };
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_GROOMER_FILTER](obj) {
    this.groomerFilter.statusId = obj.statusId;
    this.groomerFilter.groomerId = obj.groomerId;
  }

  @Action
  [Actions.UPDATE_GROOMER_FILTER](val) {
    this.context.commit(Mutations.SET_GROOMER_FILTER, val);
  }

  @Action
  [Actions.RESET_GROOMER_STORE]() {
    this.context.commit(Mutations.RESET_GROOMERS_LIST);
  }

  @Action
  [Actions.DELETE_GROOMER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('groomer', id)
      .then(() => {
        this.context.commit(Mutations.RESET_GROOMERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_GROOMER]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('groomer', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_GROOMERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_GROOMER_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_GROOMER_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_GROOMER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('groomer', id);
  }

  @Action
  [Actions.GET_GROOMERS_OPERATIONS]() {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.page,
      statusId: this.groomerFilter.statusId
        ? this.groomerFilter.statusId
        : null,
      groomerId: this.groomerFilter.groomerId
        ? this.groomerFilter.groomerId
        : null,
    };
    return ApiService.query('/groomer', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_GROOMERS_OPERATIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_GROOMERS_OPERATIONS](active = true) {
    ApiService.setAuthorizationHeader();
    const params = {
      statusId: this.groomerFilter.statusId
        ? this.groomerFilter.statusId
        : null,
      groomerId: this.groomerFilter.groomerId
        ? this.groomerFilter.groomerId
        : null,
      active: active,
    };
    return ApiService.query('/groomer/all', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_GROOMERS_OPERATIONS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.CREATE_GROOMERS_OPERATIONS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/groomer', data)
      .then((data) => {
        this.context.commit(Mutations.RESET_GROOMERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
  @Action
  [Actions.UPLOAD_GROOMER_ATTACHMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/groomer/attachments/${id}`, data)
      .then(() => {
        this.context.commit(Mutations.RESET_GROOMERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_GROOMER_ATTACHMENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/groomer/attachments', id)
      .then(() => {
        this.context.commit(Mutations.RESET_GROOMERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
}
