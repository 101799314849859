
  import { defineComponent, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n/index';
  import { PermissionObject } from '@/store/modules/PermissionModule';
  import { useRoute } from 'vue-router';

  // re-produce by adding log to any relate render fn like translate
  export default defineComponent({
    name: 'permissions-table',
    emit: ['selected-perms-change'],
    props: {
      assignedPermissions: { type: Array, default: () => [] },
      groupId: { type: [Number, String], required: true },
    },
    async setup(props, { emit }) {
      const store = useStore();
      const selectedPerms = ref(props.assignedPermissions);
      const { t, te } = useI18n();
      const perms = ref<Array<PermissionObject>>([]);
      const route = useRoute();

      watch(
        () => props.groupId,
        async () => {
          await fetchPermissions();
          selectAllPerms();
        }
      );

      watch(
        () => selectedPerms.value,
        (changed) => {
          emit('selected-perms-change', changed);
        },
        { deep: true }
      );

      const translate = (text, options) => {
        if (te(text)) {
          return t(text, options);
        } else {
          return text;
        }
      };

      const fetchPermissions = async () => {
        if (props.groupId) {
          await store.dispatch(Actions.GET_PERMISSIONS, props.groupId);
          perms.value = store.getters.permissionsList;
        }
      };

      const selectAllPerms = () => {
        selectedPerms.value = [];
        for (const key of Object.keys(perms.value)) {
          selectedPerms.value.push(...perms.value[key].map((p) => p.id));
        }
      };

      const resetSelected = () => {
        selectedPerms.value = [];
      };

      const getCheckboxValue = (resource, method) => {
        return resource.find((r) => r.method === method)?.id;
      };

      await fetchPermissions();

      if (route.name === 'roles-creating') {
        selectAllPerms();
      }

      return {
        translate,
        getCheckboxValue,
        perms,
        selectedPerms,
        resetSelected,
      };
    },
  });
