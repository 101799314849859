import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface RoleObject {
  id: number;
  title: string;
  description: string;
  groupId: number;
  rolesPermission: Array<number>;
  createdBy: string;
  createdAt: Date;
}

export interface GroupObject {
  id: number;
  name: string;
  description: string;
  rolesPermission: Array<number>;
  createdAt: Date;
}

export interface RolesListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  roles: Array<RoleObject>;
  errors: unknown;
}

@Module
export default class RolesModule extends VuexModule implements RolesListInfo {
  roles = [] as Array<RoleObject>;
  groups = [] as Array<GroupObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get roles list
   * @returns Array<RoleObject>
   */
  get rolesList(): Array<RoleObject> {
    return this.roles;
  }

  /**
   * Get groups list
   * @returns Array<GroupObject>
   */
  get rolesGroupsList(): Array<GroupObject> {
    return this.groups;
  }

  /**
   * Get total items
   * @returns number
   */
  get rolesListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_ROLES](data) {
    this.roles = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ROLES_GROUPS](data) {
    this.groups = data.data;
  }

  @Mutation
  [Mutations.SET_ROLE_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_ROLES_LIST]() {
    this.roles = [] as Array<RoleObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_ROLES_STORE]() {
    this.context.commit(Mutations.RESET_ROLES_LIST);
  }

  @Action
  [Actions.CREATE_ROLE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/roles', data)
      .then(() => {
        this.context.commit(Mutations.RESET_ROLES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_ROLE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('roles', id)
      .then(() => {
        this.context.commit(Mutations.RESET_ROLES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_ROLE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('roles', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ROLES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_ROLE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_ROLE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_ROLE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('roles', id);
  }

  @Action
  [Actions.GET_ROLES](options: { limit?: number } = { limit: 10 }) {
    ApiService.setAuthorizationHeader();
    const params = { page: this.page, limit: options.limit };
    return ApiService.query('/roles', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.createdAt = new Date(r.createdAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_ROLES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_ROLES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/roles/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
