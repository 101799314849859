
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import printJs from 'print-js';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { injectSearch } from '@/core/plugins/search-service';
  import { loadImage } from '../../../utils/loadImage';
  import moment from 'moment';
  import SearchField from '@/components/common/SearchField.vue';

  export default defineComponent({
    name: 'arena-daily-lesson-report',
    components: {
      SearchField,
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();

      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const loadingPage = ref(true);
      const searchService = injectSearch();
      const reportData = ref();
      const selectedCustomer = ref<number>(0);
      const selectedTrainer = ref<number>(0);
      const selectedArena = ref<number>(0);

      const selectedAttendance = ref<number>(0);
      const selecteDuration = ref<number>(0);
      const selecteType = ref<number>(0);
      const selectedDay = ref<number>(0);
      const startDate = ref(new Date());
      const endDate = ref();
      const trainers = ref();
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('ArenaDailyLessonReport'), [
          translate('reports'),
        ]);
        await store.dispatch(Actions.GET_ALL_TRAINERS, {});
        trainers.value = store.getters.allTrainersList;

        store.dispatch(Actions.GET_ALL_ARENAS);

        const getSaveData = searchService.getSearchValue(
          'ArenaDailyLessonReport'
        );
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(searchService.getSearchValue('ArenaDailyLessonReport'))
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.startDate) startDate.value = dataSaved.startDate;
          if (dataSaved.endDate) endDate.value = dataSaved.endDate;
          if (dataSaved.arenaId) selectedArena.value = dataSaved.arenaId;
          if (dataSaved.trainerId) selectedTrainer.value = dataSaved.trainerId;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });
      const clearSearch = () => {
        searchService.removeSearchValue('ArenaDailyLessonReport');
        selectedTrainer.value = 0;
        selectedArena.value = 0;
        startDate.value = new Date();
        endDate.value = new Date();
      };

      const print = async (values) => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');

        const columnStyles = {};
        const startColumnIndex = 0;
        const endColumnIndex = 40;

        for (let i = startColumnIndex; i <= endColumnIndex; i++) {
          if (i > 1)
            columnStyles[i] = {
              halign: 'left',
              cellWidth: 8.1,
              overflow: 'auto',
            };
          else
            columnStyles[i] = {
              halign: 'left',
              fontSize: 6.5,
              cellWidth: 11,
              overflow: 'hidden',
            };
        }
        columnStyles[0] = {
          halign: 'left',
          cellWidth: 9,
          overflow: 'hidden',
        };
        columnStyles[1] = {
          halign: 'left',
          cellWidth: 19,
          overflow: 'hidden',
        };
        columnStyles[0] = {
          halign: 'left',
          cellWidth: 9,
          overflow: 'hidden',
        };

        if (values.startDate) {
          values.endDate = values.startDate;
        }
        values.startTime = '6';
        values.endTime = '14';
        searchService.setSearchValue('ArenaDailyLessonReport', values);

        const user = getUser();
        const userName = user ? user['name'] : '';

        await store.dispatch(Actions.GET_ARENA_DAILY_LESSON, values);

        reportData.value = store.getters.getArenaDailyLesson;

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'landscape',
        });
        const fontSize = doc.getFontSize();

        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;

        const dataLength = reportData.value.data.data.length;
        if (dataLength === 0) {
          doc.text('No Result Found', 120, 70);
        } else {
          let pageCount = 0;

          let head = [
            [
              {
                content: 'Sr.',
                colSpan: 0,
                styles: { halign: 'center' },
              },
              {
                content: 'Arena',
                colSpan: 0,
                styles: { halign: 'center' },
              },
              {
                content: '07:00 AM',
                colSpan: 4,
                styles: { halign: 'center' },
              },
              {
                content: '08:00 AM',
                colSpan: 4,
                styles: { halign: 'center' },
              },
              {
                content: '09:00 AM',
                colSpan: 4,
                styles: { halign: 'center' },
              },
              {
                content: '10:00 AM',
                colSpan: 4,
                styles: { halign: 'center' },
              },
              {
                content: '11:00 AM',
                colSpan: 4,
                styles: { halign: 'center' },
              },
              {
                content: '12:00 AM',
                colSpan: 4,
                styles: { halign: 'center' },
              },
              {
                content: '1:00 PM',
                colSpan: 4,
                styles: { halign: 'center' },
              },
              {
                content: '2:00 PM',
                colSpan: 4,
                styles: { halign: 'center' },
              },
            ],
            [
              '',
              '',
              '15',
              '30',
              '45',
              '60',
              '15',
              '30',
              '45',
              '60',
              '15',
              '30',
              '45',
              '60',
              '15',
              '30',
              '45',
              '60',
              '15',
              '30',
              '45',
              '60',
              '15',
              '30',
              '45',
              '60',
              '15',
              '30',
              '45',
              '60',
              '15',
              '30',
              '45',
              '60',
              '15',
              '30',
              '45',
              '60',
            ],
          ];

          let timeSlots = [
            { h: '07', t: '15' },
            { h: '07', t: '30' },
            { h: '07', t: '45' },
            { h: '07', t: '60' },
            { h: '08', t: '15' },
            { h: '08', t: '30' },
            { h: '08', t: '45' },
            { h: '08', t: '60' },
            { h: '09', t: '15' },
            { h: '09', t: '30' },
            { h: '09', t: '45' },
            { h: '09', t: '60' },
            { h: '10', t: '15' },
            { h: '10', t: '30' },
            { h: '10', t: '45' },
            { h: '10', t: '60' },
            { h: '11', t: '15' },
            { h: '11', t: '30' },
            { h: '11', t: '45' },
            { h: '11', t: '60' },
            { h: '12', t: '15' },
            { h: '12', t: '30' },
            { h: '12', t: '45' },
            { h: '12', t: '60' },
            { h: '13', t: '15' },
            { h: '13', t: '30' },
            { h: '13', t: '45' },
            { h: '13', t: '60' },
            { h: '14', t: '15' },
            { h: '14', t: '30' },
            { h: '14', t: '45' },
            { h: '14', t: '60' },
          ];

          const resTabelBody: any[] = [];
          reportData.value.data.data.forEach((elementb, index) => {
            let stylesArray = timeSlots.map(createTimeSlot);
            let stylesArrayCopy = JSON.parse(JSON.stringify(stylesArray));
            elementb.lessons.forEach((lesson) =>
              findAndFillContent(stylesArrayCopy, lesson)
            );
            resTabelBody.push([index + 1, elementb.name, ...stylesArrayCopy]);
          });

          autoTable(doc, {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            head: head,
            body: resTabelBody,
            startY: 50,
            // tableWidth: 'wrap',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            margin: { horizontal: 8, top: 42, bottom: 45 },

            // pageBreak: 'auto',
            // rowPageBreak: 'avoid',
            horizontalPageBreak: false,
            theme: 'grid',
            headStyles: {
              cellWidth: 'wrap',
              fillColor: [194, 224, 255],
              textColor: [0, 0, 0],
              halign: 'left',
              lineColor: [0, 0, 0],
              lineWidth: 0.1,
              fontSize: 7.8,
            },
            bodyStyles: {
              halign: 'left',
              cellWidth: 'wrap',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              minCellWidth: 'wrap',
              textColor: [0, 0, 0],
              lineColor: [0, 0, 0],
            },

            styles: {
              fontSize: 8.5,
            },
            columnStyles: columnStyles,
            didDrawPage: function () {
              doc.setFontSize(10);
              pageCount++;
              doc.text(
                'Page ' + pageCount,
                textX,
                doc.internal.pageSize.height - 9
              );
              doc.setFontSize(fontSize);
            },
          });
          // });
          const logoImage = await loadImage('/media/logos/empty-krc.png');

          Array.from({ length: pageCount }, (x, i) => {
            const pageSize = doc.internal.pageSize;
            const pageWidth = pageSize.width;
            const pageHeight = pageSize.height;

            // Set the position and size of the rectangle
            const rectWidth = 6;
            const rectHeight = 3;

            const borderBoxWidth = pageWidth - 40; // Adjust the width of the border box
            const borderBoxHeight = 30; // Adjust the height of the border box
            const borderBoxX = 20; // Adjust the X position of the border box
            const borderBoxY = pageHeight - 45; // Adjust the Y position of the border box

            const legendsPerRow = 5;
            const legendSpacing = 5; // Adjust spacing between legends
            const horizontalLegendSpacing = 30; // Increase horizontal spacing

            let currentLegendY = borderBoxY; // Initial Y position

            doc.setFontSize(10);

            reportData.value.data.trainers.forEach((legend, index) => {
              const { shortName, color, name } = legend;

              // Calculate the position for each legend within the row
              const rowIndex = Math.floor(index / legendsPerRow);
              const colIndex = index % legendsPerRow;

              const rectX =
                borderBoxX +
                colIndex * (rectWidth + horizontalLegendSpacing) +
                colIndex * legendSpacing;
              const rectY =
                currentLegendY + rowIndex * (rectHeight + legendSpacing);

              if (!/^#([0-9A-Fa-f]{3}){1,2}$/.test(color))
                doc.setFillColor(`rgb(${color})`);
              else doc.setFillColor(color);
              doc.rect(rectX, rectY, rectWidth, rectHeight, 'F');

              // Add text inside the rectangle at the end of the page
              const textX = rectX + 0.5; // Adjust the X position as needed
              const textY = rectY + 2; // Adjust the Y position as needed

              doc.text(shortName.toLowerCase(), textX, textY);
              doc.text('    ' + name + '    ', textX + 5, textY, {
                maxWidth: 35,
              });
            });

            // Draw the border box

            doc.rect(
              borderBoxX - 2.5,
              borderBoxY - 2.5,
              borderBoxWidth + 5,
              borderBoxHeight + 5
            );
            doc.setFontSize(10);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            doc.addImage(logoImage, 'png', 5, 3, 35, 35);
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('Kuwait Riding Center', textX, 15);
            doc.setFontSize(12);
            doc.text('Arena Timeline Report - Day', textX, 22);

            doc.text(
              'Start Date: ' +
                new Date(values.startDate).toLocaleDateString('en-GB'),
              textX - 35,
              40
            );
            doc.text(
              'End Date: ' +
                new Date(values.startDate).toLocaleDateString('en-GB'),
              textX + 35,
              40
            );

            doc.setFontSize(10);
            doc.text(
              'Print DateTime: ' + moment(new Date()).format('LLL'),
              5,
              8
            );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            doc.setPage(i + 1);
            doc.text(userName, 5, doc.internal.pageSize.height - 10);

            doc.text(
              '   of ' + doc.getNumberOfPages(),
              textX + 10,
              doc.internal.pageSize.height - 9
            );
            doc.setFontSize(fontSize);
          });
        }
        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');

        window.open(blobUrl, '_blank');

        // printJs({ printable: blobUrl, type: 'pdf' });
      };

      const validationSchema = Yup.object().shape({});
      // const onSubmitCreate = async (values) => {
      //   debugger;
      //   if (submitButton.value) {
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = true;
      //     submitButton.value.setAttribute('data-kt-indicator', 'on');
      //   }
      //   values.duration = Number(values.duration);
      //   values.numberOfRider = Number(values.numberOfRider);

      //   const { data } = await store.dispatch(
      //     Actions.GET_CUSTOMER_ATTENDANCE,
      //     values
      //   );
      //   const [errorName] = Object.keys(store.getters.getErrors);
      //   const error = store.getters.getErrors[errorName];

      //   if (error) {
      //     Swal.fire({
      //       text: translate(error[0]),
      //       icon: 'error',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('tryAgainExcl'),
      //       customClass: {
      //         confirmButton: 'btn fw-bold btn-light-danger',
      //       },
      //     });
      //     //Deactivate indicator
      //     submitButton.value?.removeAttribute('data-kt-indicator');
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = false;
      //   } else {
      //     Swal.fire({
      //       text: translate('SUCCESSFULLY_CREATED_CLASS_CATEGORY'),
      //       icon: 'success',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('ok'),
      //       customClass: {
      //         confirmButton: 'btn btn-light-primary',
      //       },
      //     }).then(() => {
      //       router.push({ name: 'class-category-listing' });
      //     });
      //   }
      // };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.DAYS,
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.DURATION,
        LookupNameIds.STATUS,
        LookupNameIds.ATTENDEND,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.ATTENDEND;
      });

      const timeToMinutes = (timeString) => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return hours * 60 + minutes;
      };

      // const categoryList = await store.dispatch(
      //   Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      // );
      const classType = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
      });

      const dayList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DAYS;
      });

      const durationList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DURATION;
      });
      const createTimeSlot = (slot) => {
        return {
          h: slot.h,
          t: slot.t,
          content: '',
          styles: {
            fillColor: '#ffffff',
          },
        };
      };

      const findAndFillContent = (timeSlots, lesson) => {
        const lessonStartHour = parseInt(lesson.time.startTime.slice(0, 2));
        const lessonStartMinute = parseInt(lesson.time.startTime.slice(3, 5));
        const lessonEndHour = parseInt(lesson.time.endTime.slice(0, 2));
        const lessonEndMinute = parseInt(lesson.time.endTime.slice(3, 5));

        for (let timeSlot of timeSlots) {
          const slotHour = parseInt(timeSlot.h);
          const slotMinute = parseInt(timeSlot.t);

          if (
            (slotHour > lessonStartHour ||
              (slotHour == lessonStartHour &&
                slotMinute > lessonStartMinute)) && // Changed from >= to >
            (slotHour < lessonEndHour ||
              (slotHour == lessonEndHour && slotMinute <= lessonEndMinute))
          ) {
            timeSlot.content = lesson.trainerShortName
              .slice(0, 3)
              .toLowerCase();
            timeSlot.styles.fillColor = !/^#([0-9A-Fa-f]{3}){1,2}$/.test(
              lesson.trainerColor
            )
              ? `rgb(${lesson.trainerColor})`
              : lesson.trainerColor;
          }
        }
      };

      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        reportData,
        customers: computed(() => store.getters.allCustomersList),
        trainers,
        arenas: computed(() => store.getters.allArenasList),
        statusList,
        classType,
        // categoryList,
        dayList,
        durationList,
        selectedCustomer,
        selectedTrainer,
        selectedArena,
        selecteDuration,
        selectedAttendance,
        selecteType,
        selectedDay,
        startDate,
        endDate,
        loadingPage,
        clearSearch,
      };
    },
  });
