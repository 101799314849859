import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface SettingsCustomerAgeObject {
  maxAge: number;
  minAge: number;
  id: string;
}

export interface SettingsCustomerAgeListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  customerAge: Array<SettingsCustomerAgeObject>;
  errors: unknown;
}

@Module
export default class SettingsCustomerAgeModule
  extends VuexModule
  implements SettingsCustomerAgeListInfo
{
  customerAge = [] as Array<SettingsCustomerAgeObject>;
  allCustomerAge = [] as Array<SettingsCustomerAgeObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get customerAge list
   * @returns Array<SettingsCustomerAgeObject>
   */
  get customerAgeList(): Array<SettingsCustomerAgeObject> {
    return this.customerAge;
  }

  get allCustomerAgeList(): Array<SettingsCustomerAgeObject> {
    return this.allCustomerAge;
  }

  /**
   * Get total items
   * @returns number
   */
  get customerAgeListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_SETTINGS_CUSTOMER_AGE](data) {
    this.customerAge = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_SETTINGS_CUSTOMER_AGE](data) {
    this.allCustomerAge = data.data;
  }

  @Mutation
  [Mutations.SET_SETTING_CUSTOMER_AGE_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_SETTINGS_CUSTOMER_AGE_LIST]() {
    this.customerAge = [] as Array<SettingsCustomerAgeObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_SETTINGS_CUSTOMER_AGE_STORE]() {
    this.context.commit(Mutations.RESET_SETTINGS_CUSTOMER_AGE_LIST);
  }

  @Action
  [Actions.CREATE_SETTING_CUSTOMER_AGE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/settings/customer-age-group', data)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_CUSTOMER_AGE_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_SETTING_CUSTOMER_AGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/settings/customer-age-group', id)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_CUSTOMER_AGE_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SETTING_CUSTOMER_AGE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/customer-age-group', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_CUSTOMER_AGE_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SETTINGS_CUSTOMER_AGE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_SETTING_CUSTOMER_AGE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_SETTING_CUSTOMER_AGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/customer-age-group', id);
  }

  @Action
  [Actions.GET_SETTINGS_CUSTOMER_AGE]() {
    const params = {
      page: this.page,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/settings/customer-age-group', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SETTINGS_CUSTOMER_AGE, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_SETTINGS_CUSTOMER_AGE]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/settings/customer-age-group/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_SETTINGS_CUSTOMER_AGE, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
