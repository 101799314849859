enum LookupNameIds {
  GUARDIAN_TYPE = 1,
  GENDER = 2,
  HORSE_GENDER = 3,
  HORSE_BREED = 4,
  HORSE_COLOR = 5,
  HORSE_CATEGORY = 6,
  LEAVE_TYPE = 7,
  CANCEL_REASON = 8,
  NATIONALITY = 9,
  PASSPORT_TYPE = 10,
  PAYMENT_METHOD = 11,
  DOCUMENT_TYPE = 12,
  TREATMENT_TYPE = 13,
  STATUS = 14,
  COURSE_STATUS = 15,
  HORSE_TYPE = 16,
  SERVICE_TYPE = 17,
  CLASS_TYPE = 18,
  DAYS = 19,
  CUSTOMER_TYPE = 20,
  DURATION = 21,
  ATTENDEND = 22,
  PAYMENT_INDICATOR = 23,
  JOB_TITLE = 24,
  ABOUT_US = 25,
  TERMS_OF_USE = 26,
  PRIVACY = 27,
  RULES_REQULATIONS = 28,
  COMPLAINTS = 29,
  ANNOUNCEMENT_CATEGORY = 30,
  AUDIEANCE = 31,
  REQUEST_LESSON_STATUS = 32,
  QUESTION_TYPE = 33,
}

export default LookupNameIds;
