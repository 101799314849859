import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface SettingsHolidayObject {
  id: string;
  nameEn: string;
  nameAr: string;
  holidayStartDate: string;
  holidayEndDate: string;
}

export interface SettingsHolidayListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  holidays: Array<SettingsHolidayObject>;
  errors: unknown;
}

export interface holidaysFilterInfo {
  name: string;
}

@Module
export default class SettingsHolidayModule
  extends VuexModule
  implements SettingsHolidayListInfo
{
  holidays = [] as Array<SettingsHolidayObject>;
  allHolidays = [] as Array<SettingsHolidayObject>;
  holidaysFilter = {} as holidaysFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get holidays list
   * @returns Array<SettingsHolidayObject>
   */
  get holidaysList(): Array<SettingsHolidayObject> {
    return this.holidays;
  }

  get allHolidaysList(): Array<SettingsHolidayObject> {
    return this.allHolidays;
  }

  /**
   * Get total items
   * @returns number
   */
  get holidaysListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_SETTINGS_HOLIDAYS](data) {
    this.holidays = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_SETTINGS_HOLIDAYS](data) {
    this.allHolidays = data.data;
  }

  @Mutation
  [Mutations.SET_SETTING_HOLIDAY_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_SETTINGS_HOLIDAYS_LIST]() {
    this.holidays = [] as Array<SettingsHolidayObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SETTINGS_HOLIDAYS_FILTER](obj) {
    this.holidaysFilter.name = obj.name;
  }

  @Action
  [Actions.RESET_SETTINGS_HOLIDAYS_STORE]() {
    this.context.commit(Mutations.RESET_SETTINGS_HOLIDAYS_LIST);
  }

  @Action
  [Actions.UPDATE_SETTINGS_HOLIDAYS_FILTER](val) {
    this.context.commit(Mutations.SET_SETTINGS_HOLIDAYS_FILTER, val);
  }

  @Action
  [Actions.CREATE_SETTING_HOLIDAY](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/settings/holiday-type', data)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_HOLIDAYS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_SETTING_HOLIDAY](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/settings/holiday-type', id)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_HOLIDAYS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SETTING_HOLIDAY]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/holiday-type', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_HOLIDAYS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SETTINGS_HOLIDAYS_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_SETTING_HOLIDAYS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_SETTING_HOLIDAY](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/holiday-type', id);
  }

  @Action
  [Actions.GET_SETTINGS_HOLIDAY]() {
    const params = {
      page: this.page,
      name: this.holidaysFilter.name,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/settings/holiday-type', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.holidayStartDate = new Date(r.holidayStartDate)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        data.data = data.data.map((r) => {
          r.holidayEndDate = new Date(r.holidayEndDate)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_SETTINGS_HOLIDAYS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_SETTINGS_HOLIDAYS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/settings/holiday-type/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_SETTINGS_HOLIDAYS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
