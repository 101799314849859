
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import Attachment from '@/components/common/Attachments.vue';

  export default defineComponent({
    name: 'goomer-editing',
    components: {
      Attachment,
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const expiryDate = ref('');

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('groomerOperationUpdating'), [
          translate('operation'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_GROOMER,
        route.params.id
      );

      const groomer = ref(data.data);
      const attachmentsObj = groomer.value.attachments.reduce(
        (acc, attachment) => {
          const documentType = attachment.documentType;

          if (!acc[documentType]) {
            acc[documentType] = [];
          }

          acc[documentType].push(attachment);

          return acc;
        },
        {}
      );

      groomer.value.phone = groomer.value.phone.slice(3);

      const docType = ref(groomer.value?.attachments[0]?.documentTypeId || 0);

      const files = ref<{ file: File }[]>([]);
      const previewFiles = ref<string[]>([]);

      const previewImage = (event) => {
        const selectedFiles = event.target.files;
        for (let i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i];
          files.value.push({ file });
          const fileURL = URL.createObjectURL(file);
          previewFiles.value.push(fileURL);
        }
      };

      const removeExistingImage = async (index, id) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });
        if (result.value) {
          groomer.value.attachments.splice(index, 1);
          await store.dispatch(Actions.DELETE_GROOMER_ATTACHMENT, id);
        }
      };

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const HandleDateOfBirth = (dateInput) => {
        groomer.value.dateOfBirth = dateInput;
      };

      const validationSchema = Yup.object().shape({
        name: Yup.string()
          .required(() => translate('NAME_IS_REQUIRED_FIELD'))
          .label('Full Name'),
        nationalityId: Yup.string()
          .notOneOf(['0'], () => translate('NATIONALITY_IS_REQUIRED_FIELD'))
          .required(() => translate('NATIONALITY_IS_REQUIRED_FIELD'))
          .label('Nationality'),
        genderId: Yup.string()
          .notOneOf(['0'], () => translate('GENDER_IS_REQUIRED_FIELD'))
          .required(() => translate('GENDER_IS_REQUIRED_FIELD'))
          .label('Gender'),
        statusId: Yup.string()
          .notOneOf(['0'], () => translate('STATUS_IS_REQUIRED_FIELD'))
          .required(() => translate('STATUS_IS_REQUIRED_FIELD'))
          .label('Status'),
        phone: Yup.string()
          .notOneOf(['0'], () => translate('Mobile number is not valid'))
          .required(() => translate('PHONE_NUMBER_IS_REQUIRED_FIELD'))
          .matches(/^\d{8}$/, 'Mobile number is not valid')
          .label('Mobile number'),
        civilId: Yup.string()
          .notOneOf(['0'], () => translate('CIVIL_ID_IS_REQUIRED_FIELD'))
          .required(() => translate('CIVIL_ID_IS_REQUIRED_FIELD'))
          .matches(/^\d{12}$/, () => translate('CIVIL_ID_MUST_BE_12_DIGITS'))
          .label('Civil Id'),
        passportNumber: Yup.string()
          .required(() => translate('PASSPORT_NUMBER_IS_REQUIRED_FIELD'))
          .label('Passport Number'),
        address: Yup.string()
          .required(() => translate('ADDRESS_IS_REQUIRED_FIELD'))
          .label('Address'),
        dateOfBirth: Yup.string()
          .required(() => translate('DATE_IS_REQUIRED_FIELD'))
          .label('Date Of Birth'),
        email: Yup.string().email('Invalid email format').notRequired(),
      });

      const onSubmitUpdate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.phone = String('965' + values.phone);
        values.classLimityPerDay = Number(values.classLimityPerDay);
        await store.dispatch(Actions.UPDATE_GROOMER, {
          id: groomer.value?.id,
          data: values,
        });

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_GROOMER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'operation-groomers-listing' });
          });
        }
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };
      const onSubmitUpdateAttachment = async (values, cb) => {
        if (expiryDate.value) {
          values.append('expiryDate', expiryDate.value);
        }
        await store.dispatch(Actions.UPLOAD_GROOMER_ATTACHMENT, {
          data: values,
          id: groomer.value.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ATTACHMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          location.reload();
        }
        cb();
      };
      const deleteAttachment = async (id) => {
        const result = await Swal.fire({
          title: translate('You are about to delete this attachment'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          await store.dispatch(Actions.DELETE_GROOMER_ATTACHMENT, id);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            Swal.fire({
              text: translate('Successfully deleted attachment'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
            location.reload();
          }
        }
      };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.NATIONALITY,
        LookupNameIds.STATUS,
        LookupNameIds.GENDER,
        LookupNameIds.DOCUMENT_TYPE,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const nationalityList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.NATIONALITY;
      });

      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.STATUS;
      });
      const genderList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.GENDER;
      });
      const docTypeList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DOCUMENT_TYPE;
      });
      return {
        roles: computed(() => store.getters.rolesList),
        onSubmitUpdate,
        submitButton,
        translate,
        validationSchema,
        goBack,
        groomer,
        can,
        HandleDateOfBirth,
        nationalityList,
        statusList,
        genderList,
        docTypeList,
        docType,
        files,
        previewFiles,
        previewImage,
        removeExistingImage,
        expiryDate,
        attachmentsObj,
        onSubmitUpdateAttachment,
        deleteAttachment,
      };
    },
  });
