import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface SettingsLookupObject {
  id: string;
  nameEn: string;
  nameAr: string;
}

export interface SettingsLookupListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  lookups: Array<SettingsLookupObject>;
  errors: unknown;
}

export interface lookupFilterInfo {
  name: string;
}

@Module
export default class SettingsLookupModule
  extends VuexModule
  implements SettingsLookupListInfo
{
  lookups = [] as Array<SettingsLookupObject>;
  allLookups = [] as Array<SettingsLookupObject>;
  lookupFilter = {} as lookupFilterInfo;
  exportLookup = [] as Array<SettingsLookupObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get lookups list
   * @returns Array<SettingsLookupObject>
   */
  get lookupsList(): Array<SettingsLookupObject> {
    return this.lookups;
  }

  get allLookupsList(): Array<SettingsLookupObject> {
    return this.allLookups;
  }

  get exportLookupList(): Array<SettingsLookupObject> {
    return this.exportLookup;
  }

  /**
   * Get total items
   * @returns number
   */
  get lookupsListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_SETTINGS_LOOKUPS](data) {
    this.lookups = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_EXPORT_LOOKUPS](data) {
    this.exportLookup = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_SETTINGS_LOOKUPS](data) {
    this.allLookups = data.data;
  }

  @Mutation
  [Mutations.SET_SETTING_LOOKUPS_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_SETTINGS_LOOKUPS_LIST]() {
    this.lookups = [] as Array<SettingsLookupObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SETTINGS_LOOKUPS_FILTER](obj) {
    this.lookupFilter.name = obj.name;
  }

  @Action
  [Actions.RESET_SETTINGS_LOOKUPS_STORE]() {
    this.context.commit(Mutations.RESET_SETTINGS_LOOKUPS_LIST);
  }

  @Action
  [Actions.UPDATE_SETTINGS_LOOKUPS_FILTER](val) {
    this.context.commit(Mutations.SET_SETTINGS_LOOKUPS_FILTER, val);
  }

  @Action
  [Actions.CREATE_SETTING_LOOKUP](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/settings/lookups', data)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_LOOKUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_SETTING_LOOKUP](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/settings/lookups', id)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_LOOKUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SETTING_LOOKUP]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/lookups', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_SETTINGS_LOOKUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SETTING_LOOKUPS_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_SETTING_LOOKUPS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_SETTING_LOOKUP](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/lookups', id);
  }

  @Action
  [Actions.GET_SETTINGS_LOOKUPS](lookupNameIds) {
    const params = {
      page: this.page,
      name: this.lookupFilter.name,
      lookupNameId: lookupNameIds,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/settings/lookups', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SETTINGS_LOOKUPS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_SETTINGS_LOOKUPS](lookupNameIds) {
    const params = {
      page: this.page,
      name: this.lookupFilter.name,
    };

    ApiService.setAuthorizationHeader();
    let lookUpQuery = '';
    lookupNameIds.map((lookupId, i) => {
      if (i !== 0) {
        lookUpQuery += `&lookupNameId=${lookupId}`;
      } else {
        lookUpQuery += `lookupNameId=${lookupId}`;
      }
    });
    return ApiService.query(`/settings/lookups/all?${lookUpQuery}`, { params })

      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_SETTINGS_LOOKUPS, data);

        return data.data;
      })

      .catch(({ response }) => {
        console.error(response);

        // this.context.commit(Mutations.SET_ERROR, {

        //   [response.statusText]: [response.data.statusText],
      });
  }

  @Action
  [Actions.EXPORT_LOOKUPS](lookupNameIds) {
    const params = {
      page: this.page,
      name: this.lookupFilter.name,
      lookupNameId: lookupNameIds,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/settings/lookups/all`, { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EXPORT_LOOKUPS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
