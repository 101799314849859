<template>
  <div>
    <div class="horse-tag" :id="`horse-tag_${horse.id}`">
      <img class="my-4 horse-tag-image" src="/media/logos/krc.jpg" />
      <div
        class="horse-wrapper d-flex align-items-center justify-content-center flex-column"
      >
        <div class="horse-box">
          <h4 class="horse-name text-center">{{ horse.nameEn }}</h4>
          <div
            class="content d-flex align-items-center justify-content-center flex-column"
          >
            <p>Date of Birth :{{ horse.dateOfBirth.slice(0, 10) }}</p>
            <p>Sire: {{ horse.sire }}</p>
            <p>Dam : {{ horse.dam }}</p>
          </div>
        </div>
      </div>
    </div>
    <img
      @click="convertToPdf"
      src="/media/icons/duotune/files/fil017.svg"
      style="cursor: pointer"
      title="Click to print horse tag"
    />
  </div>
</template>

<script setup>
  import html2pdf from 'html2pdf.js';
  import { onMounted, onUnmounted, defineProps } from 'vue';
  import { IHorses } from '@/store/modules/operations/HorsesModule';
  import { convertDateDDMMYYYY } from '@/utils/dateFormat';

  const props = defineProps({
    horse: IHorses,
  });

  onMounted(() => {
    const element = document.getElementById(`horse-tag_${props.horse.id}`);
    document.documentElement.appendChild(element);
  });

  onUnmounted(() => {
    const element = document.getElementById(`horse-tag_${props.horse.id}`);
    if (element) {
      element.remove();
    }
  });

  const convertToPdf = async () => {
    const element = document.getElementById(`horse-tag_${props.horse.id}`);
    element.style.display = 'block';

    let opt = {
      filename: 'horse-tag.pdf',
      html2canvas: { scale: 1 },
      jsPDF: { format: 'a4', orientation: 'portrait' },
      image: { type: 'jpeg', quality: 1 },
    };

    html2pdf()
      .from(element)
      .set(opt)
      .outputPdf('blob')
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      });

    setTimeout(() => {
      element.style.display = 'none';
    }, 0);
  };
</script>

<style lang="scss">
  .horse-tag-image {
    position: relative;
    left: 250px;
  }
  .horse-tag {
    height: 55vh;
    margin-top: 30px;
    display: none;
  }
  .horse-box {
    min-width: 500px;
    border: 4px solid #000;
    padding: 15px 0;
    .horse-name {
      text-decoration: underline;
      color: #000;
      font-weight: bold;
      font-size: 20px;
    }
    p {
      font-size: 20px;
      color: #000;
      font-weight: bold;
    }
  }
</style>
