import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface Permission {
  title: string;
  resource: string;
}
export interface GroupObject {
  id: number;
  slug: string;
  description: string;
  isActive: boolean;
  permissions: Permission[];
  title: string;
  type: string;
}

export interface GroupListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  groups: Array<GroupObject>;
  errors: unknown;
}

@Module
export default class groupsModule extends VuexModule implements GroupListInfo {
  groups = [] as Array<GroupObject>;
  allGroups = [] as Array<GroupObject>;
  createdGroupId = 0;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get groups list
   * @returns Array<GroupObject>
   */
  get groupsList(): Array<GroupObject> {
    return this.groups;
  }

  get allGroupsList(): Array<GroupObject> {
    return this.allGroups;
  }

  get createdGroupIdValue(): number | null {
    return this.createdGroupId;
  }

  /**
   * Get total items
   * @returns number
   */
  get groupsListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_CREATED_GROUP_ID](groupId) {
    this.createdGroupId = groupId;
  }

  @Mutation
  [Mutations.SET_GROUPS](data) {
    this.groups = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ROLES_GROUPS](data) {
    this.allGroups = data.data;
  }

  @Mutation
  [Mutations.SET_GROUPS_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_GROUPS_LIST]() {
    this.groups = [] as Array<GroupObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_GROUPS_STORE]() {
    this.context.commit(Mutations.RESET_GROUPS_LIST);
  }

  @Action
  [Actions.CREATE_GROUP](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/groups', data)
      .then((data) => {
        const createdGroupId = data.data.data.id;
        this.context.commit(Mutations.RESET_GROUPS_LIST);
        this.context.commit(Mutations.SET_CREATED_GROUP_ID, createdGroupId);
        return createdGroupId;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_GROUP](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/groups', id)
      .then(() => {
        this.context.commit(Mutations.RESET_GROUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_GROUP]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/groups', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_GROUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_GROUP_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_GROUPS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_GROUP](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/groups', id).then(({ data }) => {
      const roles = data.data.roles;
      return roles;
    });
  }

  @Action
  [Actions.GET_GROUP_ID](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/groups', id);
  }

  @Action
  [Actions.GET_ROLES_GROUPS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/groups/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLES_GROUPS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_GROUPS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/groups', { params: { page: this.page } })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_GROUPS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
