
  import { ref, computed, defineComponent, onMounted, watch } from 'vue';
  import { getIllustrationsPath } from '@/core/helpers/assets';
  import { useStore } from 'vuex';
  import router from '@/router';
  import { Actions } from '@/store/enums/StoreEnums';

  export default defineComponent({
    name: 'notifications-menu',
    components: {},
    props: {
      toggleModal: Boolean,
    },
    setup(props) {
      const store = useStore();
      const allSeen = ref(false);

      const notificationCount = computed(() => store.getters.notificationCount);
      const notificationList = computed(() => store.getters.notificationList);

      let hasMoreData = true;
      let isLoading = ref(false);
      let page = 1;

      watch(
        () => props.toggleModal,
        (newValue, oldValue) => {
          hasMoreData = true;
          page = 1;
          isLoading.value = false;
        }
      );

      const handleScroll = async (event) => {
        hasMoreData = true;
        console.log(notificationCount.value, notificationList.value.length);
        if (notificationCount.value <= notificationList.value.length) {
          hasMoreData = false;
          return;
        }
        console.log('scrolling');
        console.log(hasMoreData);
        console.log(isLoading.value);
        const target = event.target;
        const threshold = 100;
        const position = target.scrollTop + target.offsetHeight;
        const height = target.scrollHeight;
        if (position >= height - threshold && hasMoreData && !isLoading.value) {
          isLoading.value = true;
          await store.dispatch(
            Actions.UPDATE_NOTIFICATIONS_CURRENT_PAGE,
            ++page
          );
          await store.dispatch(Actions.GET_NOTIFICATIONS);
          target.scrollTop = target.scrollTop - 100;
          isLoading.value = false;
          // eslint-disable-next-line no-empty
        } else {
        }
      };
      const navigateToDeepLink = async (item) => {
        const payload = {
          id: item.id,
          data: {
            isSeen: true,
          },
        };
        await store.dispatch(Actions.UPDATE_NOTIFICATION, payload);
        if (!item.type || !item.entityId) {
          // show toast message here
        }
        switch (item.type) {
          case 'lesson-cancellation-request':
            window.location.href = `/manage/operations/cancellation/cancellation-listing?requestId=${item.entityId}`;
            break;
          case 'new-lesson-request':
            window.location.href = `/manage/operations/lessons/lessons-pending-listing?requestId=${item.entityId}`;
            break;
          case 'new-message':
            window.location.href = `/manage/operations/tickets/ticket-listing?ticketId=${item.entityId}`;
            break;
          case 'new-ticket':
            window.location.href = `/manage/operations/tickets/ticket-listing?ticketId=${item.entityId}`;
            break;
          default:
            break;
        }
      };
      const markAll = async () => {
        await store.dispatch(Actions.MARK_ALL_AS_READ, { id: '', data: {} });
        await store.dispatch(Actions.GET_NOTIFICATIONS);
        allSeen.value = true;
      };

      return {
        notificationList,
        getIllustrationsPath,
        handleScroll,
        isLoading,
        navigateToDeepLink,
        markAll,
        allSeen,
      };
    },
  });
