import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-051d43b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      disabled: $props.disabledRange,
      class: "btn text-primary fs-1",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.prevWeek && $setup.prevWeek(...args)))
    }, " < ", 8, _hoisted_1),
    _createElementVNode("span", null, _toDisplayString($setup.startDate) + " - " + _toDisplayString($setup.endDate), 1),
    _createElementVNode("button", {
      disabled: $props.disabledRange,
      class: "btn text-primary fs-1",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.nextWeek && $setup.nextWeek(...args)))
    }, " > ", 8, _hoisted_2)
  ]))
}