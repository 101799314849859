
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import printJs from 'print-js';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { injectSearch } from '@/core/plugins/search-service';
  import SearchField from '@/components/common/SearchField.vue';
  import { loadImage } from '../../../utils/loadImage';
  import moment from 'moment';

  export default defineComponent({
    name: 'Treatment Overview',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const loadingPage = ref(true);
      const searchService = injectSearch();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const reportData = ref();
      const selectedHorse = ref<number>(0);
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('Treatment Overview'), [
          translate('reports'),
        ]);
        await store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        const getSaveData = searchService.getSearchValue(
          'TreatmentOverviewReport'
        );
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(
                searchService.getSearchValue('TreatmentOverviewReport')
              )
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.horseId) selectedHorse.value = dataSaved.horseId;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });

      const clearSearch = () => {
        searchService.removeSearchValue('TreatmentOverviewReport');
        selectedHorse.value = 0;
      };

      const print = async (values) => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
        const user = getUser();
        const userName = user ? user['name'] : '';

        await store.dispatch(Actions.GET_LESSONS, values);
        const tretmantData = await store.dispatch(
          Actions.GET_HORSES_TREATMENT,
          values.horseId === 0 ? '' : values.horseId
        );

        reportData.value = tretmantData.data?.map((treatment) => {
          return {
            ...treatment,
            treatmentDate: treatment.treatmentDate.slice(0, 10),
          };
        });

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'portrait',
        });
        doc.setFontSize(10);

        doc.text('Print DateTime: ' + moment(new Date()).format('LLL'), 50, 12);

        doc.addImage('/media/logos/krc.jpg', 'jpg', 1, 9, 40, 40);

        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;

        doc.text('Kuwait Riding Center', textX, 25);
        doc.text('Treatment Overview', textX + 10, 40);
        debugger;
        let pageCount = 0;
        const resTabelBody: any[] = [];
        reportData.value.forEach(function (element, index) {
          resTabelBody.push([
            index + 1,
            element.horse?.nameEn,
            element.treatment?.nameEn,
            element.treatmentDate,
          ]);
        });
        autoTable(doc, {
          head: [['Sr', 'Horse Name', 'Treatment Name', 'Treatment Date']],
          body: resTabelBody,
          showHead: 'firstPage', // Only show the header on the first page of the nested table
          startY: 55,
          horizontalPageBreak: false,
          theme: 'grid',
          headStyles: {
            fillColor: [0, 72, 115],
            fontStyle: 'bold',
            halign: 'left',
            fontSize: 9,
          },
          bodyStyles: {
            fontStyle: 'bold',
            halign: 'left',
            fontSize: 9,
          },
          styles: {
            fontSize: 10,
          },
          didDrawPage: function () {
            pageCount++;
            doc.text(
              'Page ' + pageCount,
              textX,
              doc.internal.pageSize.height - 10
            );
          },
        });
        Array.from({ length: pageCount }, (x, i) => {
          doc.setPage(i + 1);
          doc.text(userName, 5, doc.internal.pageSize.height - 10);
          doc.setFontSize(10);
          doc.text(
            'Start Date: ' + moment(values.startDate).format('LL'),
            60,
            50
          );
          doc.text('End Date: ' + moment(values.endDate).format('LL'), 105, 50);

          doc.text(
            '     of ' + doc.getNumberOfPages(),
            textX + 15,
            doc.internal.pageSize.height - 10
          );
        });

        // doc.save('table.pdf');
        // doc.autoPrint();
        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
        window.open(blobUrl, '_blank');

        //printJs({ printable: blobUrl, type: 'pdf' });
      };

      const validationSchema = Yup.object().shape({});
      // const onSubmitCreate = async (values) => {
      //   debugger;
      //   if (submitButton.value) {
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = true;
      //     submitButton.value.setAttribute('data-kt-indicator', 'on');
      //   }
      //   values.duration = Number(values.duration);
      //   values.numberOfRider = Number(values.numberOfRider);

      //   const { data } = await store.dispatch(
      //     Actions.GET_CUSTOMER_ATTENDANCE,
      //     values
      //   );
      //   const [errorName] = Object.keys(store.getters.getErrors);
      //   const error = store.getters.getErrors[errorName];

      //   if (error) {
      //     Swal.fire({
      //       text: translate(error[0]),
      //       icon: 'error',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('tryAgainExcl'),
      //       customClass: {
      //         confirmButton: 'btn fw-bold btn-light-danger',
      //       },
      //     });
      //     //Deactivate indicator
      //     submitButton.value?.removeAttribute('data-kt-indicator');
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = false;
      //   } else {
      //     Swal.fire({
      //       text: translate('SUCCESSFULLY_CREATED_CLASS_CATEGORY'),
      //       icon: 'success',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('ok'),
      //       customClass: {
      //         confirmButton: 'btn btn-light-primary',
      //       },
      //     }).then(() => {
      //       router.push({ name: 'class-category-listing' });
      //     });
      //   }
      // };

      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        reportData,
        horses: computed(() => store.getters.allHorsesList),
        groomers: computed(() => store.getters.allGroomerList),
        selectedHorse,
        loadingPage,
        clearSearch,
      };
    },
  });
