import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-start mb-10" }
const _hoisted_2 = { class: "d-flex flex-column align-items-start" }
const _hoisted_3 = { class: "d-flex align-items-center mb-2" }
const _hoisted_4 = {
  key: 0,
  class: "symbol symbol-35px symbol-circle"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "ms-3" }
const _hoisted_7 = {
  href: "#",
  class: "fs-5 fw-bolder text-gray-900 text-hover-primary me-1"
}
const _hoisted_8 = { class: "text-muted fs-7 mb-1" }
const _hoisted_9 = {
  class: "p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start",
  "data-kt-element": "message-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.image)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("img", {
                alt: "Pic",
                src: _ctx.image
              }, null, 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.name), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.time), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}