
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import Multiselect from '@vueform/multiselect';
  import AttachmentV2 from '@/components/common/AttachmentV2.vue';
  import Attachment from '@/components/common/Attachments.vue';

  export default defineComponent({
    name: 'product-creating',
    components: {
      AttachmentV2,
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const imageFile = ref<File | null>(null);
      const imagePreview = ref<string | null>(null);
      const selectedShop = ref(0);
      const selectedCategory = ref([]);
      const serviceDiscount = ref(0);
      const serviceAmount = ref();
      const productId = ref(route.params.id);
      const attachementKey = ref(1);
      const KWDiscount = ref(0);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('Product Editing'), [
          translate('operations'),
        ]);
      });
      const discountPercentage = computed(() => {
        return (KWDiscount.value / serviceAmount.value) * 100;
      });

      watch(
        serviceDiscount,
        (newVal) => {
          KWDiscount.value = (newVal / 100) * serviceAmount.value;
        },
        { immediate: true }
      );

      watch(
        KWDiscount,
        (newVal) => {
          serviceDiscount.value = (newVal / serviceAmount.value) * 100;
        },
        { immediate: true }
      );

      const { data } = await store.dispatch(
        Actions.GET_PRODUCT,
        route.params.id
      );

      const product = ref(data.data);
      serviceAmount.value = product.value.price;
      serviceDiscount.value = product.value.discount;
      const status = ref(product.value.status);
      const thumbnail = ref(
        product.value.attachments.filter((item) => item.isThumbnail)
      );

      const productImages = ref(
        product.value.attachments.filter((item) => !item.isThumbnail)
      );
      const categories = ref(
        product.value.productCategories.map((item) => item.categoryId)
      );
      console.log(categories.value);

      const removeImage = () => {
        imageFile.value = null;
        imagePreview.value = null;
      };

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const validationSchema = Yup.object().shape({
        nameEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('nameEn'),
        nameAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('nameAr'),
        descriptionEn: Yup.string()
          .required(() => translate('EN_CONTENT_IS_REQUIRED_FIELD'))
          .label('descriptionEn'),
        descriptionAr: Yup.string()
          .required(() => translate('AR_CONTENT_IS_REQUIRED_FIELD'))
          .label('descriptionAr'),
        shopId: Yup.string()
          .notOneOf(['0'], () => translate('Shop Is Required field'))
          .label('shop'),
        categories: Yup.array()
          .required(() => translate('Category  Is Required field'))
          .min(1, () => translate('Category  Is Required field')),
        price: Yup.number()
          .required(() => translate('Price Is Required field'))
          .min(0, 'Price must be greater than or equal to 0')
          .label('Price'),
        discount: Yup.number()
          .transform((value, originalValue) => {
            if (originalValue === '' || originalValue === null) {
              return null;
            }
            return value;
          })
          .min(0, 'Discount must be greater than or equal to 0')
          .max(100, 'Discount cannot exceed 100')
          .nullable(),
      });

      const shopList = await store.dispatch(Actions.GET_ALL_SHOP);
      const categoryList = await store.dispatch(Actions.GET_ALL_CATEGORY);
      console.log(categoryList);

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          submitButton.value.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        delete values.KWDiscount;
        values.status = status.value;
        values.price = String(values.price);

        await store.dispatch(Actions.UPDATE_PRODUCT, {
          data: values,
          id: product.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (data && data.data) {
          productId.value = data.data.id;
        }

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          submitButton.value?.removeAttribute('data-kt-indicator');
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('Successfully update Product'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }

        submitButton.value?.removeAttribute('data-kt-indicator');
        submitButton.value!.disabled = false;
      };
      const onSubmitCreateThubmnail = async (values, cb) => {
        values.append('isThumbnail', 'true');

        onSubmitCreateAttachment(values, cb);
      };
      const onSubmitCreateAttachment = async (values, cb) => {
        await store.dispatch(Actions.UPLOAD_PRODUCT_ATTACHMENT, {
          data: values,
          id: productId.value,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ATTACHMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(async () => {
            const { data } = await store.dispatch(
              Actions.GET_PRODUCT,
              route.params.id
            );

            product.value = data.data;
            thumbnail.value = product.value.attachments.filter(
              (item) => item.isThumbnail
            );

            productImages.value = product.value.attachments.filter(
              (item) => !item.isThumbnail
            );
            attachementKey.value += 1;
          });
        }
        cb();
      };
      const deleteAttachment = (id, cb) => {
        // show swal alert
        Swal.fire({
          title: translate('Are you sure?'),
          text: translate("You won't be able to revert this!"),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            store
              .dispatch(Actions.DELETE_PRODUCT_ATTACHMENT, id)
              .then(async () => {
                cb();
                Swal.fire(
                  translate('Deleted!'),
                  translate('Your file has been deleted.'),
                  'success'
                );
              })
              .catch((error) => {
                Swal.fire(
                  translate('Error!'),
                  translate('There was an error deleting the file.'),
                  'error'
                );
              });
          }
        });
      };

      const calculatedPayment = computed(() => {
        const paymentAfterDiscount =
          serviceAmount.value -
          (serviceAmount.value * serviceDiscount.value) / 100;

        return paymentAfterDiscount;
      });

      return {
        onSubmitCreate,
        onSubmitCreateAttachment,
        onSubmitCreateThubmnail,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        imagePreview,
        removeImage,
        shopList,
        categoryList,
        selectedShop,
        selectedCategory,
        calculatedPayment,
        serviceDiscount,
        serviceAmount,
        status,
        productId,
        product,
        thumbnail,
        deleteAttachment,
        productImages,
        categories,
        attachementKey,
        KWDiscount,
      };
    },
  });
