import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import LookupNameIds from '@/store/enums/settingsEnums';

export interface TrainerObject {
  id: string;
  name: string;
  email: string;
  phone: string;
  nationalityId: string;
  genderId: string;
  statusId: string;
  classCategoryId: string;
  classLimitPerDay: number;
  civilId: string;
  passport: string;
  address: string;
  residencyNumber: string;
  dateOfBirth: string;
}

export interface TrainersListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  trainers: Array<TrainerObject>;
  errors: unknown;
}

export interface trainersFilterInfo {
  name: string;
}

@Module
export default class TrainersModule
  extends VuexModule
  implements TrainersListInfo
{
  trainers = [] as Array<TrainerObject>;
  allTrainers = [] as Array<TrainerObject>;
  trainersFilter = {} as trainersFilterInfo;
  exportTrainers = [] as Array<TrainerObject>;
  coostMatrix = [];
  attachments = [];
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get trainers list
   * @returns Array<TrainerObject>
   */
  get trainersList(): Array<TrainerObject> {
    return this.trainers;
  }

  get allTrainersList(): Array<TrainerObject> {
    return this.allTrainers;
  }

  get coostMatrixList(): Array<TrainerObject> {
    return this.coostMatrix;
  }

  get exportTrainersList(): Array<TrainerObject> {
    return this.exportTrainers;
  }

  /**
   * Get total items
   * @returns number
   */
  get trainersListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_TRAINERS](data) {
    this.trainers = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_TRAINERS](data) {
    this.allTrainers = data.data;
  }

  @Mutation
  [Mutations.SET_COOST_MATRIX](data) {
    this.coostMatrix = data.data;
  }

  @Mutation
  [Mutations.SET_TRAINER_ATTACHMENTS](data) {
    this.attachments = data.data;
  }

  @Mutation
  [Mutations.SET_TRAINER_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_TRAINERS_LIST]() {
    this.trainers = [] as Array<TrainerObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
    this.trainersFilter = {} as trainersFilterInfo;
  }

  @Mutation
  [Mutations.SET_TRAINERS_FILTER](obj) {
    this.trainersFilter = obj;
  }

  @Mutation
  [Mutations.SET_EXPORT_TRAINERS](data) {
    this.exportTrainers = data.data;
  }

  @Action
  [Actions.RESET_TRAINERS_STORE]() {
    this.context.commit(Mutations.RESET_TRAINERS_LIST);
  }

  @Action
  [Actions.UPDATE_TRAINERS_FILTER](val) {
    this.context.commit(Mutations.SET_TRAINERS_FILTER, val);
  }

  @Action
  [Actions.CREATE_TRAINER](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/trainer', data)
      .then((data) => {
        this.context.commit(Mutations.RESET_TRAINERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_TRAINER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/trainer', id)
      .then(() => {
        this.context.commit(Mutations.RESET_TRAINERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_TRAINER]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/trainer', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_TRAINERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_TRAINERS_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_TRAINER_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_TRAINER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/trainer', id);
  }

  @Action
  [Actions.GET_TRAINERS]() {
    const params = {
      page: this.page,
      ...this.trainersFilter,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/trainer', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.dateOfBirth = new Date(r.dateOfBirth)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });

        this.context.commit(Mutations.SET_TRAINERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  async [Actions.GET_ALL_TRAINERS]({
    categrgoyId = undefined,
    active = false,
  }) {
    ApiService.setAuthorizationHeader();
    const params = {
      classCategoryId: categrgoyId,
      active: !active,
      ...this.trainersFilter,
    };
    return ApiService.query(`/trainer/all`, { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_TRAINERS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_COOST_MATRIX](trainerId) {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/trainer/coost-matrix/${trainerId}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COOST_MATRIX, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
  @Action
  [Actions.GET_COST_MATRIX_V2](classCategoryId) {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/settings/cost-matrix/${classCategoryId}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COOST_MATRIX, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.UPDATE_COST_MATRIX_V2]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/class-category', id, data)
      .then(() => {
        return true;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.CREATE_TRAINER_ATTACHMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    console.log(data);
    return ApiService.post(`/trainer/attachments/${id}`, data)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TRAINER_ATTACHMENTS, data);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.EXPORT_TRAINERS]() {
    const params = {
      page: this.page,
      ...this.trainersFilter,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/trainer/all`, { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EXPORT_TRAINERS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.CHANGE_TRAINER_PASSWORD](credentials) {
    ApiService.setAuthorizationHeader();
    return ApiService.put(`/trainer/change-password`, credentials)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_TRAINER_IMAGE](data) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    console.log(data, 'value');
    return ApiService.post(`/trainer/profile-picture/${data.id}`, data.data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
  @Action
  [Actions.DELETE_TRAINER_ATTACHMENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/trainer/attachments', id)
      .then(() => {
        this.context.commit(Mutations.RESET_TRAINERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
}
