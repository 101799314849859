import { createApp } from 'vue';
import App from './App.vue';

/*
TIP: To get started with clean router change path to @/router/clean.ts
 */
import router from '@/router';
import store from '@/store';
import ElementPlus from 'element-plus';
import i18n from '@/core/plugins/i18n';

//imports for app initialization
import ApiService from '@/core/services/ApiService';
import { initInlineSvg } from '@/core/plugins/inline-svg';
import { initVeeValidate } from '@/core/plugins/vee-validate';
import { abilitiesPlugin } from '@casl/vue';
import AbilityService from '@/core/services/AbilityService';
import vue3JsonExcel from 'vue3-json-excel';
import VueTelInput from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import DatePicker from '@/components/common/DatePicker.vue';

const VueTelInputOptions = {
  mode: 'international',
};

import 'bootstrap';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(abilitiesPlugin, AbilityService.ability);
app.use(vue3JsonExcel);
app.component('DatePicker', DatePicker);

ApiService.init(app);
initInlineSvg(app);
initVeeValidate();
app.use(i18n);
app.use(VueTelInput, VueTelInputOptions); // Define default global options here (optional)

app.mount('#app');
